import React, { Component } from "react";
import "@scuf/common/honeywell-compact/theme.css";
import "@scuf/datatable/honeywell-compact/theme.css";


import { Footer } from "@scuf/common";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import DataMain from "./components/DataMaintenanceForm";
import NavBar from "./components/SideBar"
const config = require('./config');
//const host_url=process.env.host_url;
//const host_url = "http://localhost:3000";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      body: {} // this is the body from /user
    };
  }
  componentDidMount() {
    fetch(`${config.host_url}/user`, {
      credentials: 'include' // fetch won't send cookies unless you set credentials
    })
      .then(response => response.json())
      .then(response => {
        this.setState(
          {
            body: response
          })
      }
      );
  }


  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <NavBar>
            <DataMain></DataMain>
          </NavBar>
        </BrowserRouter>
        <div style={{ position:"fixed", bottom:0, display:"block", width:"100%"}}>
        <Footer></Footer>
        </div>
        </div >
    );
  }
}

export default App;
