import React from "react";
import * as XLSX from "xlsx";
import { Grid, DatePicker, Button, TextArea, Select, Input, Search, Radio, Loader } from "@scuf/common";
import { DataTable } from "@scuf/datatable";
import template from '../resources/nrs_template.xlsx';
import {Redirect} from 'react-router-dom';

const config = require('../config');
//const host_url=process.env.host_url;
//const host_url = "http://localhost:3000";
export default class Comp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tologin:false,
            value: '',
            results: [],
            selectedItem: [],
            currentData: [],
            ssrOptions: [],

            gbeOptions: [],
            gbeSelected: [],
            loading: true,
            inputPrice: "",
            inputSpendPrice: "",
            currencySelected: "",
            currencyOptions: [],
            dateSelected: new Date(),
            //Site Variables
            searchSiteId: "",
            searchSiteName: "",
            resultsSiteName: [],
            resultsSiteId: [],
            resultsId: [],
            sitesNames: [],
            siteIds: [],
            err_siteid: "",

            //new suploc
            supplierLocNum1: [],


            //Supplier(loc) Variables
            supplierLocNum: [],
            supplierLocName: [],
            searchSupLocId: "",
            searchSupLocName: "",
            resultsSupLocId: [],
            resultsSupLocName: [],
            err_suplocid: "",
            //Supplier Variables
            supplierNum: [],
            supplierName: [],
            searchSupId: "",
            searchSupName: "",
            resultsSupId: [],
            resultsSupName: [],
            err_supid: "",
            //Sub Commodity Variables
            supplierComNum: [],
            supplierComName: [],
            searchSupComId: "",
            searchSupComName: "",
            resultsSupComId: [],
            resultsSupComName: [],
            err_supcomid: "",
            //Part Variables
            partId: [],
            partName: [],
            searchPartId: "",
            searchPartName: "",
            resultsPartId: [],
            resultsPartName: [],
            err_partid: "",
            comments: "",
            rdbvalue: "",
            recieptNum: [],
            err_comment: "",
            err_currency: "",
            err_spend_save_reason: "",
            err_gbe_options: "",
            err_date: "",
            err_inputspendprice: "", 
            err_inputprice: "",
            selectedfile: "",
            flg_option: "",
            err_rdbvalue: "",
            enableOnSearch: true,
            addDisabled: true,
            updateDisabled: true,
            fieldDisabled: false,
            submitDisabled: true,
            UIDdata: ""
        };
        this.resetComponent = this.resetComponent.bind(this);
        this.handleSearchSelect = this.handleSearchSelect.bind(this);
        this.handleAddSelect = this.handleAddSelect.bind(this);
        this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    }

    resetComponent = () => {
        this.setState({
            currentData: [],
            resultsSiteName: [],
            resultsSiteId: [],
            resultsId: [],
            resultsSupLocId: [],
            resultsSupLocName: [],
            resultsSupId: [],
            resultsSupName: [],
            searchSiteId: "",
            searchSiteName: "",
            searchSupComId: "",
            searchSupComName: "",
            searchSupLocId: "",
            searchSupLocName: "",
            searchSupName: "",
            searchSupId: "",
            searchPartId: "",
            searchPartName: "",
            resultsPartId: [],
            resultsPartName: [],
            resultsSupComId: [],
            resultsSupComName: [],
            selectedItem: [],
            comments: "",
            inputSpendPrice: "",
            rdbvalue: "",
            recieptNum: [],
            err_comment: "",
            err_currency: "",
            err_spend_save_reason: "",
            err_gbe_options: "",
            err_date: "",
            err_inputspendprice: "",
            err_inputprice: "",
            selectedfile: "",
            flg_option: "",
            err_rdbvalue: "",
            enableOnSearch: true,
            addDisabled: true,
            updateDisabled: true,
            fieldDisabled: false,
            submitDisabled: true,
            dateSelected: new Date(),
            spendReasonSelected: "",
            currencySelected: "",
            gbeSelected: "",
            filePathset: "",
        });
    }
    //-----------------------------Validate Numbers--------------------------
    validateNumber = (num) => {
        var isValidNum = false;
        if (/^-?\d+(\.\d{1,2})?$/.test(num)) { isValidNum = true; }
        return isValidNum;
    }
    validateSavingNumber = (num) => {
        var isValidNum = false;
        if (/^-?\d+(\.\d{1,2})?$/.test(num)) { isValidNum = true; }
        return isValidNum;
    }
    //------------------------------Date Functions---------------------------
    datetolabel = (date) => {
        const dt_year = date.slice(0, 4);
        const dt_month = date.slice(4, 6);
        const dt_day = date.slice(6, 8);
        const dt_daymon = dt_month.concat("/", dt_day);
        const dt_ndate = dt_daymon.concat("/", dt_year);
        return dt_ndate;
    }
    dateFormat = (date) => {
        console.log(date,"11");
        const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
        const dt_month = date.slice(4, 7);
        const dt_year = date.slice(11, 15);
        const dt_day = date.slice(8, 10);
        const month = months[dt_month];
        const dt_yearmon = dt_year.concat(month);
        const dt_ndate = dt_yearmon.concat(dt_day);
        // const dt_yearmon = month.concat(dt_year);
        // const dt_ndate = dt_day.concat(dt_yearmon);

        console.log(month,"month");
        console.log(dt_ndate,"1ndate");
        return dt_ndate;
    }
    dateFormatNew = (date) => {
        console.log(date,"12");
        

        const month = date.slice(0, 2);
        const day = "01";
        const year = date.slice(6, 10);
        const yearmon = year.concat("", month);
        const newdate = yearmon.concat("", day);
        return newdate;
    }
    validDate = (num) => {
        console.log(num,"num");
        let cal_date = (new Date((num - (25569)) * 86400 * 1000)).toString();
        const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
        const dt_month = cal_date.slice(4, 7);
        const dt_year = cal_date.slice(11, 15);
        const dt_day = cal_date.slice(8, 10);
        const month = months[dt_month];
        const dt_yearmon = dt_year.concat(month);
        const dt_ndate = dt_yearmon.concat(dt_day);
        console.log(dt_ndate,"dt_ndate");
        return dt_ndate;
    }

    //---------------------------To Title Functions--------------------------
    //Spend saving reason options
    transSSR = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //gbe
    transGbe = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.GBE_CODE_NAME;
            newObj.value = oldObj.GBE_CODE_NAME;

            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Get Max Receipt_num
    setRecieptNum = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.RECIEPT_NUM;
            newObj.value = oldObj.RECIEPT_NUM;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Currency code options
    transCurrToValue = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.SOURCE_CURRENCY;
            newObj.value = oldObj.SOURCE_CURRENCY;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //site ID
    siteIdToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.sitetitle = oldObj.PLANT_NAME;
            newObj.siteid = oldObj.PLANT_SK;
            newObj.title = oldObj.PLANT_SK;
            newObjects.push(newObj);
        }
        //alert(newObjects);
        return newObjects;
    }
    //site Name
    siteNameToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.siteid = oldObj.PLANT_SK;
            newObj.sitetitle = oldObj.PLANT_NAME;
            newObj.title = oldObj.PLANT_NAME;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //new
    supLocIdToTitle1 = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.siteid = oldObj.OS_SITE_ID;
            newObj.title = oldObj.VENDOR_SK;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    //Supplier(loc)ID
    supLocIdToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.siteid = oldObj.OS_SITE_ID;
            newObj.title = oldObj.VENDOR_SK;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Supplier(loc) Name
    supLocNameToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.siteid = oldObj.OS_SITE_ID;
            newObj.title = oldObj.VENDOR_NAME;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //SupplierID
    transSupplierIdToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.siteid = oldObj.PLANT_SK;
            newObj.title = oldObj.VENDOR_DUNS_NUMBER;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Supplier Name
    transSupplierNameToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.siteid = oldObj.OS_SITE_ID;
            newObj.title = oldObj.VENDOR_FULL_NM;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //SupplierComID
    transSupplierComIdToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.siteid = oldObj.OS_SITE_ID;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.title = oldObj.SUB_COMMODITY_OWNER_SK;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //SupplierCom Name
    transSupplierComNameToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.suptitle = oldObj.VENDOR_FULL_NM;
            newObj.suploctitle = oldObj.VENDOR_NAME;
            newObj.supid = oldObj.VENDOR_DUNS_NUMBER;
            newObj.suplocid = oldObj.VENDOR_SK;
            newObj.siteid = oldObj.OS_SITE_ID;
            newObj.titlesupcom = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.title = oldObj.SUB_COMMODITY_OWNER_NAME;
            newObj.idsupcom = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Part ID
    transPartIdToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.partid = oldObj.MATERIAL_NUM_SK;
            newObj.titlepart = oldObj.MATERIAL_NAME;
            newObj.title = oldObj.MATERIAL_NUM_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Part Desc
    transPartNameToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.titlepart = oldObj.MATERIAL_NAME;
            newObj.partid = oldObj.MATERIAL_NUM_SK;
            newObj.title = oldObj.MATERIAL_NAME;
            newObjects.push(newObj);
        }
        return newObjects;
    }



    

    //---------------------------Mount------------------------------------
    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/site`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    sitesNames: this.siteNameToTitle(data),
                    siteIds: this.siteIdToTitle(data),
                });
                //Spend Saving Reason
                fetch(`${config.host_url}/api/nrdm/spendsavingreason`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            ssrOptions: this.transSSR(data)
                        });
                        //GBE fields
                        fetch(`${config.host_url}/api/nrdm/gbe`)
                            .then((res) => res.json())
                            .then((data) => {
                                this.setState({
                                    gbeOptions: this.transGbe(data)
                                });

                                //Currency
                                fetch(`${config.host_url}/api/currency`)
                                    .then((res) => res.json())
                                    .then((data) => {
                                        this.setState({
                                            currencyOptions: this.transCurrToValue(data)
                                        });
                                        //Get Max Receipt Num
                                        fetch(`${config.host_url}/api/nrdm/getrecieptnum`)
                                            .then((res) => res.json())
                                            .then((data) => {
                                                this.setState({
                                                    recieptNum: this.setRecieptNum(data),
                                                });
                                                //Part Details
                                                fetch(`${config.host_url}/api/nrdm/part`)
                                                    .then((res) => res.json())
                                                    .then((data) => {
                                                        this.setState({
                                                            partId: this.transPartIdToTitle(data),
                                                            partName: this.transPartNameToTitle(data),
                                                        });
                                                        //call user details
                                                        fetch(`${config.host_url}/user`, {
                                                            credentials: 'include' // fetch won't send cookies unless you set credentials
                                                        })
                                                            .then(response => response.json())
                                                            .then(response => {
                                                                this.setState(
                                                                    {
                                                                        //body: response,
                                                                        loading: false,
                                                                        UIDData: response.sub.toUpperCase()
                                                                    })
                                                            })
                                                            .catch((err) => {
                                                                
                                                                alert(`Connection Issue with User Data. Please try again`);
                                                                this.setState({
                                                                    loading: false,
                                                                    //  tologin:true,
                                                                });
                                                               window.location.href = `${config.host_url}/login`;

                                                               
                                                              
                                                            });
                                                    })
                                                    .catch((err) => {
                                                        alert(`Connection Issue. Cant get Part number. Please try again`);
                                                        this.setState({
                                                            loading: false,
                                                        });
                                                    });
                                            })
                                            .catch((err) => {
                                                alert(`Connection Issue. Can't get Recpt Num. Please try again`);
                                                this.setState({
                                                    loading: false,
                                                });
                                            });
                                    })
                                    .catch((err) => {
                                        alert(`Connection Issue. Can't load currency options. Please try again`);
                                        this.setState({
                                            loading: false,
                                        });
                                    });

                            })
                            .catch((err) => {
                                alert(`Connection Issue. Can't load GBE  values. Please try again`);
                                this.setState({
                                    loading: false,
                                });
                            });
                    })
                    .catch((err) => {
                        alert(`Connection Issue. Can't load Spend/Saving Reason. Please try again`);
                        this.setState({
                            loading: false,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                alert(`Connection Issue. Can't load Site data. Please try again`);
            });
    }
    // --------------------Handler Functions------------------------------

    //Handler Functions for Site
    handleSearchChangeSites = (searchText) => {
        this.setState({
            resultsSiteName: this.state.sitesNames.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchChangeId = (searchText) => {
        this.setState({
            resultsSiteId: this.state.siteIds.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText,
            resultsId: this.state.siteIds
        });
    }
    handleSearchSiteSelect = (result) => {
        this.setState({
            loading: true,
            searchSiteName: result.sitetitle,
            searchSiteId: result.siteid,
        });

        fetch(`${config.host_url}/api/nrdm_supplier/${result.siteid}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    supplierLocNum: this.supLocIdToTitle(data),
                    supplierLocName: this.supLocNameToTitle(data),
                    supplierNum: this.transSupplierIdToTitle(data),
                    supplierName: this.transSupplierNameToTitle(data),
                    supplierComNum: this.transSupplierComIdToTitle(data),
                    supplierComName: this.transSupplierComNameToTitle(data),
                    loading: false,
                });
                
            })
            .catch((err) => {
                alert(`Vendor numbers not found. Please try again.`);
            });
    }
    handleSearchIdSelect = (result) => {
        this.setState({
            loading: true,
            searchSiteId: result.siteid,
            searchSiteName: result.sitetitle,
        });
        fetch(`${config.host_url}/api/nrdm_supplier/${result.siteid}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    supplierLocNum: this.supLocIdToTitle(data),
                    supplierLocName: this.supLocNameToTitle(data),
                    supplierNum: this.transSupplierIdToTitle(data),
                    supplierName: this.transSupplierNameToTitle(data),
                    supplierComNum: this.transSupplierComIdToTitle(data),
                    supplierComName: this.transSupplierComNameToTitle(data),
                });
                //Part Details
                fetch(`${config.host_url}/api/nrdm/part/${this.state.searchSiteId}`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            partId: this.transPartIdToTitle(data),
                            partName: this.transPartNameToTitle(data),
                            loading: false,
                        });
                    })
                    .catch((err) => {
                        this.setState({ loading: false });
                        alert(`Connection Issue. Cant get Part number. Please try again`);
                    });
            })
            .catch((err) => {
                this.setState({ loading: false });
                alert(`Vendor numbers not found. Please try again.`);
            });
    }

    //Handler for Site(local) Supplier

    validateVendor=(searchText)=>{
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else{
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/nrdm_supplier/vendor/${searchText}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        supplierLocNum1: data,
                        // supplierLocName: this.supLocNameToTitle(data),
                        // supplierNum: this.transSupplierIdToTitle(data),
                        // supplierName: this.transSupplierNameToTitle(data),
                        // supplierComNum: this.transSupplierComIdToTitle(data),
                        // supplierComName: this.transSupplierComNameToTitle(data),
                        
                            // searchSupLocId: searchText.title,
                            // searchSupLocName: searchText.suploctitle,
                            // searchSupId: searchText.supid,
                            // searchSupName: searchText.suptitle,
                            // searchSupComName: searchText.titlesupcom,
                            // searchSupComId: searchText.idsupcom
                        
                    });
                    if (this.state.supplierLocNum1.length > 0) {
                        this.setState({
                            searchSupLocId: searchText,
                            searchSupLocName: this.state.supplierLocNum1[0].VENDOR_NAME,
                             searchSupId: this.state.supplierLocNum1[0].VENDOR_DUNS_NUMBER,
                             searchSupName: this.state.supplierLocNum1[0].VENDOR_FULL_NM,
                             searchSupComName: this.state.supplierLocNum1[0].SUB_COMMODITY_OWNER_NAME,
                             searchSupComId: this.state.supplierLocNum1[0].SUB_COMMODITY_OWNER_SK,
                        });
                    }
                    else {
                        alert(`Vendor ID not found`);
                        this.setState({ loading: false, disableSearch: true });

                    }

                    //Part Details
                    fetch(`${config.host_url}/api/nrdm/part/${this.state.searchSiteId}`)
                        .then((res) => res.json())
                        .then((data) => {
                            this.setState({
                                partId: this.transPartIdToTitle(data),
                                partName: this.transPartNameToTitle(data),
                                loading: false,
                            });
                        })
                        .catch((err) => {
                            this.setState({ loading: false });
                            alert(`Connection Issue. Cant get Part number. Please try again`);
                        });
                }) .catch((err) => {
                    this.setState({ loading: false });
                    alert(`Connection Issue. Cant get ven number. Please try again`);
                });
                
        }
        
    }
    handleSearchChangeSupplierLocId = (searchText) => {
        this.setState({
            resultsSupLocId: this.state.supplierLocNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchChangeSupplierLocName = (searchText) => {
        this.setState({
            resultsSupLocName: this.state.supplierLocName.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchSupplierLocIdSelect = (result) => {
        this.setState({
            searchSupLocId: result.title,
            searchSupLocName: result.suploctitle,
            searchSupId: result.supid,
            searchSupName: result.suptitle,
            searchSupComName: result.titlesupcom,
            searchSupComId: result.idsupcom
        });
    }
    handleSearchSupplierLocNameSelect = (result) => {
        this.setState({
            searchSupLocName: result.suploctitle,
            searchSupLocId: result.suplocid,
            searchSupName: result.suptitle,
            searchSupId: result.supid,
            searchSupComName: result.titlesupcom,
            searchSupComId: result.idsupcom
        });
    }

    //Handler functions for Supplier ID and Name
    handleSearchChangeSupplierId = (searchText) => {
        this.setState({
            resultsSupId: this.state.supplierNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchChangeSupplierName = (searchText) => {
        this.setState({
            resultsSupName: this.state.supplierName.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchSupplierIdSelect = (result) => {
        this.setState({
            searchSupLocId: result.suplocid,
            searchSupLocName: result.suploctitle,
            searchSupId: result.title,
            searchSupName: result.suptitle,
            searchSupComName: result.titlesupcom,
            searchSupComId: result.idsupcom
        });
    }
    handleSearchSupplierNameSelect = (result) => {
        this.setState({
            searchSupLocId: result.suplocid,
            searchSupLocName: result.suploctitle,
            searchSupId: result.supid,
            searchSupName: result.title,
            searchSupComName: result.titlesupcom,
            searchSupComId: result.idsupcom
        });
    }

    //Handler functions for Supplier Sub commodity
    handleSearchChangeSupplierComId = (searchText) => {
        this.setState({
            resultsSupComId: this.state.supplierComNum.filter(item => item.idsupcom.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchChangeSupplierComName = (searchText) => {
        this.setState({
            resultsSupComName: this.state.supplierComName.filter(item => item.titlesupcom.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchSupplierComIdSelect = (result) => {
        this.setState({
            searchSupComId: result.idsupcom,
            searchSupComName: result.titlesupcom,
        });
    }
    handleSearchSupplierComNameSelect = (result) => {
        this.setState({
            searchSupComName: result.titlesupcom,
            searchSupComId: result.idsupcom,
        });
    }

    //Handler Functions for Part
    handleSearchChangePartId = (searchText) => {
        if (searchText.length >= 3) {
            this.setState({
                resultsPartId: this.state.partId.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
                searchText
            });
        }
        else {
            this.setState({ newVar: searchText });
        }
    }
    handleSearchChangePartName = (searchText) => {
        if (searchText.length >= 3) {
            this.setState({
                resultsPartName: this.state.partName.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
                searchText
            });
        }
        else {
            this.setState({ newVar: searchText });
        }
    }
    handleSearchPartIdSelect = (result) => {
        this.setState({
            searchPartId: result.partid,
            searchPartName: result.titlepart,
        });
    }
    handleSearchPartNameSelect = (result) => {
        this.setState({
            searchPartName: result.titlepart,
            searchPartId: result.partid,
        });
    }
    //Search button Handler
    handleSearchSelect = () => {
        this.setState({
            err_siteid: "",
            err_suplocid: "",
            inputPrice: "",
            inputSpendPrice: "",
            currencySelected: "",
            gbeSelected: "",
            spendReasonSelected: "",
            comments: "",
            rdbvalue: "",
            dateSelected: new Date(),
            searchPartId: "",
            searchPartName: "",
            updateDisabled: true
        });
        if (this.state.searchSiteId === '' && this.state.searchSupId === '' && this.state.searchSupId === '') {
            alert("Please enter mandatory Fields");
            this.setState({ err_siteid: " ", err_suplocid: " ", err_supid: " " });
        }
        else if (this.state.searchSiteId === '') {
            alert("Please enter mandatory Fields");
            this.setState({ err_siteid: " " });
        }
        else if (this.state.searchSupLocId === '') {
            alert("Please enter mandatory Fields");
            this.setState({ err_suplocid: " " });
        }
        else if (this.state.searchSupId === '') {
            alert("Please enter mandatory Fields");
            this.setState({ err_supid: " " });
        }
        else {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/nrdm_supplier/search/${this.state.searchSiteId}/${this.state.searchSupLocId}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        loading: false,
                        currentData: data,
                        enableOnSearch: false,
                        addDisabled: false,
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    alert(`Could not perform Search. Please try again.`);
                });
        }
    }
    //Check box Handler
    handleSelectCheckBox = (e) => {
        if (e.length > 0) {
            this.setState({
                selectedItem: e,
                updateDisabled: false,
                addDisabled: true,
                fieldDisabled: true,
                searchSiteId: e[0].OS_SITE_ID,
                searchSiteName: e[0].PLANT_NAME,
                searchSupLocId: e[0].VENDOR_SK,
                searchSupLocName: e[0].VENDOR_NAME,
                searchSupId: e[0].VENDOR_DUNS_NUMBER,
                searchSupName: e[0].VENDOR_FULL_NM,
                inputPrice: e[0].COST_SAVINGS_VAR_LCL,
                searchSupComId: e[0].SUB_COMMODITY_OWNER_SK,
                searchSupComName: e[0].SUB_COMMODITY_OWNER_NAME,
                searchPartId: e[0].MATERIAL_NUM_SK,
                searchPartName: e[0].MATERIAL_NAME,
                inputSpendPrice: e[0].ACT_AMT_LCL,
                currencySelected: e[0].CURRENCY_CODE,
                gbeSelected: e[0].GBE_CODE_NAME,
                spendReasonSelected: e[0].SPEND_SAVING_REASON,
                comments: e[0].SPEND_SAVING_DESC,
                rdbvalue: e[0].COST_AVOID,
                dateSelected: this.datetolabel(e[0].DATE_RECIEVED.toString()),
                recieptNum: e[0].RECIEPT_NUM,
            });
        }
        else {
            this.setState({
                selectedItem: e,
                updateDisabled: true,
                addDisabled: false,
                fieldDisabled: false,
                inputPrice: "",
                currencySelected: "",
                gbeSelected: "",
                searchSiteId: "",
                searchSupComId: "",
                searchSupComName: "",
                searchPartId: "",
                searchPartName: "",
                inputSpendPrice: "",
                spendReasonSelected: "",
                rdbvalue: "",
                comments: "",
                dateSelected: "",
                recieptNum: [],
            });
        }
        fetch(`${config.host_url}/api/nrdm/getrecieptnum`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    loading: false,
                    recieptNum: this.setRecieptNum(data),
                });
            })
            .catch((err) => {
            });
    }
    //Add Record
    handleAddSelect = () => {
        this.setState({
            err_partid: "",
            err_inputprice: "",
            err_inputspendprice: "",
            err_currency: "",
            err_spend_save_reason: "",
            err_gbe_options: "",
            err_date: "",
            err_comment: "",
            err_rdbvalue: "",
            flg_option: 'A',
        });
        if (this.state.searchPartId === '' &&
            this.state.spendReasonSelected === '' &&
            this.state.inputPrice === ''&&
            this.state.gbeOptions === ''
        ) {
            this.setState({
                err_spend_save_reason: " ",
                err_inputprice: " ",
                err_inputspendprice: " ",
                err_gbe_options: " ",
            });
            alert("Please enter mandatory Fields");
        }
        else if (this.state.searchSupComId === '') {
            alert("Cannot insert as Supplier Sub-commodity details not found.");
            this.setState({ err_supcomid: " " });
        }
        else if (this.state.spendReasonSelected === '') {
            alert("Please select Spend/Saving Reason");
            this.setState({ err_spend_save_reason: " " });
        }
        else if (this.state.gbeSelected === '') {
            alert("Please select GBE ");
            this.setState({ err_gbe_options: " " });
        }
        else if (this.state.dateSelected === '') {
            alert("Please enter Period");
            this.setState({ err_date: " " });
        }
        else if (this.state.inputPrice === '' && this.state.inputSpendPrice === '') {
            alert("Please enter Savings Amount(local currency) or Spend Amount(local currency).");
            this.setState({ err_price: " ", err_inputspendprice: " " });
        }
        else if (!this.validateSavingNumber(this.state.inputPrice) && !this.validateNumber(this.state.inputSpendPrice)) {
            alert("Please enter only digits for Savings Amount or Spend Amount.");
            this.setState({ err_inputprice: " ", err_inputspendprice: " " });
        }
        else {
            this.setState({
                loading: true,
                flg_option: 'A',
            });
            if (this.state.inputPrice === '') {
                this.setState({ inputPrice: 0 });
            }
            else if (this.state.inputSpendPrice === '') {
                this.setState({ inputSpendPrice: 0 });
            }
            var addrdb_values;
            if (this.state.rdbvalue === '') {
                this.setState({ rdbvalue: "0" });
            }else{if(this.state.rdbvalue ==='YES'){
                addrdb_values='1';
            }else{
                addrdb_values='0';
                
            }}
            fetch(`${config.host_url}/api/nrdm/getrecieptnum`)
                .then((res) => res.json())
                .then((data) => {

                    this.setState({
                        recieptNum: this.setRecieptNum(data),
                    });
                    if (this.state.currencySelected === '') {
                        this.setState({ currencySelected: "USD" });
                    }
                    let matNum;
                    if (this.state.searchPartId === '') {
                        matNum = this.state.newVar.toString();
                    }
                    else {
                        matNum = this.state.searchPartId.toString();
                    }
                    const addvalue = {
                        SOURCE_SYSTEM: 'OneSource',
                        SPEND_TYPE_SK: 'DIRECT',
                        SPEND_SOURCE: 'NON-RECEIPT',
                        I_RECIEPT_NUM: (parseInt(this.state.recieptNum[0].value) + 1).toString(),
                        I_OS_SITE_ID: this.state.searchSiteId,
                        I_VENDOR_SK: this.state.searchSupLocId,
                        I_DATE_RECIEVED: this.dateFormat(this.state.dateSelected.toString()),
                        I_COST_SAVINGS_VAR_LCL: this.state.inputPrice,
                        I_ACT_AMT_LCL: this.state.inputSpendPrice,
                        I_CURRENCY_CODE: this.state.currencySelected,
                        I_SPEND_SAVING_DESC: this.state.comments,
                        I_SUB_COMMODITY_OWNER_SK: this.state.searchSupComId,
                        I_MATERIAL_NUM_SK: matNum,
                        I_COST_AVOID: addrdb_values,
                        I_SPEND_SAVING_REASON: this.state.spendReasonSelected,
                        I_GBE_CODE_NAME: this.state.gbeSelected,
                        I_RECEIPTS_LOAD_TS: Math.floor((new Date()).getTime() / 1000),
                        I_FLAG: "I",
                        I_ROLLUP_FLAG: "No",
                        I_RECEIPT_LAST_MODIFIED_USER: this.state.UIDData
                    };
                    fetch(`${config.host_url}/api/nrdm/add`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(addvalue),
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                loading: false,
                                addDisabled: true,
                                updateDisabled: false,
                            });
                            this.handleSearchSelect();
                            alert(`Non-Receipt Record Added.`);
                            this.resetComponent();
                        })
                        .catch((error) => {
                            this.setState({
                                loading: false
                            });
                            alert(`Could not add data. Please try again`);
                        });
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not Fetch transaction ID. Please try again`);
                });
        }
    }
    //Update record
    handleUpdateSelect = () => {
        const selected = this.state.selectedItem[0];
        this.setState({
            err_inputspendprice: "",
            err_inputprice: ""
        });
        if (!this.validateSavingNumber(this.state.inputPrice)) {
            alert("Please enter only digits for Savings Amount");
            this.setState({ err_inputprice: " " });
        }
        else if (!this.validateNumber(this.state.inputSpendPrice)) {
            alert("Please enter only digits for Spend Amount");
            this.setState({ err_inputspendprice: " " });
        }
        else {
            let date_new = '';
            if (this.dateFormat(this.state.dateSelected.toString()) === 'undefined01') {
                date_new = this.dateFormatNew(this.state.dateSelected.toString());
            }
            else {
                date_new = this.dateFormat(this.state.dateSelected.toString());
            }
            let rdb_values;
            if (this.state.rdbvalue === '') {
                this.setState({ rdbvalue: "0" });
            }else{if(this.state.rdbvalue ==='YES'){
                rdb_values='1';
            }else{
                rdb_values='0';
            }}
            const update = {
                SOURCE_SYSTEM: 'OneSource',
                SPEND_TYPE_SK: 'DIRECT',
                SPEND_SOURCE: 'NON-RECEIPT',
                U_RECIEPT_NUM: selected.RECIEPT_NUM,
                U_OS_SITE_ID: this.state.searchSiteId,
                U_VENDOR_SK: this.state.searchSupLocId,
                U_DATE_RECIEVED: date_new,
                U_COST_SAVINGS_VAR_LCL: this.state.inputPrice,
                U_ACT_AMT_LCL: this.state.inputSpendPrice,
                U_CURRENCY_CODE: this.state.currencySelected,
                U_SPEND_SAVING_DESC: this.state.comments,
                U_SUB_COMMODITY_OWNER_SK: this.state.searchSupComId,
                U_MATERIAL_NUM_SK: this.state.searchPartId,
                U_COST_AVOID:rdb_values,
                U_SPEND_SAVING_REASON: this.state.spendReasonSelected,
                U_GBE_CODE_NAME: this.state.gbeSelected,
                U_RECEIPTS_UPDATE_TS: Math.floor((new Date()).getTime() / 1000).toString(),
                U_FLAG: "U",
                D_RECIEPT_NUM: selected.RECIEPT_NUM,
                D_OS_SITE_ID: selected.OS_SITE_ID,
                D_VENDOR_SK: selected.VENDOR_SK,
                D_DATE_RECIEVED: date_new,
                D_COST_SAVINGS_VAR_LCL: selected.COST_SAVINGS_VAR_LCL,
                D_ACT_AMT_LCL: selected.ACT_AMT_LCL,
                D_CURRENCY_CODE: selected.CURRENCY_CODE,
                D_SPEND_SAVING_DESC: selected.SPEND_SAVING_DESC,                
                D_SUB_COMMODITY_OWNER_SK: selected.SUB_COMMODITY_OWNER_SK,
                D_MATERIAL_NUM_SK: selected.MATERIAL_NUM_SK,
                D_COST_AVOID: selected.COST_AVOID,
                D_SPEND_SAVING_REASON: selected.SPEND_SAVING_REASON,
                D_GBE_CODE_NAME: selected.GBE_CODE_NAME,
                D_FLAG: "D",
                ROLLUP_FLAG: "No",
                RECEIPT_LAST_MODIFIED_USER: this.state.UIDData
            };
            this.setState({
                loading: true,
                flg_option: 'U'
            });

            fetch(`${config.host_url}/api/nrdm/update`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(update),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                    this.handleSearchSelect();
                    alert(`Non-Receipt Record Updated.`);
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        addDisabled: true
                    });
                    alert(`Could not update record. Please try again`);
                    this.resetComponent();
                });
        }
    }

    //-----------------------------Mass Upload---------------------------
    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var selectedfile = e.target.files[0];
        this.setState({ selectedfile }, () => {
            if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "" || this.state.selectedfile === undefined) {
                this.setState({ submitDisabled: true });
            }
            else {
                this.setState({ submitDisabled: false });
            }
        });
    }

    submitFile() {
        if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "") {
            this.setState({ submitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({ loading: true });
            let f = this.state.selectedfile;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
                /* Update state */
                let filteredData = data.filter(e => e.length);
                //remove header from base array
                filteredData.shift();
                for (let i = 0; i < filteredData.length; i++) {
                    //remove txn ID row
                    filteredData[i].splice(0, 1);
                    // filteredData[i].splice(2,0, '' + filteredData[i][1]);
                    
                    //filteredData[i].splice(11, 1, '' + filteredData[i][11]);
                }
                // Blank Value Validation 
                let flg_eSiteid = 0;
                let flg_eVendorno = 0;
                let flg_eReason = 0;
                let flg_ePeriod = 0;
                let flg_eSpendamt = 0;
                let flg_eSaveamt = 0;
                let flg_eCurr = 0;
                let flg_ePart = 0;
                let flg_eCostavoid = 0;
                 let flg_gbe = 0;

                let excel_err_msg = [];
                let flg_validation = 'F';
                // SiteID validation Variables
                let arr_err_siteID = [];
                let arr_err_venID = [];
                let arr_siteID = [];
                let arr_vendor =[];
                let result_siteid = [];
                for (var i in this.state.siteIds) {
                    result_siteid.push(this.state.siteIds[i].title);
                }
                //put Reason values in array
                let arr_err_ssr = [];
                let validate_ssr = [];
                for (i in this.state.ssrOptions) {
                    validate_ssr.push(this.state.ssrOptions[i].text);
                }
                // //put gbe values in array
                let arr_err_gbe = [];
                let validate_gbe = [];
                for (i in this.state.gbeOptions) {
                    validate_gbe.push(this.state.gbeOptions[i].text);
                }

                //put Currency values in array
                let arr_err_curr = [];
                let validate_curr = [];
                for (i in this.state.currencyOptions) {
                    validate_curr.push(this.state.currencyOptions[i].text);
                }
                //put Part values in array
                let arr_err_part = [];
                let validate_part = [];
                for (i in this.state.partId) {
                    validate_part.push(this.state.partId[i].partid);
                }
                let arr_err_costavoid = [];
                let arr_err_date = [];
                let arr_err_spendamt = [];
                let arr_err_savingamt = [];
                for (let i = 0; i < filteredData.length; i++) {
                    let excel_siteid = filteredData[i][1];
                    let excel_Vendorno = filteredData[i][2];
                    let excel_Reason = filteredData[i][3];
                    let excel_Period = filteredData[i][5];
                    let excel_Spendamt = filteredData[i][6];
                    let excel_Savingamt = filteredData[i][7];
                    let excel_curr = filteredData[i][8];
                    let excel_partno = filteredData[i][9];
                    let excel_costavoid = filteredData[i][10];
                    let excel_gbe = filteredData[i][11];
                    
                    let cnt_match_gbe = 0;


                    //Site ID validation
                    if (excel_siteid === '' || excel_siteid === undefined) {
                        flg_eSiteid = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_plant = excel_siteid.toString();
                        arr_siteID.push(val_plant);
                        let cnt_site_match = 0;
                        for (let j = 0; j < result_siteid.length; j++) {
                            if (val_plant === result_siteid[j].toString()) {
                                cnt_site_match = 1;
                                break;
                            }
                        }
                        if (cnt_site_match === 0) {
                            arr_err_siteID.push(val_plant);
                        }
                    }
                    if (arr_err_siteID.length > 0) {
                        flg_eSiteid = 2;
                        flg_validation = 'T';
                    }
                    


                    //Vendor ID validation
                    if (excel_Vendorno === '' || excel_Vendorno === undefined) {
                        flg_eVendorno = 1;
                        flg_validation = 'T';
                    }else{
                        let val_vendor = excel_Vendorno.toString();
                        arr_vendor.push(val_vendor);
                        
                    }
                    //Reason Validation
                    if (excel_Reason === '' || excel_Reason === undefined) {
                        flg_eReason = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_ssr = excel_Reason.toString();
                        let cnt_reason_match = 0;
                        for (let j = 0; j < validate_ssr.length; j++) {
                            if (val_ssr === validate_ssr[j].toString()) {
                                cnt_reason_match = 1;
                                break;
                            }
                        }
                        if (cnt_reason_match === 0) {
                            arr_err_ssr.push(val_ssr);
                        }
                    }

                    if (arr_err_ssr.length > 0) {
                        flg_eReason = 2;
                        flg_validation = 'T';
                    }

                    //Date Validation
                    if (excel_Period === '' || excel_Period === undefined) {
                        flg_ePeriod = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let checkDate = this.validDate(excel_Period);
                        if (checkDate === 'eundefined01') {
                            flg_ePeriod = 2;
                            flg_validation = 'T';
                            arr_err_date.push(i + 1);
                        }

                    }
                    //Numeric Value check
                    if ((excel_Spendamt === '' || excel_Spendamt === undefined)
                        && (excel_Savingamt === '' || excel_Savingamt === undefined)) {
                        flg_eSpendamt = 1;
                        flg_eSaveamt = 1;
                        flg_validation = 'T';
                    }
                    if ((excel_Spendamt !== '')
                        && typeof (excel_Spendamt) === 'string'
                        && (this.validateNumber(excel_Spendamt))) {
                        filteredData[i][7] = 0;
                    }
                    else if ((excel_Savingamt !== '')
                        && typeof (excel_Savingamt) === 'string'
                        && (this.validateSavingNumber(excel_Savingamt))) {
                        filteredData[i][6] = 0;
                    }

                    //Currency Validation
                    if (excel_curr === '' || excel_curr === undefined) {
                        filteredData[i][8] = "USD";
                    }
                    else {
                        let val_curr = excel_curr.toString();
                        let cnt_curr_match = 0;
                        for (let j = 0; j < validate_curr.length; j++) {
                            if (val_curr === validate_curr[j].toString()) {
                                cnt_curr_match = 1;
                                break;
                            }
                        }
                        if (cnt_curr_match === 0) {
                            arr_err_curr.push(val_curr);
                        }
                    }
                    if (arr_err_curr.length > 0) {
                        flg_eCurr = 2;
                        flg_validation = 'T';
                    }
                    //Check Part Number
                    
                    if (excel_partno === '' || excel_partno === undefined) {
                        filteredData[i][9] = null
                    }
                    else{
                        let val_part = excel_partno.toString();
                        let cnt_part_match = 0;
                        for (let j = 0; j < validate_part.length; j++) {
                            if (val_part === validate_part[j].toString()) {
                                cnt_part_match = 1;
                                break;
                            }
                        }
                        if (cnt_part_match === 0) {
                            arr_err_part.push(val_part);
                        }
                    }
                    if (arr_err_part.length > 0) {
                        flg_ePart = 3;
                        flg_validation = 'O';
                    }
                    //Check Cost avoidance
                    if (excel_costavoid === 'Y') {
                        filteredData[i][10] = 1;
                    }
                    else {
                        filteredData[i][10] = 0;
                    }
                    //
                    // gbe validation
                    // if (excel_gbe === '' || excel_gbe === undefined) {
                    //     filteredData[i][11] = 'null'
                    // } else if (validate_gbe.includes(excel_gbe)) {
                    //     cnt_match_gbe = 1;
                    //     validate_gbe.push(excel_gbe);
                    // }
                    // if (cnt_match_gbe === 0) {
                    //     arr_err_gbe.push(excel_gbe);
                    // }
                    // if (arr_err_gbe.length > 0) {
                    //     flg_validation = 'T';
                    //     flg_gbe = 1;

                    // }
                    if (excel_gbe === '' || excel_gbe === undefined) {
                            filteredData[i][11] = null
                        }
                    else  {
                        let val_gbe = excel_gbe.toString();
                        let cnt_gbe_match = 0;
                        for (let j = 0; j < validate_gbe.length; j++) {
                            if (val_gbe === validate_gbe[j].toString()) {
                                cnt_gbe_match = 1;
                                break;
                            }
                        }
                        if (cnt_gbe_match === 0) {
                            arr_err_gbe.push(val_gbe); 
                        }
                    }
                    if (arr_err_gbe.length > 0) {
                        flg_gbe = 2;
                        flg_validation = 'T';
                    }

                }
                //API call validations
                let arr_ven_data = [];
                arr_ven_data = (arr_vendor);

                const js_ven_data = JSON.stringify(arr_ven_data);

                // Get Vendors for selected Site ID's
                fetch(`${config.host_url}/api/allvendors`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: js_ven_data,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.setState({
                            all_ven_data: response

                        });
                        //Upload Data in case Vendor Validation pass
                        let result_venid = [];

                        for (var i in this.state.all_ven_data) {
                            result_venid.push(this.state.all_ven_data[i].VENDOR_SK);
                        }


                        for (let i = 0; i < filteredData.length; i++) {
                            let val_vendor = filteredData[i][2].toString();
                            let cnt_match = 0;

                            for (let j = 0; j < result_venid.length; j++) {
                                if (val_vendor === result_venid[j].toString()) {
                                    cnt_match = 1;
                                    break;
                                }
                            }
                            if (cnt_match === 0) {
                                arr_err_venID.push(val_vendor);
                            }
                        }
                        if (arr_err_venID.length > 0) {
                            flg_validation = 'T';
                            flg_eVendorno = 2;
                        }
                        if (flg_validation === 'T') {
                            if (flg_eSiteid === 1) {
                                excel_err_msg.push("Site ID can't be blank. \n");
                            }
                            if (flg_eSiteid === 2) {
                                excel_err_msg.push("Site ID's not found in Base Table: " + arr_err_siteID);
                            }
                            if (flg_eVendorno === 1) {
                                excel_err_msg.push("\nVendor ID can't be blank. \n");
                            }
                            if (flg_eVendorno === 2) {
                                excel_err_msg.push("Vendors not found in Base Table: " + arr_err_venID);
                            }
                            if (flg_eReason === 1) {
                                excel_err_msg.push("\nReason Can't be blank. \n");
                            }
                            if (flg_eReason === 2) {
                                excel_err_msg.push("\nReason not Found: " + arr_err_ssr);
                            }
                            if (flg_ePeriod === 1) {
                                excel_err_msg.push("\nPeriod Can't be blank. \n");
                            }
                            if (flg_ePeriod === 2) {
                                excel_err_msg.push(" Enter Period in (MM/YYYY) Format. Check dates in row number/s: " + arr_err_date);
                            }
                            if (flg_eSpendamt === 1) {
                                excel_err_msg.push("\nSpend Amount & Savings Amount Can't be blank. \n");
                            }
                            if (flg_eSpendamt === 2) {
                                excel_err_msg.push("Check digits in format N.NN in row/s: ", arr_err_spendamt);
                            }
                            if (flg_eSaveamt === 1) {
                                excel_err_msg.push("\nSavings Amount & Spend Amount Can't be blank. \n");
                            }
                            if (flg_eSaveamt === 2) {
                                excel_err_msg.push("Check digits in format N.NN in row/s: ", arr_err_savingamt);
                            }
                            if (flg_eCurr === 2) {
                                excel_err_msg.push("Currency code not found: " + arr_err_curr);
                            }
                            if (flg_ePart === 2) {
                                excel_err_msg.push("Part Number not found in base table: " + arr_err_part);
                            }

                            if (flg_gbe === 2) {
                                excel_err_msg.push("GBE value is invalid: " + arr_err_gbe);
                            }

                            if (flg_eCostavoid === 1) {
                                excel_err_msg.push("Cost Avoidance Can't be blank. \n");
                            }
                            if (flg_eCostavoid === 2) {
                                excel_err_msg.push("Cost Avoidance is not valid in row number/s: " + arr_err_costavoid);
                            }
                            alert("Validation Failed : \n" + excel_err_msg);
                            this.setState({ loading: false });
                        }
                        else {
                            const table_values = {
                                SOURCE_SYSTEM: 'OneSource',
                                SPEND_TYPE_SK: 'NON-RECEIPT',
                                SPEND_SOURCE: 'DIRECT',
                                RECEIPTS_LOAD_TS: (Math.floor((new Date()).getTime() / 1000)).toString(),
                                FLAG: 'I',
                                ROLLUP_FLAG: 'No',
                                RECEIPT_LAST_MODIFIED_USER: this.state.UIDData
                            };
                            let new_filteredData = filteredData;
                            //Add Sub-commodity-number
                            let arr_site_ven_data = [];
                            for (let i = 0; i < filteredData.length; i++) {
                                arr_site_ven_data.push(filteredData[i][1], filteredData[i][2]);
                            }

                            const js_subcom_data = JSON.stringify(arr_site_ven_data);
                            console.log(js_subcom_data,"sk");
                            fetch(`${config.host_url}/api/allsubcommodity`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: js_subcom_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        subcomsk: response

                                    })
                                    console.log("hi");
                                    //insert into excel data
                                    const check = "ZU";
                                    let check_subcomsk = [];
                                    let check_site = [];
                                    let check_vendor = [];
                                    let final_check = [];
                                    for (var i in this.state.subcomsk) {
                                        check_site.push(this.state.subcomsk[i].OS_SITE_ID);
                                        check_vendor.push(this.state.subcomsk[i].VENDOR_SK);
                                        check_subcomsk.push(this.state.subcomsk[i].SUB_COMMODITY_OWNER_SK);
                                        final_check.push([check_site[i], check_vendor[i], check_subcomsk[i]]);
                                    }
                                    console.log("hi");
                                    console.log(final_check,"final");

                                    for (let i = 0; i < new_filteredData.length; i++) {
                                        //if site and vendor match
                                        if (final_check[i] !== undefined) {
                                            console.log(i,final_check[i]);
                                            if (new_filteredData[i][1]===(final_check[i][0]) && new_filteredData[i][2]===(final_check[i][1])) {
                                                new_filteredData[i].push(final_check[0][2]);
                                        
                                            }
                                            else {
                                                new_filteredData[i].push(check);
                                            }
                                        }
                                        else {
                                            new_filteredData[i].push(check);
                                        }
                                    }
                                    console.log("hi");

                                    //get receipt num
                                    fetch(`${config.host_url}/api/nrdm/getrecieptnum`)
                                        .then((res) => res.json())
                                        .then((data) => {
                                            this.setState({
                                                recieptNum: this.setRecieptNum(data),
                                            });
                                            let max_rec_num = parseInt(this.state.recieptNum[0].value);

                                            for (let i = 0; i < filteredData.length; i++) {
                                                new_filteredData[i][0] = ++max_rec_num;
                                                new_filteredData[i].push(table_values.SOURCE_SYSTEM);
                                                new_filteredData[i].push(table_values.SPEND_SOURCE);
                                                new_filteredData[i].push(table_values.SPEND_TYPE_SK);
                                                new_filteredData[i].push(table_values.RECEIPTS_LOAD_TS);
                                                new_filteredData[i].push(table_values.FLAG);
                                                new_filteredData[i].push(table_values.ROLLUP_FLAG);
                                                new_filteredData[i].push(table_values.RECEIPT_LAST_MODIFIED_USER);
                                                new_filteredData[i][5] = this.validDate(new_filteredData[i][5]);
                                                //console.log(new_filteredData[i][0]);
                                            }
                                            
                                            const js_data_n = JSON.stringify(new_filteredData);
                                            console.log("hi",js_data_n);
                                            fetch(`${config.host_url}/api/nrdm/massupload`, {
                                                method: "PUT",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                },
                                                body: js_data_n,
                                            })
                                                .then((response) => response.json())
                                                .then((response) => {
                                                    this.setState({ loading: false });
                                                    let str_msg = JSON.stringify(response[0]);
                                                    alert(`Bulk Upload Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                                })
                                                .catch((error) => {
                                                    this.setState({ loading: false });
                                                    alert(`Could not perform Bulk Upload1. Please try again.`);
                                                });
                                        })
                                        .catch((err) => {
                                        });
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    console.log("bulk error",error);
                                    alert(`Could not perform Bulk Upload2. Please try again.`);
                                });
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        alert(`Could not fetch Vendor data for validations. Please try again`);
                    });
            };
            reader.readAsBinaryString(f);
        }
    }
    render() {

        if (this.state.tologin) {
            return <Redirect to= "/LoginRed" />
          }
        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}></Loader>
                <Grid className="main-body">
                    <Grid.Row>
                        <Grid.Column width={12}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <label style={{ fontSize: 18 }}>Non-Receipt Maintenance (Spend/Savings)</label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Search
                                placeholder="Site ID*"
                                error={this.state.err_siteid}
                                results={this.state.resultsSiteId}
                                value={this.state.searchSiteId}
                                key={this.state.siteIds}
                                onResultSelect={(value) => this.handleSearchIdSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeId(value)}
                                disabled={this.state.fieldDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                            <Search
                                placeholder="Site"
                                error={this.state.err_siteid}
                                results={this.state.resultsSiteName}
                                value={this.state.searchSiteName}
                                onResultSelect={(value) => this.handleSearchSiteSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSites(value)}
                                disabled={this.state.fieldDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                                <div>
                                    <Input
                                        placeholder="Local Supplier Id"
                                        value={this.state.searchSupLocId}
                                        onChange={(value) => this.setState({ searchSupLocId: value })}
                                        style={{  width: 225 }}
                                        disabled={this.state.fieldDisabled}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10", paddingLeft: 10, }} onClick={() => { this.validateVendor(this.state.searchSupLocId); }} />&nbsp;&nbsp;

                                    <Input
                                        placeholder="Local Supplier  Name" 
                                        value={this.state.searchSupLocName}
                                        style={{ paddingLeft: 10, width: 350 }}
                                        disabled={this.state.fieldDisabled}
                                    />
                                </div>
                                </Grid.Column>
                    </Grid.Row>
                    <br />
                    
                    {/* <Grid.Row>
                        <br />
                        <Grid.Column width={12}>
                                <br></br>

                            <Search
                                placeholder="Site(local) Supplier ID"
                                error={this.state.err_suplocid}
                                key={this.state.supplierLocNum.title}
                                results={this.state.resultsSupLocId}
                                value={this.state.searchSupLocId}
                                onResultSelect={(value) => this.handleSearchSupplierLocIdSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupplierLocId(value)}
                                disabled={this.state.fieldDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                            <Search
                                placeholder="Site(local) Supplier Name"
                                error={this.state.err_suplocid}
                                key={this.state.supplierLocName.title}
                                results={this.state.resultsSupLocName}
                                value={this.state.searchSupLocName}
                                onResultSelect={(value) => this.handleSearchSupplierLocNameSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupplierLocName(value)}
                                disabled={this.state.fieldDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                        </Grid.Column>
                    </Grid.Row> */}
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Search
                                placeholder="SupplierID"
                                error={this.state.err_supid}
                                results={this.state.resultsSupId}
                                value={this.state.searchSupId}
                                onResultSelect={(value) => this.handleSearchSupplierIdSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupplierId(value)}
                                disabled={this.state.fieldDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                            <Search
                                placeholder="Supplier Name"
                                error={this.state.err_supid}
                                results={this.state.resultsSupName}
                                value={this.state.searchSupName}
                                onResultSelect={(value) => this.handleSearchSupplierNameSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupplierName(value)}
                                disabled={this.state.fieldDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleSearchSelect} style={{ "float": "left" }} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Search
                                placeholder="Supplier Sub-Commodity OwnerID"
                                error={this.state.err_supcomid}
                                disabled={true}
                                results={this.state.resultsSupComId}
                                value={this.state.searchSupComId}
                                onResultSelect={(value) => this.handleSearchSupplierComIdSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupplierComId(value)}
                                style={{ "paddingLeft": 10 }}
                            />
                            <Search
                                placeholder="Supplier Sub-Commodity Owner Name"
                                error={this.state.err_supcomid}
                                results={this.state.resultsSupComName}
                                value={this.state.searchSupComName}
                                disabled={true}
                                onResultSelect={(value) => this.handleSearchSupplierComNameSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupplierComName(value)}
                                style={{ "paddingLeft": 10 }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Search
                                placeholder="Part"
                                error={this.state.err_partid}
                                results={this.state.resultsPartId}
                                minCharacters={3}
                                value={this.state.searchPartId}
                                onResultSelect={(value) => this.handleSearchPartIdSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangePartId(value)}
                                disabled={this.state.enableOnSearch}
                                style={{ "paddingLeft": 10 }}
                            />
                            <Search
                                placeholder="Part Description"
                                error={this.state.err_partid}
                                results={this.state.resultsPartName}
                                minCharacters={3}
                                value={this.state.searchPartName}
                                onResultSelect={(value) => this.handleSearchPartNameSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangePartName(value)}
                                disabled={this.state.enableOnSearch}
                                style={{ "paddingLeft": 10 }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Select
                                fluid={true}
                                placeholder="Spend/Saving Reason*"
                                error={this.state.err_spend_save_reason}
                                value={this.state.spendReasonSelected}
                                onChange={(value) => this.setState({ spendReasonSelected: value })}
                                options={this.state.ssrOptions}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            Period:&nbsp;
                            <DatePicker
                                type="date"
                                error={this.state.err_date}
                                value={this.state.dateSelected}
                                placeholder="DD/MM/YYYY"
                                displayFormat="DD/MM/YYYY"
                                showYearSelector={true}
                                onChange={(value) => this.setState({ dateSelected: value })}
                                disabled={this.state.enableOnSearch}
                                disablePast={false}
                            />
                        </Grid.Column>

                        
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <TextArea
                                error={this.state.err_comment}
                                placeholder="Spend/Saving Description"
                                value={this.state.comments}
                                onChange={(value) => this.setState({ comments: value })}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Input
                                label="Savings Amount(Local Curreny)"
                                error={this.state.err_inputprice}
                                placeholder="Savings Amount(Local Curreny)"
                                value={this.state.inputPrice}
                                onChange={(value) => this.setState({ inputPrice: value })}
                                disabled={this.state.enableOnSearch}
                                style={{ "paddingLeft": 10 }}
                            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Input
                                label="Spend Amount(Local Curreny)"
                                error={this.state.err_inputspendprice}
                                placeholder="Spend Amount(Local Curreny)"
                                value={this.state.inputSpendPrice}
                                disabled={this.state.enableOnSearch}
                                onChange={(value) => this.setState({ inputSpendPrice: value })}
                            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Select
                                error={this.state.err_currency}
                                label="Currency Code*"
                                placeholder="Select a currency"
                                search={true}
                                value={this.state.currencySelected}
                                onChange={(value) => this.setState({ currencySelected: value })}
                                options={this.state.currencyOptions}
                                disabled={this.state.enableOnSearch}
                            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            
                        
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <label style={{ "paddingLeft": 10 }}><strong>Cost Avoidance:</strong></label>
                            <Radio
                                label="Yes"
                                name="RadioGroup"
                                checked={this.state.rdbvalue === "YES"}
                                onChange={(value) => this.setState({ rdbvalue: "YES" })}
                                disabled={this.state.enableOnSearch}
                            />
                            <Radio
                                label="No"
                                name="RadioGroup"
                                checked={this.state.rdbvalue === "NO"}
                                onChange={(value) => this.setState({ rdbvalue: "NO" })}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={6}>
              
            </Grid.Column>
            <br />
            
             <Grid.Column width={4}>
                            <Select
                                fluid={true}
                                error={this.state.err_gbe_options}
                                label="GBE"
                                placeholder="GBE"
                                search={true}
                            
                                value={this.state.gbeSelected}
                                onChange={(value) => this.setState({ gbeSelected: value })}
                                options={this.state.gbeOptions}
                                disabled={this.state.enableOnSearch}
                            />
                            </Grid.Column> 

                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Input
                                label="Spend Type:"
                                placeholder="DIRECT"
                                disabled={true}
                            />
                            <Input
                                label="Spend Source:"
                                placeholder="NON RECEIPT"
                                disabled={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <div>
                                <Button content="Add" onClick={this.handleAddSelect} disabled={this.state.addDisabled} style={{ "float": "left" }} />
                                <Button content="Clear" onClick={this.resetComponent} />
                                <Button content="Update" onClick={this.handleUpdateSelect} disabled={this.state.updateDisabled} />
                                <label style={{ "paddingLeft": 40 }}> Mass Upload : &nbsp;&nbsp;</label>
                                <input type="file" id="file" ref="fileUploader" onChange={this.filePathset.bind(this)} />
                                <button onClick={() => { this.submitFile(); }} disabled={this.state.submitDisabled}>Submit File</button>
                                <a href={template} download="Non-Receipt Template.xlsx" style={{ "paddingLeft": 40 }}>
                                    <Button content="Download Template"></Button>
                                </a>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <div style={{ "paddingBottom": 40 }}>
                                <DataTable
                                    scrollable={true}
                                    scrollWidth='1300px'
                                    reorderableColumns={true}
                                    resizableColumns={true}
                                    loading={false}
                                    data={this.state.currentData}
                                    selection={this.state.selectedItem}
                                    selectionMode="single"
                                    rows={10}
                                    search={true}
                                    onSelectionChange={(e) => { this.handleSelectCheckBox(e) }
                                    }>
                                    <DataTable.Column
                                        field="RECIEPT_NUM"
                                        header="Transaction ID"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="OS_SITE_ID"
                                        header="Site ID"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="PLANT_NAME"
                                        header="Site Name"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_SK"
                                        header="Site (local) Supplier Number"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_NAME"
                                        header="Site(Local) Supplier Name"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_DUNS_NUMBER"
                                        header="Supplier ID"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_FULL_NM"
                                        header="Supplier Name"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="SUB_COMMODITY_OWNER_SK"
                                        header="Supplier Sub-Commodity Owner Code"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="SUB_COMMODITY_OWNER_NAME"
                                        header="Supplier Sub-Commodity Owner Description"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="SPEND_SAVING_REASON"
                                        header="Spend/Saving Reason"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                     <DataTable.Column
                                        field="GBE_CODE_NAME"
                                        header="GBE"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="SPEND_SAVING_DESC"
                                        header="Spend/Saving Description"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="DATE_RECIEVED"
                                        header="Period"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="ACT_AMT_LCL"
                                        header="Spend Amount(Local Currency)"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="ACT_AMT_USD_MA"
                                        header="Spend Amount"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="COST_SAVINGS_VAR_LCL"
                                        header="Save Amount(Local Currency)"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="COST_SAVINGS_VAR_USD_MA"
                                        header="Save Amount"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="CURRENCY_CODE"
                                        header="Currency Code"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="MATERIAL_NUM_SK"
                                        header="Part Number"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="MATERIAL_NAME"
                                        header="Part Description"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="COST_AVOID"
                                        header="Cost Avoidance"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="RECEIPTS_UPDATE_TS"
                                        header="Last Modified Date"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="RECEIPT_LAST_MODIFIED_USER"
                                        header="Last Modified User ID"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="RECEIPT_CREATE_USER"
                                        header="Last Modified User Name"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="ROLLUP_FLAG"
                                        header="Rollup Processed"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Pagination />
                                </DataTable>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

}