import React from "react";
import * as XLSX from "xlsx";
import { Card, Button, Loader } from "@scuf/common";
const config = require('../config');
export default class PaymentCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submitDisabled: true,
        };
    }
    getTimeStamp = () => {
        return Math.floor((new Date()).getTime() / 1000);
    }
    transSystemClient = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    sbgToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    paymentToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    validDate = (num) => {
        //let cal_date = (new Date((num - (25567 + 1)) * 86400 * 1000)).toString();
        var utc_days  = Math.floor(num - 25569);
        var utc_value = utc_days * 86400;                                        
        var date_info = new Date(utc_value * 1000);
     
        let cal_date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());

        const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
        let dt_month = cal_date.slice(4, 7);
        let dt_year = cal_date.slice(11, 15);
        let dt_day = cal_date.slice(8, 10);
        let month = months[dt_month];
        const dt_ndate = month + '/' + dt_day + '/' + dt_year;
        return dt_ndate;
    }
    resetComponent = () => {
        this.setState({});
    }
    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/payment_calendar/systemclient`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    sysIds: this.transSystemClient(data)
                });
                fetch(`${config.host_url}/api/payment_calendar/sbg`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            sbgCheck: this.sbgToTitle(data),
                        });
                        fetch(`${config.host_url}/api/payment_calendar/paymentfrequency`)
                            .then((res) => res.json())
                            .then((data) => {
                                this.setState({
                                    paymentFrequency: this.paymentToTitle(data),                                   
                                });
                                //call user details
                                fetch(`${config.host_url}/user`, {
                                    credentials: 'include' // fetch won't send cookies unless you set credentials
                                })
                                    .then(response => response.json())
                                    .then(response => {
                                        this.setState(
                                            {
                                                loading: false,
                                                UIDData: response.sub.toUpperCase()
                                            });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        this.setState({ loading: false, });
                                        alert(`Connection Issue with User Data. Please try again`);
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({ loading: false });
                                alert(`Payment Frequency data error`);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loading: false, });
                        alert(`Connection Issue with SBG data. Please try again`);
                    });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, });
                alert(`Connection Issue with System Client data. Please try again`);
                
            });
    }

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        this.setState({ file }, () => {
            if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
                this.setState({ submitDisabled: true });
            }
            else {
                this.setState({ submitDisabled: false });
            }
        });
    }

    submitFile() {
        if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
            this.setState({ submitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({
                loading: true
            });
            let f = this.state.file;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
                /* Update state */
                let filteredData = data.filter(e => e.length);
                //remove header form base array
                filteredData.shift();
                // Blank Value Validation 
                let flg_sysid = 0;
                let flg_sbg = 0;
                let flg_payment = 0;
                let flg_date = 0;
                let excel_err_msg = [];
                let flg_validation = 'F';
                let arr_err_sysiD = [];
                let arr_err_sbg = [];
                let arr_err_date = [];
                let arr_err_payment = [];
                //sysIDs in array
                let validate_sysID = [];
                for (var i in this.state.sysIds) {
                    validate_sysID.push(this.state.sysIds[i].text);
                }
                //put SBG values in array
                let validate_sbg = [];
                for (i in this.state.sbgCheck) {
                    validate_sbg.push(this.state.sbgCheck[i].text);
                }
                //put PayemtnFrequency in array
                let validate_payment = [];
                for (i in this.state.paymentFrequency) {
                    validate_payment.push(this.state.paymentFrequency[i].text);
                }
                for (let i = 0; i < filteredData.length; i++) {
                    let excel_sbg = filteredData[i][0];
                    let excel_sysID = filteredData[i][1];
                    let excel_paymentFrequency = filteredData[i][5];
                    let excel_date = filteredData[i][10];
                    //SystemID Validation
                    if (excel_sysID === '' || excel_sysID === undefined) {
                        flg_sysid = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_sysID = excel_sysID.toString();
                        let cnt_sysID_match = 0;
                        for (let j = 0; j < validate_sysID.length; j++) {
                            if (val_sysID === validate_sysID[j].toString()) {
                                cnt_sysID_match = 1;
                                break;
                            }
                        }
                        if (cnt_sysID_match === 0) {
                            arr_err_sysiD.push(val_sysID);
                        }
                    }
                    if (arr_err_sysiD.length > 0) {
                        flg_sysid = 2;
                        flg_validation = 'T';
                    }
                    //SBG Validation
                    else if (excel_sbg === '' || excel_sbg === undefined) {
                        flg_sbg = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_sbg = excel_sbg.toString();
                        let cnt_sbg_match = 0;
                        for (let j = 0; j < validate_sbg.length; j++) {
                            if (val_sbg === validate_sbg[j].toString()) {
                                cnt_sbg_match = 1;
                                break;
                            }
                        }
                        if (cnt_sbg_match === 0) {
                            arr_err_sbg.push(val_sbg);
                        }
                    }
                    if (arr_err_sbg.length > 0) {
                        flg_sbg = 2;
                        flg_validation = 'T';
                    }
                    //Date Validation
                    if (excel_date === '' || excel_date === undefined) {
                        flg_date = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let checkDate = this.validDate(excel_date);
                        if (checkDate === 'undefined/Da/e') {
                            flg_date = 2;
                            flg_validation = 'T';
                            arr_err_date.push(i + 1);
                        }
                        else{
                            filteredData[i][10]=checkDate;
                        }
                    }
                    //paymentfrequency
                    let null_frequency = " ";
                    if (excel_paymentFrequency === undefined) {
                        filteredData[i][5]=null_frequency;
                    }
                    else {
                        let val_pay = excel_paymentFrequency.toString();
                        let cnt_pay_match = 0;
                        for (let j = 0; j < validate_payment.length; j++) {
                            if (val_pay === validate_payment[j].toString()) {
                                cnt_pay_match = 1;
                                break;
                            }
                        }
                        if (cnt_pay_match === 0) {
                            arr_err_payment.push(val_pay);
                        }
                    }
                    if (arr_err_payment.length > 0) {
                        flg_payment = 2;
                        flg_validation = 'T';
                    }
                }
                if (flg_validation === 'T') {
                    if (flg_sysid === 1) {
                        excel_err_msg.push("System ID can't be blank. \n");
                    }
                    if (flg_sysid === 2) {
                        excel_err_msg.push("\nSystem ID's not found in Base Table: " + arr_err_sysiD);
                    }
                    if (flg_sbg === 1) {
                        excel_err_msg.push("SBG can't be blank. \n");
                    }
                    if (flg_sbg === 2) {
                        excel_err_msg.push("\nSBG not found in Base Table: " + arr_err_sbg);
                    }
                    if (flg_payment === 2) {
                        excel_err_msg.push("\nPayment Frequency not found in table: " + arr_err_payment);
                    }
                    if (flg_date === 1) {
                        excel_err_msg.push("Date Can't be blank. \n");
                    }
                    if (flg_date === 2) {
                        excel_err_msg.push("\nDate is invalid in row/s: " + arr_err_date);
                    }
                    alert("Validation Failed :\n " + excel_err_msg);
                    this.setState({ loading: false });
                }
                else {
                    //Insert new date
                    for (let i = 0; i < filteredData.length; i++) {
                        filteredData[i].push(this.getTimeStamp().toString());
                    }
                    console.log("Data: ",filteredData);
                    const js_data = JSON.stringify(filteredData);
                    fetch(`${config.host_url}/api/payment_calendar/massupload`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: js_data,
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                loading: false
                            });
                            let str_msg = JSON.stringify(response[0]);
                            alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            console.error("Mass Upload Error:", error);
                            alert(`Could not Upload the data. Please try again.`);
                        });
                }
            };
            reader.readAsBinaryString(f);
        }
    }

    render() {
        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}></Loader>
                <Card>
                    <Card.Header title="Payment Calendar">
                    </Card.Header>
                    <Card.Content>
                        <br></br><br></br>
                        <div>
                            <label>File Upload : &nbsp;&nbsp;</label>
                            <input
                                type="file"
                                id="file"
                                ref="fileUploader"
                                onChange={this.filePathset.bind(this)}
                            /><br></br><br></br><br></br>
                            <Button
                                onClick={() => {
                                    this.submitFile();
                                }}
                                disabled={this.state.submitDisabled}
                                content="Submit File">
                            </Button>
                        </div>
                    </Card.Content>
                    <Card.Footer>
                    </Card.Footer>
                </Card>
            </div>
        )
    }
}