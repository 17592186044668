
import React from 'react';
import { Button } from "@scuf/common";
export default class LogInOutLanding extends React.Component {
    render() {
        let message = (this.props.body.token)
            ? 'sign out'
            : 'sign in';
        let path = (this.props.body.token)
            ? '/logout'
            : '/login';
        return (
            <div style={{ alignContent: "center" }}>
                <a href={this.props.uri + path}>
                    <Button
                        type="primary"
                        content={message}>
                    </Button></a>
            </div>
        );
    }
}