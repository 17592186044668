import React from "react";
import { Grid, Button, Input, Loader, Select, TextArea, Radio } from "@scuf/common";
import { DataTable } from "@scuf/datatable";
import * as XLSX from "xlsx";
import template from '../resources/KPI_template.xlsx';
const config = require('../config');
export default class KPIData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      submitDisabled: true,
      selectedfile: "",
      addDisabled: true,
      deleteDisabled: true,
      enableWeek: true,
      updateDisabled: true,
      enableOnSearch: true,
      selectedItem: null,
      err_sbg: "",
      err_measure: "",
      err_year: "",
      err_kpiName: "",
      err_kpiValue: "",
      kpiValueSelected: "",
      err_month: "",
      err_monthEnd: "",
      err_comment: "",
      err_valueType: "",
      err_week: "",
      yearSelected: new Date().getFullYear(),
      sbgOptions: [],
      measureOptions: [],
      kpiNameOptions: [],
      kpiTypeOptions: [],
      yearOptions: [],
      monthOptions: [],
      weekOptions: [],
      currentData: [],
    }
    this.resetComponent = this.resetComponent.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    this.handleAddSelect = this.handleAddSelect.bind(this);
  }
  resetComponent = () => {
    this.setState({
      submitDisabled: true,
      selectedfile: "",
      addDisabled: true,
      enableWeek: true,
      updateDisabled: true,
      deleteDisabled: true,
      enableOnSearch: true,
      selectedItem: null,
      err_sbg: "",
      err_measure: "",
      err_year: "",
      err_kpiName: "",
      err_kpiValue: "",
      err_month: "",
      err_monthEnd: "",
      err_comment: "",
      err_valueType: "",
      err_week: "",
      yearSelected: new Date().getFullYear(),
      currentData: [],
      measureSelected: "",
      weekSelected: "",
      monthSelected: "",
      sbgSelected: "",
      monthEndSelected: "",
      kpiNameSelected: "",
      kpiValueSelected: "",
      valueSelected: "",
      comments: "",
    });
  }
  getTimeStamp = () => {
    return Math.floor((new Date()).getTime() / 1000);
  }
  setYearOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.FISCAL_YEAR_INT;
      newObj.value = oldObj.FISCAL_YEAR_INT;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  setMonthOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.PERIOD;
      newObj.value = oldObj.PERIOD;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  setWeekOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.WK_NUM;
      newObj.value = oldObj.WK_NUM;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  setSbgOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.SSBG;
      newObj.value = oldObj.SSBG;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  setMeasureOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE1;
      newObj.value = oldObj.VALUE1;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  setKpiNameOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  setKpiTypeOptions = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE1;
      newObj.value = oldObj.VALUE1;
      newObjects.push(newObj);
    }
    return newObjects;
  }

  getMonthNum = (text) => {
    const months = {
      'January': '01',
      'February': '02',
      'March': '03',
      'April': '04',
      'May': '05',
      'June': '06',
      'July': '07',
      'August': '08',
      'Sep': '09',
      'Oct': '10',
      'Nov': '11',
      'Dec': '12'
    };
    const month = months[text];
    return month;
  }



  componentDidMount() {
    //add all dropdowns
    fetch(`${config.host_url}/api/kpi/year`)
      .then(response => response.json())
      .then((data) => {
        this.setState({
          yearOptions: this.setYearOptions(data),
        });
        fetch(`${config.host_url}/api/kpi/month`)
          .then(response => response.json())
          .then((data) => {
            this.setState({
              monthOptions: this.setMonthOptions(data),
            });
            fetch(`${config.host_url}/api/kpi/sbg`)
              .then(response => response.json())
              .then((data) => {
                this.setState({
                  sbgOptions: this.setSbgOptions(data),
                });
                fetch(`${config.host_url}/api/kpi/measurement`)
                  .then(response => response.json())
                  .then((data) => {
                    this.setState({
                      measureOptions: this.setMeasureOptions(data),
                    });
                    fetch(`${config.host_url}/api/kpi/kpiname`)
                      .then(response => response.json())
                      .then((data) => {
                        this.setState({
                          kpiNameOptions: this.setKpiNameOptions(data),
                        });
                        fetch(`${config.host_url}/api/kpi/kpitype`)
                          .then(response => response.json())
                          .then((data) => {
                            this.setState({
                              kpiTypeOptions: this.setKpiTypeOptions(data),
                            });
                            fetch(`${config.host_url}/api/kpi/week`)
                              .then(response => response.json())
                              .then((data) => {
                                this.setState({
                                  weekOptions: this.setWeekOptions(data),
                                });
                                //call user details
                                fetch(`${config.host_url}/user`, {
                                  credentials: 'include' // fetch won't send cookies unless you set credentials
                                })
                                  .then(response => response.json())
                                  .then(response => {
                                    this.setState(
                                      {
                                        UIDData: response.sub.toUpperCase(),
                                        loading: false
                                      });
                                  })
                                  .catch((err) => {
                                    this.setState({
                                      loading: false,
                                    });
                                    alert(`Connection Issue with User Data. Please try again`);
                                    window.location.href = `${config.host_url}/login`;
                                  });
                              })
                              .catch((err) => {
                                alert(`Connection Issue with Week Data. Please try again`);
                                this.setState({
                                  loading: false,
                                });
                              })
                          })
                          .catch((err) => {
                            alert(`Connection Issue with KPI Type Data. Please try again`);
                            this.setState({
                              loading: false,
                            });
                          })
                      })
                      .catch((err) => {
                        alert(`Connection Issue with KPI Name Data. Please try again`);
                        this.setState({
                          loading: false,
                        });
                      })
                  })
                  .catch((err) => {
                    alert(`Connection Issue with Measurement Name Data. Please try again`);
                    this.setState({
                      loading: false,
                    });
                  })
              })
              .catch((err) => {
                alert(`Connection Issue with SBG Data. Please try again`);
                this.setState({
                  loading: false,
                });
              })
          })
          .catch((err) => {
            alert(`Connection Issue with Month Data. Please try again`);
            this.setState({
              loading: false,
            });
          })
      })
      .catch((err) => {
        alert(`Connection Issue with Year Data. Please try again`);
        this.setState({
          loading: false,
        });
      })
  }

  validateNumber = (num) => {
    var isValidNum = false;
    if (/^\d+(\.\d{0,6})?$/.test(num)) { isValidNum = true; }
    return isValidNum;
  }
  validate_year = (valid_year) => {
    var d = new Date();
    var m = d.getMonth() + 1;
    var y = d.getFullYear();

    //console.log(y,"y");
    if (y === valid_year) {
      valid_year = y;
    } else if ((y - 1) === valid_year) {
      valid_year = y - 1;
    } else if ((m === 11) || (m === 12)) {
      valid_year = y + 1;
    } else {
      valid_year = '';
    }
    return valid_year;
  }


  monthCallSelected = () => {
    this.setState({ loading: true });
    let monthNum = this.getMonthNum(this.state.monthSelected);
    fetch(`${config.host_url}/api/kpi/week/${monthNum}`)
      .then(response => response.json())
      .then((data) => {
        this.setState({
          weekOptions: this.setWeekOptions(data),
          loading: false
        });
      })
      .catch((err) => {
        alert(`Connection Issue with Week Data.Please try again`);
        this.setState({
          loading: false,
        });
      })
  }

  handleCheckBoxSelect = (eve) => {
    if (eve.length > 0) {
      this.setState({
        selectedItem: eve,
        addDisabled: true,
        deleteDisabled: false,
        updateDisabled: false,
        sbgSelected: eve[0].SBG_DM_COE,
        measureSelected: eve[0].MEASURE_NAME,
        yearSelected: parseInt(eve[0].YEAR, 10),
        monthSelected: eve[0].MONTH,
        weekSelected: eve[0].WEEK,
        monthEndSelected: eve[0].MONTH_END_FLAG,
        kpiNameSelected: eve[0].KPI_NAME,
        kpiValueSelected: eve[0].KPI_VALUE,
        valueSelected: eve[0].VALUE_TYPE,
        comments: eve[0].COMMENTS,
        enableWeek: false,
      });
    }
    else {
      this.setState({
        selectedItem: eve,
        addDisabled: false,
        deleteDisabled: true,
        updateDisabled: true,
        sbgSelected: "",
        measureSelected: "",
        yearSelected: new Date().getFullYear(),
        monthSelected: "",
        weekSelected: "",
        monthEndSelected: "",
        kpiNameSelected: "",
        kpiValueSelected: "",
        valueSelected: "",
        comments: "",
        enableWeek: true
      });
    }
  }


  handleSearchSelect = () => {
    this.setState({
      selectedItem: [],
      err_measure: "",
      err_year: "",
      err_sbg: "",
      deleteDisabled: true
    });
    if ((this.state.sbgSelected === undefined || this.state.sbgSelected === "") && (this.state.yearSelected === undefined || this.state.yearSelected === '')
      && (this.state.measureSelected === undefined || this.state.measureSelected === "")) {
      this.setState({
        err_year: " ",
        err_sbg: " ",
        err_measure: " "
      });
      alert(`Please enter mandatory fields.`);
    }
    else if ((this.state.sbgSelected === undefined || this.state.sbgSelected === "")
      && this.state.yearSelected !== '' &&
      (this.state.measureSelected === undefined || this.state.measureSelected === "")) {
      this.setState({
        loading: true,
      });
      fetch(`${config.host_url}/api/kpi/search/${this.state.yearSelected}`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            currentData: data,
            addDisabled: false,
            enableOnSearch: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          alert(`Could not perform a Search. Please try again`);
        });
    }
    else if ((this.state.sbgSelected !== undefined || this.state.sbgSelected !== "") && this.state.yearSelected !== "" &&
      (this.state.measureSelected === undefined || this.state.measureSelected === "")) {
      this.setState({
        loading: true,
      });
      fetch(`${config.host_url}/api/kpi/search1/${this.state.yearSelected}/${this.state.sbgSelected}`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            currentData: data,
            addDisabled: false,
            enableOnSearch: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          alert(`Could not perform a Search. Please try again`);
        });
    }
    else if (this.state.sbgSelected !== "" && this.state.yearSelected !== '' && this.state.measureSelected !== "") {
      this.setState({
        loading: true,
      });
      fetch(`${config.host_url}/api/kpi/search2/${this.state.yearSelected}/${this.state.sbgSelected}/${this.state.measureSelected}`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            currentData: data,
            addDisabled: false,
            enableOnSearch: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          alert(`Could not perform a Search. Please try again`);
        });
    }
    else if ((this.state.sbgSelected === undefined || this.state.sbgSelected === "")
      && this.state.yearSelected !== '' && this.state.measureSelected !== '') {
      this.setState({
        loading: true,
      });
      fetch(`${config.host_url}/api/kpi/search3/${this.state.yearSelected}/${this.state.measureSelected}`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            currentData: data,
            addDisabled: false,
            enableOnSearch: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          alert(`Could not perform a Search. Please try again`);
        });
    }
  }

  handleAddSelect = () => {
    if ((this.state.monthEndSelected === undefined || this.state.monthEndSelected === '')
      && (this.state.monthSelected === undefined || this.state.monthSelected === '')
      && (this.state.sbgSelected === undefined || this.state.sbgSelected === '')
      && (this.state.kpiNameSelected === undefined || this.state.kpiNameSelected === '')
      && (this.state.kpiValueSelected === undefined || this.kpiValueSelected === '')
      && (this.state.measureSelected === undefined || this.state.measureSelected === '')
      && (this.state.yearSelected === undefined || this.state.yearSelected === '')
      && (this.state.weekSelected === undefined || this.state.weekSelected === '')
      && (this.state.valueSelected === undefined || this.state.valueSelected === '')) //Check Mandatory fields
    {
      alert("Please enter mandatory Fields");
      this.setState({
        loading: false,
        err_measure: " ",
        err_month: " ",
        err_monthEnd: " ",
        err_sbg: " ",
        err_kpiName: " ",
        err_kpiValue: " ",
        err_valueType: " ",
        err_year: " ",
      });
    }
    else if (this.state.sbgSelected === undefined || this.state.sbgSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_sbg: " " });
    }
    else if (this.state.yearSelected === undefined || this.state.yearSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_year: " " });
    }
    else if (this.state.measureSelected === undefined || this.state.measureSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_measure: " " });
    }
    else if (this.state.monthSelected === undefined || this.state.monthSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_month: " " });
    }
    else if (this.state.monthEndSelected === undefined || this.state.monthEndSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_monthEnd: " " });
    }
    else if (this.state.kpiNameSelected === undefined || this.state.kpiNameSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_kpiName: " " });
    }
    else if (this.state.valueSelected === undefined || this.state.valueSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_valueType: " " });
    }
    else if (!this.validateNumber(this.state.kpiValueSelected)) {
      //check values after . digit
      let kpiValue_temp = this.state.kpiValueSelected;
      let strValAfterDigit = kpiValue_temp.indexOf(".");
      if (((kpiValue_temp.slice(strValAfterDigit)).slice(1)).length > 6) {
        alert("Please enter only Numeric KPI Value with 6 decimal limit.");
      } else {
        alert("Please enter only digits for KPI Value");
      }

      this.setState({ err_kpiValue: " " });
    }
    else {
      const addvalue = {
        I_MEASUREMENT_NAME: this.state.measureSelected,
        I_YEAR: this.state.yearSelected,
        I_WEEK: this.state.weekSelected,
        I_MONTH: this.state.monthSelected,
        I_SBG: this.state.sbgSelected,//change
        I_MONTH_END_FLAG: this.state.monthEndSelected,
        I_KPI_NAME: this.state.kpiNameSelected,
        I_KPI_VALUE: this.state.kpiValueSelected,
        I_VALUE_TYPE: this.state.valueSelected,
        I_KPI_TRAN_LOAD_TS: this.getTimeStamp().toString(),
        I_KPI_TRAN_UPDATED_TS: this.getTimeStamp().toString(),
        I_COMMENTS: this.state.comments,
        I_UPDATED_BY: this.state.UIDData,
        I_FLAG: "I",
      };
      const new_var = JSON.stringify(addvalue);
      this.setState({
        loading: true
      });

      fetch(`${config.host_url}/api/kpi/add`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: new_var,
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false,
            addDisabled: false,
            deleteDisabled: true,
            monthEndSelected: "",
            monthSelected: "",
            kpiNameSelected: "",
            kpiValueSelected: "",
            valueSelected: "",
            comments: "",
            weekSelected: ""
          });
          this.handleSearchSelect();
          alert(`Data Added Successfully!`);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            deleteDisabled: true
          });
          alert(`Could not Add data. Please try again`);
        });

    }

  }

  handleUpdateSelect = () => {
    const selected = this.state.selectedItem[0];
    this.setState({
      err_kpiValue: "",
      err_measure: "",
      err_year: "",
      err_sbg: ""
    });
    if (this.state.sbgSelected === undefined || this.state.sbgSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_sbg: " " });
    }
    else if (this.state.yearSelected === undefined || this.state.yearSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_year: " " });
    }
    else if (this.state.measureSelected === undefined || this.state.measureSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_measure: " " });
    }
    else if (this.state.monthSelected === undefined || this.state.monthSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_month: " " });
    }
    else if (!this.validateNumber(this.state.kpiValueSelected)) {

      //check values after . digit
      let kpiValue_temp = this.state.kpiValueSelected;
      let strValAfterDigit = kpiValue_temp.indexOf(".");
      if (((kpiValue_temp.slice(strValAfterDigit)).slice(1)).length > 6) {
        alert("Please enter only Numeric KPI Value with 6 decimal limit.");
      } else {
        alert("Please enter only digits for KPI Value");
      }


      this.setState({ err_kpiValue: " " });
    }
    else {
      const updatevalue = {
        //Add delete flag values
        U_MEASUREMENT_NAME: this.state.measureSelected,
        U_YEAR: this.state.yearSelected,
        U_WEEK: this.state.weekSelected,
        U_MONTH: this.state.monthSelected,
        U_SBG: this.state.sbgSelected,
        U_MONTH_END_FLAG: this.state.monthEndSelected,
        U_KPI_NAME: this.state.kpiNameSelected,
        U_KPI_VALUE: this.state.kpiValueSelected,
        U_VALUE_TYPE: this.state.valueSelected,
        U_KPI_TRAN_LOAD_TS: this.getTimeStamp().toString(),
        U_KPI_TRAN_UPDATED_TS: this.getTimeStamp().toString(),
        U_COMMENTS: this.state.comments,
        U_UPDATED_BY: this.state.UIDData,
        U_FLAG: 'U',
        D_MEASUREMENT_NAME: selected.MEASURE_NAME,
        D_YEAR: selected.YEAR,
        D_WEEK: selected.WEEK,
        D_MONTH: selected.MONTH,
        D_SBG: selected.SBG_DM_COE,
        D_MONTH_END_FLAG: selected.MONTH_END_FLAG,
        D_KPI_NAME: selected.KPI_NAME,
        D_KPI_VALUE: selected.KPI_VALUE,
        D_VALUE_TYPE: selected.VALUE_TYPE,
        D_COMMENTS: selected.COMMENTS,
        D_UPDATED_BY: selected.UPDATED_BY,
        D_FLAG: 'D',
      };
      const new_var = JSON.stringify(updatevalue);
      this.setState({
        loading: true
      });

      fetch(`${config.host_url}/api/kpi/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: new_var,
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false,
            addDisabled: false,
            deleteDisabled: true,
            monthEndSelected: "",
            monthSelected: "",
            kpiNameSelected: "",
            kpiValueSelected: "",
            valueSelected: "",
            comments: "",
            weekSelected: ""
          });
          alert(`Data Updated Successfully!`);
          this.handleSearchSelect();
        })
        .catch((error) => {
          this.setState({
            loading: false,
            addDisabled: true
          });
          alert(`Could not Update record. Please try again.`);
          this.resetComponent();
        });
    }
  }

  handleDeleteRecord = () => {
    const r = window.confirm(`Are you sure you want to DELETE this record? Click on OK to confirm or CANCEL to cancel delete request!`);
    if (r === true) {
      const selected = this.state.selectedItem[0];
      const DeleteRec = {
        D_MEASUREMENT_NAME: selected.MEASURE_NAME,
        D_YEAR: selected.YEAR,
        D_WEEK: selected.WEEK,
        D_MONTH: selected.MONTH,
        D_SBG: selected.SBG_DM_COE,
        D_MONTH_END_FLAG: selected.MONTH_END_FLAG,
        D_KPI_NAME: selected.KPI_NAME,
        D_KPI_VALUE: selected.KPI_VALUE,
        D_VALUE_TYPE: selected.VALUE_TYPE,
        D_COMMENTS: selected.COMMENTS,
        D_FLAG: 'D',
      };
      this.setState({
        loading: true,
        flg_option: 'D'
      });

      fetch(`${config.host_url}/api/kpi/delete`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(DeleteRec),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false,
            addDisabled: true,
            monthSelected: "",
            weekSelected: "",
            kpiNameSelected: "",
            kpiValueSelected: "",
            valueSelected: "",
            monthEndSelected: "",
            comments: ""
          });
          this.handleSearchSelect();
          alert(`Data Deleted Successfully!`);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            addDisabled: true
          });
          alert(`Could not delete record. Please try again.`);
          this.resetComponent();
        });
    }
  }



  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var selectedfile = e.target.files[0];
    this.setState({ selectedfile }, () => {
      if (this.state.selectedfile === "undefined" || this.state.selectedfile === "" || this.state.selectedfile === " " || this.state.selectedfile === undefined) {
        this.setState({ submitDisabled: true });
      }
      else {
        this.setState({ submitDisabled: false });
      }
    });
  }

  submitFile() {
    if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "") {
      this.setState({ submitDisabled: true });
      alert(`Please select a file!`);
    }
    else {
      this.setState({ loading: true });
      let f = this.state.selectedfile;
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
        let filteredData = data.filter(e => e.length);

        filteredData.shift();
        // Blank Value Validation 
        let flg_sbg = 0;
        let flg_measurename = 0;
        let flg_year = 0;
        let flg_month = 0;
        let flg_week = 0;
        let flg_monthEnd = 0;
        let flg_kpiName = 0;
        let flg_kpiValue = 0;
        let flg_valueType = 0;
        let excel_err_msg = [];
        let dup_arr = [];
        let flg_validation = 'F';
        let arr_err_kpiValue = [];
        var i = 0;
        //put SBG values in array
        let arr_err_sbg = [];
        let validate_sbg = [];
        for (i in this.state.sbgOptions) {
          validate_sbg.push(this.state.sbgOptions[i].text);
        }
        //put Measurement values in array
        let arr_err_measure = [];
        let validate_measure = [];
        for (i in this.state.measureOptions) {
          validate_measure.push(this.state.measureOptions[i].text);
        }
        //put KPI Name values in array
        let arr_err_KPIName = [];
        let validate_kpiName = [];
        for (i in this.state.kpiNameOptions) {
          validate_kpiName.push(this.state.kpiNameOptions[i].text);
        }
        //put KPI Value values in array
        let arr_err_kpiType = [];
        let validate_kpiType = [];
        for (i in this.state.kpiTypeOptions) {
          validate_kpiType.push(this.state.kpiTypeOptions[i].text);
        }


        if (filteredData.length === 0) {
          flg_validation = 'T';
        }

        for (let i = 0; i < filteredData.length; i++) {
          let excel_sbg = filteredData[i][0];
          let excel_measurename = filteredData[i][1];
          let excel_year = this.validate_year(filteredData[i][2]);
          let excel_month = filteredData[i][3];
          let excel_week = filteredData[i][4];
          let excel_monthEnd = filteredData[i][5];
          let excel_kpiName = filteredData[i][6];
          let excel_kpiValue = filteredData[i][7];
          let excel_valueType = filteredData[i][8];

          //duplicate
          let arr_dup = [excel_sbg, excel_measurename, excel_year, excel_month, excel_week, excel_monthEnd, excel_kpiName, excel_kpiValue, excel_valueType];
          dup_arr.push(arr_dup);

          //Check SBG

          if (excel_sbg === undefined || excel_sbg === " " || excel_sbg === "") {
            flg_sbg = 1;
            flg_validation = 'T';
          }
          else {
            let val_sbg = excel_sbg.toString();
            let cnt_site_match = 0;
            for (let j = 0; j < validate_sbg.length; j++) {
              if (val_sbg === validate_sbg[j].toString()) {
                cnt_site_match = 1;
                break;
              }
            }
            if (cnt_site_match === 0) {
              arr_err_sbg.push((i + 2));
            }
          }
          if (arr_err_sbg.length > 0) {
            flg_sbg = 2;
            flg_validation = 'T';
          }
          //Check Measure names
          if (excel_measurename === undefined || excel_measurename === " " || excel_measurename === "") {
            flg_measurename = 1;
            flg_validation = 'T';
          }
          else {
            let val_measure = excel_measurename.toString().toUpperCase();
            let cnt_measure_match = 0;
            for (let j = 0; j < validate_measure.length; j++) {
              if (val_measure === validate_measure[j].toString().toUpperCase()) {
                cnt_measure_match = 1;
                break;
              }
            }
            if (cnt_measure_match === 0) {
              arr_err_measure.push((i + 2));
            }
          }
          if (arr_err_measure.length > 0) {
            flg_measurename = 2;
            flg_validation = 'T';
          }
          //Check KPI Name
          if (excel_kpiName === undefined || excel_kpiName === " " || excel_kpiName === "") {
            flg_kpiName = 1;
            flg_validation = 'T';
          }
          else {
            let val_kpiName = excel_kpiName.toString();
            let cnt_KPIName_match = 0;
            for (let j = 0; j < validate_kpiName.length; j++) {
              if (val_kpiName === validate_kpiName[j].toString()) {
                cnt_KPIName_match = 1;
                break;
              }
            }
            if (cnt_KPIName_match === 0) {
              arr_err_KPIName.push((i + 2));
            }
          }
          if (arr_err_KPIName.length > 0) {
            flg_kpiName = 2;
            flg_validation = 'T';
          }
          //Check digit input for KPI Value
          if (excel_kpiValue === undefined || excel_kpiValue === " " || excel_kpiValue === "") {
            flg_kpiValue = 1;
            flg_validation = 'T';
          }
          if (typeof (excel_kpiValue) === 'string') {
            if (!(this.validateNumber(excel_kpiValue))) {
              flg_kpiValue = 2;
              flg_validation = 'T';
              arr_err_kpiValue.push(i + 2);
            }
          }
          //Check KPI Value Type
          if (excel_valueType === undefined || excel_valueType === "" || excel_valueType === " ") {
            flg_valueType = 1;
            flg_validation = 'T';
          }
          else {
            let val_kpiVal = excel_valueType.toString().toUpperCase();
            let cnt_valueType_match = 0;
            for (let j = 0; j < validate_kpiType.length; j++) {
              if (val_kpiVal === validate_kpiType[j].toString().toUpperCase()) {
                cnt_valueType_match = 1;
                break;
              }
            }
            if (cnt_valueType_match === 0) {
              arr_err_kpiType.push((i + 2));
            }
          }
          if (arr_err_kpiType.length > 0) {
            flg_valueType = 2;
            flg_validation = 'T';
          }

          if (excel_year === undefined || excel_year === " " || excel_year === "") {
            flg_year = 1;
            flg_validation = 'T';
          }
          if (excel_month === undefined || excel_month === " " || excel_month === "") {
            flg_month = 1;
            flg_validation = 'T';
          }
          if (excel_week === undefined || excel_week === "" || excel_week === " ") {
            flg_week = 1;
            flg_validation = 'T';
          }
          if (excel_monthEnd === undefined || excel_monthEnd === " " || excel_monthEnd === "") {
            flg_monthEnd = 1;
            flg_validation = 'T';
          }
          // if (excel_monthEnd !== "YES" || excel_monthEnd !== "Yes" || excel_monthEnd !== "yes" ||
          //   excel_monthEnd !== "NO" || excel_monthEnd !== "No" || excel_monthEnd !== "no") {
          //   flg_monthEnd = 2;
          //   flg_validation = 'T';
          // }


        }
        //duplicate
        let flg_duplicate = 0;
        const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
        console.log(duplicate_count, "dupcount");
        if (duplicate_count > 0) {
          flg_duplicate = 1;
        }
        if (flg_duplicate === 1) {
          alert(`Duplicate Data found in template : 
            Could not Upload the data. Please try again.`);
          this.setState({ loading: false });
        }

        else {
          if (flg_validation === 'T') {
            if (filteredData.length === 0) {
              excel_err_msg.push("Excel File can not be Blank. \n");
            }
            if (flg_sbg === 1) {
              excel_err_msg.push("SBG/COE_DM can't be blank. \n");
            }
            if (flg_sbg === 2) {
              excel_err_msg.push("\nInvalid SBG/COE_DM code at row/s: " + arr_err_sbg);
            }
            if (flg_measurename === 1) {
              excel_err_msg.push("Measurement Name can't be blank. \n");
            }
            if (flg_measurename === 2) {
              excel_err_msg.push("\nInvalid Measure Name at row/s: " + arr_err_measure);
            }
            if (flg_year === 1) {
              excel_err_msg.push("Year data invalid /blank. \n");
            }
            if (flg_month === 1) {
              excel_err_msg.push("Month can't be blank. \n");
            }
            if (flg_week === 1) {
              excel_err_msg.push("Week can't be blank. \n");
            }
            if (flg_monthEnd === 1) {
              excel_err_msg.push("Month End Flag can't be blank. \n");
            }
            if (flg_monthEnd === 2) {
              excel_err_msg.push("Invalid Month End flag values.\n");
            }
            if (flg_kpiName === 1) {
              excel_err_msg.push("KPI Name can't be blank. \n");
            }
            if (flg_kpiName === 2) {
              excel_err_msg.push("\nInvalid KPI Name value at row/s: " + arr_err_KPIName);
            }
            if (flg_kpiValue === 1) {
              excel_err_msg.push("KPI Value can't be blank. \n");
            }
            if (flg_kpiValue === 2) {
              excel_err_msg.push("\nInvalid KPI Value at row/s: " + arr_err_kpiValue);
            }
            if (flg_valueType === 1) {
              excel_err_msg.push("Value Type can't be blank. \n");
            }
            if (flg_valueType === 2) {
              excel_err_msg.push("\nInvalid Value Type at row/s: " + arr_err_kpiType);
            }
            alert("Validation Failed :\n " + excel_err_msg);
            this.setState({ loading: false });
          }



          else {
            //Add timestamp, flag, updated by        
            for (i = 0; i < filteredData.length; i++) {
              filteredData[i][10] = (this.getTimeStamp().toString());
              filteredData[i][11] = (this.getTimeStamp().toString());
              filteredData[i][12] = (this.state.UIDData);
              filteredData[i][13] = 'I';
            }
            const js_data = JSON.stringify(filteredData);
            console.log(js_data, "jsbaba");
            fetch(`${config.host_url}/api/kpi/massupload`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: js_data,
            })
              .then((response) => response.json())
              .then((response) => {
                this.setState({
                  loading: false
                });
                let str_msg = JSON.stringify(response[0]);
                alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
              })
              .catch((error) => {
                this.setState({ loading: false });
                alert(`Could not Upload the data. Please try again.`);
              });
          }
        }
      };
      reader.readAsBinaryString(f);
    }
  }

  render() {
    return (
      <div>
        <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}>
        </Loader>
        <Grid className="main-body">
          <Grid.Row>
            <Grid.Column width={12}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <label style={{ fontSize: 18 }}>KPI Data Maintenance</label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <Select
                label="SBG/DM_COE*"
                options={this.state.sbgOptions}
                error={this.state.err_sbg}
                value={this.state.sbgSelected}
                onChange={(value) => this.setState({ sbgSelected: value })}
              //disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Year*"
                options={this.state.yearOptions}
                error={this.state.err_year}
                value={this.state.yearSelected}
                onChange={(value) => this.setState({ yearSelected: value })}
              //disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Measurement Name*"
                options={this.state.measureOptions}//measureOptions
                error={this.state.err_measure}
                value={this.state.measureSelected}
                onChange={(value) => this.setState({ measureSelected: value })}
              //disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <div>
                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleSearchSelect} style={{ "float": "left" }} />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>

            <Grid.Column width={2}>
              <Select
                label="Month*"
                options={this.state.monthOptions}
                disabled={this.state.enableOnSearch}
                error={this.state.err_month}
                value={this.state.monthSelected}
                onChange={(value) => this.setState({
                  monthSelected: value,
                  enableWeek: false
                })
                }
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Week Number*"
                options={this.state.weekOptions}
                error={this.state.err_week}
                value={this.state.weekSelected}
                onFocus={this.monthCallSelected}
                onChange={(value) => this.setState({ weekSelected: value })}
                disabled={this.state.enableWeek}
              //disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <Select
                label="KPI Name*"
                options={this.state.kpiNameOptions}//kpiNameOptions
                error={this.state.err_kpiName}
                value={this.state.kpiNameSelected}
                onChange={(value) => this.setState({ kpiNameSelected: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <Input
                label="KPI Value*"
                error={this.state.err_kpiValue}
                placeholder="DDDD.DDDDDD"
                value={this.state.kpiValueSelected}
                onChange={(value) => this.setState({ kpiValueSelected: value })}//Numeric check
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <Select
                label="Value Type*"
                options={this.state.kpiTypeOptions}
                error={this.state.err_valueType}
                value={this.state.valueSelected}
                onChange={(value) => this.setState({ valueSelected: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <label style={{ "paddingLeft": 10 }}><b>Month End Flag:*</b>&nbsp;&nbsp;</label>
              <Radio
                label="Yes"
                name="RadioGroup"
                checked={this.state.monthEndSelected === "Yes"}
                onChange={(value) => this.setState({ monthEndSelected: "Yes" })}
                disabled={this.state.enableOnSearch}
              />
              <Radio
                label="No"
                name="RadioGroup"
                checked={this.state.monthEndSelected === "No"}
                onChange={(value) => this.setState({ monthEndSelected: "No" })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column><br></br></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <TextArea
                label="Comments"
                maxLength={2000}
                error={this.state.err_comment}
                disabled={this.state.enableOnSearch}
                placeholder="Comments"
                value={this.state.comments}
                onChange={(value) => this.setState({ comments: value })}
                fluid={true}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <div>
                <Button content="Add" onClick={this.handleAddSelect} disabled={this.state.addDisabled} />
                <Button content="Clear" onClick={this.resetComponent} />
                <Button content="Update" onClick={this.handleUpdateSelect} disabled={this.state.updateDisabled} />
                <Button content="Delete" onClick={this.handleDeleteRecord} disabled={this.state.deleteDisabled} />
                <label style={{ "paddingLeft": 40 }}> Mass Upload : &nbsp;&nbsp;</label>
                <input type="file" id="file" ref="fileUploader" onChange={this.filePathset.bind(this)} />
                <button onClick={() => { this.submitFile(); }} disabled={this.state.submitDisabled}>Submit File</button>
                <a href={template} download="KPI_DataMaintenance_Template.xlsx" style={{ "paddingLeft": 40 }}>
                  <Button content="Download Template"></Button>
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div style={{ "paddingBottom": 45 }}>
                <DataTable
                  scrollable={true}
                  search={true}
                  rows={20}
                  data={this.state.currentData}
                  selectionMode="single"
                  selection={this.state.selectedItem}
                  onSelectionChange={
                    (eve) => {
                      this.handleCheckBoxSelect(eve)
                    }
                  }
                >
                  <DataTable.Column
                    field="MEASURE_NAME"
                    header="TYPE"
                    sortable={false}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="WEEK"
                    header="WEEK"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="MONTH"
                    header="MONTH"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="YEAR"
                    header="YEAR"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="SBG_DM_COE"
                    header="SBG"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="MONTH_END_FLAG"
                    header="MONTH END"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="KPI_NAME"
                    header="KPI TYPE"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="KPI_VALUE"
                    header="KPI VALUE"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Column
                    field="VALUE_TYPE"
                    header="VALUE TYPE"
                    sortable={true}
                  //initialWidth='250px'
                  />
                  <DataTable.Pagination />
                </DataTable>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }


}