import React from "react";

import * as XLSX from "xlsx";
import {
    Button, Grid,


    Select,
    Input,
    Divider,
    Loader,
} from "@scuf/common";
import { DataTable } from "@scuf/datatable";

import template from '../resources/SiteIdMaintenance_Template.xlsx';
const config = require('../config');
export default class SiteIdMaintenance extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            tologin: false,
            submitDisabled: true,
            valsubmitDisabled: true,
            disableadd: false,
            file: "",
            siteIds: [],
            companycode: [],
            plantno: [],
            sourceSystem: [],
            sbedata: [],
            sbgdata: [],

            //search
            searchSiteId: [],
            siteIdcheck: [],
            currentData: [],

            buton: true,
            buton1:false,
            buton2: false,
            sbeselected: "",
            sbgselected: "",
            sourceSystemselected: "",
            searchPlant: "",
            searchCompanycode: "",
            searchprofit: "",
            disablesiteid: false,
            disableCompanycode: false,
            disableplant: false,
            disableprofit: false,
            disabless: false,
            disablesbe: true,
            disablesbg: true,


            //Dim Plant

            selectedplantsk: "",
            selectedplant: "",
            selectedplantname: "",
            selectedcountryname: "",

            selectedcity: "",
            selectedstate: "",
            selectedaddr_l1: "",
            selectedaddr_l2: "",
            selectedlat: "",
            selectedlon: "",
            selectedgeo: "",
            selectedgeo_name: "",
            selectedsite_id: "",
            selectedsite_id_name: "",

            selectedsbg_name: "",
            selectedsbu: "",
            selectedsbu_name: "",

            selectedsbe_name: "",


            showPrime: true,
            showDimPlant: false,

        }
    }




    siteIdToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.OS_SITE_ID;
            newObjects.push(newObj);
        }
        return newObjects;
    }




    transCCode = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.COMPANY_CODE;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    transPlantNo = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.PLANT_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transSourceSystem = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.SOURCE_SYSTEM;
            newObj.value = oldObj.SOURCE_SYSTEM;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transSbg = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.SBG;
            newObj.value = oldObj.SBG;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transSbe = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.SBE;
            newObj.value = oldObj.SBE;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    getTimeStamp = () => {
        return Math.floor((new Date()).getTime() / 1000);
    }

    handleDIMplant = () => {
        this.setState(
            {
                showDimPlant: true,
                showPrime: false,
            })
    }


    resetComponent = () => {
        this.setState({
            loading: false,
            currentData: [],

            submitDisabled: true,
            valsubmitDisabled: true,
            file: "",
            all_profit_data: [],

            searchSiteId: "",
            searchPlant: "",
            searchCompanycode: "",
            searchprofit: "",
            disablesiteid: false,
            disableplant: false,
            disableCompanycode: false,
            disableprofit: false,
            disabless: false,
            disablesbe: false,
            disablesbg: false,

            selectedItem: [],

            sourceSystemselected: "",
            sbgselected: "",
            sbeselected: "",

            showPrime: true,
            showDimPlant: false,


        });
    }



    validateSiteID = (searchText) => {
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            let valSiteId = [];
            for (var j in this.state.siteIds) {
                valSiteId.push((this.state.siteIds[j].title).toString());
            }
            if (valSiteId.includes(searchText)) {
                this.setState({
                    disablesiteid: true
                })
            }
            else {
                alert("Site ID NOT FOUND IN DIM PLANT TABLE.")
            }

        }
    }


    validateErpPlant = (searchText) => {
        console.log(searchText, "text");
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            let valErpPlant = [];
            for (var j in this.state.plantno) {
                valErpPlant.push((this.state.plantno[j].title).toString());
            }
            if (valErpPlant.includes(searchText)) {
                this.setState({
                    disableplant: true
                })

            }
            else {
                alert("ERP PLANT NO IS NOT VALID")
            }

        }
    }

    validateCompanyCode = (searchText) => {
        console.log(searchText, "text");
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            let valCompanyCode = [];
            for (var j in this.state.companycode) {
                valCompanyCode.push((this.state.companycode[j].title).toString());
            }
            if (valCompanyCode.includes(searchText)) {
                this.setState({
                    disableCompanycode: true
                })

            }
            else {
                alert("COMPANY CODE IS NOT VALID.")
            }

        }
    }

    validateProfit = (searchText) => {
        console.log(searchText, "text");
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {

            this.setState({ loading: true });
            fetch(
                `${config.host_url}/api/valprofit/${searchText}`
            )
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        loading: false,
                        profitData: data,
                    });

                    let result_profitData = [];

                    for (var i in this.state.profitData) {
                        result_profitData.push(this.state.profitData[i].PROFIT_CENTER);
                    }
                    if (result_profitData.includes(searchText)) {
                        this.setState({
                            disableprofit: true,

                        })

                    }
                    else {
                        alert("PROFIT CENTER IS NOT VALID.")
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                    });
                    console.log(err);
                    alert(`Could not perform a Search. Please try again`);
                });



        }
    }

    searchSiteID = (searchText) => {
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            this.setState({ loading: true });
            if (searchText !== '' && this.state.searchCompanycode === '' && this.state.searchPlant === '' && this.state.searchprofit === '') {
                fetch(
                    `${config.host_url}/api/search/${searchText}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            loading: false,
                            currentData: data,
                        });

                        if (data.length === 0) {
                            this.setState({
                                disableadd: false,
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(err);
                        alert(`Could not perform a Search. Please try again`);
                    });
            }
            if (searchText !== '' && this.state.searchCompanycode !== '' && this.state.searchPlant === '' && this.state.searchprofit === '') {
                fetch(
                    `${config.host_url}/api/search/${searchText}/${this.state.searchCompanycode}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            loading: false,
                            currentData: data,
                        });

                        if (data.length === 0) {
                            this.setState({
                                disableadd: false,
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(err);
                        alert(`Could not perform a Search. Please try again`);
                    });
            }
            if (searchText !== '' && this.state.searchCompanycode === '' && this.state.searchPlant === '' && this.state.searchprofit !== '') {
                fetch(
                    `${config.host_url}/api/search/${searchText}/${this.state.searchprofit}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            loading: false,
                            currentData: data,
                        });

                        if (data.length === 0) {
                            this.setState({
                                disableadd: false,
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(err);
                        alert(`Could not perform a Search. Please try again`);
                    });
            }
            if (searchText !== '' && this.state.searchCompanycode !== '' && this.state.searchPlant !== '' && this.state.searchprofit === '') {
                fetch(
                    `${config.host_url}/api/search/${searchText}/${this.state.searchCompanycode}/${this.state.searchPlant}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            loading: false,
                            currentData: data,
                        });

                        if (data.length === 0) {
                            this.setState({
                                disableadd: false,
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(err);
                        alert(`Could not perform a Search. Please try again`);
                    });
            }
            if (searchText !== '' && this.state.searchCompanycode !== '' && this.state.searchPlant !== '' && this.state.searchprofit !== '') {
                fetch(
                    `${config.host_url}/api/search/${searchText}/${this.state.searchCompanycode}/${this.state.searchPlant}/${this.state.searchprofit}`
                )
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            loading: false,
                            currentData: data,
                        });

                        if (data.length === 0) {
                            this.setState({
                                disableadd: false,
                            })
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(err);
                        alert(`Could not perform a Search. Please try again`);
                    });
            }
        }

    }

    handleCheckBoxSelect = (eve) => {
        if (eve.length > 0) {
            this.setState({
                selectedItem: eve,
                // updateDisabled: false,
                disablesiteid: false,
                disableplant: true,
                disableprofit: true,
                disableCompanycode: true,
                disabless: true,
                addDisabled: true,
                searchSiteID: eve[0].OS_SITE_ID,
                searchCompanycode: eve[0].COMPANY_CODE,
                searchPlant: eve[0].PLANT,
                searchprofit: eve[0].PROFIT_CENTER,
                sourceSystemselected: eve[0].SOURCE_SYSTEM,
                sbgselected: eve[0].SBG,
                sbeselected: eve[0].SBE,
                disablesbg: true,
                disablesbe: true,
            });

        }
        else {
            this.setState({
                selectedItem: eve,
                // updateDisabled: true,
                // deleteDisabled: true,
                disableplant: false,
                disableprofit: false,
                disableCompanycode: false,
                disabless: false,
                addDisabled: false,
                searchSiteID: "",
                searchCompanycode: '',
                searchPlant: "",
                searchprofit: "",
                sourceSystemselected: "",
                sbgselected: "",
                sbeselected: "",
            });
        }
    }

    handleUpdateSelect = () => {
        const selected = this.state.selectedItem[0];

        console.log(this.state.sourceSystemselected, "ss");

        const update = {
            U_SOURCE_SYSTEM: this.state.sourceSystemselected,
            U_OS_SITE_ID: this.state.searchSiteId,
            U_COMPANY_CODE: this.state.searchCompanycode,
            U_PLANT: this.state.searchPlant,
            U_PROFIT_CENTER: this.state.searchprofit,
            U_MAPPING_FIELD: this.state.UIDData,
            U_FLAG: "U",
            D_OS_SITE_ID: selected.OS_SITE_ID,
            D_COMPANY_CODE: selected.COMPANY_CODE,
            D_PLANT: selected.PLANT,
            D_PROFIT_CENTER: selected.PROFIT_CENTER,
            D_FLAG: "D",
            OS_SITE_UPDATE_TS: this.getTimeStamp().toString()


        };
        this.setState({
            loading: true,
            flg_option: 'U'
        });

        fetch(`${config.host_url}/api/siteid/update`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(update),
        })
            .then((response) => response.json())
            .then((response) => {
                this.setState({
                    loading: false
                });

                alert(`Data Updated Successfully!`);
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    addDisabled: true
                });
                alert(`Could not update data. Please try again`);
                this.resetComponent();
            });
    }

    handleAddSelect = () => {

        if (this.state.searchSiteId === '' ||
            this.state.searchprofit === '' ||
            this.state.searchCompanycode === '' ||
            this.state.searchPlant === ''

        ) {

            alert("Please enter mandatory Fields");
        }


        else if (this.state.searchSiteId === '') {
            alert("Please enter Site Id");

        }
        else if (this.state.searchprofit === '') {
            alert("Please enter PROFIT CENTER");

        }
        else if (this.state.searchCompanycode === '') {
            alert("Please enter Company code");
        }
        else if (this.state.searchPlant === '') {
            alert("Please enter Plant No");
        } else if (this.state.sourceSystemselected === '') {
            alert("Please select source system");
        }

        else {
            if (this.state.disablesiteid === false){
                alert("Please validate site id");
            }
            else {
            
                const addvalue = {
                    SOURCE_SYSTEM: this.state.sourceSystemselected,
                    OS_SITE_ID: this.state.searchSiteId,
                    COMPANY_CODE: this.state.searchCompanycode,
                    PLANT: this.state.searchPlant,
                    PROFIT_CENTER: this.state.searchprofit,
                    MAPPING_FIELD: this.state.UIDData,
                    FLAG: "I",
                    OS_SITE_UPDATE_TS: this.getTimeStamp().toString(),
                    D_FLAG: "D"

                };
                const check = JSON.stringify(addvalue);
                this.setState({
                    loading: true,
                    flg_option: 'A',
                });
                fetch(`${config.host_url}/api/siteid/addrecord`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: check,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.setState({
                            loading: false,
                            addDisabled: true,
                            updateDisabled: false,
                        });
                        alert(`Site Id  Data Record Added.`);
                        this.resetComponent();
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false
                        });
                        alert(`Could not add data. Please try again`);
                    });

                }
        }
    }

    handleDimAddSelect = () => {

        if (this.state.selectedsite_id === '') {
            alert("Please enter mandatory Fields");
        } else {
            const adddimvalue = {
                SOURCE_SYSTEM: 'OneSource',
                PLANT_SK: this.state.selectedsite_id,
                PLANT: this.state.selectedsite_id,
                PLANT_NAME: this.state.selectedplantname,
                COUNTRY_NAME: this.state.selectedcountryname,
                LOAD_TS: this.getTimeStamp().toString(),
                UPDATE_TS: this.getTimeStamp().toString(),
                CITY: this.state.selectedcity,
                STATE: this.state.selectedstate,
                ADDR_LINE1: this.state.selectedaddr_l1,
                ADDR_LINE2: this.state.selectedaddr_l2,
                LATITUDE: this.state.selectedlat,
                LONGITUDE: this.state.selectedlon,
                GEOGRAPHY: this.state.selectedgeo,
                GEOGRAPHY_NAME: this.state.selectedgeo_name,
                OS_SITE_ID: this.state.selectedsite_id,
                OS_SITE_NAME: this.state.selectedsite_id_name,
                SBG: this.state.sbgselected,
                SBG_NAME: this.state.selectedsbg_name,
                SBU: this.state.selectedsbu,
                SBU_NAME: this.state.selectedsbu_name,
                SBE: this.state.sbeselected,
                SBE_NAME: this.state.selectedsbe_name,
                FLAG: 'I',
                D_FLAG: "D"
            };
            const checkdim = JSON.stringify(adddimvalue);
            console.log(checkdim, "check");
            this.setState({
                loading: true,
                flg_option: 'A',
            });
            fetch(`${config.host_url}/api/siteid/adddimplant`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: checkdim,
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,

                    });
                    alert(`Dim Plant Record Added.`);
                    this.resetComponent();
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not add data. Please try again`);
                });
        }


    }

    clearDimPlant = () => {

        this.setState({
            selectedplantsk: "",
            selectedplant: "",
            selectedplantname: "",
            selectedcountryname: "",

            sbgselected: "",
            sbeselected: "",

            selectedcity: "",
            selectedstate: "",
            selectedaddr_l1: "",
            selectedaddr_l2: "",
            selectedlat: "",
            selectedlon: "",
            selectedgeo: "",
            selectedgeo_name: "",
            selectedsite_id: "",
            selectedsite_id_name: "",

            selectedsbg_name: "",
            selectedsbu: "",
            selectedsbu_name: "",

            selectedsbe_name: "",
        })

    }


    // //********************************************************************************** */


    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/siteid`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({

                    siteIds: this.siteIdToTitle(data),
                });
                fetch(`${config.host_url}/api/sourcesystem`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({

                            sourceSystem: this.transSourceSystem(data),
                        });

                        fetch(`${config.host_url}/api/siteid/sbg`)
                            .then((res) => res.json())
                            .then((data) => {
                                this.setState({

                                    sbgdata: this.transSbg(data),
                                });

                                fetch(`${config.host_url}/api/siteid/sbe`)
                                    .then((res) => res.json())
                                    .then((data) => {
                                        this.setState({

                                            sbedata: this.transSbe(data),
                                        });
                                        //Spend Saving Reason
                                        fetch(`${config.host_url}/api/companycode`)
                                            .then((res) => res.json())
                                            .then((data) => {
                                                this.setState({
                                                    companycode: this.transCCode(data)
                                                });
                                                //Currency
                                                fetch(`${config.host_url}/api/erpplantno`)
                                                    .then((res) => res.json())
                                                    .then((data) => {
                                                        this.setState({
                                                            plantno: this.transPlantNo(data)
                                                        });

                                                        //call user details
                                                        fetch(`${config.host_url}/user`, {
                                                            credentials: 'include' // fetch won't send cookies unless you set credentials
                                                        })
                                                            .then(response => response.json())
                                                            .then(response => {
                                                                this.setState(
                                                                    {
                                                                        //body: response,
                                                                        loading: false,
                                                                        UIDData: response.sub.toUpperCase()
                                                                    })
                                                            })
                                                            .catch((err) => {
                                                                alert(`Connection Issue with User Data. Please try again`);
                                                                this.setState({
                                                                    loading: false,
                                                                    // tologin:true,
                                                                });
                                                                 window.location.href = `${config.host_url}/login`;
                                                            });


                                                    })
                                                    .catch((err) => {
                                                        alert(`Connection Issue. Can't load currency options. Please try again`);
                                                        this.setState({
                                                            loading: false,
                                                        });
                                                    });
                                            })
                                            .catch((err) => {
                                                alert(`Connection Issue. Can't load Spend/Saving Reason. Please try again`);
                                                this.setState({
                                                    loading: false,
                                                });
                                            });
                                    })
                                    .catch((err) => {
                                        alert(`Connection Issue. Can't load Sbe. Please try again`);
                                        this.setState({
                                            loading: false,
                                        });
                                    });
                            })
                            .catch((err) => {
                                alert(`Connection Issue. Can't load Sbg. Please try again`);
                                this.setState({
                                    loading: false,
                                });
                            });
                    })
                    .catch((err) => {
                        alert(`Connection Issue. Can't load Sourcesystem. Please try again`);
                        this.setState({
                            loading: false,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                alert(`Connection Issue. Can't load Site data. Please try again`);
            });

    }

    /**************************************************************************************************** */

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        this.setState({ file }, () => {
            if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " " || this.state.file === undefined) {
                this.setState({ submitDisabled: true });
            }
            else {
                this.setState({ submitDisabled: false });
            }
        });
    }

    submitFile() {
        if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
            this.setState({ submitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({
                loading: true
            });
            let f = this.state.file;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
                /* Update state */
                let FilteredData = data.filter(e => e.length);
                //remove header form base array
                console.log("hi");
                FilteredData.shift();
                console.log("hi");
                for (let i = 0; i < FilteredData.length; i++) {
                    FilteredData[i].splice(0, 1, '' + FilteredData[i][0]);//Convert Source System to String
                    FilteredData[i].splice(1, 1, '' + FilteredData[i][1]);//Convert Site ID to String
                    FilteredData[i].splice(2, 1, '' + FilteredData[i][2]);//Convert ERP PLANT NO  to string
                    FilteredData[i].splice(3, 1, '' + FilteredData[i][3]);//Convert Company code to string   
                    FilteredData[i].splice(4, 1, '' + FilteredData[i][4]);//Convert Profit center code to string 
                    //Convert Comments to string                  

                    //Add timestamp        
                    FilteredData[i][6] = (this.getTimeStamp().toString());
                    //Add timestamp        
                    FilteredData[i][7] = (this.getTimeStamp().toString());
                    FilteredData[i][8] = 'I';


                }
                console.log(FilteredData, "filter");
                console.log("hi");

                // Blank Value Validation 
                let flg_siteid = 0;
                let flg_sourcesystem = 0;

                let flg_plantno;
                let flg_companycode;
                let flg_profitcenter;


                let dup_arr = [];
                let excel_err_msg = [];
                let all_site_data = [];
                let all_sourcesystem_data = [];

                let arr_err_sourcesystem = [];
                let arr_err_siteID = [];



                let all_plantno_data = [];
                let all_companycode_data = [];
                let all_profitcenter_data = [];

                let flg_validation = 'F';

                let allSiteId = [];
                for (var j in this.state.siteIds) {
                    allSiteId.push((this.state.siteIds[j].title).toString());
                }


                let all_sourcesystem = [];
                console.log(this.state.sourceSystem.text, "a");
                console.log(this.state.sourceSystem.title, "b");

                for (var n in this.state.sourceSystem) {
                    all_sourcesystem.push((this.state.sourceSystem[n].text));
                }
                console.log(all_sourcesystem, "sys");

                for (let i = 0; i < FilteredData.length; i++) {
                    let excel_sourcesystem = FilteredData[i][0];
                    let excel_siteid = FilteredData[i][1];
                    let excel_plantno = FilteredData[i][2];
                    let excel_companycode = FilteredData[i][3];
                    let excel_profitcenter = FilteredData[i][4];
                    let excel_mapping = FilteredData[i][5];


                    let arr_dup = [excel_siteid, excel_plantno, excel_companycode, excel_profitcenter];
                    dup_arr.push(arr_dup);

                    if (excel_sourcesystem === '' || excel_sourcesystem === undefined || excel_sourcesystem === 'undefined') {
                        flg_sourcesystem = 1;
                        flg_validation = 'T'
                    }
                    else {

                        if (all_sourcesystem.includes(excel_sourcesystem)) {
                            all_sourcesystem_data.push(excel_sourcesystem);
                        } else {
                            arr_err_sourcesystem.push(excel_sourcesystem);
                            flg_sourcesystem = 2;
                            flg_validation = 'T';
                        }
                    }

                    if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
                        flg_siteid = 1;
                        flg_validation = 'T'
                    }
                    else {
                        if (allSiteId.includes(excel_siteid)) {
                            all_site_data.push(excel_siteid);
                        } else {
                            arr_err_siteID.push(excel_siteid);
                            flg_siteid = 2;
                            flg_validation = 'T';
                        }
                        console.log(all_site_data, "all");
                    }
                    if (excel_plantno === '' || excel_plantno === undefined || excel_plantno === 'undefined') {
                        flg_plantno = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_plantno_data.push(excel_plantno); console.log(all_plantno_data, "matell");
                    }
                    if (excel_companycode === '' || excel_companycode === undefined || excel_companycode === 'undefined') {
                        flg_companycode = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_companycode_data.push(excel_companycode); console.log(all_companycode_data, "allerp sup");
                    }
                    if (excel_profitcenter === '' || excel_profitcenter === undefined || excel_profitcenter === 'undefined') {
                        flg_profitcenter = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_profitcenter_data.push(excel_profitcenter); console.log(all_profitcenter_data, "allerp sup");
                    }

                    if (excel_mapping === '' || excel_mapping === undefined || excel_mapping === 'undefined') {
                        FilteredData[i][5] = this.state.UIDData;
                    } else {
                        FilteredData[i][5] = (this.state.UIDData + "--" + excel_mapping);
                    }


                } let flg_duplicate = 0;
                const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
                console.log(duplicate_count, "dupcount");
                if (duplicate_count > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }
                else {



                    if (flg_validation === 'T') {
                        if (flg_siteid === 1) {
                            excel_err_msg.push("Site ID can't be blank. \n");
                        }

                        if (flg_siteid === 2) {
                            excel_err_msg.push("Site IDs not found in base table " + arr_err_siteID);
                        }
                        if (flg_sourcesystem === 1) {
                            excel_err_msg.push("SourceSystem can't be blank. \n");
                        }

                        if (flg_sourcesystem === 2) {
                            excel_err_msg.push("Sourcesystem not found in base table " + arr_err_sourcesystem);
                        }

                        if (flg_plantno === 1) {
                            excel_err_msg.push("PLANT NO can't be blank. \n");
                        }


                        if (flg_companycode === 1) {
                            excel_err_msg.push("COMPANY CODE  can't be blank. \n");
                        }


                        if (flg_profitcenter === 1) {
                            excel_err_msg.push("PROFIT CENTER  can't be blank. \n");
                        }


                        alert("Validation Failed :\n " + excel_err_msg);
                        this.setState({ loading: false });
                    }
                    else {

                        const site_data = (JSON.stringify(FilteredData));
                        console.log(site_data, "site");
                        fetch(`${config.host_url}/api/siteid/massupload`, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: site_data,
                        })
                            .then((response) => response.json())
                            .then((response) => {
                                this.setState({
                                    loading: false
                                });
                                let str_msg = JSON.stringify(response[0]);
                                alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                this.resetComponent();
                            })
                            .catch((error) => {
                                this.setState({ loading: false });
                                alert(`Could not Upload the data. Please try again.`);
                            });
                    }


                }

            }; reader.readAsBinaryString(f);

        }
    }


    /****************************************************************************************************/

    //    validFilePathset(e) {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     var file = e.target.files[0];
    //     this.setState({ file }, () => {
    //         if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " " || this.state.file === undefined) {
    //             this.setState({ valsubmitDisabled: true });
    //         }
    //         else {
    //             this.setState({ valsubmitDisabled: false });
    //         }
    //     });
    // }

    //     validSubmitFile() {
    //     if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
    //         this.setState({ valsubmitDisabled: true });
    //         alert(`Please select a file!`);
    //     }
    //     else {
    //         this.setState({
    //             loading: true
    //         });
    //         let f = this.state.file;
    //         const reader = new FileReader();
    //         reader.onload = (evt) => {
    //             /* Parse data */
    //             const bstr = evt.target.result;
    //             const wb = XLSX.read(bstr, { type: "binary" });
    //             /* Get first worksheet */
    //             const wsname = wb.SheetNames[0];
    //             const ws = wb.Sheets[wsname];
    //             /* Convert array of arrays */
    //             const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
    //             /* Update state */
    //             let FilteredData = data.filter(e => e.length);
    //             //remove header form base array
    //             console.log("hi");
    //             FilteredData.shift();
    //             console.log("hi");
    //             for (let i = 0; i < FilteredData.length; i++) {
    //                 FilteredData[i].splice(0, 1, '' + FilteredData[i][0]);//Convert Source System to String
    //                 FilteredData[i].splice(1, 1, '' + FilteredData[i][1]);//Convert Site ID to String
    //                 FilteredData[i].splice(2, 1, '' + FilteredData[i][2]);//Convert ERP PLANT NO  to string
    //                 FilteredData[i].splice(3, 1, '' + FilteredData[i][3]);//Convert Company code to string   
    //                 FilteredData[i].splice(4, 1, '' + FilteredData[i][4]);//Convert Profit center code to string 
    //                 //Convert Comments to string                  

    //                 //Add timestamp        
    //                 FilteredData[i][6] = (this.getTimeStamp().toString());
    //                 //Add timestamp        
    //                 FilteredData[i][7] = (this.getTimeStamp().toString());
    //                 FilteredData[i][8] = 'I';


    //             }
    //             console.log(FilteredData, "filter");
    //             console.log("hi");

    //             // Blank Value Validation 
    //             let flg_siteid = 0;
    //             let flg_sourcesystem = 0;
    //             let flg_plantno = 0;
    //             let flg_companycode = 0;
    //             let flg_profitcenter = 0;

    //             let dup_arr = [];
    //             let excel_err_msg = [];
    //             let all_site_data = [];
    //             let all_sourcesystem_data = [];

    //             let all_plantno_data = [];
    //             let all_companycode_data = [];
    //             let all_profitcenter_data = [];

    //             let flg_validation = 'F';

    //             for (let i = 0; i < FilteredData.length; i++) {
    //                 let excel_sourcesystem = FilteredData[i][0];
    //                 let excel_siteid = FilteredData[i][1];
    //                 let excel_plantno = FilteredData[i][2];
    //                 let excel_companycode = FilteredData[i][3];
    //                 let excel_profitcenter = FilteredData[i][4];
    //                 let excel_mapping = FilteredData[i][5];


    //                 let arr_dup = [excel_siteid, excel_plantno, excel_companycode, excel_profitcenter];
    //                 dup_arr.push(arr_dup);

    //                 if (excel_sourcesystem === '' || excel_sourcesystem === undefined || excel_sourcesystem === 'undefined') {
    //                     flg_sourcesystem = 1;
    //                     flg_validation = 'T'
    //                 }
    //                 else {
    //                     all_sourcesystem_data.push(excel_sourcesystem); console.log(all_site_data, "all");
    //                 }

    //                 if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
    //                     flg_siteid = 1;
    //                     flg_validation = 'T'
    //                 }
    //                 else {
    //                     all_site_data.push(excel_siteid); console.log(all_site_data, "all");
    //                 }
    //                 if (excel_plantno === '' || excel_plantno === undefined || excel_plantno === 'undefined') {
    //                     flg_plantno = 1;
    //                     flg_validation = 'T'
    //                 }
    //                 else {
    //                     all_plantno_data.push(excel_plantno); console.log(all_plantno_data, "matell");
    //                 }
    //                 if (excel_companycode === '' || excel_companycode === undefined || excel_companycode === 'undefined') {
    //                     flg_companycode = 1;
    //                     flg_validation = 'T'
    //                 }
    //                 else {
    //                     all_companycode_data.push(excel_companycode); console.log(all_companycode_data, "allerp sup");
    //                 }
    //                 if (excel_profitcenter === '' || excel_profitcenter === undefined || excel_profitcenter === 'undefined') {
    //                     flg_profitcenter = 1;
    //                     flg_validation = 'T'
    //                 }
    //                 else {
    //                     all_profitcenter_data.push(excel_profitcenter); console.log(all_profitcenter_data, "allerp sup");
    //                 }

    //                 if (excel_mapping === '' || excel_mapping === undefined || excel_mapping === 'undefined') {
    //                     FilteredData[i][5] = this.state.UIDData;
    //                 } else {
    //                     FilteredData[i][5] = (this.state.UIDData + "--" + excel_mapping);
    //                 }


    //             } let flg_duplicate = 0;
    //             const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
    //             console.log(duplicate_count, "dupcount");
    //             if (duplicate_count > 0) {
    //                 flg_duplicate = 1;
    //             }
    //             if (flg_duplicate === 1) {
    //                 alert(`Duplicate Data found in template : 
    //                 Could not Upload the data. Please try again.`);
    //                 this.setState({ loading: false });
    //             }
    //             else {

    //                 let arr_err_siteID = [];
    //                 let arr_excelSiteIds = [];
    //                 let arr_sourcesystem = [];
    //                 let arr_err_sourcesystem = [];

    //                 let arr_plantno = [];
    //                 let arr_err_plantno = [];
    //                 let arr_companycode = [];
    //                 let arr_err_companycode = [];
    //                 let arr_profitcenter = [];
    //                 let arr_err_profitcenter = [];




    //                 const js_profit_data = JSON.stringify(all_profitcenter_data);
    //                 console.log(js_profit_data, "profit");
    //                 fetch(`${config.host_url}/api/profitcenter`, {
    //                     method: "PUT",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                     },
    //                     body: js_profit_data,
    //                 })
    //                     .then((response) => response.json())
    //                     .then((response) => {
    //                         this.setState({
    //                             all_profit_data: response
    //                         });

    //                         let result_profitcenter = [];

    //                         for (var i in this.state.all_profit_data) {
    //                             result_profitcenter.push(this.state.all_profit_data[i].PROFIT_CENTER);
    //                         }
    //                         console.log(result_profitcenter, "result_profit");

    //                         console.log("hi");


    //                         let allSiteId = [];
    //                         for (var j in this.state.siteIds) {
    //                             allSiteId.push((this.state.siteIds[j].title).toString());
    //                         }
    //                         console.log("hi");


    //                         let all_plantno = [];
    //                         for (var k in this.state.plantno) {
    //                             all_plantno.push((this.state.plantno[k].title).toString());

    //                         }
    //                         console.log("hi");

    //                         let all_companycode = [];
    //                         for (var l in this.state.companycode) {
    //                             all_companycode.push((this.state.companycode[l].title).toString());
    //                         }
    //                         console.log("hi");

    //                         let all_sourcesystem = [];
    //                         console.log(this.state.sourceSystem.text, "a");
    //                         console.log(this.state.sourceSystem.title, "b");

    //                         for (var n in this.state.sourceSystem) {
    //                             all_sourcesystem.push((this.state.sourceSystem[n].text));
    //                         }
    //                         console.log(all_sourcesystem, "sys");

    //                         console.log("hi");
    //                         for (let i = 0; i < FilteredData.length; i++) {
    //                             let val_sourcesystem = FilteredData[i][0].toString();

    //                             let val_site = FilteredData[i][1].toString();
    //                             let val_plantno = FilteredData[i][2].toString();
    //                             let val_companycode = FilteredData[i][3].toString();
    //                             let val_profitcenter = FilteredData[i][4].toString();

    //                             let cnt_match_sourcesystem = 0;

    //                             let cnt_match_site = 0;
    //                             let cnt_match_plant = 0;
    //                             let cnt_match_companycode = 0;
    //                             let cnt_match_profitcenter = 0;


    //                             if (all_sourcesystem.includes(val_sourcesystem)) {
    //                                 cnt_match_sourcesystem = 1;
    //                                 arr_sourcesystem.push(val_sourcesystem);
    //                             }
    //                             if (cnt_match_sourcesystem === 0) {
    //                                 arr_err_sourcesystem.push(val_sourcesystem);
    //                             }

    //                             if (allSiteId.includes(val_site)) {
    //                                 cnt_match_site = 1;
    //                                 arr_excelSiteIds.push(val_site);
    //                             }
    //                             if (cnt_match_site === 0) {
    //                                 arr_err_siteID.push(val_site);
    //                             }
    //                             if (all_plantno.includes(val_plantno)) {
    //                                 cnt_match_plant = 1;
    //                                 arr_plantno.push(val_plantno);
    //                             }
    //                             if (cnt_match_plant === 0) {
    //                                 arr_err_plantno.push(val_plantno);
    //                             }
    //                             if (result_profitcenter.includes(val_profitcenter)) {
    //                                 cnt_match_profitcenter = 1;
    //                                 arr_profitcenter.push(val_profitcenter);
    //                             }
    //                             if (cnt_match_profitcenter === 0) {
    //                                 arr_err_profitcenter.push(val_profitcenter);
    //                             }
    //                             if (all_companycode.includes(val_companycode)) {
    //                                 cnt_match_companycode = 1;
    //                                 arr_companycode.push(val_companycode);
    //                             }
    //                             if (cnt_match_companycode === 0) {
    //                                 arr_err_companycode.push(val_companycode);
    //                             }
    //                         }

    //                         console.log("hi");

    //                         if (arr_err_sourcesystem.length > 0) {
    //                             if (arr_err_sourcesystem.includes('undefined')) {
    //                                 flg_sourcesystem = 1;
    //                                 flg_validation = 'T';
    //                             }
    //                             else {
    //                                 flg_validation = 'T';
    //                                 flg_sourcesystem = 2;
    //                             }
    //                         }
    //                         console.log("hi");
    //                         if (arr_err_siteID.length > 0) {
    //                             if (arr_err_siteID.includes('undefined')) {
    //                                 flg_siteid = 1;
    //                                 flg_validation = 'T';
    //                             }
    //                             else {
    //                                 flg_validation = 'T';
    //                                 flg_siteid = 2;
    //                             }
    //                         }
    //                         if (arr_err_plantno.length > 0) {
    //                             if (arr_err_plantno.includes('undefined')) {
    //                                 flg_plantno = 1;
    //                                 flg_validation = 'T';
    //                             }
    //                             else {
    //                                 flg_validation = 'T';
    //                                 flg_plantno = 2;
    //                             }
    //                         }
    //                         if (arr_err_companycode.length > 0) {
    //                             if (arr_err_companycode.includes('undefined')) {
    //                                 flg_companycode = 1;
    //                                 flg_validation = 'T';
    //                             }
    //                             else {
    //                                 flg_validation = 'T';
    //                                 flg_companycode = 2;
    //                             }
    //                         } if (arr_err_profitcenter.length > 0) {
    //                             if (arr_err_profitcenter.includes('undefined')) {
    //                                 flg_profitcenter = 1;
    //                                 flg_validation = 'T';
    //                             }
    //                             else {
    //                                 flg_validation = 'T';
    //                                 flg_profitcenter = 2;
    //                             }
    //                         }

    //                         if (flg_validation === 'T') {
    //                             if (flg_siteid === 1) {
    //                                 excel_err_msg.push("Site ID can't be blank. \n");
    //                             }

    //                             if (flg_siteid === 2) {
    //                                 excel_err_msg.push("Site IDs not found in base table " + arr_err_siteID);
    //                             }
    //                             if (flg_sourcesystem === 1) {
    //                                 excel_err_msg.push("SourceSystem can't be blank. \n");
    //                             }

    //                             if (flg_sourcesystem === 2) {
    //                                 excel_err_msg.push("Sourcesystem not found in base table " + arr_err_sourcesystem);
    //                             }

    //                             if (flg_plantno === 1) {
    //                                 excel_err_msg.push("PLANT NO can't be blank. \n");
    //                             }
    //                             if (flg_plantno === 2) {
    //                                 excel_err_msg.push("PLANT NO not found in base table " + arr_err_plantno);
    //                             }

    //                             if (flg_companycode === 1) {
    //                                 excel_err_msg.push("COMPANY CODE  can't be blank. \n");
    //                             }
    //                             if (flg_companycode === 2) {
    //                                 excel_err_msg.push("COMPANY CODE  not found in base table " + arr_err_companycode);
    //                             }

    //                             if (flg_profitcenter === 1) {
    //                                 excel_err_msg.push("PROFIT CENTER  can't be blank. \n");
    //                             }
    //                             if (flg_profitcenter === 2) {
    //                                 excel_err_msg.push("profit center  not found in base table " + arr_err_profitcenter);
    //                             }
    //                             alert("Validation Failed :\n " + excel_err_msg);
    //                             this.setState({ loading: false });
    //                         }
    //                         else {

    //                             const site_data = (JSON.stringify(FilteredData));
    //                             console.log(site_data, "site");
    //                             fetch(`${config.host_url}/api/siteid/massupload`, {
    //                                 method: "PUT",
    //                                 headers: {
    //                                     "Content-Type": "application/json",
    //                                 },
    //                                 body: site_data,
    //                             })
    //                                 .then((response) => response.json())
    //                                 .then((response) => {
    //                                     this.setState({
    //                                         loading: false
    //                                     });
    //                                     let str_msg = JSON.stringify(response[0]);
    //                                     alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
    //                                     this.resetComponent();
    //                                 })
    //                                 .catch((error) => {
    //                                     this.setState({ loading: false });
    //                                     alert(`Could not Upload the data. Please try again.`);
    //                                 });
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         this.setState({ loading: false });
    //                         alert(`Material API data error`);
    //                     });
    //             }

    //         }; reader.readAsBinaryString(f);

    //     }
    // }

    render() {

        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}></Loader>
                {
                    this.state.showPrime &&
                    <Grid className="main-body">
                        <Grid.Row>
                            <Grid.Column width={12}></Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <label style={{ fontSize: 24 }}>SITE ID  Maintenance</label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        //  label="ERP Plant No"
                                        placeholder="P2P SITE ID"
                                        value={this.state.searchSiteId}
                                        onChange={(value) => this.setState({ searchSiteId: value })}
                                        disabled={this.state.disablesiteid}
                                    />


                                    <Button content="Validate" style={{ paddingTop: "50" }}
                                        onClick={() => { this.validateSiteID(this.state.searchSiteId); }} 
                                        />&nbsp;&nbsp;

                        </div>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        //  label="ERP Plant No"

                                        placeholder="ERP Plant No"
                                        value={this.state.searchPlant}
                                        onChange={(value) => this.setState({ searchPlant: value })}
                                        disabled={this.state.disableplant}

                                    />
                                    {/* {/* <Button content="Validate" style={{ paddingTop: "20" visibility: this.state.buton2 ? 'visible' : 'hidden' }}
                                        onClick={() => { this.validateErpPlant(this.state.searchPlant); }} 
                                       
                                         />&nbsp;&nbsp; */}
{/* style={{ paddingTop: "10" }} 
                            // onClick={() => { this.validateVendor(this.state.searchDunsNum);
                              />&nbsp;&nbsp; */}
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        // label="ERP Plant No"
                                        placeholder="Company Code"
                                        value={this.state.searchCompanycode}
                                        onChange={(value) => this.setState({ searchCompanycode: value })}
                                        disabled={this.state.disableCompanycode}

                                    />
                                    {/* <Button content="Validate" style={{ paddingTop: "20" , visibility: this.state.buton ? 'visible' : 'hidden' }}
                                        onClick={() => { this.validateCompanyCode(this.state.searchCompanycode); }}
                                        
                                        />&nbsp;&nbsp; */}
{/* {/* style={{ paddingTop: "10" }} 
                             onClick={() => { this.validateVendor(this.state.searchDunsNum);
                              />&nbsp;&nbsp; */}
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        //  label="ERP Plant No"
                                        placeholder="Profit Center"
                                        value={this.state.searchprofit}
                                        onChange={(value) => this.setState({ searchprofit: value })}
                                        disabled={this.state.disableprofit}

                                    />
                                    {/* <Button content="Validate" style={{ paddingTop: "20" , visibility: this.state.buton ? 'visible' : 'hidden' }}
                                        onClick={() => { this.validateProfit(this.state.searchprofit); }} 
                                       
                                        />&nbsp;&nbsp; */}


                                </div>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Button content="Search"
                                        onClick={() => { this.searchSiteID(this.state.searchSiteId); }}

                                    />

                                    <Button content="Add"
                                        onClick={this.handleAddSelect}
                                        disabled={this.state.disableadd} style={{ "float": "left" }}
                                    />
                                    <Button content="Clear"
                                        onClick={this.resetComponent}
                                    />
                                    <Button content="Update"
                                        onClick={this.handleUpdateSelect}

                                    />
                                    <Button content="Dim Plant"
                                        disabled={false}
                                        onClick={this.handleDIMplant}
                                    />
                                    <br />

                                    <br />


                                    <Divider />
                                    <br></br>

                                    <label style={{ "paddingTop": 50 }}> Mass Upload <b>WITHOUT VALIDATE: </b> &nbsp;&nbsp;</label>
                                    <input type="file" id="file" ref="fileUploader"
                                        onChange={this.filePathset.bind(this)}
                                    />
                                    <button
                                        onClick={() => { this.submitFile(); }}
                                        disabled={this.state.submitDisabled}
                                    >Submit File</button>
                                    <a href={template} download="SiteId Maintenance Template.xlsx" style={{ "paddingLeft": 40 }}>
                                        <Button content="Download Template"></Button>
                                    </a>
                                    <Divider />

                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Select
                                        options={this.state.sourceSystem}
                                        placeholder="Source system"
                                        value={this.state.sourceSystemselected}
                                        onChange={(value) =>
                                            this.setState({ sourceSystemselected: value })
                                        }
                                        disabled={this.state.disabless}
                                    />

                                    <Select
                                        options={this.state.sbgdata}
                                        placeholder="SBG"
                                        value={this.state.sbgselected}
                                        onChange={(value) =>
                                            this.setState({ sbgselected: value })
                                        }
                                        disabled={this.state.disablesbe}
                                    />
                                    <Select
                                        options={this.state.sbedata}
                                        placeholder="SBE"
                                        value={this.state.sbeselected}
                                        onChange={(value) =>
                                            this.setState({ sbeselected: value })
                                        }
                                        disabled={this.state.disablesbg}
                                    />
                                    {/* <Select
                                    options={this.state.sbgOptions}
                                    placeholder="SBG Code*"
                                    value={this.state.sbgSelected}
                                    onChange={(value) => this.setState({ sbgSelected: value })
                                    }
                                /> */}

                                    {/* <Select
                                    options={this.state.sbeOptions}
                                    placeholder="SBE Code"
                                    disabled={this.state.sbeDisabled}
                                    //results={this.state.resultssbe}
                                    value={this.state.sbeSelected}
                                    // onResultSelect={(value) =>this.handleSearchSbeSelect(value)}
                                    // onSearchChange={(value) =>this.handleSearchChangeSbeName(value)}
                                    onChange={(value) =>
                                        this.setState({ sbeSelected: value })
                                    }
                                    style={{ paddingLeft: 10 }}
                                /> */}

                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12}></Grid.Column>
                        </Grid.Row>
                        <br />
                        <br />
                        <Grid.Row>
                            <br />
                            <br />
                            <Grid.Column width={12}>
                                <div style={{ "paddingBottom": 5 }}>
                                    <DataTable
                                        data={this.state.currentData}
                                        selectionMode="single"
                                        rows={10}
                                        search={true}
                                        selection={this.state.selectedItem}
                                        onSelectionChange={
                                            (eve) => {
                                                this.handleCheckBoxSelect(eve)
                                            }
                                        }
                                    >

                                        <DataTable.Column
                                            field="OS_SITE_ID"
                                            header="OS_SITE_ID "
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="PLANT"
                                            header="Plant ID"
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="SOURCE_SYSTEM"
                                            header="SOURCE_SYSTEM "
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="SBG"
                                            header="SBG"
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="SBE"
                                            header="SBE "
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="COMPANY_CODE"
                                            header="COMPANY_CODE "
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="PROFIT_CENTER"
                                            header="PROFIT_CENTER"
                                            sortable={false}
                                        />
                                        <DataTable.Column
                                            field="MAPPING_FIELD"
                                            header="MAPPING_FIELD"
                                            sortable={false}
                                        />

                                        <DataTable.Pagination />
                                    </DataTable>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }

                {this.state.showDimPlant &&
                    <Grid>
                        <br />
                        <Grid.Row>
                            <Grid.Column width={12}></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <label style={{ fontSize: 24 }}>DIM Plant  Maintenance</label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    {/* <Select
                                        options={this.state.sourceSystem}
                                        placeholder="Source system"
                                        value={this.state.sourceSystemselected}
                                        onChange={(value) =>
                                            this.setState({ sourceSystemselected: value })
                                        }
                                    />
                                    <Select
                                        options={this.state.sbgOptions}
                                        placeholder="SBG Code*"
                                        value={this.state.sbgSelected}
                                        onChange={(value) => (this.callSbe(value))
                                        }
                                    />

                                    <Select
                                        options={this.state.sbeOptions}
                                        placeholder="SBE Code"
                                        disabled={this.state.sbeDisabled}
                                        //results={this.state.resultssbe}
                                        value={this.state.sbeSelected}
                                        // onResultSelect={(value) =>this.handleSearchSbeSelect(value)}
                                        // onSearchChange={(value) =>this.handleSearchChangeSbeName(value)}
                                        onChange={(value) =>
                                            this.setState({ sbeSelected: value })
                                        }
                                        style={{ paddingLeft: 10 }}
                                    />
                                    <Select
                                        options={this.state.sbeOptions}
                                        placeholder="SBU Code"
                                        disabled={this.state.sbeDisabled}
                                        //results={this.state.resultssbe}
                                        value={this.state.sbeSelected}
                                        // onResultSelect={(value) =>this.handleSearchSbeSelect(value)}
                                        // onSearchChange={(value) =>this.handleSearchChangeSbeName(value)}
                                        onChange={(value) =>
                                            this.setState({ sbeSelected: value })
                                        }
                                        style={{ paddingLeft: 10 }}
                                    /> */}

                                    {/* <Select
                                        options={this.state.sourceSystem}
                                        placeholder="Source system"
                                        value={this.state.sourceSystemselected}
                                        onChange={(value) =>
                                            this.setState({ sourceSystemselected: value })
                                        }
                                    /> */}

                                    <Input
                                        label="Source System"
                                        placeholder="OneSource"
                                        disabled={true}
                                    />


                                    <br />
                                    <br />
                                    <Select
                                        options={this.state.sbgdata}
                                        placeholder="SBG"
                                        value={this.state.sbgselected}
                                        onChange={(value) =>
                                            this.setState({ sbgselected: value })
                                        }
                                    />
                                    <Input
                                        // label="ERP Plant No"
                                        placeholder="SBG NAME"
                                        value={this.state.selectedsbg_name}
                                        onChange={(value) => this.setState({ selectedsbg_name: value })}
                                    // disabled={true}
                                    />

                                    <br />
                                    <br />

                                    <Select
                                        options={this.state.sbedata}
                                        placeholder="SBE"
                                        value={this.state.sbeselected}
                                        onChange={(value) =>
                                            this.setState({ sbeselected: value })
                                        }
                                    />

                                    <Input
                                        // label="ERP Plant No"
                                        placeholder="SBE NAME"
                                        value={this.state.selectedsbe_name}
                                        onChange={(value) => this.setState({ selectedsbe_name: value })}
                                    // disabled={true}
                                    />

                                    <br />
                                    <br />
                                    <Input
                                        // label="ERP Plant No"
                                        placeholder="SBU"
                                        value={this.state.selectedsbu}
                                        onChange={(value) => this.setState({ selectedsbu: value })}
                                    // disabled={true}
                                    />
                                    <Input
                                        // label="ERP Plant No"
                                        placeholder="SBU NAME"
                                        value={this.state.selectedsbu_name}
                                        onChange={(value) => this.setState({ selectedsbu_name: value })}
                                    // disabled={true}
                                    />

                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row><Grid.Column>
                            <div>
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="SITE ID"
                                    value={this.state.selectedsite_id}
                                    onChange={(value) => this.setState({ selectedsite_id: value })}
                                // disabled={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="SITE ID NAME"
                                    value={this.state.selectedsite_id_name}
                                    onChange={(value) => this.setState({ selectedsite_id_name: value })}
                                // disabled={true}
                                />

                            </div>
                        </Grid.Column>

                        </Grid.Row>
                        <Grid.Row><Grid.Column>
                            <div>
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="PLANT"
                                    value={this.state.selectedsite_id}
                                    onChange={(value) => this.setState({ selectedsite_id: value })}
                                    disabled={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="PLANT SK"
                                    value={this.state.selectedsite_id}
                                    onChange={(value) => this.setState({ selectedsite_id: value })}
                                    disabled={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="PLANT NAME"
                                    value={this.state.selectedplantname}
                                    onChange={(value) => this.setState({ selectedplantname: value })}
                                // disabled={true}
                                />


                            </div>
                        </Grid.Column>

                        </Grid.Row>

                        <Grid.Row><Grid.Column>
                            <div>
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="COUNTRY NAME"
                                    value={this.state.selectedcountryname}
                                    onChange={(value) => this.setState({ selectedcountryname: value })}
                                // disabled={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="CITY"
                                    value={this.state.selectedcity}
                                    onChange={(value) => this.setState({ selectedcity: value })}
                                // disabled={true}={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="STATE"
                                    value={this.state.selectedstate}
                                    onChange={(value) => this.setState({ selectedstate: value })}
                                // disabled={true}={true}
                                />

                            </div>
                        </Grid.Column>

                        </Grid.Row>
                        <Grid.Row><Grid.Column>
                            <div>
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="ADDRESS 1"
                                    value={this.state.selectedaddr_l1}
                                    onChange={(value) => this.setState({ selectedaddr_l1: value })}
                                // disabled={true}={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="ADDRESS 2"
                                    value={this.state.selectedaddr_l2}
                                    onChange={(value) => this.setState({ selectedaddr_l2: value })}
                                // disabled={true}={true}
                                />

                            </div>
                        </Grid.Column>

                        </Grid.Row>

                        <Grid.Row><Grid.Column>
                            <div>
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="LATITUDE"
                                    value={this.state.selectedlat}
                                    onChange={(value) => this.setState({ selectedlat: value })}
                                // disabled={true}={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="LONGITUDE"
                                    value={this.state.selectedlon}
                                    onChange={(value) => this.setState({ selectedlon: value })}
                                // disabled={true}={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="GEOGRAPHY"

                                    value={this.state.selectedgeo}
                                    onChange={(value) => this.setState({ selectedgeo: value })}
                                // disabled={true}={true}
                                />
                                <Input
                                    // label="ERP Plant No"
                                    placeholder="GEOGRAPHY_NAME"

                                    value={this.state.selectedgeo_name}
                                    onChange={(value) => this.setState({ selectedgeo_name: value })}
                                // disabled={true}={true}
                                />

                            </div>
                        </Grid.Column>

                        </Grid.Row>
                        <Grid.Row><Grid.Column>
                            <div>

                                <Button content="Add"
                                    onClick={this.handleDimAddSelect}
                                // disabled={this.state.disableadd} style={{ "float": "left" }}
                                />
                                <Button content="Clear"
                                    onClick={this.clearDimPlant}
                                />
                                <Button content="BACK"
                                    onClick={this.resetComponent}
                                />
                            </div>
                        </Grid.Column>

                        </Grid.Row>

                    </Grid>



                }

            </div>
        );
    }
}