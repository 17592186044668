import React from "react";
import * as XLSX from "xlsx";
import { Button, Search, Loader, Grid, Card, TextArea, Input, Select, Radio, Divider } from "@scuf/common";
import { DataTable } from "@scuf/datatable";
import { Redirect } from 'react-router-dom';
import duns_template from '../resources/Vendor_DUNS_MAINTENANCE_TEMPLATE.xlsx';
import scorecard_template from '../resources/Vendor_Scorecard.xlsx';
import erp_template from '../resources/ERP_Vendor.xlsx';
import mpo_template from '../resources/MPO_Vendor.xlsx';
const config = require('../config');

export default class vendorMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,//Constructor variables
            //site variable
            searchSiteId: "",
            tologin: false,
            searchSiteName: "",
            resultsSiteName: [],
            resultsSiteId: [],
            resultsId: [],
            sitesNames: [],
            siteIds: [],
            err_siteid: "",
            searchData: [],
            searchScorecardData: [],
            //Duns Variables
            searchDunsNum: "",
            searchDunsName: "",
            //resultsDunsName: [],
            vendorDuns: [],
            vendorSearchTabDuns: [],
            resultsSubComSk: [],
            err_duns: "",
            //Global Duns Variables
            searchGlobalDunsNum: "",
            searchGlobalDunsName: "",
            err_globalDuns: "",
            //ERP Vendor Variables
            searchErpVendorNum: "",
            searchErpVendorName: "",
            err_erpVendor: "",
            vendorSearchTabErp: [],
            //Alternative Vendor Group Name Variables
            resultsAltVenGrpName: [],
            searchAltVenGrpName: "",
            altVenGrpName: [],
            err_altVendor: "",
            //ERP Plant Num Variables
            resultsErpPlantNum: [],
            searchErpPlantNum: "",
            erpPlantNum: [],
            err_erpPlant: "",
            //Company Code Variables
            resultsCompanyCode: [],
            searchCompanyCode: "",
            companyCode: [],
            err_companyCode: "",
            //Purchasing Org Variables
            resultsPurchasingOrg: [],
            searchPurchasingOrg: "",
            purchasingOrg: [],
            err_purcahsing: "",

            all_vendor_data: [],
            selectedfile: "",
            erpSelectedfile: "",
            mpoSelectedfile: "",
            scorecardSelectedfile: "",
            //Vendor Maintenance Variables
            primaryCountry: "",
            customGeo: "",
            interComFlag: "",
            vendorStatus: "",
            vendorType: "",
            subComSk: "",
            freezeFlag: "",
            venAerStrategy: "",
            venHbtStrategy: "",
            venSpsStrategy: "",
            venPmtStrategy: "",
            venHdqStrategy: "",
            venCoeStrategy: "",
            venCoeCode: "",
            venPmtCode: "",
            venSpsCode: "",
            allSubComSK: [],
            ltaNum: "",
            ltaRev: "",
            venOnContract: "",
            cageCode: "",
            lat: "",
            lon: "",
            //ERP Vendor Variable
            plantVenNo: "",
            rdbvalueAlwaysOTD: "",
            rdbvalueFreeze: "",
            earlyDelivery: "",
            lateDelivery: "",
            rollingBusiness: "",
            err_rollingbusiness: "",
            //MPO Variables

            searchMpoData: [],
            rdbvalueMpoIndicator: "",
            venPhaseGate: "",
            venGateInfo: "",
            venComcode: "",
            venNs_Site: "",
            searchMpoSiteId: "",
            searchMpoSourcesystem: "",
            spendType: "",
            apType: "",
            // mpoNsSiteData: [],
            showCC: true,

            mposourcedisable: false,
            radiodisable: false,
            mpositedisable: false,
            mpovendornamedisable: false,
            mpoerpvendordisable: false,
            mpoccdisable: false,
            mpons_sitedisable:true,

            //Scorecard variables
            sbgSelected: "",
            selectedScorecardItem: [],
            sbeSelected: "",
            resultssbe: [],
            reportName: "",
            reportFileName: "",
            reportLevel: "",
            reportFrequency: "",
            vendorContactTitle: "",
            vendorContactFirstName: "",
            vendorContactLastName: "",
            corpVendorContact: "",
            aeroVendorContact: "",
            hbtVendorContact: "",
            pmtVendorContact: "",
            spsVendorContact: "",
            executiveContact: "No",
            activeFlag: "No",
            corpReviewer: "",
            corpCC: "",
            aerReviewer: "",
            aerCC: "",
            hbtReveiwer: "",
            hbtCC: "",
            pmtReviewer: "",
            pmtCC: "",
            spsReveiwer: "",
            spsCC: "",
            scorecardStatus: "Not yet generated",
            comments: "",
            err_reportLevel: "",
            err_reportFrequency: "",
            err_contact: "",
            err_Reviewer: "",
            sbeDisabled: true,

            vendorScorecardDisabled: true,
            vendorGroupScorecardDisabled: true,
            vendorAltNameScorecardDisabled: true,
            scorecardSubmitDisabled: true,
            erpSubmitDisabled: true,
            mpoSubmitDisabled: true,
            submitDisabled: true,

            //Show Cards
            showPrimeCard: true,
            showVendorCard: false,
            showERPVendorCard: false,
            showERPVendorMPOCard: false,
            showVendorScorecardCard: false,
            showBulkUpload: false,

            disableSearch: true,
            disableVendorButton: true,
            disableOtherButtons: true,

            addErpVendorDisabled: true,
            updateErpVenodrDisabled: true,
            selectedItem: [],
        };
    }
    resetComponent = () => {
        this.setState({
            searchData: [],
            searchSiteId: "",
            searchSiteName: "",
            resultsSiteName: [],
            resultsSiteId: [],
            resultsId: [],
            selectedfile: "",
            erpSelectedfile: "",
            mpoSelectedfile: "",
            scorecardSelectedfile: "",
            loading: false,//reset variables
            selectedItem: [],
            searchDunsNum: "",
            searchDunsName: "",
            searchScorecardData: [],
            all_vendor_data: [],

            searchGlobalDunsNum: "",
            searchGlobalDunsName: "",

            searchErpVendorNum: "",
            searchErpVendorName: "",

            resultsAltVenGrpName: [],
            searchAltVenGrpName: "",

            resultsErpPlantNum: [],
            searchErpPlantNum: "",

            resultsCompanyCode: [],
            searchCompanyCode: "",

            resultsPurchasingOrg: [],
            searchPurchasingOrg: "",

            //Show Cards
            showPrimeCard: true,
            showVendorCard: false,
            showERPVendorCard: false,
            showERPVendorMPOCard: false,
            showVendorScorecardCard: false,
            showBulkUpload: false,
            scorecardSubmitDisabled: true,
            erpSubmitDisabled: true,
            mpoSubmitDisabled: true,
            submitDisabled: true,

            disableSearch: true,
            disableVendorButton: true,
            disableOtherButtons: true,

            //Vendor Maintenance Variables
            primaryCountry: "",
            customGeo: "",
            interComFlag: "",
            vendorStatus: "",
            vendorType: "",
            subComSk: "",
            freezeFlag: "",
            venAerStrategy: "",
            venHbtStrategy: "",
            venSpsStrategy: "",
            venPmtStrategy: "",
            venHdqStrategy: "",
            venCoeStrategy: "",
            venCoeCode: "",
            hbtCoeCode: "",
            venPmtCode: "",
            venSpsCode: "",
            ltaNum: "",
            ltaRev: "",
            venOnContract: "",
            cageCode: "",
            lat: "",
            lon: "",
            vendorSearchTabDuns: [],

            //ERP Vendor Variable
            plantVenNo: "",
            rdbvalueAlwaysOTD: "",
            rdbvalueFreeze: "",
            earlyDelivery: "",
            lateDelivery: "",
            rollingBusiness: "",
            vendorSearchTabErp: [],
            err_rollingbusiness: "",

            //MPO Variables
            rdbvalueMpoIndicator: "",
            venPhaseGate: "",
            venGateInfo: "",
            venComcode: "",
            venNs_Site:"",
            searchMpoSiteId: "",
            searchMpoSourcesystem: "",
            spendType: "",
            apType: "",

            //Scorecard variable            
            sbgSelected: "",
            selectedScorecardItem: [],
            sbeSelected: "",
            reportName: "",
            reportFileName: "",
            reportLevel: "",
            reportFrequency: "",
            vendorContactTitle: "",
            vendorContactFirstName: "",
            vendorContactLastName: "",
            corpVendorContact: "",
            aeroVendorContact: "",
            hbtVendorContact: "",
            pmtVendorContact: "",
            spsVendorContact: "",
            executiveContact: "No",
            activeFlag: "No",
            corpReviewer: "",
            corpCC: "",
            aerReviewer: "",
            aerCC: "",
            hbtReveiwer: "",
            hbtCC: "",
            pmtReviewer: "",
            pmtCC: "",
            spsReveiwer: "",
            spsCC: "",
            scorecardStatus: "Not yet generated",
            comments: "",
            err_reportLevel: "",
            err_reportFrequency: "",
            err_contact: "",
            err_Reviewer: "",
            sbeDisabled: true,

            vendorScorecardDisabled: true,
            vendorGroupScorecardDisabled: true,
            vendorAltNameScorecardDisabled: true,
        });
    }

    resetScorecardComponent = () => {
        this.setState({
            //Scorecard variable
            sbgSelected: "",
            selectedScorecardItem: [],
            sbeSelected: "",
            resultssbe: [],
            reportName: "",
            reportFileName: "",
            reportLevel: "",
            reportFrequency: "",
            vendorContactTitle: "",
            vendorContactFirstName: "",
            vendorContactLastName: "",
            corpVendorContact: "",
            aeroVendorContact: "",
            hbtVendorContact: "",
            pmtVendorContact: "",
            spsVendorContact: "",
            executiveContact: "No",
            activeFlag: "No",
            corpReviewer: "",
            corpCC: "",
            aerReviewer: "",
            aerCC: "",
            hbtReveiwer: "",
            hbtCC: "",
            pmtReviewer: "",
            pmtCC: "",
            spsReveiwer: "",
            spsCC: "",
            scorecardStatus: "Not yet generated",
            comments: "",
            err_reportLevel: "",
            err_reportFrequency: "",
            err_contact: "",
            err_Reviewer: "",
            sbeDisabled: true,

            vendorScorecardDisabled: true,
            vendorGroupScorecardDisabled: true,
            vendorAltNameScorecardDisabled: true,
        });
    }

    //--------------Add title functions--------------

    getTimeStamp = () => {
        return Math.floor((new Date()).getTime() / 1000);
    }
    siteIdToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.sitetitle = oldObj.PLANT_NAME;
            newObj.siteid = oldObj.PLANT_SK;
            newObj.title = oldObj.PLANT_SK;
            newObjects.push(newObj);
        }
        //alert(newObjects);
        return newObjects;
    }
    siteNameToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.siteid = oldObj.PLANT_SK;
            newObj.sitetitle = oldObj.PLANT_NAME;
            newObj.title = oldObj.PLANT_NAME;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    subComOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.SUB_COMMODITY_OWNER_SK;
            newObj.title = oldObj.SUB_COMMODITY_OWNER_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setErpPlantNum = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.erpplantnum = oldObj.PLANT_SK;
            newObj.title = oldObj.PLANT_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setCompanyCode = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.companycode = oldObj.COMPANY_CODE;
            newObj.title = oldObj.COMPANY_CODE;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setNsSiteData = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.ns_site = oldObj.NS_SITE;
            newObj.title = oldObj.NS_SITE;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setPurchasingOrg = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.purchasingorg = oldObj.PURCHASING_ORG;
            newObj.title = oldObj.PURCHASING_ORG;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setSbgOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setSbeOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.SBE_NAME;
            newObj.value = oldObj.SBE;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setGeoOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setBusinessOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setTypeOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setStrategyOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setCoeOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setHbtCoeOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setSpendOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setApOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setGatesOptions = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    setGateInfo = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VENDOR_MPO_GATE_INFO;
            newObj.value = oldObj.VENDOR_MPO_GATE_INFO;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    callSbe = (value) => {
        this.setState({ sbgSelected: value, loading: true, sbeDisabled: false });
        fetch(`${config.host_url}/api/vendor/sbe/${value}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    sbeOptions: this.setSbeOptions(data),
                    loading: false
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                alert(`SBE API data error`);
            });
    }

    //--------------Mount function-----------
    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/vendor/erp_plantnum`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    erpPlantNum: this.setErpPlantNum(data),
                });
                fetch(`${config.host_url}/api/vendor/company_code`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            companyCode: this.setCompanyCode(data),
                        });
                        fetch(`${config.host_url}/api/vendor/ns_site`)
                            .then((res) => res.json())
                            .then((data) => {
                                this.setState({
                                    mpoNsSiteData: this.setNsSiteData(data),
                                });
                                fetch(`${config.host_url}/api/vendor/purchasing_org`)
                                    .then((res) => res.json())
                                    .then((data) => {
                                        this.setState({
                                            purchasingOrg: this.setPurchasingOrg(data),
                                        });
                                        fetch(`${config.host_url}/api/site`)
                                            .then((res) => res.json())
                                            .then((data) => {
                                                this.setState({
                                                    sitesNames: this.siteNameToTitle(data),
                                                    siteIds: this.siteIdToTitle(data),
                                                });
                                                fetch(`${config.host_url}/api/vendor/subcomsk`)
                                                    .then((res) => res.json())
                                                    .then((data) => {
                                                        this.setState({
                                                            allSubComSK: this.subComOptions(data),
                                                        });
                                                        fetch(`${config.host_url}/api/vendor/sbg`)
                                                            .then((response) => response.json())
                                                            .then((data) => {
                                                                this.setState({
                                                                    sbgOptions: this.setSbgOptions(data),
                                                                });
                                                                fetch(`${config.host_url}/api/vendor/sbe`)
                                                                    .then((response) => response.json())
                                                                    .then((data) => {
                                                                        this.setState({
                                                                            sbeOptions: this.setSbeOptions(data),
                                                                        });
                                                                        fetch(`${config.host_url}/api/vendor/geography`)
                                                                            .then((response) => response.json())
                                                                            .then((data) => {
                                                                                this.setState({
                                                                                    geoOptions: this.setGeoOptions(data),
                                                                                });
                                                                                fetch(`${config.host_url}/api/vendor/businessStatus`)
                                                                                    .then((response) => response.json())
                                                                                    .then((data) => {
                                                                                        this.setState({
                                                                                            businessOptions: this.setBusinessOptions(data),
                                                                                        });
                                                                                        fetch(`${config.host_url}/api/vendor/type`)
                                                                                            .then((response) => response.json())
                                                                                            .then((data) => {
                                                                                                this.setState({
                                                                                                    typeOptions: this.setTypeOptions(data),
                                                                                                });
                                                                                                fetch(`${config.host_url}/api/vendor/strategy`)
                                                                                                    .then((response) => response.json())
                                                                                                    .then((data) => {
                                                                                                        this.setState({
                                                                                                            strategyOptions: this.setStrategyOptions(data),
                                                                                                        });
                                                                                                        fetch(`${config.host_url}/api/vendor/coe`)
                                                                                                            .then((response) => response.json())
                                                                                                            .then((data) => {
                                                                                                                this.setState({
                                                                                                                    coeOptions: this.setCoeOptions(data),
                                                                                                                });
                                                                                                                fetch(`${config.host_url}/api/vendor/hbtcoe`)
                                                                                                                    .then((response) => response.json())
                                                                                                                    .then((data) => {
                                                                                                                        this.setState({
                                                                                                                            hbtcoeOptions: this.setHbtCoeOptions(data),
                                                                                                                        });
                                                                                                                        fetch(`${config.host_url}/api/vendor/spendType`)
                                                                                                                            .then((response) => response.json())
                                                                                                                            .then((data) => {
                                                                                                                                this.setState({
                                                                                                                                    spendOptions: this.setSpendOptions(data),
                                                                                                                                });

                                                                                                                                fetch(`${config.host_url}/api/vendor/apType`)
                                                                                                                                    .then((response) => response.json())
                                                                                                                                    .then((data) => {
                                                                                                                                        this.setState({
                                                                                                                                            apOptions: this.setApOptions(data),
                                                                                                                                        });

                                                                                                                                        fetch(`${config.host_url}/api/vendor/mpoGates`)
                                                                                                                                            .then((response) => response.json())
                                                                                                                                            .then((data) => {
                                                                                                                                                this.setState({
                                                                                                                                                    gatesOptions: this.setGatesOptions(data),
                                                                                                                                                });

                                                                                                                                                fetch(`${config.host_url}/api/vendor/gateInfo`)
                                                                                                                                                    .then((response) => response.json())
                                                                                                                                                    .then((data) => {
                                                                                                                                                        this.setState({
                                                                                                                                                            gateInfo: this.setGateInfo(data),
                                                                                                                                                        });
                                                                                                                                                        //call user details
                                                                                                                                                        fetch(`${config.host_url}/user`, {
                                                                                                                                                            credentials: 'include'//fetch won't send cookies unless you set credentials
                                                                                                                                                        })
                                                                                                                                                            .then(response => response.json())
                                                                                                                                                            .then(response => {
                                                                                                                                                                this.setState(
                                                                                                                                                                    {
                                                                                                                                                                        loading: false,
                                                                                                                                                                         UIDData: response.sub.toUpperCase()
                                                                                                                                                                    });
                                                                                                                                                            })
                                                                                                                                                            .catch((err) => {
                                                                                                                                                                alert(`Connection Issue with User Data.Please try again`);
                                                                                                                                                                this.setState({
                                                                                                                                                                    loading: false,
                                                                                                                                                                    // tologin:true,
                                                                                                                                                                });
                                                                                                                                                                  window.location.href = `${config.host_url}/login`;
                                                                                                                                                            });
                                                                                                                                                    })
                                                                                                                                                    .catch((error) => {
                                                                                                                                                        this.setState({ loading: false });
                                                                                                                                                        alert(`Gates Info data error`);
                                                                                                                                                    });
                                                                                                                                            })
                                                                                                                                            .catch((error) => {
                                                                                                                                                this.setState({ loading: false });
                                                                                                                                                alert(`MPO Gates data error`);
                                                                                                                                            });
                                                                                                                                    })
                                                                                                                                    .catch((error) => {
                                                                                                                                        this.setState({ loading: false });
                                                                                                                                        alert(`Ap Type data error`);
                                                                                                                                    });
                                                                                                                            })
                                                                                                                            .catch((error) => {
                                                                                                                                this.setState({ loading: false });
                                                                                                                                alert(`Spend Type data error`);
                                                                                                                            });
                                                                                                                    })
                                                                                                                    .catch((error) => {
                                                                                                                        this.setState({ loading: false });
                                                                                                                        alert(`HBT Vendor COE data error`);
                                                                                                                    });
                                                                                                            })
                                                                                                            .catch((error) => {
                                                                                                                this.setState({ loading: false });
                                                                                                                alert(`Aero Vendor COE data error`);
                                                                                                            });
                                                                                                    })
                                                                                                    .catch((error) => {
                                                                                                        this.setState({ loading: false });
                                                                                                        alert(`Strategy data error`);
                                                                                                    });
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                this.setState({ loading: false });
                                                                                                alert(`Vendor Type data error`);
                                                                                            });
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        this.setState({ loading: false });
                                                                                        alert(`Business status data error`);
                                                                                    });
                                                                            })
                                                                            .catch((error) => {
                                                                                this.setState({ loading: false });
                                                                                alert(`Geography data error`);
                                                                            });
                                                                    })
                                                                    .catch((error) => {
                                                                        this.setState({ loading: false });
                                                                        alert(`SBE API data error`);
                                                                    });
                                                            })
                                                            .catch((error) => {
                                                                this.setState({ loading: false });
                                                                alert(`SBG API data error`);
                                                            });
                                                    })
                                                    .catch((err) => {
                                                        alert(`Connection Issue.Supplier Sub commodity.Please try again`);
                                                        this.setState({
                                                            loading: false,
                                                        });
                                                    });
                                            })
                                            .catch((err) => {
                                                alert(`Connection Issue.Site data Please try again`);
                                                this.setState({
                                                    loading: false,
                                                });
                                            });
                                    })
                                    .catch((err) => {
                                        alert(`Connection Issue.Purchasing Org.data Please try again`);
                                        this.setState({
                                            loading: false,
                                        });
                                    });
                            })
                            .catch((err) => {
                                alert(`Connection Issue.Company code data Please try again`);
                                this.setState({
                                    loading: false,
                                });
                            });
                    })
                    .catch((err) => {
                        alert(`Connection Issue.Company code data Please try again`);
                        this.setState({
                            loading: false,
                        });
                    });
            })
            .catch((err) => {
                alert(`Connection Issue.ERP Plant Data Please try again`);
                this.setState({
                    loading: false,
                });
            });
    }
    //---------Autofill functions--------------
    //Site
    handleSearchChangeSites = (searchText) => {
        this.setState({
            //resultsSiteName: this.state.sitesNames.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchChangeId = (searchText) => {
        this.setState({
            resultsSiteId: this.state.siteIds.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())
            ), searchText,
            resultsId: this.state.siteIds
        });
    }
    handleSearchSiteSelect = (result) => {
        this.setState({
            //loading: true,
            searchSiteName: result.sitetitle,
            searchSiteId: result.siteid,
        });
    }
    handleSearchIdSelect = (result) => {
        this.setState({
            //loading: true,
            searchSiteId: result.siteid,
            searchSiteName: result.sitetitle,
            disableSearch: false
        });
    }

    validateERPVendor = (searchText) => {
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/erp_vendor/${searchText}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        vendorCheck: data
                    });
                    if (this.state.vendorCheck.length > 0) {
                        this.setState({
                            searchErpVendorNum: searchText,
                            searchErpVendorName: this.state.vendorCheck[0].VENDOR_NAME,
                            loading: false,
                            disableSearch: false,
                            disableVendorButton: false,
                            disableOtherButtons: false,
                            //searchErpVendorName: this.state.vendorSetName.erpvendorname
                        });
                    }
                    else {
                        alert(`ERP Vendor ID not found`);
                        this.setState({ loading: false, disableSearch: true });
                    }
                }).catch((err) => {

                    alert(`Connection Issue.ERP Vendor data Please try again`);
                    this.setState({ loading: false, });
                });
        }
    }
    validateVendor = (searchText) => {
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/duns/${searchText}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        vendorDuns: data
                    });
                    if (this.state.vendorDuns.length > 0) {
                        this.setState({
                            searchDunsNum: searchText,
                            searchDunsName: this.state.vendorDuns[0].VENDOR_FULL_NM,
                            loading: false,
                            disableSearch: false,
                            disableVendorButton: false,
                            disableOtherButtons: false,
                            updateErpVendorDisabled: false,
                        });
                    }
                    else {
                        alert(`Vendor ID not found`);
                        this.setState({
                            loading: false, disableSearch: true,
                            updateErpVendorDisabled: true
                        });

                    }
                }).catch((err) => {

                    alert(`Connection Issue.Vendor data Please try again`);
                    this.setState({ loading: false, });
                });
        }
    }
    validateVendorGroup = (searchText) => {
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/global_duns/${searchText}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        vendorGroupCheck: data
                    });
                    if (this.state.vendorGroupCheck.length > 0) {
                        this.setState({
                            searchGlobalDunsNum: searchText,
                            searchGlobalDunsName: this.state.vendorGroupCheck[0].VENDOR_GLOBAL_ULTIMATE_NAME,
                            loading: false,
                            disableSearch: false,
                            disableVendorButton: false,
                            disableOtherButtons: false,
                            //searchGlobalDunsName: this.state.vendorSetName.erpvendorname
                        });
                    }
                    else {
                        alert(`Vendor Group ID not found`);
                        this.setState({ loading: false, disableSearch: true });

                    }
                }).catch((err) => {

                    alert(`Connection Issue.Vendor Group data Please try again`);
                    this.setState({ loading: false, });
                });
        }
    }
    validateAltGrpName = (searchText) => {
        let sendSearch;
        if (searchText === '') {
            alert(`Text cannot be blank`);
        }
        else {
            sendSearch = searchText.toString().toUpperCase();
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/alt_vendor_grp/${sendSearch}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        vendorAltGroupCheck: data
                    });
                    if (this.state.vendorAltGroupCheck.length > 0) {
                        this.setState({
                            searchAltVenGrpName: sendSearch,
                            loading: false,
                            disableSearch: false,
                            disableVendorButton: false,
                            disableOtherButtons: false,
                        });
                    }
                    else {
                        alert(`Alternate Group Name not found`);
                        this.setState({ loading: false, disableSearch: true });
                    }
                }).catch((err) => {

                    alert(`Connection Issue.Alternate Group data not found`);
                    this.setState({ loading: false, });
                });
        }
    }

    //ERP Plant Num Functions
    handleSearchChangeErpPlant = (searchText) => {
        this.setState({
            resultsErpPlantNum: this.state.erpPlantNum.filter(item => item.title.toLowerCase().includes(searchText)),
            searchText
        });
    }
    handleSearchSelectErpPlant = (result) => {
        this.setState({
            searchErpPlantNum: result.erpplantnum,
            disableSearch: false
        });
    }
    //Company Code Functions
    handleSearchChangeCompanyCode = (searchText) => {
        this.setState({
            resultsCompanyCode: this.state.companyCode.filter(item => item.title.toLowerCase().includes(searchText)),
            searchText
        });
    }
    handleSearchSelectCompanyCode = (result) => {
        this.setState({
            searchCompanyCode: result.companycode,
            disableSearch: false
        });
    }
    //Purchasing Org Functions
    handleSearchChangePurchasingOrg = (searchText) => {
        if (searchText !== null)
            this.setState({
                resultsPurchasingOrg: this.state.purchasingOrg.filter(item =>
                    item.title.toLowerCase().includes(searchText.toLowerCase())
                ), searchText
            });
    }
    handleSearchSelectPurchasingOrg = (result) => {
        this.setState({
            searchPurchasingOrg: result.purchasingorg,
            disableSearch: false
        });
    }
    //Sub Cmmodity
    handleSearchChangeSubCommodity = (searchText) => {
        this.setState({
            resultsSubComSk: this.state.allSubComSK.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }
    handleSearchSelectSubCommodity = (result) => {
        this.setState({
            subComSk: result.text,
        });
    }
    //search button
    handleSearchSelect = () => {
        this.setState({
            err_siteid: "",
            err_erpVendor: "",
            err_duns: "",
            err_globalDuns: "",
            err_altVendor: "",
            err_companyCode: "",
            err_erpPlant: "",
            err_purcahsing: "",
            loading: true
        });
        if (this.state.searchSiteId === '' &&
            this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '') {
            console.warn("in 1");
            //Mandatory Fields error check
            this.setState({
                err_siteid: " ",
                err_erpVendor: " ",
                err_duns: " ",
                err_globalDuns: " ",
                err_altVendor: " ",
                err_companyCode: " ",
                err_erpPlant: " ",
                err_purcahsing: " ",
                loading: false
            });
            alert("Please enter mandatory Fields and Validate");
            this.setState({ loading: false });
        }
        //OS_SITE_ID
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId !== '') {
            console.warn("in 2");
            alert(`Please select Site and Vendor ID/ VEndor ERP Number`);
            this.setState({ loading: false });
            // fetch(`${config.host_url}/api/vendor/search/site/${this.state.searchSiteId}`)
            //     .then((res) => res.json())
            //     .then((data) => {
            //         this.setState({
            //             searchData: data,
            //             loading: false,
            //             disableOtherButtons: false,
            //             disableVendorButton: false,
            //         });
            //     })
            //     .catch((err) => {
            //         this.setState({ loading: false });
            //         alert(`Could not perform Search, Please try again.`);
            //     });

        }
        //VENDOR_SK
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum !== '' &&
            this.state.searchSiteId === '') {
            console.warn("in 3");
            fetch(`${config.host_url}/api/vendor/search/erp_vendor/${this.state.searchErpVendorNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });
        }

        //search for Vendor Duns
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum !== '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId === '') {
            console.warn("in 4");
            fetch(`${config.host_url}/api/vendor/search/vendor/${this.state.searchDunsNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });

        }
        else if (this.state.searchAltVenGrpName !== '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId === '') {
            console.warn("in 5");
            //Search for AltVenGrpName
            fetch(`${config.host_url}/api/vendor/search/altvengrpname/${this.state.searchAltVenGrpName}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });
        }
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum !== '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId === '') {
            console.warn("in 6");
            //ERP Plant Num
            fetch(`${config.host_url}/api/vendor/search/erpplantnum/${this.state.searchErpPlantNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });

        }
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum !== '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId === '') {
            console.warn("in 7");
            //Global Duns
            fetch(`${config.host_url}/api/vendor/search/global_duns/${this.state.searchGlobalDunsNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });

        }
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg !== '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId === '') {
            console.warn("in 8");
            //purchasing org
            fetch(`${config.host_url}/api/vendor/search/purchasing_org/${this.state.searchPurchasingOrg}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });
        }
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode !== '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum === '' &&
            this.state.searchSiteId === '') {
            console.warn("in 9");
            //Company code
            fetch(`${config.host_url}/api/vendor/search/companycode/${this.state.searchCompanyCode}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });

        }
        else if (this.state.searchAltVenGrpName === '' &&
            this.state.searchSiteId !== '' &&
            this.state.searchErpPlantNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.searchPurchasingOrg === '' &&
            this.state.searchCompanyCode === '' &&
            this.state.searchDunsNum === '' &&
            this.state.searchErpVendorNum !== '') {
            console.warn("in 10");
            //Check for ERP Mapping
            fetch(`${config.host_url}/api/vendor/search/${this.state.searchSiteId}/${this.state.searchErpVendorNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });

                    if (this.state.searchData.length === 0) {
                        this.setState({ disableVendorButton: false, disableOtherButtons: true });
                    }
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });
        }
        else if (this.state.searchSiteId !== '' &&
            this.state.searchErpVendorNum !== '' &&
            this.state.searchDunsNum !== '') {
            console.warn("in 11");
            fetch(`${config.host_url}/api/vendor/search/${this.state.searchSiteId}/${this.state.searchErpVendorNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchData: data,
                        loading: false,
                        disableOtherButtons: false,
                        disableVendorButton: false,
                    });
                    if (this.state.searchData.length === 0) {
                        this.setState({ disableVendorButton: false, disableOtherButtons: true });
                    }
                })
                .catch((err) => {

                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });

        }

    }

    handleSelectCheckBox = (eve) => {
        if (eve.length > 0) {
            this.setState({
                selectedItem: eve,
                searchSiteId: eve[0].OS_SITE_ID,
                searchErpVendorNum: eve[0].VENDOR_SK,
                primaryCountry: eve[0].VENDOR_PRIMARY_COUNTRY,
                customGeo: eve[0].CUSTOM_GEO,
                searchSiteName: eve[0].PLANT_NAME,
                searchGlobalDunsNum: eve[0].VENDOR_GLOBAL_ULTIMATE_DUNS,
                searchDunsNum: eve[0].VENDOR_DUNS_NUMBER,
                searchGlobalDunsName: eve[0].VENDOR_GLOBAL_ULTIMATE_NAME,
                searchErpVendorName: eve[0].VENDOR_NAME,
                searchDunsName: eve[0].VENDOR_FULL_NM,
                searchPurchasingOrg: eve[0].PURCHASING_ORG,
                //searchCompanyCode: eve[0].COMPANY_CODE,
            });
        }
        else {
            this.setState({
                selectedItem: eve,
                searchSiteId: "",
                searchErpVendorNum: "",
                searchDunsNum: "",
                primaryCountry: "",
                customGeo: "",
                searchSiteName: "",
                searchGlobalDunsName: "",
                searchErpVendorName: "",
                searchDunsName: "",
                searchPurchasingOrg: "",
                //searchCompanyCode: "",
            });
        }
    }

    //{MPO}

    handleSelectMpoCheckBox = (eve) => {
        if (eve.length > 0) {

            console.log(eve.length);
            this.setState({
                selectedItem: eve,
                searchMpoSourcesystem: eve[0].SOURCE_SYSTEM,
                searchMpoSiteId: eve[0].OS_SITE_ID,
                searchErpVendorNum: eve[0].VENDOR_SK,
                searchErpVendorName: eve[0].VENDOR_NAME,
                venComcode: eve[0].COMPANY_CODE,
                venNs_Site: eve[0].NS_SITE,
                rdbvalueMpoIndicator: eve[0].VENDOR_MPO_FLAG,
                venPhaseGate: eve[0].VENDOR_MPO_PHASE_GATES,
                venGateInfo: eve[0].VENDOR_MPO_GATE_INFO

                //searchCompanyCode: eve[0].COMPANY_CODE,
            });

            if (eve[0].OS_SITE_ID === 'ALL') {
                this.setState({
                    radiodisable: true,
                    mpositedisable: true,
                    mpovendornamedisable: true,
                    mpoerpvendordisable: true,
                    mpoccdisable: true,
                    mposourcedisable: true,
                    mpons_sitedisable:true,
                    showCC: true,


                })
            }
            else {
                this.setState({
                    radiodisable: false,
                    mpositedisable: true,
                    mpovendornamedisable: false,
                    mpoerpvendordisable: true,
                    mpoccdisable: true,
                    mpons_sitedisable: true,
                    mposourcedisable: true,
                    showCC: false,
                    venNs_Site: eve[0].OS_SITE_ID,
                })
            }
        }
        else {
            this.setState({
                selectedItem: eve,
                searchMpoSiteId: "",
                searchMpoSourcesystem: "",
                searchErpVendorNum: "",
                searchErpVendorName: "",
                venComcode: "",
                venNs_Site: "",
                rdbvalueMpoIndicator: "",
                venPhaseGate: "",
                venGateInfo: "",
                radiodisable: false,
                mpositedisable: false,
                mpovendornamedisable: false,
                mpoerpvendordisable: false,
                mpoccdisable: true,
                mpons_sitedisable:true,

            });
        }

    }


    handleVendorSelect = () => {
        //Search API call
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/vendor/search/tab/vendor/${this.state.searchDunsNum}`)
            .then((res) => res.json())
            .then((data) => {
                console.warn("Data is ", data);
                console.warn("Data len is ", data.length);
                if (data.length > 0) {
                    this.setState({
                        vendorSearchTabDuns: data,
                        loading: false,
                        showPrimeCard: false,
                        showVendorCard: true,
                        primaryCountry: data[0].VENDOR_PRIMARY_COUNTRY,
                        ltaNum: data[0].LTA_NUM,
                        ltaRev: data[0].LTA_REVISION,
                        customGeo: data[0].CUSTOM_GEO,
                        interComFlag: data[0].INTER_COMPANY_FLAG,
                        subComSk: data[0].SUB_COMMODITY_OWNER_SK,
                        vendorStatus: data[0].VENDOR_STATUS,
                        vendorType: data[0].VEDOR_TYPE,
                        lat: data[0].LATITUDE,
                        lon: data[0].LONGITUDE,
                        searchGlobalDunsNum: data[0].VENDOR_GLOBAL_ULTIMATE_DUNS,
                        freezeFlag: data[0].FREEZE_FLAG,
                        searchAltVenGrpName: data[0].ALTERNATE_GROUP_NAME,
                        cageCode: data[0].CAGE_CODE,
                        venAerStrategy: data[0].VENDOR_AER_STRATERGY,
                        venHbtStrategy: data[0].VENDOR_HBT_STRATERGY,
                        venHdqStrategy: data[0].VENDOR_HDQ_STRATERGY,
                        venCoeStrategy: data[0].VENDOR_COE_STRATEGY,
                        venSpsStrategy: data[0].VENDOR_SPS_STRATERGY,
                        venPmtStrategy: data[0].VENDOR_PMT_STRATERGY,
                        hbtCoeCode: data[0].VENDOR_HBT_COE_CODE,
                        venCoeCode: data[0].VENDOR_COE_CODE,
                        venPmtCode: data[0].VENDOR_PMT_COE_CODE,
                    });
                } else {
                    this.setState({ loading: false });
                    alert(`Could not find selected Vendor ID.`);

                }

                if (this.state.freezeFlag === 0) {
                    this.setState({
                        grpDisable: false,
                    });
                }
                else {
                    this.setState({ grpDisable: true });
                }
            })
            .catch((err) => {

                this.setState({ loading: false });
                alert(`Could not perform Search, Please try again.`);
            });
    }

    handleERPVendorSelect = () => {
        //Insert Vendor Updations
        if (this.state.searchSiteId === '' || this.state.searchErpVendorNum === '') {
            alert(`Please select Site ID and ERP Vendor ID`);
        }
        else {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/search/tab/erpvendor/${this.state.searchErpVendorNum}/${this.state.searchSiteId}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        vendorSearchTabErp: data,
                        loading: false,
                        showPrimeCard: false,
                        showERPVendorCard: true,
                    });
                    if (this.state.vendorSearchTabErp.length > 0) {
                        this.setState({
                            addErpVendorDisabled: true,
                            updateErpVendorDisabled: false,
                            searchDunsNum: this.state.vendorSearchTabErp[0].VENDOR_DUNS_NUMBER,
                            plantVenNo: this.state.vendorSearchTabErp[0].SITE_CORP_SUPPLIER_PREFERRED,
                            rdbvalueAlwaysOTD: this.state.vendorSearchTabErp[0].ALWAYS_OTD,
                            rdbvalueFreeze: this.state.vendorSearchTabErp[0].FREEZE_FLAG,
                            earlyDelivery: this.state.vendorSearchTabErp[0].DELIVERY_DAYS_EARLY,
                            lateDelivery: this.state.vendorSearchTabErp[0].DELIVERY_DAYS_LATE,
                            rollingBusiness: this.state.vendorSearchTabErp[0].ROLLING_12_MONTH_BUSINESS_STATUS,
                            searchDunsName: this.state.vendorSearchTabErp[0].VENDOR_FULL_NM,
                        });
                    }
                    else {
                        this.setState({ updateErpVendorDisabled: true, addErpVendorDisabled: false });
                    }
                    if (this.state.rdbvalueFreeze === 0) {
                        this.setState({ disableDuns: false });
                    }
                    else {
                        this.setState({ disableDuns: true });
                    }
                }).catch((err) => {
                    this.setState({ loading: false });
                    alert(`Could not perform Search, Please try again.`);
                });
        }
    }

    handleERPVendorMPOSelect = () => {
        if (this.state.searchSiteId === '' || this.state.searchErpVendorNum === '') {
            alert(`Please select Site ID and ERP Vendor ID`);
        }
        else {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/search/tab/mpovendor/${this.state.searchErpVendorNum}/${this.state.searchSiteId}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        vendorSearchTabMpo: data,
                        loading: false,
                        showPrimeCard: false,
                        showERPVendorMPOCard: true,
                        showVendorCard: false,
                        showERPVendorCard: false
                    });
                    if (this.state.vendorSearchTabMpo.length > 0) {
                        if (this.state.vendorSearchTabMpo[0].OS_SITE_ID === 'ALL') {
                            this.setState({
                                radiodisable: true,
                                mpositedisable: true,
                                mpovendornamedisable: true,
                                mpoerpvendordisable: true,
                                mpoccdisable: true,
                                mposourcedisable: true,
                                mpons_sitedisable: true,


                            })
                        } else {
                            this.setState({
                                radiodisable: false,
                                mpositedisable: true,
                                mpovendornamedisable: false,
                                mpoerpvendordisable: true,
                                mpoccdisable: true,
                                mpons_sitedisable:true,
                                showCC: false,
                                venNs_Site: this.state.vendorSearchTabMpo[0].OS_SITE_ID,

                            })
                        }

                        this.setState({
                            searchMpoSourcesystem: this.state.vendorSearchTabMpo[0].SOURCE_SYSTEM,
                            searchMpoSiteId: this.state.vendorSearchTabMpo[0].OS_SITE_ID,
                            rdbvalueMpoIndicator: this.state.vendorSearchTabMpo[0].VENDOR_MPO_FLAG,
                            venPhaseGate: this.state.vendorSearchTabMpo[0].VENDOR_MPO_PHASE_GATES,
                            venGateInfo: this.state.vendorSearchTabMpo[0].VENDOR_MPO_GATE_INFO,
                            venComcode: this.state.vendorSearchTabMpo[0].COMPANY_CODE,
                            venNs_Site: this.state.vendorSearchTabMpo[0].NS_SITE,
                            //spendType: this.state.vendorSearchTabMpo[0].SPEND_TYPE_SK,
                            // apType: this.state.vendorSearchTabMpo[0].ACCOUNT_TYPE,
                            searchErpPlantNum: this.state.vendorSearchTabMpo[0].PLANT_SK,
                            searchErpVendorName: this.state.vendorSearchTabMpo[0].VENDOR_NAME,
                        });

                    }

                }).catch((err) => {
                    this.setState({ loading: false });
                    alert(`No Results found, Please try again.`);
                });
        }
    }

    handleVendorScoreCardSelect = () => {
        //Insert Vendor Updations
        if (this.state.searchDunsNum !== '') {
            //Search query on duns in scorecard intermediate table
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/vendor/search/tab/vendorscorecard/${this.state.searchDunsNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        searchScorecardData: data,
                        loading: false,
                        showPrimeCard: false,
                        showERPVendorMPOCard: false,
                        showVendorCard: false,
                        showERPVendorCard: false,
                        showVendorScorecardCard: true,
                    });
                })
        }
        else {
            this.setState({
                showPrimeCard: false,
                showVendorScorecardCard: true,
            });
        }
    }

    handleBulkUploadSelect = () => {
        this.setState({
            showPrimeCard: false,
            showBulkUpload: true
        })
    }

    handleSearchSbeSelect = (result) => {
        this.setState({
            sbeSelected: result.value,
        });
    }

    handleSearchChangeSbeName = (searchText) => {
        this.setState({
            resultssbe: this.state.sbeOptions.filter(item => item.value.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }

    selectCloseVendor = () => {
        this.setState({
            showPrimeCard: true,
            showVendorCard: false,
            showERPVendorCard: false,
            showERPVendorMPOCard: false,
            showVendorScorecardCard: false,
            showBulkUpload: false
        });
    }
    //Vendor tab
    vendorUpdate = () => {
        const vendorTabDuns = this.state.vendorSearchTabDuns[0];
        this.setState({
            //set error states
            flg_option: 'U',
        });

        if (//Check mandatory fields
            0) {
            alert(`Enter mandatory fields!`);
        }
        else {
            this.setState({
                loading: true,
                flg_option: 'U',
            });
            let onContract = '';
            let latitude_update, longi_update = '';
            if (this.state.ltaNum !== '') {
                this.setState({ venOnContract: 1 });
                onContract = 1;
            }
            else {
                this.setState({ venOnContract: 0 });
                onContract = 0;
            }
            if ((this.state.lat === '' && this.state.lon === '') ||
                (this.state.lat === null && this.state.lon === null)) {
                this.setState({ lat: 0, lon: 0 });
                latitude_update = 0;
                longi_update = 0;
            }
            else {
                latitude_update = this.state.lat;
                longi_update = this.state.lon;
            }
            const updatevalue = {
                I_VENDOR_DUNS_NUM: vendorTabDuns.DUNS_NUM,
                I_VENDOR_PRIMARY_COUNTRY: vendorTabDuns.VENDOR_PRIMARY_COUNTRY,
                I_CUSTOM_GEO: this.state.customGeo,
                I_INTER_COMPANY_FLAG: this.state.interComFlag,
                I_VENDOR_STATUS: this.state.vendorStatus,
                I_VEDOR_TYPE: this.state.vendorType,
                I_SUB_COMMODITY_OWNER_SK: this.state.subComSk,
                I_VENDOR_GLOBAL_ULTIMATE_DUNS: this.state.searchGlobalDunsNum,
                I_FREEZE_FLAG: this.state.freezeFlag,
                I_ALTERNATE_GROUP_NAME: this.state.searchAltVenGrpName,
                I_VENDOR_AER_STRATERGY: this.state.venAerStrategy,
                I_VENDOR_HBT_STRATERGY: this.state.venHbtStrategy,
                I_VENDOR_SPS_STRATERGY: this.state.venSpsStrategy,
                I_VENDOR_PMT_STRATERGY: this.state.venPmtStrategy,
                I_VENDOR_HDQ_STRATERGY: this.state.venHdqStrategy,
                I_VENDOR_COE_STRATEGY: this.state.venCoeStrategy,
                I_VENDOR_COE_CODE: this.state.venCoeCode,
                I_VENDOR_HBT_COE_CODE: this.state.hbtCoeCode,
                I_VENDOR_PMT_COE_CODE: this.state.venPmtCode,
                I_LTA_NUM: this.state.ltaNum,
                I_LTA_REVISION: this.state.ltaRev,
                I_VENDOR_ON_LTA_FLAG: onContract,
                I_CAGE_CODE: this.state.cageCode,
                I_LATITUDE: latitude_update,
                I_LONGITUDE: longi_update,
                I_LAST_CHANGE_DATE: this.getTimeStamp().toString(),
                I_FLAG: 'U',
                I_UPDATED_BY: this.state.UIDData,
                //Delete
                D_VENDOR_DUNS_NUM: vendorTabDuns.DUNS_NUM,
                D_FLAG: 'D'
            };

            console.log(updatevalue, "updatevalue");

            fetch(`${config.host_url}/api/vendor/updatevendor`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatevalue),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                        //addDisabled: true,
                        //updateDisabled: false,
                        showPrimeCard: true,
                        showVendorCard: false
                    });
                    this.handleSearchSelect();
                    alert(`Vendor Record Updated.`);
                    this.resetComponent();
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not update data. Please try again`);
                });
        }
    }
    //ERP Vendor tab
    erpVendorUpdate = () => {
        this.setState({
            //set error states
            flg_option: 'A',
        });

        console.log()

        if (//Check mandatory fields And need default Values
            0) {
            alert(`Enter mandatory fields!`);
        }
        else {
            this.setState({
                loading: true,
                flg_option: 'A',
            });
            let early_Delivery = '';
            let late_Delivery = '';
            let ear = this.state.earlyDelivery;
            console.log(ear, "ear");
            if ((this.state.earlyDelivery === '') || (this.state.earlyDelivery === null)) {
                this.setState({ earlyDelivery: 0 })
                early_Delivery = 0;
            }

            else {
                early_Delivery = this.state.earlyDelivery;
            }
            if ((this.state.lateDelivery === '') || (this.state.lateDelivery === null)) {
                this.setState({ lateDelivery: 0 })
                late_Delivery = 0;
            }

            else {
                late_Delivery = this.state.lateDelivery;
            }
            console.log(early_Delivery, "early");
            console.log(late_Delivery, "early");

            const updatevalue = {
                U_VENDOR_SK: this.state.searchErpVendorNum,
                U_OS_SITE_ID: this.state.searchSiteId,
                U_SITE_CORP_SUPPLIER_PREFERRED: this.state.plantVenNo,
                U_VENDOR_DUNS_NUMBER: this.state.searchDunsNum,
                U_ALWAYS_OTD: this.state.rdbvalueAlwaysOTD,
                U_DELIVERY_DAYS_EARLY: early_Delivery,
                U_DELIVERY_DAYS_LATE: late_Delivery,
                U_FREEZE_FLAG: this.state.rdbvalueFreeze,
                U_ROLLING_12_MONTH_BUSINESS_STATUS: this.state.rollingBusiness,
                U_LAST_CHANGE_DATE: this.getTimeStamp().toString(),
                U_FLAG: 'U',
                U_UPDATED_BY: this.state.UIDData

            };
            fetch(`${config.host_url}/api/vendor/erpupdatevendor`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatevalue),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                        addErpVendorDisabled: true,
                        updateErpVendorDisabled: false,
                        showPrimeCard: true,
                        showVendorCard: false
                    });
                    this.handleSearchSelect();
                    alert(`ERP Vendor Record Updated.`);
                    this.resetComponent();
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not Update data. Please try again.`);
                });

        }
    }
    erpVendorAdd = () => {
        if (this.state.rollingBusiness === '') {
            alert(`Enter Rolling Business state`);
            this.setState({
                err_rollingbusiness: " ",
            });
        }
        else {
            this.setState({
                loading: true,
                flg_option: 'A',
            });
            const addvalue = {
                D_FLAG: 'D',
                I_VENDOR_SK: this.state.searchErpVendorNum,
                I_OS_SITE_ID: this.state.searchSiteId,
                I_SITE_CORP_SUPPLIER_PREFERRED: this.state.plantVenNo,
                I_VENDOR_DUNS_NUMBER: this.state.searchDunsNum,
                I_ALWAYS_OTD: this.state.rdbvalueAlwaysOTD,
                I_DELIVERY_DAYS_EARLY: this.state.earlyDelivery,
                I_DELIVERY_DAYS_LATE: this.state.lateDelivery,
                I_FREEZE_FLAG: this.state.rdbvalueFreeze,
                I_ROLLING_12_MONTH_BUSINESS_STATUS: this.state.rollingBusiness,
                I_LAST_CHANGE_DATE: this.getTimeStamp().toString(),
                I_UPDATED_BY: this.state.UIDData,
                I_FLAG: 'I'
            };
            fetch(`${config.host_url}/api/erpvendor/add`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(addvalue),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                        addErpVendorDisabled: true,
                        updateErpVendorDisabled: false,
                    });
                    //this.handleVendorSearchSelect();
                    alert(`ERP Vendor Record Added.`);
                    this.resetComponent();
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not add data. Please try again`);
                });
        }
    }
    //MPO vendor Tab
    mpoVendorUpdate = () => {
        if (0) { }
        else {
            this.setState({
                loading: true,
                flg_option: 'A',
            });

            console.log(this.state.venPhaseGate," hhhhh");

            if((this.state.venPhaseGate)=== ''|| (this.state.venPhaseGate)=== 'null' ){
               
                alert(` MPO Phase gates is mandatory `);
                this.setState({ loading: false});

            }else{

            const updatevalue = {
                D_FLAG: 'D',
                I_SOURCE_SYSTEM: this.state.searchMpoSourcesystem,
                I_VENDOR_SK: this.state.searchErpVendorNum,
                I_OS_SITE_ID: this.state.searchMpoSiteId,
                //  I_PLANT_SK: this.state.searchErpPlantNum,
                I_VENDOR_MPO_FLAG: this.state.rdbvalueMpoIndicator,
                I_VENDOR_MPO_PHASE_GATES: this.state.venPhaseGate,
                I_VENDOR_MPO_GATE_INFO: this.state.venGateInfo,
                // I_SPEND_TYPE: this.state.spendType,
                // I_ACCOUNT_TYPE: this.state.apType,
                I_COMPANY_CODE: this.state.venComcode,
                I_NS_SITE: this.state.venNs_Site,
                I_LAST_CHANGE_DATE: this.getTimeStamp().toString(),
                I_FLAG: 'U',
                I_UPDATED_BY: this.state.UIDData
            };
            fetch(`${config.host_url}/api/vendor/mpoupdatevendor`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatevalue),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                    });
                    this.handleSearchSelect();
                    alert(`MPO Vendor Record Updated.`);
                    this.resetComponent();
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not Update data. Please try again`);
                });
        }}
    }

    //Scorecard Specific 
    handleSelectScorecardCheckBox = (eve) => {
        if (eve.length > 0) {
            this.setState({
                selectedScorecardItem: eve,
                sbgSelected: eve[0].SBG_ID,
                sbeSelected: eve[0].SBE_ID,
                reportName: eve[0].REPORT_NAME,
                reportFileName: eve[0].REPORT_FILE_NAME,
                reportLevel: eve[0].REPORT_LEVEL,
                reportFrequency: eve[0].REPORT_FREQUENCY,
                vendorContactTitle: eve[0].VENDOR_CONTACT_TITLE,
                vendorContactFirstName: eve[0].VENDOR_CONTACT_FIRST_NAME,
                vendorContactLastName: eve[0].VENDOR_CONTACT_LAST_NAME,
                corpVendorContact: eve[0].CORP_VENDOR_CONTACT,
                aeroVendorContact: eve[0].AERO_VENDOR_CONTACT,
                hbtVendorContact: eve[0].HBT_VENDOR_CONTACT,
                pmtVendorContact: eve[0].PMT_VENDOR_CONTACT,
                spsVendorContact: eve[0].SPS_VENDOR_CONTACT,
                executiveContact: eve[0].EXECUTIVE_CONTACT,
                activeFlag: eve[0].SCORE_CARD_ACITVE_FLAG,
                corpReviewer: eve[0].CORP_REVIEWER,
                corpCC: eve[0].CORP_CC,
                aerReviewer: eve[0].AER_REVIEWER,
                aerCC: eve[0].AER_CC,
                hbtReveiwer: eve[0].HBT_REVIEWER,
                hbtCC: eve[0].HBT_CC,
                pmtReviewer: eve[0].PMT_REVIEWER,
                pmtCC: eve[0].PMT_CC,
                spsReveiwer: eve[0].SPS_REVIEWER,
                spsCC: eve[0].SPS_CC,
                scorecardStatus: eve[0].CARD_SCORECARDSTATUS,
                comments: eve[0].COMMENTS,
            });
        }
        else {
            this.setState({
                selectedScorecardItem: eve,
                sbgSelected: "",
                sbeSelected: "",
                reportName: "",
                reportFileName: "",
                reportLevel: "",
                reportFrequency: "",
                vendorContactTitle: "",
                vendorContactFirstName: "",
                vendorContactLastName: "",
                corpVendorContact: "",
                aeroVendorContact: "",
                hbtVendorContact: "",
                pmtVendorContact: "",
                spsVendorContact: "",
                executiveContact: "No",
                activeFlag: "No",
                corpReviewer: "",
                corpCC: "",
                aerReviewer: "",
                aerCC: "",
                hbtReveiwer: "",
                hbtCC: "",
                pmtReviewer: "",
                pmtCC: "",
                spsReveiwer: "",
                spsCC: "",
                scorecardStatus: "Not yet generated",
                comments: "",
            });
        }
    }
    handleVendorSearchScorecardSelect = () => {
        this.setState({
            err_erpVendor: "",
            err_duns: "",
            err_globalDuns: "",
            err_altVendor: "",
            sbgSelected: "",
            selectedScorecardItem: [],
            sbeSelected: "",
            reportName: "",
            reportFileName: "",
            reportLevel: "",
            reportFrequency: "",
            vendorContactTitle: "",
            vendorContactFirstName: "",
            vendorContactLastName: "",
            corpVendorContact: "",
            aeroVendorContact: "",
            hbtVendorContact: "",
            pmtVendorContact: "",
            spsVendorContact: "",
            executiveContact: "No",
            activeFlag: "No",
            corpReviewer: "",
            corpCC: "",
            aerReviewer: "",
            aerCC: "",
            hbtReveiwer: "",
            hbtCC: "",
            pmtReviewer: "",
            pmtCC: "",
            spsReveiwer: "",
            spsCC: "",
            scorecardStatus: "",
            comments: "",
            err_reportLevel: "",
            err_reportFrequency: "",
            err_contact: "",
            err_Reviewer: "",

            loading: true
        });

        if (this.state.searchDunsNum === '' &&
            this.state.searchGlobalDunsNum === '' &&
            this.state.altVenGrpName === '') {
            alert(`Enter at least One search field.`);
        }
        else {
            if (this.state.searchDunsNum !== '') {
                this.setState({ loading: true });
                fetch(`${config.host_url}/api/vendor/search/tab/vendorscorecard/search1/${this.state.searchDunsNum}`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            searchScorecardData: data,
                            loading: false,
                            showPrimeCard: false,
                            showERPVendorMPOCard: false,
                            showVendorCard: false,
                            showERPVendorCard: false,
                            showVendorScorecardCard: true,
                        });
                    })
            }
            else if (this.state.searchAltVenGrpName !== '') {
                this.setState({ loading: true });
                fetch(`${config.host_url}/api/vendor/search/tab/vendorscorecard/search3/${this.state.searchAltVenGrpName}`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            searchScorecardData: data,
                            loading: false,
                            showPrimeCard: false,
                            showERPVendorMPOCard: false,
                            showVendorCard: false,
                            showERPVendorCard: false,
                            showVendorScorecardCard: true,
                        });
                    })
            }
            else if (this.state.searchGlobalDunsNum !== '') {
                this.setState({ loading: true });
                fetch(`${config.host_url}/api/vendor/search/tab/vendorscorecard/search2/${this.state.searchGlobalDunsName}`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            searchScorecardData: data,
                            loading: false,
                            showPrimeCard: false,
                            showERPVendorMPOCard: false,
                            showVendorCard: false,
                            showERPVendorCard: false,
                            showVendorScorecardCard: true,
                        });
                    })
            }
        }
    }
    vendorScoreAdd = () => {
        if (this.state.sbgSelected === '' && this.state.reportFrequency === '') {
            alert("Please enter mandatory fields.");
        }
        else if (this.state.sbgSelected === '') {
            alert("Please enter SBG fields.");
        }
        else if (this.state.reportFrequency === '') {
            alert("Please enter Report Frequency.");
        }
        else if (this.state.hbtVendorContact === '' &&
            this.state.pmtVendorContact === '' &&
            this.state.hbtVendorContact === '' &&
            this.state.spsVendorContact === '' &&
            this.state.corpVendorContact === '' &&
            this.state.aeroVendorContact === '') {
            alert(`Please enter at least one Contact`);
        }
        else if (this.state.hbtReveiwer === '' &&
            this.state.pmtReviewer === '' &&
            this.state.hbtReviewer === '' &&
            this.state.spsReviewer === '' &&
            this.state.corpReviewer === '' &&
            this.state.aeroReviewer === '') {
            alert(`Please enter at least one Reviewer`);
        }
        else {
            this.setState({
                loading: true,
                flg_option: 'A',
            });
            const addvalue = {
                I_SBG_ID: this.state.sbgSelected,
                I_SBE_ID: this.state.sbeSelected,
                I_DUNS_NUM: this.state.searchDunsNum,
                I_VENDOR_GLOBAL_ULTIMATE_DUNS: this.state.searchGlobalDunsNum,
                I_ALTERNATE_GROUP_NAME: this.state.searchAltVenGrpName,
                I_REPORT_NAME: this.state.reportName,
                I_REPORT_FILE_NAME: this.state.reportFileName,
                I_REPORT_LEVEL: this.state.reportLevel,
                I_REPORT_FREQUENCY: this.state.reportFrequency,
                I_VENDOR_CONTACT_TITLE: this.state.vendorContactTitle,
                I_VENDOR_CONTACT_FIRST_NAME: this.state.vendorContactFirstName,
                I_VENDOR_CONTACT_LAST_NAME: this.state.vendorContactLastName,
                I_CORP_VENDOR_CONTACT: this.state.corpVendorContact,
                I_AERO_VENDOR_CONTACT: this.state.aeroVendorContact,
                I_HBT_VENDOR_CONTACT: this.state.hbtVendorContact,
                I_PMT_VENDOR_CONTACT: this.state.pmtVendorContact,
                I_SPS_VENDOR_CONTACT: this.state.spsVendorContact,
                I_EXECUTIVE_CONTACT: this.state.executiveContact,
                I_SCORE_CARD_ACITVE_FLAG: this.state.activeFlag,
                I_CORP_REVIEWER: this.state.corpReviewer,
                I_CORP_CC: this.state.corpCC,
                I_AER_REVIEWER: this.state.aerReviewer,
                I_AER_CC: this.state.aerCC,
                I_HBT_REVIEWER: this.state.hbtReveiwer,
                I_HBT_CC: this.state.hbtCC,
                I_PMT_REVIEWER: this.state.pmtReviewer,
                I_PMT_CC: this.state.pmtCC,
                I_SPS_REVIEWER: this.state.spsReveiwer,
                I_SPS_CC: this.state.spsCC,
                I_CARD_SCORECARDSTATUS: this.state.scorecardStatus,
                I_COMMENTS: this.state.comments,
                I_LAST_CHANGE_DATE: this.getTimeStamp().toString(),
                I_UPDATED_BY: this.state.UIDData,
                I_FLAG: 'I'
            };
            fetch(`${config.host_url}/api/vendorscorecard/add`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(addvalue),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                    });
                    this.handleVendorSearchScorecardSelect();
                    alert(`Vendor Scorecard Record Added.`);
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Could not add data. Please try again`);
                });
        }
    }
    vendorScoreUpdate = () => {
        const selectedScorecard = this.state.selectedScorecardItem[0];
        if (this.state.sbgSelected === '' && this.state.reportFrequency === '') {
            alert("Please enter mandatory fields.");
        }
        else if (this.state.sbgSelected === '') {
            alert("Please enter SBG fields.");
        }
        else if (this.state.reportFrequency === '') {
            alert("Please enter Report Frequency.");
        }
        else if (this.state.hbtVendorContact === '' &&
            this.state.pmtVendorContact === '' &&
            this.state.hbtVendorContact === '' &&
            this.state.spsVendorContact === '' &&
            this.state.corpVendorContact === '' &&
            this.state.aeroVendorContact === '') {
            alert(`Please enter at least one Contact`);
        }
        else if (this.state.hbtReveiwer === '' &&
            this.state.pmtReviewer === '' &&
            this.state.hbtReviewer === '' &&
            this.state.spsReviewer === '' &&
            this.state.corpReviewer === '' &&
            this.state.aeroReviewer === '') {
            alert(`Please enter at least one Reviewer`);
        }
        else {
            this.setState({
                loading: true,
                flg_option: 'A',
            });
            const updatevalue = {
                D_FLAG: 'D',
                //p
                U_SBG_ID: this.state.sbgSelected,
                U_SBE_ID: this.state.sbeSelected,
                U_DUNS_NUM: this.state.searchDunsNum,
                U_VENDOR_GLOBAL_ULTIMATE_DUNS: this.state.searchGlobalDunsNum,
                U_ALTERNATE_GROUP_NAME: this.state.searchAltVenGrpName,
                U_REPORT_NAME: this.state.reportName,
                U_REPORT_FILE_NAME: this.state.reportFileName,
                U_REPORT_LEVEL: this.state.reportLevel,
                U_REPORT_FREQUENCY: this.state.reportFrequency,
                U_VENDOR_CONTACT_TITLE: this.state.vendorContactTitle,
                U_VENDOR_CONTACT_FIRST_NAME: this.state.vendorContactFirstName,
                U_VENDOR_CONTACT_LAST_NAME: this.state.vendorContactLastName,
                U_CORP_VENDOR_CONTACT: this.state.corpVendorContact,
                U_AERO_VENDOR_CONTACT: this.state.aeroVendorContact,
                U_HBT_VENDOR_CONTACT: this.state.hbtVendorContact,
                U_PMT_VENDOR_CONTACT: this.state.pmtVendorContact,
                U_SPS_VENDOR_CONTACT: this.state.spsVendorContact,
                U_EXECUTIVE_CONTACT: this.state.executiveContact,
                U_SCORE_CARD_ACITVE_FLAG: this.state.activeFlag,
                U_CORP_REVIEWER: this.state.corpReviewer,
                U_CORP_CC: this.state.corpCC,
                U_AER_REVIEWER: this.state.aerReviewer,
                U_AER_CC: this.state.aerCC,
                U_HBT_REVIEWER: this.state.hbtReveiwer,
                U_HBT_CC: this.state.hbtCC,
                U_PMT_REVIEWER: this.state.pmtReviewer,
                U_PMT_CC: this.state.pmtCC,
                U_SPS_REVIEWER: this.state.spsReveiwer,
                U_SPS_CC: this.state.spsCC,
                U_CARD_SCORECARDSTATUS: this.state.scorecardStatus,
                U_COMMENTS: this.state.comments,
                U_LAST_CHANGE_DATE: this.getTimeStamp().toString(),
                U_FLAG: 'U',
                U_UPDATED_BY: this.state.UIDData
            };
            if (this.state.reportLevel === "Supplier ID Level") {
                fetch(`${config.host_url}/api/vendorscorecard/update1`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatevalue),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.setState({
                            loading: false,
                        });
                        this.handleVendorSearchScorecardSelect();
                        alert(`Vendor Scorecard Record Updated.`);
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false
                        });
                        alert(`Could not Update data. Please try again`);
                    });
            }

            else if (this.state.reportLevel === "Supplier Group Level") {
                fetch(`${config.host_url}/api/vendorscorecard/update2`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatevalue),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.setState({
                            loading: false,
                        });
                        this.handleVendorSearchScorecardSelect();
                        alert(`Vendor Scorecard Record Updated.`);
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false
                        });
                        alert(`Could not Update data. Please try again`);
                    });
            }

            else if (this.state.reportLevel === "Supplier Alias Level") {
                fetch(`${config.host_url}/api/vendorscorecard/update3`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatevalue),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.setState({
                            loading: false,
                        });
                        this.handleVendorSearchScorecardSelect();
                        alert(`Vendor Scorecard Record Updated.`);
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false
                        });
                        alert(`Could not Update data. Please try again`);
                    });
            }
        }


    }

    //Bulk Uploads

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var selectedfile = e.target.files[0];
        this.setState({ selectedfile }, () => {
            if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "" || this.state.selectedfile === undefined) {
                this.setState({ submitDisabled: true });
            }
            else {
                this.setState({ submitDisabled: false });
            }
        });
    }
    submitFileVendorDuns() {
        if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "" || this.state.selectedfile === undefined) {
            this.setState({ submitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({ loading: true });
            let f = this.state.selectedfile;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
                /* Update state */
                let dunsFilteredData = data.filter(e => e.length);
                //remove header from base array
                dunsFilteredData.shift();
                for (let i = 0; i < dunsFilteredData.length; i++) {
                    dunsFilteredData[i].splice(1, 1);//Remove Supplier Name
                    dunsFilteredData[i].splice(2, 1);//Remove Supplier Group Name
                    dunsFilteredData[i].splice(5, 1);//Remove Sup Com Sk Description
                    dunsFilteredData[i].splice(25, 1);
                    dunsFilteredData[i].splice(0, 1, '' + dunsFilteredData[i][0]);//Convert Supplier ID to String
                    dunsFilteredData[i].splice(1, 1, '' + dunsFilteredData[i][1]);//Convert Supplier Group ID to String
                    dunsFilteredData[i].splice(4, 1, '' + dunsFilteredData[i][4]);//Convert Supplier Subcommodity to String
                    dunsFilteredData[i].splice(17, 1, '' + dunsFilteredData[i][17]);//Convert Contract ID to String
                }
                let flg_eVendorno = 0;
                let flg_eVendorGrpId = 0;
                let flg_eVendorAltGrpName = 0;
                let flg_eSubComSk = 0;
                let flg_eVendorStatus = 0;
                let flg_eVendorType = 0;
                let flg_eVendorGeo = 0;
                let flg_eInterComFlag = 0;
                let flg_duplicate = 0;
                let excel_err_msg = [];
                let dup_arr = [];
                let all_ven_data = [];
                let flg_validation = 'F';

                for (let i = 0; i < dunsFilteredData.length; i++) {
                    let excel_VendorID = dunsFilteredData[i][0];
                    let excel_VendorGroupID = dunsFilteredData[i][1];
                    let excel_AlternaterVendor = dunsFilteredData[i][3];
                    let excel_VendorSubCom = dunsFilteredData[i][4];
                    let excel_VendorBusinessStatus = dunsFilteredData[i][5];
                    let excel_VendorType = dunsFilteredData[i][6];
                    let excel_VendorGeo = dunsFilteredData[i][7];
                    let excel_VendorInterComFlag = dunsFilteredData[i][9];

                    //duplicates vendorid
                    let arr_dup = [excel_VendorID];
                    dup_arr.push(arr_dup);

                    if (excel_VendorID === '' || excel_VendorID === undefined || excel_VendorID === 'undefined') {
                        flg_eVendorno = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_ven_data.push('' + excel_VendorID);

                    }
                    if (excel_VendorGroupID === '' || excel_VendorGroupID === undefined || excel_VendorGroupID === 'undefined') {
                        flg_eVendorGrpId = 1;
                        flg_validation = 'T';
                    }
                    if (excel_AlternaterVendor === '' || excel_AlternaterVendor === undefined || excel_AlternaterVendor === 'undefined') {
                        flg_eVendorAltGrpName = 1;
                        flg_validation = 'T';
                    }
                    if (excel_VendorSubCom === '' || excel_VendorSubCom === undefined || excel_VendorSubCom === 'undefined') {
                        flg_eSubComSk = 1;
                        flg_validation = 'T';
                    }
                    if (excel_VendorBusinessStatus === '' || excel_VendorBusinessStatus === undefined || excel_VendorBusinessStatus === 'undefined') {
                        flg_eVendorStatus = 1;
                        flg_validation = 'T';
                    }
                    if (excel_VendorType === '' || excel_VendorType === undefined || excel_VendorType === 'undefined') {
                        flg_eVendorType = 1;
                        flg_validation = 'T';
                    }
                    if (excel_VendorGeo === '' || excel_VendorGeo === undefined || excel_VendorGeo === 'undefined') {
                        flg_eVendorGeo = 1;
                        flg_validation = 'T';
                    }
                    if (excel_VendorInterComFlag === '' || excel_VendorInterComFlag === undefined || excel_VendorInterComFlag === 'undefined') {
                        flg_eInterComFlag = 1;
                        flg_validation = 'T';
                    }
                }

                const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
                console.log(duplicate_count, "dupcount");
                if (duplicate_count > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }

                else {
                    const duns_data = JSON.stringify(all_ven_data);
                    let arr_err_matID = [];
                    console.log("dundata", duns_data);
                    fetch(`${config.host_url}/api/vendor/allvendors`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: duns_data,
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                all_vendor_data: response
                            });
                            let result_dunsid = [];
                            for (var i in this.state.all_vendor_data) {
                                result_dunsid.push((this.state.all_vendor_data[i].VENDOR_DUNS_NUMBER).toString());
                            }
                            for (let i = 0; i < dunsFilteredData.length; i++) {
                                let val_material = dunsFilteredData[i][0].toString();
                                let cnt_match = 0;
                                if (result_dunsid.includes(val_material)) {
                                    cnt_match = 1;
                                }
                                if (cnt_match === 0) {
                                    arr_err_matID.push(val_material);
                                }
                            }
                            if (arr_err_matID.length > 0) {
                                flg_validation = 'T';
                                flg_eVendorno = 2;
                            }
                            if (flg_validation === 'T') {
                                if (flg_eVendorno === 1) {
                                    excel_err_msg.push("Vendor ID can't be blank. \n");
                                }
                                if (flg_eVendorno === 2) {
                                    excel_err_msg.push("Vendor ID's not found in Base Table: " + arr_err_matID);
                                }
                                if (flg_eVendorGrpId === 1) {
                                    excel_err_msg.push("Vendor Group ID can't be blank. \n");
                                }
                                if (flg_eVendorAltGrpName === 1) {
                                    excel_err_msg.push("Vendor Alternate Group Name can't be blank. \n");
                                }
                                if (flg_eSubComSk === 1) {
                                    excel_err_msg.push("Sub commodity can't be blank. \n");
                                }
                                if (flg_eVendorStatus === 1) {
                                    excel_err_msg.push("Vendor Business Status can't be blank. \n");
                                }
                                if (flg_eVendorType === 1) {
                                    excel_err_msg.push("Vendor Type can't be blank. \n");
                                }
                                if (flg_eInterComFlag === 1) {
                                    excel_err_msg.push("Inter Company Flag can't be blank. \n");
                                }
                                if (flg_eVendorGeo === 1) {
                                    excel_err_msg.push("Vendor Geography can't be blank. \n");
                                }
                                alert("Validation Failed :\n " + excel_err_msg);
                                this.setState({ loading: false });
                            }
                            else {
                                for (let i = 0; i < dunsFilteredData.length; i++) {
                                    let excel_check = dunsFilteredData[i][21];
                                    let excel_lat = dunsFilteredData[i][23];
                                    let excel_long = dunsFilteredData[i][24];
                                    if (excel_check !== '') {
                                        dunsFilteredData[i][25] = 1;//LTA_flag
                                    }
                                    else {
                                        dunsFilteredData[i][25] = 0;//LTA_Flag
                                    }
                                    if (excel_lat === '' && excel_long === '') {
                                        dunsFilteredData[i][23] = 0;
                                        dunsFilteredData[i][24] = 0;
                                    }
                                    dunsFilteredData[i][26] = this.getTimeStamp().toString();
                                    dunsFilteredData[i][27] = 'I';
                                     dunsFilteredData[i].push(this.state.UIDData);
                                }
                                const js_data = JSON.stringify(dunsFilteredData);
                                const duns_data = JSON.stringify(result_dunsid);

                                console.log(js_data, "jsdata");
                                console.log(duns_data, "duns");
                                fetch(`${config.host_url}/api/vendor/duns/massupload/update`, {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: duns_data,
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        fetch(`${config.host_url}/api/vendor/duns/massupload`, {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: js_data,
                                        })
                                            .then((response) => response.json())
                                            .then((response) => {
                                                this.setState({
                                                    loading: false
                                                });
                                                let str_msg = JSON.stringify(response[0]);
                                                alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                                this.resetComponent();
                                            })
                                            .catch((error) => {
                                                this.setState({ loading: false });
                                                alert(`Could not Upload the data. Please try again.`);
                                            });
                                    })
                                    .catch((error) => {
                                        this.setState({ loading: false });
                                        alert(`Mass Upload Update error`);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            alert(`Vendor API data error`);
                        });
                }
            };
            reader.readAsBinaryString(f);
        }
    }

    erpFilePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var erpSelectedfile = e.target.files[0];
        this.setState({ erpSelectedfile }, () => {
            if (this.state.erpSelectedfile === "undefined" || this.state.erpSelectedfile === " " || this.state.erpSelectedfile === "") {
                this.setState({ erpSubmitDisabled: true });
            }
            else {
                this.setState({ erpSubmitDisabled: false });
            }
        });
    }
    submitFileERPVendor() {
        if (this.state.erpSelectedfile === "undefined" || this.state.erpSelectedfile === " " || this.state.erpSelectedfile === "") {
            this.setState({ erpSubmitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({ loading: true });
            let f = this.state.erpSelectedfile;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
                /* Update state */
                let erpFilteredData = data.filter(e => e.length);
                //remove header from base array
                erpFilteredData.shift();
                for (let i = 0; i < erpFilteredData.length; i++) {
                     erpFilteredData[i].splice(1, 1);//Site Name
                     erpFilteredData[i].splice(2, 1);//Supplier Name(SK), Site primary Number
                     erpFilteredData[i].splice(4, 1);//Supplier Name
                    erpFilteredData[i].splice(9, 1, '' + erpFilteredData[i][9]);//Last User
                    erpFilteredData[i].splice(0, 1, '' + erpFilteredData[i][0]);//Convert Site to String
                    erpFilteredData[i].splice(1, 1, '' + erpFilteredData[i][1]);//Convert Vendor SK to string
                                      
                }

                console.log(erpFilteredData,"fgg");
                //Bank Value Validation
                let flg_eSiteId = 0;
                let flg_eERPNum = 0;
                let flg_eDunsNum = 0;
                let flg_BusinessStatus = 0;
                let flg_eUser = 0;
                let dup_arr = [];
                let excel_err_msg = [];
                let all_site_data = [];
                let all_erp_data = [];
                let flg_validation = 'F';
                for (let i = 0; i < erpFilteredData.length; i++) {
                    let excel_siteid = erpFilteredData[i][0];
                    let excel_duns = erpFilteredData[i][3];
                    let excel_erp = erpFilteredData[i][1];
                    let excel_businessStatus = erpFilteredData[i][7];
                    let excel_user = erpFilteredData[i][9];

                    //duplicate
                    let arr_dup = [excel_siteid, excel_erp];
                    dup_arr.push(arr_dup);

                    if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
                        flg_eSiteId = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_site_data.push(excel_siteid); console.log(all_site_data, "all");
                    }
                    if (excel_duns === '' || excel_duns === undefined || excel_duns === 'undefined') {
                        flg_eDunsNum = 1;
                        flg_validation = 'T';
                    }
                    if (excel_erp === '' || excel_erp === undefined || excel_erp === 'undefined') {
                        flg_eERPNum = 1;
                        flg_validation = 'T';
                    }
                    else {
                        all_erp_data.push(excel_erp); console.log(all_erp_data, "all");
                    }
                    if (excel_businessStatus === '' || excel_businessStatus === undefined || excel_businessStatus === 'undefined') {
                        flg_BusinessStatus = 1;
                        flg_validation = 'T';
                    }
                    if (excel_user === '' || excel_user === undefined || excel_user === 'undefined') {
                        flg_eUser = 1;
                        flg_validation = 'T'
                    }
                }
                let flg_duplicate = 0;
                const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
                console.log(duplicate_count, "dupcount");
                if (duplicate_count > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }
                else {
                    let arr_err_siteID = [];
                    let arr_excelSiteIds = [];
                    let arr_erpVendor = [];
                    let arr_err_erpVendor = [];
                    const erp_data = JSON.stringify(all_erp_data);
                    fetch(`${config.host_url}/api/vendor/erp/allvendors`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: erp_data,
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                all_erpvendor_data: response
                            });
                            let result_erpid = [];
                            for (var i in this.state.all_erpvendor_data) {
                                result_erpid.push((this.state.all_erpvendor_data[i].VENDOR_SK).toString());
                            }
                            let allSiteId = [];
                            for (var j in this.state.siteIds) {
                                allSiteId.push((this.state.siteIds[j].title).toString());
                            }
                            for (let i = 0; i < erpFilteredData.length; i++) {
                                let val_site = erpFilteredData[i][0].toString();
                                let val_vendor = erpFilteredData[i][1].toString();
                                let cnt_match_site = 0;
                                let cnt_match_vendor = 0;
                                if (allSiteId.includes(val_site)) {
                                    cnt_match_site = 1;
                                    arr_excelSiteIds.push(val_site);
                                }
                                if (cnt_match_site === 0) {
                                    arr_err_siteID.push(val_site);
                                }
                                if (result_erpid.includes(val_vendor)) {
                                    cnt_match_vendor = 1;
                                    arr_erpVendor.push(val_vendor);
                                }
                                if (cnt_match_vendor === 0) {
                                    arr_err_erpVendor.push(val_vendor);
                                }
                            }
                            if (arr_err_siteID.length > 0) {
                                if (arr_err_siteID.includes('undefined')) {
                                    flg_eSiteId = 1;
                                    flg_validation = 'T';
                                }
                                else {
                                    flg_validation = 'T';
                                    flg_eSiteId = 2;
                                }
                            }
                            if (arr_err_erpVendor.length > 0) {
                                if (arr_err_erpVendor.includes('undefined')) {
                                    flg_eERPNum = 1;
                                    flg_validation = 'T';
                                }
                                else {
                                    flg_validation = 'T';
                                    flg_eERPNum = 2;
                                }
                            }
                            if (flg_validation === 'T') {
                                if (flg_eSiteId === 1) {
                                    excel_err_msg.push("Site ID can't be blank. \n");
                                }
                                if (flg_eSiteId === 2) {
                                    excel_err_msg.push("Site IDs not found in base table " + arr_err_siteID);
                                }
                                if (flg_eDunsNum === 1) {
                                    excel_err_msg.push("Vendor ID can't be blank. \n");
                                }
                                if (flg_eERPNum === 1) {
                                    excel_err_msg.push("Vendor local ID can't be blank. \n");
                                }
                                if (flg_eERPNum === 2) {
                                    excel_err_msg.push("Vendor local IDs not found in base table " + arr_err_erpVendor);
                                }
                                if (flg_BusinessStatus === 1) {
                                    excel_err_msg.push("Vendor Business Status cannot be blank \n");
                                }
                                if (flg_eUser === 1) {
                                    excel_err_msg.push("Last Modified User ID cannot be blank \n");
                                }
                                alert("Validation Failed :\n " + excel_err_msg);
                                this.setState({ loading: false });
                            }
                            else {
                                for (let i = 0; i < erpFilteredData.length; i++) {
                                    erpFilteredData[i].splice(9, 1);
                                    erpFilteredData[i][9] = (this.getTimeStamp().toString());
                                    erpFilteredData[i][10] = 'I';
                                    erpFilteredData[i][11]=this.state.UIDData;
                                }
                                const erpVendor_data = JSON.stringify(erpFilteredData);
                                console.log(erpVendor_data, "erp ven");
                                fetch(`${config.host_url}/api/vendor/erp/massupload`, {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: erpVendor_data,
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        this.setState({
                                            loading: false
                                        });
                                        let str_msg = JSON.stringify(response[0]);
                                        alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                        this.resetComponent();
                                    })
                                    .catch((error) => {
                                        this.setState({ loading: false });
                                        alert(`Could not Upload the data. Please try again.`);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            alert(`Vendor API data error`);
                        });
                }
            };
            reader.readAsBinaryString(f);
        }
    }

    mpoFilePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var mpoSelectedfile = e.target.files[0];
        this.setState({ mpoSelectedfile }, () => {
            if (this.state.mpoSelectedfile === "undefined" || this.state.mpoSelectedfile === " " || this.state.mpoSelectedfile === "") {
                this.setState({ mpoSubmitDisabled: true });
            }
            else {
                this.setState({ mpoSubmitDisabled: false });
            }
        });
    }
    submitFileMPOVendor() {
        if (this.state.mpoSelectedfile === "undefined" || this.state.mpoSelectedfile === " " || this.state.mpoSelectedfile === "") {
            this.setState({ mpoSubmitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({ loading: true });
            let f = this.state.mpoSelectedfile;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
                /* Update state */
                let mpoFilteredData = data.filter(e => e.length);
                //remove header from base array
                mpoFilteredData.shift();
                for (let i = 0; i < mpoFilteredData.length; i++) {

                    mpoFilteredData[i].splice(0, 1, '' + mpoFilteredData[i][0]);//Convert Site to string
                    mpoFilteredData[i].splice(1, 1, '' + mpoFilteredData[i][1]);//Convert Sourcesystem  to string
                    mpoFilteredData[i].splice(2, 1, '' + mpoFilteredData[i][2]);//Convert Loc-sup_no to string
                    mpoFilteredData[i].splice(3, 1, '' + mpoFilteredData[i][3]);//Convert phase gate to string
                    mpoFilteredData[i].splice(4, 1, '' + mpoFilteredData[i][4]);//Convert Mpo status  to string
                    mpoFilteredData[i].splice(5, 1, '' + mpoFilteredData[i][5]);//Convert Gate info to string
                    mpoFilteredData[i].splice(6, 1, '' + mpoFilteredData[i][6]);//Convert Company code to string
                    mpoFilteredData[i].splice(7, 1, '' + mpoFilteredData[i][7]);//Convert Ns_Site to string

                } console.log(mpoFilteredData, "mpodata1");
                //Bank Value Validation

                let flg_eSiteId = 0;
                let flg_sourcesystem = 0;
                let flg_sup_no = 0;
                let flg_phaseGate = 0;
                let flg_mpoStatus = 0;
                let flg_gatesInfo = 0;
                let flg_companyCode = 0;
                let flg_nsSite = 0;

                let dup_arr = [];
                let excel_err_msg = [];
                let arr_err_siteID = [];
                let arr_err_companycode = [];
                let arr_err_nssite = [];
                let arr_err_mpoVen = [];
                let arr_excelSiteIds = [];
                let arr_excel_Vendor = [];
                let flg_validation = 'F';

                let all_companycode = [];
                for (var l in this.state.companyCode) {
                    all_companycode.push((this.state.companyCode[l].title).toString());
                }
                let all_ns_site = [];
                for (var k in this.state.mpoNsSiteData) {
                    all_ns_site.push((this.state.mpoNsSiteData[k].title).toString());
                }


                for (let i = 0; i < mpoFilteredData.length; i++) {
                    let excel_siteid = mpoFilteredData[i][0];
                    let excel_sourcesystem = mpoFilteredData[i][1];
                    let excel_sup_no = mpoFilteredData[i][2];
                    let excel_phaseGates = mpoFilteredData[i][3];
                    let excel_mpoStatus = mpoFilteredData[i][4];
                    let excel_GatesInfo = mpoFilteredData[i][5];
                    let excel_companycode = mpoFilteredData[i][6];
                    let excel_nsSite = mpoFilteredData[i][7];

                    //duplicate

                    // let arr_dup = [excel_siteid, excel_erp, excel_apType, excel_SpendType];
                    // dup_arr.push(arr_dup);

                    // if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
                    //     flg_eSiteId = 1;
                    //     flg_validation = 'T'
                    // }

                    if (excel_sourcesystem === '' || excel_sourcesystem === undefined || excel_sourcesystem === 'undefined') {
                        flg_sourcesystem = 1;
                        flg_validation = 'T'
                    } else {
                        if (excel_sourcesystem === 'P11004' || excel_sourcesystem === 'EEP300' || excel_sourcesystem === 'C11111' ||
                            excel_sourcesystem === 'BRP900' || excel_sourcesystem === 'CIP300' || excel_sourcesystem === 'CIP900' ||
                            excel_sourcesystem === 'PRD010' || excel_sourcesystem === 'ERP900') {
                            mpoFilteredData[i][0] = 'ALL';
                            // mpoFilteredData[i][4] = null;
                            mpoFilteredData[i][7] = 'ALL';

                            if (excel_companycode === '' || excel_companycode === undefined || excel_companycode === 'undefined') {
                                flg_companyCode = 1;
                                flg_validation = 'T';
                            } else {


                                if (!(all_companycode.includes(excel_companycode))) {
                                    flg_companyCode = 2;
                                    flg_validation = 'T';
                                    arr_err_companycode.push(excel_companycode);
                                }

                            }



                        } else {
                            if (excel_sourcesystem === 'ORACLE-APAC'
                                || excel_sourcesystem === 'ORACLE-IGS') {
                                mpoFilteredData[i][0] = 'ALL';
                                //  mpoFilteredData[i][4] = 0.00 ;

                                if (excel_nsSite === '' || excel_nsSite === undefined || excel_nsSite === 'undefined') {
                                    flg_nsSite = 1;
                                    flg_validation = 'T';
                                }
                                else {


                                    if (!(all_ns_site.includes(excel_nsSite))) {
                                        flg_nsSite = 2;
                                        flg_validation = 'T';
                                        arr_err_nssite.push(all_ns_site);
                                    }

                                }


                                if (excel_companycode === '' || excel_companycode === undefined || excel_companycode === 'undefined') {
                                    flg_companyCode = 1;
                                    flg_validation = 'T';
                                } else {


                                    if (!(all_companycode.includes(excel_companycode))) {
                                        flg_companyCode = 2;
                                        flg_validation = 'T';
                                        arr_err_companycode.push(excel_companycode);
                                    }

                                }

                            }
                            else {


                                if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
                                    flg_eSiteId = 1;
                                    flg_validation = 'T'
                                }
                                // else{


                                // }
                                mpoFilteredData[i][6] = 'ALL';
                                mpoFilteredData[i][7] = mpoFilteredData[i][0];

                                if (excel_mpoStatus === '' || excel_mpoStatus === undefined || excel_mpoStatus === 'undefined') {
                                    flg_mpoStatus = 1;
                                    flg_validation = 'T'
                                }

                            }
                        }
                    }

                    // if (excel_sup_no === '' || excel_sup_no === undefined || excel_sup_no === 'undefined') {
                    //     flg_sup_no = 1;
                    //     flg_validation = 'T';
                    // }

                    if (excel_phaseGates === '' || excel_phaseGates === undefined || excel_phaseGates === 'undefined') {
                        flg_phaseGate = 1;
                        flg_validation = 'T'
                    }

                    // if (excel_GatesInfo === '' || excel_GatesInfo === undefined || excel_GatesInfo === 'undefined') {
                    //     flg_gatesInfo = 1;
                    //     flg_validation = 'T';
                    // }


                    if (excel_sup_no === '' || excel_sup_no === undefined || excel_sup_no === 'undefined') {
                        flg_sup_no = 1;
                        flg_validation = 'T';
                    }
                    else {
                        arr_excel_Vendor.push('' + excel_sup_no);
                    }
                }

                //check for duplicates

                let flg_duplicate = 0;
                console.log("dupl",)
                const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
                console.log(duplicate_count, "dupcount");
                if (duplicate_count > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }
                else {


                    //Check if Vendors exist


                    console.log("arrexcel", arr_excel_Vendor);
                    const mpo_data = JSON.stringify(arr_excel_Vendor);
                    console.log("mpodta", mpo_data);
                    fetch(`${config.host_url}/api/vendor/erp/allvendors`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: mpo_data,
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                all_mpovendor_data: response
                            });
                            //Upload Data in case Material Validation pass
                            let result_mpoid = [];
                            for (var i in this.state.all_mpovendor_data) {
                                result_mpoid.push((this.state.all_mpovendor_data[i].VENDOR_SK).toString());
                            }
                            let allSiteId = [];
                            for (var j in this.state.siteIds) {
                                allSiteId.push((this.state.siteIds[j].title).toString());
                            }
                            for (let i = 0; i < mpoFilteredData.length; i++) {
                                let val_site_mpo = '' + mpoFilteredData[i][0];
                                let val_vendor_mpo = '' + mpoFilteredData[i][2];
                                let cnt_match = 0;
                                let cnt_match_vendor = 0;

                                if (mpoFilteredData[i][1])
                                    if (allSiteId.includes(val_site_mpo) || val_site_mpo === 'ALL') {
                                        cnt_match = 1;
                                        arr_excelSiteIds.push(val_site_mpo);
                                    }

                                if (cnt_match === 0) {
                                    arr_err_siteID.push(val_site_mpo);
                                }
                                if (result_mpoid.includes(val_vendor_mpo)) {
                                    cnt_match_vendor = 1;
                                    arr_excel_Vendor.push(val_vendor_mpo);
                                }
                                if (cnt_match_vendor === 0) {
                                    arr_err_mpoVen.push(val_vendor_mpo);
                                }
                            }
                            if (arr_err_siteID.length > 0) {
                                flg_validation = 'T';
                                flg_eSiteId = 2;
                            }
                            if (arr_err_mpoVen.length > 0) {
                                flg_validation = 'T';
                                // flg_eERPNum = 2;
                            }
                            if (flg_validation === 'T') {
                                if (flg_eSiteId === 1) {
                                    excel_err_msg.push("Site ID can't be blank. \n");
                                }
                                if (flg_eSiteId === 2) {
                                    excel_err_msg.push("Site IDs not found in base table " + arr_err_siteID);
                                }
                                if (flg_phaseGate === 1) {
                                    excel_err_msg.push("Phase Gates can't be blank. \n");
                                }
                                if (flg_sourcesystem === 1) {
                                    excel_err_msg.push("SourceSystem can't be blank. \n");
                                }
                                if (flg_mpoStatus === 1) {
                                    excel_err_msg.push("MPO Status can't be blank. \n");
                                }

                                if (flg_gatesInfo === 1) {
                                    excel_err_msg.push("Gates Info can't be blank. \n");
                                }
                                if (flg_companyCode === 1) {
                                    excel_err_msg.push("Company Code  can't be blank. \n");
                                }
                                if (flg_companyCode === 2) {
                                    excel_err_msg.push("Company Code is invalid: " + arr_err_companycode);
                                }
                                if (flg_nsSite === 1) {
                                    excel_err_msg.push("Ns_Site can't be blank. \n");
                                }
                                if (flg_nsSite === 2) {
                                    excel_err_msg.push("Ns_Site data is invalid " + arr_err_nssite);
                                }

                                if (flg_sup_no === 1) {
                                    excel_err_msg.push("LOC SUP NUM can't be blank. \n");
                                }
                                if (flg_sup_no === 2) {
                                    excel_err_msg.push("LOC SUP NUM not found in base table " + arr_err_mpoVen);
                                }
                                alert("Validation Failed :\n " + excel_err_msg);
                                this.setState({ loading: false });
                            }
                            else {
                                for (let i = 0; i < mpoFilteredData.length; i++) {
                                    mpoFilteredData[i].push(this.state.UIDData);//Add PLANT_SK value
                                    mpoFilteredData[i].push(this.getTimeStamp().toString());
                                    mpoFilteredData[i].push('I');
                                    if (mpoFilteredData[i][4] === 'NO' || mpoFilteredData[i][4] === 'No' || mpoFilteredData[i][4] === 'no') {
                                        mpoFilteredData[i][4] = 0;
                                    }
                                    else {
                                        if (mpoFilteredData[i][4] === 'YES' || mpoFilteredData[i][4] === 'Yes' || mpoFilteredData[i][4] === 'yes') {
                                            mpoFilteredData[i][4] = 1;
                                        }
                                    }

                                }
                                const mpoVendor_data = JSON.stringify(mpoFilteredData);
                                console.log("mpoVendordata " + mpoVendor_data);
                                fetch(`${config.host_url}/api/vendor/mpo/massupload`, {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: mpoVendor_data,
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        this.setState({
                                            loading: false
                                        });
                                        let str_msg = JSON.stringify(response[0]);
                                        alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                        this.resetComponent();
                                    })
                                    .catch((error) => {
                                        this.setState({ loading: false });
                                        alert(`Could not Upload the data. Please try again.`);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            alert(`Vendor API data error`);
                        });
                }
            };
            reader.readAsBinaryString(f);
        }
    }

    scoreCardFilePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var scorecardSelectedfile = e.target.files[0];
        this.setState({ scorecardSelectedfile }, () => {
            if (this.state.scorecardSelectedfile === "undefined" || this.state.scorecardSelectedfile === " " ||
                this.state.scorecardSelectedfile === "") {
                this.setState({ scorecardSubmitDisabled: true });
            }
            else {
                this.setState({ scorecardSubmitDisabled: false });
            }
        });
    }

    submitFileVendorScorecard() {
        if (this.state.scorecardSelectedfile === "undefined" || this.state.scorecardSelectedfile === " " || this.state.scorecardSelectedfile === "") {
            this.setState({ scorecardSubmitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({ loading: true });
            let f = this.state.scorecardSelectedfile;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
                /* Update state */
                let scoreFilteredData = data.filter(e => e.length);
                //remove header from base array
                scoreFilteredData.shift();
                for (let i = 0; i < scoreFilteredData.length; i++) {
                    scoreFilteredData[i].splice(3, 1);
                    scoreFilteredData[i].splice(4, 1);
                    scoreFilteredData[i].splice(18, 3);
                }
                //Bank Value Validation
                let str_msg1, str_msg2, str_msg3;
                //let step1=0,step2=0,step3=0;
                let flg_SBG = 0;
                let flg_SBE = 0;
                let flg_reportLevel = 0;
                let flg_eERPNum = 0;
                let flg_eAliasName = 0;
                let flg_eSupGrp = 0;

                let dup_arr1 = [];
                let dup_arr2 = [];
                let dup_arr3 = [];

                let excel_err_msg_venID = [];
                let excel_err_msg_venGrp = [];
                let excel_err_msg_altName = [];
                let excel_err_msg1 = [];
                let arr_excel_VendorID = [];
                let arr_excel_SupGrp = [];
                let arr_excel_AliasName = [];
                let arr_err_VendorID = [];
                let arr_err_SupGrp = [];
                let arr_err_AliasName = [];
                let flg_validation = 'F';
                for (let i = 0; i < scoreFilteredData.length; i++) {
                    let excel_sbg = scoreFilteredData[i][0];
                    let excel_sbe = scoreFilteredData[i][1];
                    let excel_repLevel = scoreFilteredData[i][7];
                    let excel_scorecard_vendorID = scoreFilteredData[i][2];
                    let excel_scorecard_vendorGrp = scoreFilteredData[i][3];
                    let excel_scorecard_aliasName = scoreFilteredData[i][4];

                    // duplicate
                    let arr_dup1 = [excel_sbg, excel_sbe, excel_repLevel, excel_scorecard_vendorID];
                    dup_arr1.push(arr_dup1);
                    let arr_dup2 = [excel_sbg, excel_sbe, excel_repLevel, excel_scorecard_vendorGrp];
                    dup_arr2.push(arr_dup2);
                    let arr_dup3 = [excel_sbg, excel_sbe, excel_repLevel, excel_scorecard_aliasName];
                    dup_arr3.push(arr_dup3);

                    if (excel_sbg === '' || excel_sbg === undefined || excel_sbg === 'undefined') {
                        flg_SBG = 1;
                        excel_err_msg1.push("SBG can't be blank in row: " + (i + 1));
                        flg_validation = 'T';
                    }
                    if (excel_sbe === '' || excel_sbe === undefined || excel_sbe === 'undefined') {
                        flg_SBE = 1;
                        excel_err_msg1.push("SBE can't be blank in row: " + (i + 1));
                        flg_validation = 'T';
                    }
                    if (excel_repLevel === '' || excel_repLevel === undefined || excel_repLevel === 'undefined') {
                        flg_reportLevel = 1;
                        flg_validation = 'T';
                    }
                    else {
                        if (excel_repLevel === 'Supplier ID Level') {
                            if (excel_scorecard_vendorID === '' || excel_scorecard_vendorID === undefined || excel_scorecard_vendorID === 'undefined') {
                                excel_err_msg1.push("Supplier Id can't be blank for Supplier Level Report in row: " + (i + 1));
                                flg_validation = 'T';
                            }
                            else {
                                arr_excel_VendorID.push(scoreFilteredData[i]);
                            }
                        }
                        else if (excel_repLevel === 'Supplier Alias Level') {
                            if (excel_scorecard_aliasName === '' || excel_scorecard_aliasName === undefined || excel_scorecard_aliasName === 'undefined') {
                                excel_err_msg1.push("ALias Name can't be blank for Supplier Alias Level Report in row: " + (i + 1));
                                flg_validation = 'T';
                            }
                            else {
                                arr_excel_AliasName.push(scoreFilteredData[i]);
                            }
                        }
                        else if (excel_repLevel === 'Supplier Group Level') {
                            if (excel_scorecard_vendorGrp === '' || excel_scorecard_vendorGrp === undefined || excel_scorecard_vendorGrp === 'undefined') {
                                excel_err_msg1.push("Supplier Group Id can't be blank for Supplier Group Level Report in row:\n " + (i + 1));
                                flg_validation = 'T';
                            }
                            else {
                                arr_excel_SupGrp.push(scoreFilteredData[i]);
                            }
                        }
                    }
                }

                let flg_duplicate = 0;
                const duplicate_count1 = dup_arr1.length - new Set(dup_arr1.map(JSON.stringify)).size;
                console.log(duplicate_count1, "dupcount");
                if (duplicate_count1 > 0) {
                    flg_duplicate = 1;
                }
                const duplicate_count2 = dup_arr2.length - new Set(dup_arr2.map(JSON.stringify)).size;
                console.log(duplicate_count2, "dupcount");
                if (duplicate_count2 > 0) {
                    flg_duplicate = 1;
                }
                const duplicate_count3 = dup_arr3.length - new Set(dup_arr3.map(JSON.stringify)).size;
                console.log(duplicate_count3, "dupcount");
                if (duplicate_count3 > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }
                else {
                    if (flg_validation === 'T') {
                        alert("Validation Failed\n " + excel_err_msg1);
                        this.setState({ loading: false });
                    }
                    else {
                        //call1
                        if (arr_excel_VendorID.length > 0 && arr_excel_SupGrp.length === 0 && arr_excel_AliasName.length === 0) {
                            //Supplier ID level
                            let scoreVendorCheck = [];
                            for (var i = 0; i < arr_excel_VendorID.length; i++) {
                                scoreVendorCheck.push('' + arr_excel_VendorID[i][2]);
                            }
                            //Check if Vendors exist
                            const vend_data = JSON.stringify(scoreVendorCheck);
                            fetch(`${config.host_url}/api/vendor/allvendors`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: vend_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_VendorIDdata: response
                                    });
                                    let result_dunsid = [];
                                    for (var i in this.state.all_vendorscore_VendorIDdata) {
                                        result_dunsid.push((this.state.all_vendorscore_VendorIDdata[i].VENDOR_DUNS_NUMBER).toString());
                                    }
                                    for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                        let val_vendor_score = arr_excel_VendorID[i][2].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_dunsid.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_VendorID.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_VendorID.length > 0) {
                                        flg_validation = 'T';
                                        flg_eERPNum = 2;
                                    }
                                    if (flg_validation === 'T') {
                                        if (flg_SBG === 1) {
                                            excel_err_msg_venID.push("SBG can't be blank. \n");
                                        }
                                        if (flg_SBE === 1) {
                                            excel_err_msg_venID.push("SBE can't be blank. \n");
                                        }
                                        if (flg_reportLevel === 1) {
                                            excel_err_msg_venID.push("Report Level can't be blank. \n");
                                        }
                                        if (flg_eERPNum === 2) {
                                            excel_err_msg_venID.push("Vendor ID not found in base Table. " + arr_err_VendorID);
                                        }
                                        if (flg_eAliasName === 2) {
                                            excel_err_msg_venID.push("Alternate Group Name not found in base Table. " + arr_err_AliasName);
                                        }
                                        if (flg_eSupGrp === 2) {
                                            excel_err_msg_venID.push("Vendor Group ID not found in base Table. " + arr_err_SupGrp);
                                        }
                                        alert("Validation Failed :\n " + excel_err_msg_venID);
                                        this.setState({ loading: false });
                                    }
                                    else {
                                        for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                            arr_excel_VendorID[i][31] = (this.getTimeStamp().toString());
                                            arr_excel_VendorID[i][32] = 'I';
                                            // arr_excel_VendorID[i].push(this.state.UIDData);
                                        }
                                        const vendorIDScorecard = JSON.stringify(arr_excel_VendorID);
                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload1`, {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: vendorIDScorecard,
                                        })
                                            .then((response) => response.json())
                                            .then((response) => {
                                                str_msg1 = JSON.stringify(response[0]);
                                                this.setState({ loading: false });
                                                alert(`Data Uploaded Successfully\n` +
                                                    `Supplier ID Level: ` + str_msg1.substring(1, str_msg1.length - 1));
                                                this.resetComponent();
                                            })
                                            .catch((error) => {
                                                this.setState({ loading: false });
                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                            });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor Scorecard Vendor ID API data error`);
                                });
                        }
                        //call2
                        else if (arr_excel_VendorID.length === 0 && arr_excel_SupGrp.length > 0 && arr_excel_AliasName.length === 0) {
                            //Supplier Group ID
                            //Check if Vendors Group exist
                            let scoreVendorGrpCheck = [];
                            for (var a = 0; a < arr_excel_SupGrp.length; a++) {
                                scoreVendorGrpCheck.push('' + arr_excel_SupGrp[a][3]);
                            }
                            const grp_data = JSON.stringify(scoreVendorGrpCheck);
                            fetch(`${config.host_url}/api/vendor/score/allgroup`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: grp_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_VendorGrpdata: response
                                    });
                                    let result_grpid = [];
                                    for (var c in this.state.all_vendorscore_VendorGrpdata) {
                                        result_grpid.push((this.state.all_vendorscore_VendorGrpdata[c].VENDOR_GLOBAL_ULTIMATE_DUNS).toString());
                                    }
                                    for (let j = 0; j < arr_excel_SupGrp.length; j++) {
                                        let val_vendor_score = arr_excel_SupGrp[j][3].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_grpid.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_SupGrp.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_SupGrp.length > 0) {
                                        flg_validation = 'T';
                                        flg_eSupGrp = 2;
                                    }
                                    if (flg_validation === 'T') {
                                        if (flg_SBG === 1) {
                                            excel_err_msg_venGrp.push("SBG can't be blank. \n");
                                        }
                                        if (flg_SBE === 1) {
                                            excel_err_msg_venGrp.push("SBE can't be blank. \n");
                                        }
                                        if (flg_reportLevel === 1) {
                                            excel_err_msg_venGrp.push("Report Level can't be blank. \n");
                                        }
                                        if (flg_eSupGrp === 2) {
                                            excel_err_msg_venGrp.push("Vendor Group ID not found in base Table. " + arr_err_SupGrp);
                                        }
                                        alert("Validation Failed :\n " + excel_err_msg_venGrp);
                                        this.setState({ loading: false });
                                    }
                                    else {
                                        for (let i = 0; i < arr_excel_SupGrp.length; i++) {
                                            arr_excel_SupGrp[i][31] = (this.getTimeStamp().toString());
                                            arr_excel_SupGrp[i][32] = 'I';
                                            // arr_excel_SupGrp[i].push(this.state.UIDData);
                                        }
                                        //Add while
                                        const vendorGrpID = JSON.stringify(arr_excel_SupGrp);
                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload2`, {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: vendorGrpID,
                                        })
                                            .then((response) => response.json())
                                            .then((response) => {
                                                str_msg2 = JSON.stringify(response[0]);
                                                this.setState({ loading: false });
                                                alert(`Data Uploaded Successfully\n` +
                                                    `Supplier Group Level: ` + str_msg2.substring(1, str_msg2.length - 1));
                                                this.resetComponent();
                                            })
                                            .catch((error) => {
                                                this.setState({ loading: false });
                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                            });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor Group API data error`);
                                });
                        }
                        //call3
                        else if (arr_excel_VendorID.length === 0 && arr_excel_SupGrp.length === 0 && arr_excel_AliasName.length > 0) {
                            //Check if Alias Name exist
                            let scoreVendorAliasCheck = [];
                            for (var b = 0; b < arr_excel_AliasName.length; b++) {
                                scoreVendorAliasCheck.push('' + arr_excel_AliasName[b][4]);
                            }
                            const alias_data = JSON.stringify(scoreVendorAliasCheck);
                            fetch(`${config.host_url}/api/vendor/score/allalias`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: alias_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_Aliasdata: response
                                    });

                                    let result_aliasName = [];
                                    for (var i in this.state.all_vendorscore_Aliasdata) {
                                        result_aliasName.push((this.state.all_vendorscore_Aliasdata[i].ALTERNATE_GROUP_NAME).toString());
                                    }
                                    for (let k = 0; k < arr_excel_AliasName.length; k++) {
                                        let val_vendor_score = arr_excel_AliasName[k][4].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_aliasName.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_AliasName.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_AliasName.length > 0) {
                                        flg_validation = 'T';
                                        flg_eAliasName = 2;
                                    }
                                    if (flg_validation === 'T') {
                                        if (flg_SBG === 1) {
                                            excel_err_msg_altName.push("SBG can't be blank. \n");
                                        }
                                        if (flg_SBE === 1) {
                                            excel_err_msg_altName.push("SBE can't be blank. \n");
                                        }
                                        if (flg_reportLevel === 1) {
                                            excel_err_msg_altName.push("Report Level can't be blank. \n");
                                        }
                                        if (flg_eAliasName === 2) {
                                            excel_err_msg_altName.push("Alternate Group Name not found in base Table. " + arr_err_AliasName);
                                        }
                                        alert("Validation Failed :\n " + excel_err_msg_altName);
                                        this.setState({ loading: false });
                                    }
                                    else {
                                        for (let i = 0; i < arr_excel_AliasName.length; i++) {
                                            arr_excel_AliasName[i][31] = (this.getTimeStamp().toString());
                                            arr_excel_AliasName[i][32] = 'I';
                                            // arr_excel_AliasName[i].push(this.state.UIDData);
                                        }
                                        //Add while
                                        const vendorAlias = JSON.stringify(arr_excel_AliasName);
                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload3`, {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: vendorAlias,
                                        })
                                            .then((response) => response.json())
                                            .then((response) => {
                                                str_msg3 = JSON.stringify(response[0]);
                                                this.setState({ loading: false });
                                                alert(`Data Uploaded Successfully\n` +
                                                    `Supplier Alias Level: ` + str_msg3.substring(1, str_msg3.length - 1));
                                                this.resetComponent();
                                            })
                                            .catch((error) => {
                                                this.setState({ loading: false });
                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                            });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor ALias API data error`);
                                });
                        }
                        // T T F -- call 1 and 2
                        else if (arr_excel_VendorID.length > 0 && arr_excel_SupGrp.length > 0 && arr_excel_AliasName.length === 0) {
                            //Supplier ID level
                            let scoreVendorCheck = [];
                            for (var j = 0; j < arr_excel_VendorID.length; j++) {
                                scoreVendorCheck.push('' + arr_excel_VendorID[j][2]);
                            }
                            //Check if Vendors exist
                            const vend_data = JSON.stringify(scoreVendorCheck);
                            fetch(`${config.host_url}/api/vendor/allvendors`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: vend_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_VendorIDdata: response
                                    });
                                    let result_dunsid = [];
                                    for (var i in this.state.all_vendorscore_VendorIDdata) {
                                        result_dunsid.push((this.state.all_vendorscore_VendorIDdata[i].VENDOR_DUNS_NUMBER).toString());
                                    }
                                    for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                        let val_vendor_score = arr_excel_VendorID[i][2].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_dunsid.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_VendorID.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_VendorID.length > 0) {
                                        flg_validation = 'T';
                                        flg_eERPNum = 2;
                                    }
                                    //Supplier Group ID
                                    //Check if Vendors Group exist
                                    let scoreVendorGrpCheck = [];
                                    for (var a = 0; a < arr_excel_SupGrp.length; a++) {
                                        scoreVendorGrpCheck.push('' + arr_excel_SupGrp[a][3]);
                                    }
                                    const grp_data = JSON.stringify(scoreVendorGrpCheck);
                                    fetch(`${config.host_url}/api/vendor/score/allgroup`, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: grp_data,
                                    })
                                        .then((response) => response.json())
                                        .then((response) => {
                                            this.setState({
                                                all_vendorscore_VendorGrpdata: response
                                            });
                                            let result_grpid = [];
                                            for (var c in this.state.all_vendorscore_VendorGrpdata) {
                                                result_grpid.push((this.state.all_vendorscore_VendorGrpdata[c].VENDOR_GLOBAL_ULTIMATE_DUNS).toString());
                                            }
                                            for (let j = 0; j < arr_excel_SupGrp.length; j++) {
                                                let val_vendor_score = arr_excel_SupGrp[j][3].toString();
                                                let cnt_match_vendor = 0;
                                                if (result_grpid.includes(val_vendor_score)) {
                                                    cnt_match_vendor = 1;
                                                }
                                                if (cnt_match_vendor === 0) {
                                                    arr_err_SupGrp.push(val_vendor_score);
                                                }
                                            }
                                            if (arr_err_SupGrp.length > 0) {
                                                flg_validation = 'T';
                                                flg_eSupGrp = 2;
                                            }
                                            if (flg_validation === 'T') {
                                                if (flg_SBG === 1) {
                                                    excel_err_msg_venID.push("SBG can't be blank. \n");
                                                }
                                                if (flg_SBE === 1) {
                                                    excel_err_msg_venID.push("SBE can't be blank. \n");
                                                }
                                                if (flg_reportLevel === 1) {
                                                    excel_err_msg_venID.push("Report Level can't be blank. \n");
                                                }
                                                if (flg_eERPNum === 2) {
                                                    excel_err_msg_venID.push("Vendor ID not found in base Table. " + arr_err_VendorID);
                                                }
                                                if (flg_eAliasName === 2) {
                                                    excel_err_msg_venID.push("Alternate Group Name not found in base Table. " + arr_err_AliasName);
                                                }
                                                if (flg_eSupGrp === 2) {
                                                    excel_err_msg_venID.push("Vendor Group ID not found in base Table. " + arr_err_SupGrp);
                                                }
                                                alert("Validation Failed :\n " + excel_err_msg_venID);
                                                this.setState({ loading: false });
                                            }
                                            else {
                                                for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                                    arr_excel_VendorID[i][31] = (this.getTimeStamp().toString());
                                                    arr_excel_VendorID[i][32] = 'I';
                                                    // arr_excel_VendorID[i].push(this.state.UIDData);
                                                }
                                                for (let i = 0; i < arr_excel_SupGrp.length; i++) {
                                                    arr_excel_SupGrp[i][31] = (this.getTimeStamp().toString());
                                                    arr_excel_SupGrp[i][32] = 'I';
                                                    // arr_excel_SupGrp[i].push(this.state.UIDData);
                                                }
                                                const vendorGrpID = JSON.stringify(arr_excel_SupGrp);
                                                const vendorIDScorecard = JSON.stringify(arr_excel_VendorID);
                                                fetch(`${config.host_url}/api/vendor/scorecard/massupload1`, {
                                                    method: "PUT",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: vendorIDScorecard,
                                                })
                                                    .then((response) => response.json())
                                                    .then((response) => {
                                                        str_msg1 = JSON.stringify(response[0]);
                                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload2`, {
                                                            method: "PUT",
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                            },
                                                            body: vendorGrpID,
                                                        })
                                                            .then((response) => response.json())
                                                            .then((response) => {
                                                                str_msg2 = JSON.stringify(response[0]);
                                                                this.setState({ loading: false });
                                                                alert(`Data Uploaded Successfully.\n` +
                                                                    `Supplier ID Level: ` + (str_msg1.substring(1, str_msg1.length - 1) + `\n` +
                                                                        `Supplier Group Level: ` + str_msg2.substring(1, str_msg2.length - 1)));
                                                                this.resetComponent();
                                                            })
                                                            .catch((error) => {
                                                                this.setState({ loading: false });
                                                                console.log("1", error);
                                                                alert(`Could not Upload Vendor2 Scorecard data. Please try again.`);
                                                            });
                                                    })
                                                    .catch((error) => {
                                                        this.setState({ loading: false });
                                                        console.log("2", error);
                                                        alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                    });
                                            }

                                        })
                                        .catch((error) => {
                                            this.setState({ loading: false });
                                            alert(`Vendor Group API data error`);
                                        });
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor Scorecard Vendor ID API data error`);
                                });
                        }
                        // T F T -- call 1 and 3
                        else if (arr_excel_VendorID.length > 0 && arr_excel_SupGrp.length > 0 && arr_excel_AliasName.length > 0) {
                            //Supplier ID level
                            let scoreVendorCheck = [];
                            for (var x = 0; x < arr_excel_VendorID.length; x++) {
                                scoreVendorCheck.push('' + arr_excel_VendorID[x][2]);
                            }
                            //Check if Vendors exist
                            const vend_data = JSON.stringify(scoreVendorCheck);
                            fetch(`${config.host_url}/api/vendor/allvendors`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: vend_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_VendorIDdata: response
                                    });
                                    let result_dunsid = [];
                                    for (var i in this.state.all_vendorscore_VendorIDdata) {
                                        result_dunsid.push((this.state.all_vendorscore_VendorIDdata[i].VENDOR_DUNS_NUMBER).toString());
                                    }
                                    for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                        let val_vendor_score = arr_excel_VendorID[i][2].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_dunsid.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_VendorID.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_VendorID.length > 0) {
                                        flg_validation = 'T';
                                        flg_eERPNum = 2;
                                    }
                                    //Check if Alias Name exist
                                    let scoreVendorAliasCheck = [];
                                    for (var b = 0; b < arr_excel_AliasName.length; b++) {
                                        scoreVendorAliasCheck.push('' + arr_excel_AliasName[b][4]);
                                    }
                                    const alias_data = JSON.stringify(scoreVendorAliasCheck);
                                    fetch(`${config.host_url}/api/vendor/score/allalias`, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: alias_data,
                                    })
                                        .then((response) => response.json())
                                        .then((response) => {
                                            this.setState({
                                                all_vendorscore_Aliasdata: response
                                            });

                                            let result_aliasName = [];
                                            for (var i in this.state.all_vendorscore_Aliasdata) {
                                                result_aliasName.push((this.state.all_vendorscore_Aliasdata[i].ALTERNATE_GROUP_NAME).toString());
                                            }
                                            for (let k = 0; k < arr_excel_AliasName.length; k++) {
                                                let val_vendor_score = arr_excel_AliasName[k][4].toString();
                                                let cnt_match_vendor = 0;
                                                if (result_aliasName.includes(val_vendor_score)) {
                                                    cnt_match_vendor = 1;
                                                }
                                                if (cnt_match_vendor === 0) {
                                                    arr_err_AliasName.push(val_vendor_score);
                                                }
                                            }
                                            if (arr_err_AliasName.length > 0) {
                                                flg_validation = 'T';
                                                flg_eAliasName = 2;
                                            }
                                            if (flg_validation === 'T') {
                                                if (flg_SBG === 1) {
                                                    excel_err_msg_venID.push("SBG can't be blank. \n");
                                                }
                                                if (flg_SBE === 1) {
                                                    excel_err_msg_venID.push("SBE can't be blank. \n");
                                                }
                                                if (flg_reportLevel === 1) {
                                                    excel_err_msg_venID.push("Report Level can't be blank. \n");
                                                }
                                                if (flg_eERPNum === 2) {
                                                    excel_err_msg_venID.push("Vendor ID not found in base Table. " + arr_err_VendorID);
                                                }
                                                if (flg_eAliasName === 2) {
                                                    excel_err_msg_venID.push("Alternate Group Name not found in base Table. " + arr_err_AliasName);
                                                }
                                                if (flg_eSupGrp === 2) {
                                                    excel_err_msg_venID.push("Vendor Group ID not found in base Table. " + arr_err_SupGrp);
                                                }
                                                alert("Validation Failed :\n " + excel_err_msg_venID);
                                                this.setState({ loading: false });
                                            }
                                            else {
                                                for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                                    arr_excel_VendorID[i][31] = (this.getTimeStamp().toString());
                                                    arr_excel_VendorID[i][32] = 'I';
                                                    // arr_excel_VendorID[i].push(this.state.UIDData);
                                                }
                                                for (let i = 0; i < arr_excel_AliasName.length; i++) {
                                                    arr_excel_AliasName[i][31] = (this.getTimeStamp().toString());
                                                    arr_excel_AliasName[i][32] = 'I';
                                                    // arr_excel_AliasName[i].push(this.state.UIDData);
                                                }
                                                const vendorIDScorecard = JSON.stringify(arr_excel_VendorID);
                                                const vendorAlias = JSON.stringify(arr_excel_AliasName);
                                                fetch(`${config.host_url}/api/vendor/scorecard/massupload1`, {
                                                    method: "PUT",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: vendorIDScorecard,
                                                })
                                                    .then((response) => response.json())
                                                    .then((response) => {
                                                        str_msg1 = JSON.stringify(response[0]);
                                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload3`, {
                                                            method: "PUT",
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                            },
                                                            body: vendorAlias,
                                                        })
                                                            .then((response) => response.json())
                                                            .then((response) => {
                                                                str_msg3 = JSON.stringify(response[0]);
                                                                this.setState({ loading: false });
                                                                alert(`Data Uploaded Successfully\n` +
                                                                    `Supplier ID Level: ` + str_msg1.substring(1, str_msg1.length - 1) + `\n` +
                                                                    `Supplier Alias Level: ` + str_msg3.substring(1, str_msg3.length - 1));
                                                                this.resetComponent();
                                                            })
                                                            .catch((error) => {
                                                                this.setState({ loading: false });
                                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                            });

                                                    })
                                                    .catch((error) => {
                                                        this.setState({ loading: false });
                                                        alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                    });
                                            }
                                        })
                                        .catch((error) => {
                                            this.setState({ loading: false });
                                            alert(`Vendor ALias API data error`);
                                        });
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor Scorecard Vendor ID API data error`);
                                });
                        }
                        // F T T -- call 2 and 3
                        else if (arr_excel_VendorID.length === 0 && arr_excel_SupGrp.length > 0 && arr_excel_AliasName.length > 0) {
                            //Supplier Group ID
                            //Check if Vendors Group exist
                            let scoreVendorGrpCheck = [];
                            for (var y = 0; y < arr_excel_SupGrp.length; y++) {
                                scoreVendorGrpCheck.push('' + arr_excel_SupGrp[y][3]);
                            }
                            const grp_data = JSON.stringify(scoreVendorGrpCheck);
                            fetch(`${config.host_url}/api/vendor/score/allgroup`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: grp_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_VendorGrpdata: response
                                    });
                                    let result_grpid = [];
                                    for (var c in this.state.all_vendorscore_VendorGrpdata) {
                                        result_grpid.push((this.state.all_vendorscore_VendorGrpdata[c].VENDOR_GLOBAL_ULTIMATE_DUNS).toString());
                                    }
                                    for (let j = 0; j < arr_excel_SupGrp.length; j++) {
                                        let val_vendor_score = arr_excel_SupGrp[j][3].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_grpid.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_SupGrp.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_SupGrp.length > 0) {
                                        flg_validation = 'T';
                                        flg_eSupGrp = 2;
                                    }
                                    //Check if Alias Name exist
                                    let scoreVendorAliasCheck = [];
                                    for (var b = 0; b < arr_excel_AliasName.length; b++) {
                                        scoreVendorAliasCheck.push('' + arr_excel_AliasName[b][4]);
                                    }
                                    const alias_data = JSON.stringify(scoreVendorAliasCheck);
                                    fetch(`${config.host_url}/api/vendor/score/allalias`, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: alias_data,
                                    })
                                        .then((response) => response.json())
                                        .then((response) => {
                                            this.setState({
                                                all_vendorscore_Aliasdata: response
                                            });

                                            let result_aliasName = [];
                                            for (var i in this.state.all_vendorscore_Aliasdata) {
                                                result_aliasName.push((this.state.all_vendorscore_Aliasdata[i].ALTERNATE_GROUP_NAME).toString());
                                            }
                                            for (let k = 0; k < arr_excel_AliasName.length; k++) {
                                                let val_vendor_score = arr_excel_AliasName[k][4].toString();
                                                let cnt_match_vendor = 0;
                                                if (result_aliasName.includes(val_vendor_score)) {
                                                    cnt_match_vendor = 1;
                                                }
                                                if (cnt_match_vendor === 0) {
                                                    arr_err_AliasName.push(val_vendor_score);
                                                }
                                            }
                                            if (arr_err_AliasName.length > 0) {
                                                flg_validation = 'T';
                                                flg_eAliasName = 2;
                                            }
                                            if (flg_validation === 'T') {
                                                if (flg_SBG === 1) {
                                                    excel_err_msg_altName.push("SBG can't be blank. \n");
                                                }
                                                if (flg_SBE === 1) {
                                                    excel_err_msg_altName.push("SBE can't be blank. \n");
                                                }
                                                if (flg_reportLevel === 1) {
                                                    excel_err_msg_altName.push("Report Level can't be blank. \n");
                                                }
                                                if (flg_eAliasName === 2) {
                                                    excel_err_msg_altName.push("Alternate Group Name not found in base Table. " + arr_err_AliasName);
                                                }
                                                if (flg_eSupGrp === 2) {
                                                    excel_err_msg_altName.push("Vendor Group ID not found in base Table. " + arr_err_SupGrp);
                                                }
                                                alert("Validation Failed :\n " + excel_err_msg_altName);
                                                this.setState({ loading: false });
                                            }
                                            else {
                                                for (let i = 0; i < arr_excel_SupGrp.length; i++) {
                                                    arr_excel_SupGrp[i][31] = (this.getTimeStamp().toString());
                                                    arr_excel_SupGrp[i][32] = 'I';
                                                    // arr_excel_SupGrp[i].push(this.state.UIDData);
                                                }
                                                for (let i = 0; i < arr_excel_AliasName.length; i++) {
                                                    arr_excel_AliasName[i][31] = (this.getTimeStamp().toString());
                                                    arr_excel_AliasName[i][32] = 'I';
                                                    // arr_excel_AliasName[i].push(this.state.UIDData);
                                                }
                                                const vendorGrpID = JSON.stringify(arr_excel_SupGrp);
                                                const vendorAlias = JSON.stringify(arr_excel_AliasName);
                                                fetch(`${config.host_url}/api/vendor/scorecard/massupload2`, {
                                                    method: "PUT",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: vendorGrpID,
                                                })
                                                    .then((response) => response.json())
                                                    .then((response) => {
                                                        str_msg2 = JSON.stringify(response[0]);
                                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload3`, {
                                                            method: "PUT",
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                            },
                                                            body: vendorAlias,
                                                        })
                                                            .then((response) => response.json())
                                                            .then((response) => {
                                                                str_msg3 = JSON.stringify(response[0]);
                                                                this.setState({ loading: false });
                                                                alert(`Data Uploaded Successfully\n` +
                                                                    `Supplier Group Level:` + str_msg2.substring(1, str_msg2.length - 1) + `\n` +
                                                                    `Supplier Alias Level: ` + str_msg3.substring(1, str_msg3.length - 1));
                                                                this.resetComponent();
                                                            })
                                                            .catch((error) => {
                                                                this.setState({ loading: false });
                                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                            });
                                                    })
                                                    .catch((error) => {
                                                        this.setState({ loading: false });
                                                        alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                    });
                                            }
                                        })
                                        .catch((error) => {
                                            this.setState({ loading: false });
                                            alert(`Vendor ALias API data error`);
                                        });
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor Group API data error`);
                                });
                        }
                        //call all3
                        else if (arr_excel_VendorID.length > 0 && arr_excel_SupGrp.length > 0 && arr_excel_AliasName.length > 0) {
                            //Supplier ID level
                            let scoreVendorCheck = [];
                            for (var z = 0; z < arr_excel_VendorID.length; z++) {
                                scoreVendorCheck.push('' + arr_excel_VendorID[z][2]);
                            }
                            //Check if Vendors exist
                            const vend_data = JSON.stringify(scoreVendorCheck);
                            fetch(`${config.host_url}/api/vendor/allvendors`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: vend_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_vendorscore_VendorIDdata: response
                                    });
                                    let result_dunsid = [];
                                    for (var i in this.state.all_vendorscore_VendorIDdata) {
                                        result_dunsid.push((this.state.all_vendorscore_VendorIDdata[i].VENDOR_DUNS_NUMBER).toString());
                                    }
                                    for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                        let val_vendor_score = arr_excel_VendorID[i][2].toString();
                                        let cnt_match_vendor = 0;
                                        if (result_dunsid.includes(val_vendor_score)) {
                                            cnt_match_vendor = 1;
                                        }
                                        if (cnt_match_vendor === 0) {
                                            arr_err_VendorID.push(val_vendor_score);
                                        }
                                    }
                                    if (arr_err_VendorID.length > 0) {
                                        flg_validation = 'T';
                                        flg_eERPNum = 2;
                                    }
                                    //Supplier Group ID
                                    //Check if Vendors Group exist
                                    let scoreVendorGrpCheck = [];
                                    for (var a = 0; a < arr_excel_SupGrp.length; a++) {
                                        scoreVendorGrpCheck.push('' + arr_excel_SupGrp[a][3]);
                                    }
                                    const grp_data = JSON.stringify(scoreVendorGrpCheck);
                                    fetch(`${config.host_url}/api/vendor/score/allgroup`, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: grp_data,
                                    })
                                        .then((response) => response.json())
                                        .then((response) => {
                                            this.setState({
                                                all_vendorscore_VendorGrpdata: response
                                            });
                                            let result_grpid = [];
                                            for (var c in this.state.all_vendorscore_VendorGrpdata) {
                                                result_grpid.push((this.state.all_vendorscore_VendorGrpdata[c].VENDOR_GLOBAL_ULTIMATE_DUNS).toString());
                                            }
                                            for (let j = 0; j < arr_excel_SupGrp.length; j++) {
                                                let val_vendor_score = arr_excel_SupGrp[j][3].toString();
                                                let cnt_match_vendor = 0;
                                                if (result_grpid.includes(val_vendor_score)) {
                                                    cnt_match_vendor = 1;
                                                }
                                                if (cnt_match_vendor === 0) {
                                                    arr_err_SupGrp.push(val_vendor_score);
                                                }
                                            }
                                            if (arr_err_SupGrp.length > 0) {
                                                flg_validation = 'T';
                                                flg_eSupGrp = 2;
                                            }
                                            //Check if Alias Name exist
                                            let scoreVendorAliasCheck = [];
                                            for (var b = 0; b < arr_excel_AliasName.length; b++) {
                                                scoreVendorAliasCheck.push('' + arr_excel_AliasName[b][4]);
                                            }
                                            const alias_data = JSON.stringify(scoreVendorAliasCheck);
                                            fetch(`${config.host_url}/api/vendor/score/allalias`, {
                                                method: "PUT",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                },
                                                body: alias_data,
                                            })
                                                .then((response) => response.json())
                                                .then((response) => {
                                                    this.setState({
                                                        all_vendorscore_Aliasdata: response
                                                    });
                                                    let result_aliasName = [];
                                                    for (var i in this.state.all_vendorscore_Aliasdata) {
                                                        result_aliasName.push((this.state.all_vendorscore_Aliasdata[i].ALTERNATE_GROUP_NAME).toString());
                                                    }
                                                    for (let k = 0; k < arr_excel_AliasName.length; k++) {
                                                        let val_vendor_score = arr_excel_AliasName[k][4].toString();
                                                        let cnt_match_vendor = 0;
                                                        if (result_aliasName.includes(val_vendor_score)) {
                                                            cnt_match_vendor = 1;
                                                        }
                                                        if (cnt_match_vendor === 0) {
                                                            arr_err_AliasName.push(val_vendor_score);
                                                        }
                                                    }
                                                    if (arr_err_AliasName.length > 0) {
                                                        flg_validation = 'T';
                                                        flg_eAliasName = 2;
                                                    }
                                                    if (flg_validation === 'T') {
                                                        if (flg_SBG === 1) {
                                                            excel_err_msg_venID.push("SBG can't be blank. \n");
                                                        }
                                                        if (flg_SBE === 1) {
                                                            excel_err_msg_venID.push("SBE can't be blank. \n");
                                                        }
                                                        if (flg_reportLevel === 1) {
                                                            excel_err_msg_venID.push("Report Level can't be blank. \n");
                                                        }
                                                        if (flg_eERPNum === 2) {
                                                            excel_err_msg_venID.push("Vendor ID not found in base Table. " + arr_err_VendorID);
                                                        }
                                                        if (flg_eAliasName === 2) {
                                                            excel_err_msg_venID.push("Alternate Group Name not found in base Table. " + arr_err_AliasName);
                                                        }
                                                        if (flg_eSupGrp === 2) {
                                                            excel_err_msg_venID.push("Vendor Group ID not found in base Table. " + arr_err_SupGrp);
                                                        }
                                                        alert("Validation Failed :\n " + excel_err_msg_venID);
                                                        this.setState({ loading: false });
                                                    }
                                                    else {
                                                        for (let i = 0; i < arr_excel_VendorID.length; i++) {
                                                            arr_excel_VendorID[i][31] = (this.getTimeStamp().toString());
                                                            arr_excel_VendorID[i][32] = 'I';
                                                            // arr_excel_VendorID[i].push(this.state.UIDData);
                                                        }
                                                        const vendorIDScorecard = JSON.stringify(arr_excel_VendorID);
                                                        for (let i = 0; i < arr_excel_SupGrp.length; i++) {
                                                            arr_excel_SupGrp[i][31] = (this.getTimeStamp().toString());
                                                            arr_excel_SupGrp[i][32] = 'I';
                                                            // arr_excel_SupGrp[i].push(this.state.UIDData);
                                                        }
                                                        const vendorGrpID = JSON.stringify(arr_excel_SupGrp);
                                                        for (let i = 0; i < arr_excel_AliasName.length; i++) {
                                                            arr_excel_AliasName[i][31] = (this.getTimeStamp().toString());
                                                            arr_excel_AliasName[i][32] = 'I';
                                                            // arr_excel_AliasName[i].push(this.state.UIDData);
                                                        }
                                                        const vendorAlias = JSON.stringify(arr_excel_AliasName);
                                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload1`, {
                                                            method: "PUT",
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                            },
                                                            body: vendorIDScorecard,
                                                        })
                                                            .then((response) => response.json())
                                                            .then((response) => {
                                                                str_msg1 = JSON.stringify(response[0]);
                                                                fetch(`${config.host_url}/api/vendor/scorecard/massupload2`, {
                                                                    method: "PUT",
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                    },
                                                                    body: vendorGrpID,
                                                                })
                                                                    .then((response) => response.json())
                                                                    .then((response) => {
                                                                        str_msg2 = JSON.stringify(response[0]);
                                                                        fetch(`${config.host_url}/api/vendor/scorecard/massupload3`, {
                                                                            method: "PUT",
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                            body: vendorAlias,
                                                                        })
                                                                            .then((response) => response.json())
                                                                            .then((response) => {
                                                                                str_msg3 = JSON.stringify(response[0]);
                                                                                this.setState({ loading: false });
                                                                                alert(`Data Uploaded Successfully\n` +
                                                                                    `Supplier ID Level: ` + str_msg1.substring(1, str_msg1.legth - 1) + `\n` +
                                                                                    `Supplier Group Level: ` + str_msg2.substring(1, str_msg2.length - 1) + `\n` +
                                                                                    `Supplier Alias Level: ` + str_msg3.substring(1, str_msg3.length - 1));
                                                                                this.resetComponent();
                                                                            })
                                                                            .catch((error) => {
                                                                                this.setState({ loading: false });
                                                                                console.log(error);
                                                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                                            });
                                                                    })
                                                                    .catch((error) => {
                                                                        this.setState({ loading: false });
                                                                        console.log(error);
                                                                        alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                                    });
                                                            })
                                                            .catch((error) => {
                                                                this.setState({ loading: false });
                                                                console.log(error);
                                                                alert(`Could not Upload Vendor Scorecard data. Please try again.`);
                                                            });
                                                    }
                                                })
                                                .catch((error) => {
                                                    this.setState({ loading: false });
                                                    alert(`Vendor Alias API data error`);
                                                });
                                        })
                                        .catch((error) => {
                                            this.setState({ loading: false });
                                            alert(`Vendor Group API data error`);
                                        });
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Vendor Scorecard Vendor ID API data error`);
                                });
                        }
                        else {
                            alert(`Please Select the Report level in the Excel template.`);
                            this.setState({ loading: false });
                            this.resetComponent();
                        }
                    }
                }
            };
            reader.readAsBinaryString(f);
        }
    }

    render() {
        if (this.state.tologin) {
            return <Redirect to="/LoginRed" />
        }

        const yesNoOptions = [{ value: '1', text: 'Yes' }, { value: '0', text: 'No' }];
        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}></Loader>
                {/* Vendor Maintenance */}
                {
                    this.state.showVendorCard &&
                    <Card interactive={true} style={{ paddingBottom: 40 }}>
                        <Card.Content>
                            <div>
                                <Search
                                    placeholder="Vendor ID"
                                    value={this.state.searchDunsNum}
                                    disabled={true}
                                />
                                <Search
                                    placeholder="Vendor Name"
                                    value={this.state.searchDunsName}
                                    disabled={true}
                                    style={{ paddingLeft: 10 }}
                                /><br></br><br></br>
                                <Search
                                    placeholder="Primary Country"
                                    value={this.state.primaryCountry}
                                    disabled={true}
                                />
                                {/* <br></br><br></br> */}

                                <Search
                                    placeholder="Vendor Subcommodity Owner*"
                                    value={this.state.subComSk}
                                    error={this.state.err_subComSk}
                                    results={this.state.resultsSubComSk}
                                    style={{ paddingLeft: 10 }}
                                    onSearchChange={(value) => this.handleSearchChangeSubCommodity(value)}
                                    onResultSelect={(value) => this.handleSearchSelectSubCommodity(value)}
                                /><br></br><br></br>

                                <Select
                                    options={yesNoOptions}
                                    error={this.state.err_interComFlag}
                                    label="Inter Company Flag*"
                                    value={this.state.interComFlag}
                                    onChange={(value) => this.setState({ interComFlag: value })}
                                />&nbsp;&nbsp;&nbsp;&nbsp;
                                <Select
                                    label="Vendor Georgraphy*"
                                    options={this.state.geoOptions}
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.customGeo}
                                    onChange={(value) => this.setState({ customGeo: value })}

                                />

                                <br></br><br></br>
                                {/* <br></br><br></br> */}
                                <Select
                                    error={this.state.err_vendorStatus}
                                    label="Vendor Business Status*"
                                    style={{ paddingLeft: 10 }}
                                    options={this.state.businessOptions}
                                    value={this.state.vendorStatus}
                                    onChange={(value) => this.setState({ vendorStatus: value })}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Select
                                    error={this.state.err_vendorType}
                                    label="Vendor Type*"
                                    style={{ paddingLeft: 10 }}
                                    options={this.state.typeOptions}
                                    value={this.state.vendorType}
                                    onChange={(value) => this.setState({ vendorType: value })}
                                />
                                <br></br><br></br>
                                <Input
                                    label="Vendor Group ID*"
                                    disabled={this.state.grpDisable}
                                    value={this.state.searchGlobalDunsNum}
                                    onChange={(value) => this.setState({ searchGlobalDunsNum: value })}
                                />
                                <Input
                                    label="Vendor Group Name"
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.searchGlobalDunsName}
                                    disabled={this.state.grpDisable}
                                    onChange={(value) => this.setState({ searchGlobalDunsName: value })}
                                />
                                <br></br><br></br>
                                <Select
                                    //error={this.state.err_currency}
                                    options={yesNoOptions}
                                    label="DnB Freeze Flag"
                                    value={this.state.freezeFlag}
                                    onChange={(value) => this.setState({ freezeFlag: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Input
                                    error={this.state.err_AltGrpName}
                                    label="Alternate Vendor Group Name*"
                                    style={{ paddingLeft: 10, width: 350 }}
                                    maxLength={100}
                                    value={this.state.searchAltVenGrpName}
                                    onChange={(value) => this.setState({ searchAltVenGrpName: value })}
                                />
                                <br></br><br></br>
                                <Select
                                    //error={this.state.err_currency}
                                    label="AERO Vendor Strategy"
                                    options={this.state.strategyOptions}
                                    value={this.state.venAerStrategy}
                                    onChange={(value) => this.setState({ venAerStrategy: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Select
                                    //error={this.state.err_currency}
                                    label="HBT Vendor Strategy"
                                    style={{ paddingLeft: 10 }}
                                    options={this.state.strategyOptions}
                                    value={this.state.venHbtStrategy}
                                    onChange={(value) => this.setState({ venHbtStrategy: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Select
                                    //error={this.state.err_currency}
                                    label="SPS Vendor Strategy"
                                    options={this.state.strategyOptions}
                                    value={this.state.venSpsStrategy}
                                    onChange={(value) => this.setState({ venSpsStrategy: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Select
                                    //error={this.state.err_currency}
                                    label="PMT Vendor Strategy"
                                    style={{ paddingLeft: 10 }}
                                    options={this.state.strategyOptions}
                                    value={this.state.venPmtStrategy}
                                    onChange={(value) => this.setState({ venPmtStrategy: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Select
                                    //error={this.state.err_currency}
                                    label="HDQ Vendor Strategy"
                                    options={this.state.strategyOptions}
                                    value={this.state.venHdqStrategy}
                                    onChange={(value) => this.setState({ venHdqStrategy: value })}
                                />
                                <Select
                                    //error={this.state.err_currency}
                                    label="COE Vendor Strategy"
                                    options={this.state.strategyOptions}
                                    value={this.state.venCoeStrategy}
                                    onChange={(value) => this.setState({ venCoeStrategy: value })}
                                />
                                <br></br><br></br>
                                <Select
                                    //error={this.state.err_currency}
                                    label="AERO Vendor COE"
                                    style={{ paddingLeft: 10 }}
                                    options={this.state.coeOptions}
                                    value={this.state.venCoeCode}
                                    onChange={(value) => this.setState({ venCoeCode: value })}
                                />
                                {/*<br></br><br></br>*/}
                                <Select
                                    //error={this.state.err_currency}
                                    label="HBT Vendor COE"
                                    options={this.state.hbtcoeOptions}
                                    value={this.state.hbtCoeCode}
                                    onChange={(value) => this.setState({ hbtCoeCode: value })}
                                />
                                {/* <Select
                                    //error={this.state.err_currency}
                                    label="SPS Vendor COE"
                                    placeholder="given by business in 2022"
                                    options={this.state.spsOptions}
                                    value={this.state.venCoeCode}
                                    onChange={(value) => this.setState({ venCoeCode: value })}
                                    disabled={true}
                                   
                                />  */}
                                <Input
                                    label="SPS Vendor COE"
                                    placeholder=" "
                                    disabled={true}
                                />

                                <Select
                                    //error={this.state.err_currency}
                                    label="PMT Vendor COE"
                                    options={this.state.coeOptions}
                                    value={this.state.venPmtCode}
                                    onChange={(value) => this.setState({ venPmtCode: value })}
                                />
                                <Input
                                    label="HDQ Vendor COE"
                                    placeholder=" "
                                    disabled={true}
                                />

                                {/* <Select
                                    //error={this.state.err_currency}
                                    label="HDQ Vendor COE"
                                    placeholder="given by business in 2022"

                                    options={this.state.coeOptions}
                                    value={this.state.venCoeCode}
                                    onChange={(value) => this.setState({ venCoeCode: value })}
                                />  */}

                                <br></br><br></br>
                                <Input
                                    //error={this.state.err_currency}
                                    label="Contract ID"
                                    maxLength={100}
                                    value={this.state.ltaNum}
                                    onChange={(value) => this.setState({ ltaNum: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Input
                                    //error={this.state.err_currency}
                                    label="Contract Rev"
                                    maxLength={100}
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.ltaRev}
                                    onChange={(value) => this.setState({ ltaRev: value })}
                                />
                                <br></br><br></br>
                                <Input
                                    //error={this.state.err_currency}
                                    label="Vendor on Contract Flag"
                                    value={this.state.venOnContract}
                                    disabled={true}
                                />
                                {/* <br></br><br></br> */}
                                <Input
                                    //error={this.state.err_currency}
                                    label="Cage Code"
                                    maxLength={100}
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.cageCode}
                                    onChange={(value) => this.setState({ cageCode: value })}
                                />
                                <br></br><br></br>
                                <Input
                                    //error={this.state.err_currency}
                                    label="Vendor Location Latitude"
                                    maxLength={10}
                                    value={this.state.lat}
                                    onChange={(value) => this.setState({ lat: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Input
                                    //error={this.state.err_currency}
                                    label="Vendor Location Longitude"
                                    maxLength={10}
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.lon}
                                    onChange={(value) => this.setState({ lon: value })}
                                />
                                <br></br><br></br>
                                <Button content="Update" onClick={this.vendorUpdate} style={{ paddingLeft: 10 }}></Button>
                                <Button content="Back" onClick={this.selectCloseVendor}></Button>
                            </div>
                        </Card.Content>
                    </Card>
                }

                {/* ERP Vendor Maintenance */}
                {
                    this.state.showERPVendorCard &&
                    <Card interactive={true} style={{ paddingBottom: 40 }}>
                        <Card.Content>
                            <div>
                                <Search
                                    placeholder="ERP Vendor Number"
                                    disabled={true}
                                    value={this.state.searchErpVendorNum}
                                />
                                <Search
                                    placeholder="Vendor Name"
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.searchErpVendorName}
                                    disabled={true}
                                /><br></br><br></br>
                                <Search
                                    placeholder="OS Site ID"
                                    //style={{ paddingLeft: 10 }}
                                    value={this.state.searchSiteId}
                                    disabled={true}
                                />
                                <br></br><br></br>
                                <Input
                                    placeholder="Vendor ID"
                                    value={this.state.searchDunsNum}
                                    onChange={(value) => this.setState({ searchDunsNum: value })}
                                    disabled={this.state.disableDuns}
                                />&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10" }} onClick={() => { this.validateVendor(this.state.searchDunsNum); }} />&nbsp;&nbsp;

                                    <Input
                                    placeholder="Vendor Name"
                                    value={this.state.searchDunsName}
                                    style={{ paddingLeft: 10 }}
                                    disabled={this.state.disableDuns}
                                // disabled={this.state.vendorScorecardDisabled}
                                />
                                {/* <Input
                                    placeholder="Vendor Number"
                                    value={this.state.searchDunsNum}
                                    disabled={this.state.disableDuns}
                                    onChange={(value) => this.setState({ searchDunsNum: value })}
                                />
                                <Input
                                    placeholder="Vendor Name"
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.searchDunsName}
                                    disabled={this.state.disableDuns}
                                    onChange={(value) => this.setState({ searchDunsName: value })}
                                /> */}
                                <br></br><br></br>
                                <Input
                                    label="Plant Primary Vendor Number"
                                    value={this.state.plantVenNo}
                                    onChange={(value) => this.setState({ plantVenNo: value })}
                                //style={{ paddingLeft: 10 }}
                                /><br></br><br></br>
                                <b style={{ fontSize: "12" }}>Always OTD</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Radio
                                    label="Yes"
                                    name="RadioGroup"
                                    checked={this.state.rdbvalueAlwaysOTD === 1}
                                    onChange={(value) => this.setState({ rdbvalueAlwaysOTD: 1 })}
                                    disabled={this.state.enableOnSearch}
                                />
                                <Radio
                                    label="No"
                                    name="RadioGroup"
                                    checked={this.state.rdbvalueAlwaysOTD === 0}
                                    onChange={(value) => this.setState({ rdbvalueAlwaysOTD: 0 })}
                                    disabled={this.state.enableOnSearch}
                                /><br></br><br></br>
                                <b style={{ fontSize: "12" }}>ERP Vendor Freeze Flag</b>&nbsp;&nbsp;
                                <Radio
                                    label="Yes"
                                    name="RadioGroup"
                                    checked={this.state.rdbvalueFreeze === 1}
                                    onChange={(value) => this.setState({ rdbvalueFreeze: 1 })}
                                    disabled={this.state.enableOnSearch}
                                />
                                <Radio
                                    label="No"
                                    name="RadioGroup"
                                    checked={this.state.rdbvalueFreeze === 0}
                                    onChange={(value) => this.setState({ rdbvalueFreeze: 0 })}
                                    disabled={this.state.enableOnSearch}
                                />
                                <br></br><br></br>
                                <Input
                                    label="Early Delivery (Days)"
                                    value={this.state.earlyDelivery}
                                    onChange={(value) => this.setState({ earlyDelivery: value })}
                                />
                                {/* <br></br><br></br> */}
                                <Input
                                    //error={this.state.err_currency}
                                    label="Late Delivery (Days)"
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.lateDelivery}
                                    onChange={(value) => this.setState({ lateDelivery: value })}
                                />
                                <br></br><br></br>
                                <Select
                                    options={this.state.businessOptions}
                                    error={this.state.err_rollingbusiness}
                                    label="Rolling 12 Month Business"
                                    value={this.state.rollingBusiness}
                                    onChange={(value) => this.setState({ rollingBusiness: value })}
                                />
                                <br></br><br></br>
                                <Button content="Add" onClick={this.erpVendorAdd}
                                    style={{ paddingLeft: 10 }}
                                    disabled={this.state.addErpVendorDisabled}></Button>
                                <Button content="Update"
                                    disabled={this.state.updateErpVendorDisabled}
                                    onClick={this.erpVendorUpdate} style={{ paddingLeft: 10 }}></Button>
                                <Button content="Back" onClick={this.selectCloseVendor} ></Button>
                            </div>
                        </Card.Content>
                    </Card>
                }

                {/* ERP Vendor MPO Maintenance */}
                {
                    this.state.showERPVendorMPOCard &&
                    <Card interactive={true} style={{ paddingBottom: 40 }}>
                        <Card.Content>
                            <div>
                                <Search
                                    placeholder="SOURCE SYSTEM"
                                    disabled={this.state.mposourcedisable}
                                    value={this.state.searchMpoSourcesystem} />
                                <br></br><br></br>
                                <Search
                                    placeholder="OS Site ID"
                                    disabled={this.state.mpositedisable}
                                    value={this.state.searchMpoSiteId} />
                                <br></br><br></br>
                                <Search
                                    placeholder="ERP Vendor Number"
                                    disabled={this.state.mpoerpvendordisable}
                                    // disabled={true}

                                    value={this.state.searchErpVendorNum}
                                />
                                <br>
                             
                                </br>
                                <br/>


                                {/* <Search
                                    placeholder="Vendor Name"
                                    style={{ paddingLeft: 10 }}
                                    value={this.state.searchErpVendorName}
                                    disabled={this.state.mpovendornamedisable}

                                    // disabled={true}
                                /><br></br><br></br> */}
                                <Input

                                    placeholder="COMPANY CODE"
                                    // disabled={true}
                                    disabled={this.state.mpoccdisable}
                                    value={this.state.venComcode}
                                    // style={{ visibility: this.state.showCC ? 'visible' : 'hidden' }}
                                />

                                <Input

                                placeholder="NS SITE"
                                // disabled={true}
                                disabled={this.state.mpons_sitedisable}
                                value={this.state.venNs_Site}
                                // style={{ visibility: this.state.showCC ? 'visible' : 'hidden' }}
                                /><br></br>
                                <br/>


                                <b style={{ fontSize: "12" }}>Vendor MPO Indicator</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Radio
                                    label="Yes"
                                    name="RadioGroup"
                                    checked={this.state.rdbvalueMpoIndicator === 1}
                                    onChange={(value) => this.setState({ rdbvalueMpoIndicator: 1 })}
                                    disabled={this.state.radiodisable}
                                />
                                <Radio
                                    label="No"
                                    name="RadioGroup"
                                    checked={this.state.rdbvalueMpoIndicator === 0}
                                    onChange={(value) => this.setState({ rdbvalueMpoIndicator: 0 })}
                                    disabled={this.state.radiodisable}
                                /><br></br><br></br>
                                <Select
                                    options={this.state.gatesOptions}
                                    label="Vendor MPO Phase Gate"
                                    value={this.state.venPhaseGate}
                                    onChange={(value) => this.setState({ venPhaseGate: value })}
                                />
                                <Select
                                    options={this.state.gateInfo}
                                    label="Vendor MPO Gate Info"
                                    value={this.state.venGateInfo}
                                    //search={true}
                                    onChange={(value) =>
                                        this.setState({ venGateInfo: value })
                                    }
                                /><br></br><br></br>

                                <Button content="Update" onClick={this.mpoVendorUpdate} style={{ paddingLeft: 10 }}></Button>
                                <Button content="Back" onClick={this.selectCloseVendor} ></Button>


                                <Grid.Row>
                                    <Grid.Column width={12} sWidth={6}>
                                        <div style={{ "paddingBottom": 40 }}>
                                            <DataTable
                                                scrollable={true}
                                                scrollWidth='1300px'
                                                reorderableColumns={true}
                                                resizableColumns={true}
                                                loading={false}
                                                data={this.state.vendorSearchTabMpo}
                                                selection={this.state.selectedItem}
                                                selectionMode="single"
                                                search={true}
                                                rows={10}
                                                onSelectionChange={(e) => { this.handleSelectMpoCheckBox(e) }
                                                }>
                                                <DataTable.Column
                                                    field="OS_SITE_ID"
                                                    header="SITE ID"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />

                                                <DataTable.Column
                                                    field="VENDOR_SK"
                                                    header="ERP Vendor Number"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />

                                                <DataTable.Column
                                                    field="VENDOR_NAME"
                                                    header="ERP Vendor Name"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />

                                                <DataTable.Column
                                                    field="COMPANY_CODE"
                                                    header="Company code"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />
                                                <DataTable.Column
                                                    field="SOURCE_SYSTEM"
                                                    header="Source system"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />

                                                <DataTable.Column
                                                    field="VENDOR_MPO_FLAG"
                                                    header="Vendor MPO Indicator"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />
                                                <DataTable.Column
                                                    field="VENDOR_MPO_PHASE_GATES"
                                                    header="VENDOR_MPO_PHASE_GATES"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />
                                                <DataTable.Column
                                                    field="VENDOR_MPO_GATE_INFO"
                                                    header="VENDOR_MPO_GATE_INFO"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />
                                                 <DataTable.Column
                                                    field="NS_SITE"
                                                    header="NS_SITE"
                                                    sortable={false}
                                                    initialWidth='250px'
                                                />



                                                <DataTable.Pagination />
                                            </DataTable>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </div>
                        </Card.Content>
                    </Card>
                }

                {/* ERP Vendor Scorecard */}
                {
                    this.state.showVendorScorecardCard &&
                    <Card interactive={true} style={{ paddingBottom: 40 }}>
                        <Card.Content>
                            <div>
                                <Select
                                    options={this.state.sbgOptions}
                                    placeholder="SBG Code*"
                                    value={this.state.sbgSelected}
                                    onChange={(value) => (this.callSbe(value))
                                    }
                                />

                                <Select
                                    options={this.state.sbeOptions}
                                    placeholder="SBE Code"
                                    disabled={this.state.sbeDisabled}
                                    //results={this.state.resultssbe}
                                    value={this.state.sbeSelected}
                                    // onResultSelect={(value) =>this.handleSearchSbeSelect(value)}
                                    // onSearchChange={(value) =>this.handleSearchChangeSbeName(value)}
                                    onChange={(value) =>
                                        this.setState({ sbeSelected: value })
                                    }
                                    style={{ paddingLeft: 10 }}
                                /><br></br><br></br>
                                <label style={{ "paddingLeft": 10 }}>Report Level*:&nbsp;&nbsp;</label>
                                <Radio
                                    label="Supplier ID Level"
                                    name="RadioGroup"
                                    checked={this.state.reportLevel === "Supplier ID Level"}
                                    onChange={(value) => this.setState({
                                        reportLevel: "Supplier ID Level",
                                        vendorScorecardDisabled: false,
                                        vendorGroupScorecardDisabled: true,
                                        vendorAltNameScorecardDisabled: true,
                                    })}
                                />
                                <Radio
                                    label="Supplier Group Level"
                                    name="RadioGroup"
                                    checked={this.state.reportLevel === "Supplier Group Level"}
                                    onChange={(value) => this.setState({
                                        reportLevel: "Supplier Group Level",
                                        vendorScorecardDisabled: true,
                                        vendorGroupScorecardDisabled: false,
                                        vendorAltNameScorecardDisabled: true,
                                    })}
                                />
                                <Radio
                                    label="Supplier Alias Level"
                                    name="RadioGroup"
                                    checked={this.state.reportLevel === "Supplier Alias Level"}
                                    onChange={(value) => this.setState({
                                        reportLevel: "Supplier Alias Level",
                                        vendorScorecardDisabled: true,
                                        vendorGroupScorecardDisabled: true,
                                        vendorAltNameScorecardDisabled: false,
                                    })}
                                />
                                <br></br><br></br>
                                <Input
                                    placeholder="Vendor ID"
                                    value={this.state.searchDunsNum}
                                    onChange={(value) => this.setState({ searchDunsNum: value })}
                                    disabled={this.state.vendorScorecardDisabled}
                                />&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10" }} onClick={() => { this.validateVendor(this.state.searchDunsNum); }} />&nbsp;&nbsp;

                                    <Input
                                    placeholder="Vendor Name"
                                    value={this.state.searchDunsName}
                                    style={{ paddingLeft: 10 }}
                                    disabled={this.state.vendorScorecardDisabled}
                                />
                                <br></br><br></br>
                                <Input
                                    placeholder="Vendor Group ID"
                                    value={this.state.searchGlobalDunsNum}
                                    disabled={this.state.vendorGroupScorecardDisabled}
                                    onChange={(value) => this.setState({ searchGlobalDunsNum: value })}
                                />
                                &nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10" }} onClick={() => { this.validateVendorGroup(this.state.searchGlobalDunsNum); }} />&nbsp;&nbsp;

                                <br></br><br></br>
                                <Input
                                    placeholder="Vendor ALIAS"
                                    value={this.state.searchAltVenGrpName}
                                    disabled={this.state.vendorAltNameScorecardDisabled}
                                    onChange={(value) => this.setState({ searchAltVenGrpName: value })}
                                />&nbsp;&nbsp;
                                <Button content="Validate" style={{ paddingTop: "10" }} onClick={() => { this.validateAltGrpName(this.state.searchAltVenGrpName); }} />&nbsp;&nbsp;
                                <br></br><br></br>

                                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleVendorSearchScorecardSelect}
                                    disabled={this.state.disableSearch} />
                                <br></br><br></br>
                                <Input
                                    placeholder="Report Name"
                                    value={this.state.reportName}
                                    onChange={(value) => this.setState({ reportName: value })}
                                    maxLength={50}
                                />
                                <Input
                                    placeholder="Report File Name"
                                    value={this.state.reportFileName}
                                    onChange={(value) => this.setState({ reportFileName: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                /><br></br><br></br>
                                <Input
                                    placeholder="Vendor Contact Title"
                                    value={this.state.vendorContactTitle}
                                    onChange={(value) => this.setState({ vendorContactTitle: value })}
                                    maxLength={50}
                                //style={{ paddingLeft: 10 }}
                                />

                                <Input
                                    placeholder="Vendor Contact First Name"
                                    value={this.state.vendorContactFirstName}
                                    onChange={(value) => this.setState({ vendorContactFirstName: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                />

                                <Input
                                    placeholder="Vendor Contact Last Name"
                                    value={this.state.vendorContactLastName}
                                    onChange={(value) => this.setState({ vendorContactLastName: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                />
                                <Input
                                    placeholder="CORP Vendor Contact"
                                    value={this.state.corpVendorContact}
                                    onChange={(value) => this.setState({ corpVendorContact: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                    error={this.state.err_contact}
                                /><br></br>
                                <Input
                                    placeholder="AERO Vendor Contact"
                                    value={this.state.aeroVendorContact}
                                    maxLength={50}
                                    onChange={(value) => this.setState({ aeroVendorContact: value })}
                                    error={this.state.err_contact}
                                />
                                <Input
                                    placeholder="HBT Vendor Contact"
                                    value={this.state.hbtVendorContact}
                                    onChange={(value) => this.setState({ hbtVendorContact: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                    error={this.state.err_contact}
                                />
                                <Input
                                    placeholder="PMT Vendor Contact"
                                    value={this.state.pmtVendorContact}
                                    onChange={(value) => this.setState({ pmtVendorContact: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                    error={this.state.err_contact}
                                />
                                <Input
                                    placeholder="SPS Vendor Contact"
                                    value={this.state.spsVendorContact}
                                    onChange={(value) => this.setState({ spsVendorContact: value })}
                                    maxLength={50}
                                    style={{ paddingLeft: 10 }}
                                    error={this.state.err_contact}
                                /><br></br>
                                <Input
                                    label="Corp Reviewer"
                                    maxLength={500}
                                    value={this.state.corpReviewer}
                                    onChange={(value) => this.setState({ corpReviewer: value })}
                                    error={this.state.err_Reviewer}
                                />
                                <Input
                                    label="Corp CC"
                                    value={this.state.corpCC}
                                    onChange={(value) => this.setState({ corpCC: value })}
                                    maxLength={500}
                                    style={{ paddingLeft: 10 }}
                                />
                                <br></br>

                                <Input
                                    label="AERO Reviewer"
                                    maxLength={500}
                                    value={this.state.aerReviewer}
                                    onChange={(value) => this.setState({ aerReviewer: value })}
                                    error={this.state.err_Reviewer}
                                />
                                <Input
                                    label="AERO CC"
                                    maxLength={500}
                                    value={this.state.aerCC}
                                    onChange={(value) => this.setState({ aerCC: value })}
                                    style={{ paddingLeft: 10 }}
                                />
                                <br></br>

                                <Input
                                    label="HBT Reviewer"
                                    maxLength={500}
                                    value={this.state.hbtReveiwer}
                                    onChange={(value) => this.setState({ hbtReveiwer: value })}
                                    error={this.state.err_Reviewer}
                                />
                                <Input
                                    label="HBT CC"
                                    maxLength={500}
                                    value={this.state.hbtCC}
                                    onChange={(value) => this.setState({ hbtCC: value })}
                                    style={{ paddingLeft: 10 }}
                                />
                                <br></br>
                                <Input
                                    label="PMT Reviewer"
                                    maxLength={500}
                                    value={this.state.pmtReviewer}
                                    onChange={(value) => this.setState({ pmtReviewer: value })}
                                    error={this.state.err_Reviewer}
                                />
                                <Input
                                    label="PMT CC"
                                    maxLength={500}
                                    value={this.state.pmtCC}
                                    onChange={(value) => this.setState({ pmtCC: value })}
                                    style={{ paddingLeft: 10 }}
                                />
                                <br></br>
                                <Input
                                    label="SPS Reviewer"
                                    maxLength={500}
                                    value={this.state.spsReveiwer}
                                    onChange={(value) => this.setState({ spsReveiwer: value })}
                                    error={this.state.err_Reviewer}
                                />
                                <Input
                                    label="SPS CC"
                                    maxLength={500}
                                    value={this.state.spsCC}
                                    onChange={(value) => this.setState({ spsCC: value })}
                                    style={{ paddingLeft: 10 }}
                                />
                                <br></br>
                                <label><b>Report Frequency*:</b> &nbsp;&nbsp;</label>
                                <Radio
                                    label="Monthly"
                                    name="RadioGroup0"
                                    checked={this.state.reportFrequency === "Monthly"}
                                    onChange={(value) => this.setState({ reportFrequency: "Monthly" })}
                                />
                                <Radio
                                    label="Quarterly"
                                    name="RadioGroup0"
                                    checked={this.state.reportFrequency === "Quarterly"}
                                    onChange={(value) => this.setState({ reportFrequency: "Quarterly" })}
                                />
                                <br></br>
                                <label><b>Scorecard Status:</b>&nbsp;&nbsp;</label>
                                <Radio
                                    label="Not yet generated"
                                    name="RadioGroup1"
                                    checked={this.state.scorecardStatus === "Not yet generated"}
                                    onChange={(value) => this.setState({
                                        scorecardStatus: "Not yet generated"
                                    })}
                                />
                                <Radio
                                    label="Under Review"
                                    name="RadioGroup1"
                                    checked={this.state.scorecardStatus === "Under Review"}
                                    onChange={(value) => this.setState({
                                        scorecardStatus: "Under Review"
                                    })}
                                />
                                <Radio
                                    label="Distributed"
                                    name="RadioGroup1"
                                    checked={this.state.scorecardStatus === "Distributed"}
                                    onChange={(value) => this.setState({
                                        scorecardStatus: "Distributed"
                                    })}
                                />
                                <br></br>
                                <label><b>Scorecard Active: </b>&nbsp;&nbsp;</label>
                                <Radio
                                    label="No"
                                    name="RadioGroup2"
                                    checked={this.state.activeFlag === "No"}
                                    onChange={(value) => this.setState({
                                        activeFlag: "No"
                                    })}
                                />
                                <Radio
                                    label="Yes"
                                    name="RadioGroup2"
                                    checked={this.state.activeFlag === "Yes"}
                                    onChange={(value) => this.setState({
                                        activeFlag: "Yes"
                                    })}
                                />
                                <br></br>
                                <label><b>Executive Contact: </b>&nbsp;&nbsp;</label>
                                <Radio
                                    label="No"
                                    name="RadioGroup3"
                                    checked={this.state.executiveContact === "No"}
                                    onChange={(value) => this.setState({
                                        executiveContact: "No"
                                    })}
                                />
                                <Radio
                                    label="Yes"
                                    name="RadioGroup3"
                                    checked={this.state.executiveContact === "Yes"}
                                    onChange={(value) => this.setState({
                                        executiveContact: "Yes"
                                    })}
                                />
                                <br></br>
                                <TextArea
                                    label="Remarks"
                                    value={this.state.comments}
                                    onChange={(value) => this.setState({ comments: value })}
                                    maxLength={2000}
                                />
                                <br></br><br></br>
                                <Button content="Add" onClick={this.vendorScoreAdd} style={{ paddingLeft: 10 }}></Button>
                                <Button content="Update" onClick={this.vendorScoreUpdate} style={{ paddingLeft: 10 }}></Button>
                                <Button content="Clear" onClick={this.resetScorecardComponent} ></Button>
                                <Button content="Back" onClick={this.selectCloseVendor} ></Button>
                            </div>
                            <br></br>
                            <div style={{ "paddingBottom": 0 }}>
                                <DataTable
                                    scrollable={true}
                                    scrollWidth='1300px'
                                    reorderableColumns={true}
                                    resizableColumns={true}
                                    loading={false}
                                    data={this.state.searchScorecardData}
                                    selection={this.state.selectedScorecardItem}
                                    selectionMode="single"
                                    search={true}
                                    rows={10}
                                    onSelectionChange={(e) => { this.handleSelectScorecardCheckBox(e) }
                                    }>
                                    <DataTable.Column
                                        field="SBG_ID"
                                        header="SBG"
                                        sortable={false}
                                        initialWidth='250px'
                                    />

                                    <DataTable.Column
                                        field="SBE_ID"
                                        header="SBE"
                                        sortable={false}
                                        initialWidth='250px'
                                    />

                                    <DataTable.Column
                                        field="DUNS_NUM"
                                        header="Vendor ID"
                                        sortable={false}
                                        initialWidth='250px'
                                    />

                                    <DataTable.Column
                                        field="VENDOR_FULL_NM"
                                        header="Vendor Name"
                                        sortable={false}
                                        initialWidth='250px'
                                    />

                                    <DataTable.Column
                                        field="VENDOR_GLOBAL_ULTIMATE_DUNS"
                                        header="Vendor Group ID"
                                        sortable={false}
                                        initialWidth='250px'
                                    />

                                    <DataTable.Column
                                        field="VENDOR_GLOBAL_ULTIMATE_NAME"
                                        header="Vendor Group Name"
                                        sortable={false}
                                        initialWidth='250px'
                                    />

                                    <DataTable.Column
                                        field="ALTERNATE_GROUP_NAME"
                                        header="Vendor ALIAS"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="REPORT_NAME"
                                        header="Report Name"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Pagination />
                                </DataTable>
                            </div>
                        </Card.Content>
                    </Card>
                }
                {/* Vendor Search Page */}
                {
                    this.state.showPrimeCard &&
                    <Grid className="main-body">
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <label style={{ fontSize: 18 }}>Vendor Data Maintenance</label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <Search
                                    placeholder="Site ID"
                                    error={this.state.err_siteid}
                                    results={this.state.resultsSiteId}
                                    value={this.state.searchSiteId}
                                    onResultSelect={(value) => this.handleSearchIdSelect(value)}
                                    onSearchChange={(value) => this.handleSearchChangeId(value)}
                                    disabled={this.state.fieldDisabled}
                                    style={{ "paddingLeft": 10 }}
                                />
                                <Search
                                    placeholder="Site"
                                    error={this.state.err_siteid}
                                    results={this.state.resultsSiteName}
                                    value={this.state.searchSiteName}
                                    onResultSelect={(value) => this.handleSearchSiteSelect(value)}
                                    onSearchChange={(value) => this.handleSearchChangeSites(value)}
                                    disabled={this.state.fieldDisabled}
                                    style={{ "paddingLeft": 10 }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        placeholder="ERP Vendor Number"
                                        value={this.state.searchErpVendorNum}
                                        onChange={(value) => this.setState({ searchErpVendorNum: value })} />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: 10 }} onClick={() => { this.validateERPVendor(this.state.searchErpVendorNum); }} />&nbsp;&nbsp;
                                    <Input
                                        placeholder="ERP Vendor Name"
                                        //results={this.state.resultsErpVendorName}
                                        value={this.state.searchErpVendorName}
                                        style={{ paddingLeft: 10, width: 350 }}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <div>
                                    <Input
                                        placeholder="Vendor ID"
                                        value={this.state.searchDunsNum}
                                        onChange={(value) => this.setState({ searchDunsNum: value })}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10" }} onClick={() => { this.validateVendor(this.state.searchDunsNum); }} />&nbsp;&nbsp;

                                    <Input
                                        placeholder="Vendor Name"
                                        value={this.state.searchDunsName}
                                        style={{ paddingLeft: 10, width: 350 }}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        placeholder="Vendor Group ID"
                                        value={this.state.searchGlobalDunsNum}
                                        onChange={(value) => this.setState({ searchGlobalDunsNum: value })}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "50" }}
                                        onClick={() => { this.validateVendorGroup(this.state.searchGlobalDunsNum); }} />&nbsp;&nbsp;

                                    <Input
                                        placeholder="Vendor Group Name"
                                        //results={this.state.resultsGlobalDunsName}
                                        value={this.state.searchGlobalDunsName}
                                        onChange={(value) => this.handleSearchChangeGlobalDunsName(value)}
                                        //onResultSelect={(value) => this.handleSearchSelectGlobalDunsName(value)}
                                        style={{ paddingLeft: 10, width: 350 }}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Input
                                        placeholder="Alternative Vendor Group Name"
                                        value={this.state.searchAltVenGrpName}
                                        onChange={(value) => this.setState({ searchAltVenGrpName: value })}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10" }}
                                        onClick={() => { this.validateAltGrpName(this.state.searchAltVenGrpName); }} />&nbsp;&nbsp;
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Search
                                        placeholder="ERP Plant Number"
                                        key={this.state.searchErpPlantNum}
                                        results={this.state.resultsErpPlantNum}
                                        value={this.state.searchErpPlantNum}
                                        onSearchChange={(value) => this.handleSearchChangeErpPlant(value)}
                                        onResultSelect={(value) => this.handleSearchSelectErpPlant(value)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Search
                                        placeholder="Company Code"
                                        key={this.state.companyCode}
                                        results={this.state.resultsCompanyCode}
                                        value={this.state.searchCompanyCode}
                                        onSearchChange={(value) => this.handleSearchChangeCompanyCode(value)}
                                        onResultSelect={(value) => this.handleSearchSelectCompanyCode(value)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div>
                                    <Search
                                        placeholder="Purchase Organization"
                                        key={this.state.purchasingOrg}
                                        results={this.state.resultsPurchasingOrg}
                                        value={this.state.searchPurchasingOrg}
                                        onSearchChange={(value) => this.handleSearchChangePurchasingOrg(value)}
                                        onResultSelect={(value) => this.handleSearchSelectPurchasingOrg(value)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleSearchSelect}
                                    disabled={this.state.disableSearch} />
                                <Button content="Clear" onClick={this.resetComponent} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12}></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <Button content="Vendor"
                                    disabled={this.state.disableOtherButtons}
                                    onClick={this.handleVendorSelect}
                                ></Button>
                                <Button
                                    content="ERP Vendor"
                                    disabled={this.state.disableVendorButton}
                                    onClick={this.handleERPVendorSelect}
                                />

                                <Button
                                    content="ERP Vendor MPO"
                                    disabled={this.state.disableOtherButtons}
                                    onClick={this.handleERPVendorMPOSelect}
                                />
                                <Button
                                    content="Vendor Scorecard"
                                    //disabled={this.state.disableOtherButtons}
                                    onClick={this.handleVendorScoreCardSelect}
                                    disabled={false}
                                />
                                <Button
                                    content="Bulk Upload"
                                    //disabled={this.state.disableOtherButtons}
                                    onClick={this.handleBulkUploadSelect}
                                    disabled={false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12} sWidth={6}>
                                <div style={{ "paddingBottom": 40 }}>
                                    <DataTable
                                        scrollable={true}
                                        scrollWidth='1300px'
                                        reorderableColumns={true}
                                        resizableColumns={true}
                                        loading={false}
                                        data={this.state.searchData}
                                        selection={this.state.selectedItem}
                                        selectionMode="single"
                                        search={true}
                                        rows={10}
                                        onSelectionChange={(e) => { this.handleSelectCheckBox(e) }
                                        }>
                                        <DataTable.Column
                                            field="OS_SITE_ID"
                                            header="SITE ID"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_SK"
                                            header="ERP Vendor Number"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_NAME"
                                            header="ERP Vendor Name"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_DUNS_NUMBER"
                                            header="Vendor ID"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_FULL_NM"
                                            header="Vendor Name"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_GLOBAL_ULTIMATE_DUNS"
                                            header="Vendor Group ID"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_GLOBAL_ULTIMATE_NAME"
                                            header="Vendor Group Name"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="ALWAYS_OTD_FLAG"
                                            header="Always OTD"
                                            sortable={false}
                                            initialWidth='250px'
                                        />
                                        <DataTable.Column
                                            field="DELIVERY_DAYS_EARLY"
                                            header="Early Delivery(Days)"
                                            sortable={false}
                                            initialWidth='250px'
                                        />
                                        {/* <DataTable.Column
                                            field="PLANT_SK"
                                            header="ERP Plant Number"
                                            sortable={false}
                                            initialWidth='250px'
                                        /> */}
                                        <DataTable.Column
                                            field="PLANT_NAME"
                                            header="ERP PLANT NAME"
                                            sortable={false}
                                            initialWidth='250px'
                                        />
                                        {/* <DataTable.Column
                                            field="COMPANY_CODE"
                                            header="COMPANY CODE"
                                            sortable={false}
                                            initialWidth='250px'
                                        /> */}

                                        {/* <DataTable.Column
                                            field="PURCHASING_ORG"
                                            header="Purchase Org"
                                            sortable={false}
                                            initialWidth='250px'
                                        /> */}

                                        <DataTable.Column
                                            field="SITE_CORP_SUPPLIER_PREFERRED"
                                            header="Plant Primary Vendor Number"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        {/* <DataTable.Column
                                            field="DELIVERY_DAYS_LATE"
                                            header="Late Delivery (Days)"
                                            sortable={false}
                                            initialWidth='250px'
                                        /> */}


                                        <DataTable.Column
                                            field="VENDOR_MPO_FLAG"
                                            header="Vendor MPO Indicator"
                                            sortable={false}
                                            initialWidth='250px'
                                        />


                                        <DataTable.Column
                                            field="FREEZE_FLAG"
                                            header="ERP Vendor Freeze Flag"
                                            sortable={false}
                                            initialWidth='250px'
                                        />


                                        <DataTable.Column
                                            field="ROLLING_12_MONTH_BUSINESS_STATUS"
                                            header="Rolling 12 Month Business Status"
                                            sortable={false}
                                            initialWidth='250px'
                                        />


                                        <DataTable.Column
                                            field="INTER_COMPANY_FLAG"
                                            header="Inter Company Flag"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VENDOR_STATUS"
                                            header="Vendor Business Status"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="VEDOR_TYPE"
                                            header="Vendor Type"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="SUB_COMMODITY_OWNER_SK"
                                            header="Vendor Sub-Commodity Owner"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="LTA_NUM"
                                            header="Contract ID"
                                            sortable={false}
                                            initialWidth='250px'
                                        />

                                        <DataTable.Column
                                            field="FREEZE_FLAG"
                                            header="DnB Freeze Flag"
                                            sortable={false}
                                            initialWidth='250px'
                                        />
                                        <DataTable.Pagination />
                                    </DataTable>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
                {/* Vendor Bulk Upload Page */}
                {
                    this.state.showBulkUpload &&
                    <Card interactive={true} style={{ paddingBottom: 40 }}>
                        <Card.Content>
                            <div>
                                <p style={{ fontSize: "14" }}><b>Vendor Maintenance</b></p>
                                <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
                                <input
                                    type="file"
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.filePathset.bind(this)}
                                />
                                <button onClick={() => { this.submitFileVendorDuns(); }} disabled={this.state.submitDisabled}>Submit File</button>
                                <a href={duns_template} download="Vendor Duns.xlsx" style={{ "paddingLeft": 40 }}>
                                    <Button content="Download Template"></Button>
                                </a>
                                <Divider />
                                <p style={{ fontSize: "14" }}><b>ERP Vendor Maintenance</b></p>
                                <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
                                <input
                                    type="file"
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.erpFilePathset.bind(this)}
                                />
                                <button onClick={() => { this.submitFileERPVendor(); }} disabled={this.state.erpSubmitDisabled}>Submit File</button>
                                <a href={erp_template} download="ERP Vendor.xlsx" style={{ "paddingLeft": 40 }}>
                                    <Button content="Download Template"></Button>
                                </a>
                                <Divider />
                                <p style={{ fontSize: "14" }}><b>MPO Vendor Maintenance</b></p>
                                <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
                                <input
                                    type="file"
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.mpoFilePathset.bind(this)}
                                />
                                <button onClick={() => { this.submitFileMPOVendor(); }} disabled={this.state.mpoSubmitDisabled}>Submit File</button>
                                <a href={mpo_template} download="Vendor MPO.xlsx" style={{ "paddingLeft": 40 }}>
                                    <Button content="Download Template"></Button>
                                </a>
                                <Divider />
                                <p style={{ fontSize: "14" }}><b>Vendor Scorecard</b></p>
                                <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
                                <input
                                    type="file"
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.scoreCardFilePathset.bind(this)}
                                />
                                <button onClick={() => { this.submitFileVendorScorecard(); }} disabled={this.state.scorecardSubmitDisabled}>Submit File</button>
                                <a href={scorecard_template} download="Vendor Scorecard.xlsx" style={{ "paddingLeft": 40 }}>
                                    <Button content="Download Template"></Button>
                                </a>
                                <Divider />


                                <Button
                                    content="Back"
                                    onClick={this.selectCloseVendor}
                                />
                            </div>
                        </Card.Content>
                    </Card>
                }
            </div >
        )
    }
}