
import React from "react";
import * as XLSX from "xlsx";
import template from '../resources/BaselineBulkMaintenanceRequestTemplate.xlsx';
import {
  Grid,
  DatePicker,
  Button,
  TextArea,
  Select,
  Input,
  Search,
  Loader,
} from "@scuf/common";
import { DataTable } from "@scuf/datatable";
const config = require('../config');
export default class SearchComp extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      loading: false,
      searchSite: "",
      searchId: "",
      resultsSite: [],
      resultsId: [],
      sites: [],
      ids: [],
      materialNum: [],
      materialName: [],
      pmt_site: [],

      searchMnum: "",
      searchMnumName: "",
      inputMaterialNum: [],
      // inputMaterialNum: "",

      searchMatName: "",
      currencySelected: "USD",
      changeSelected: "",
      itemSelected: "",
      showItem: false,
      dateSelected: "",
      searchMatId: "",
      resultsMatName: [],
      resultsMatId: [],
      inputPrice: "",
      buom:"",
       currentData: [],
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      authenticated: true,
      priceDisabled: true,
      changeDisabled: true,
      itemDisabled: true,
      currencyDisabled: true,
      dateDisabled: true,
      textDisabled: true,
      submitDisabled: true,
      selectedItem: [],
      dt_submit: "",
      err_siteid: "",
      err_matid: "",
      err_price: "",
      err_currency: "",
      err_res_for_chnage: "",
      err_date: "",
      err_comment: "",
      rfc_data: [],
      itemcategory_data: [],
      valcomments: "",
      currencyOptions: [],
      flg_option: "",
      file: "",
      newVar: "",
      all_mat_data: []
    };
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    this.handleDeleteRecord = this.handleDeleteRecord.bind(this);
    this.resetComponent = this.resetComponent.bind(this);
    this.handleAddSelect = this.handleAddSelect.bind(this);
  }

  resetComponent = () => {
    this.setState({
      loading: false,
      searchSite: "",
      searchId: "",
      resultsSite: [],
      resultsId: [],
      searchMatName: "",
      currencySelected: "",
      changeSelected: "",
      itemSelected: "",
      showItem: "",
      dateSelected: "",
      searchMatId: "",
      newVar: "",
      resultsMatName: [],
      resultsMatId: [],
      inputPrice: "",
      buom:"",
      currentData: [],
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      priceDisabled: true,
      changeDisabled: true,
      itemDisabled: true,
      currencyDisabled: true,
      dateDisabled: true,
      textDisabled: true,
      submitDisabled: true,
      selectedItem: [],
      dt_submit: "",
      err_siteid: "",
      err_matid: "",
      err_price: "",
      err_currency: "",
      err_res_for_chnage: "",
      err_date: "",
      err_comment: "",
      valcomments: "",
      all_mat_data: [],
      flg_option: "",
      searchMnumName: ""
    });
  }

  getTimeStamp = () => {
    return Math.floor((new Date()).getTime() / 1000);
  }

  ExcelDateToJSDate(serial) {

    var hours = Math.floor((serial % 1) * 24);
    var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    let d = new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    //console.log([year, month, day]);
    return [year, month, day].join('');

  }

  transRFC = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE1;
      newObj.value = oldObj.VALUE1;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  transItemCategory = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObj.text1 = oldObj.VALUE1;
      newObj.value1 = oldObj.VALUE1;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  transPmtSite = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.OS_SITE_ID;
      newObj.value = oldObj.OS_SITE_ID;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  transCurrToValue = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.SOURCE_CURRENCY;
      newObj.value = oldObj.SOURCE_CURRENCY;
      newObjects.push(newObj);
    }
    return newObjects;
  }

  transSitesToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.title = oldObj.PLANT_NAME;
      newObj.id = oldObj.PLANT_SK;
      newObjects.push(newObj);
    }
    return newObjects;
  }

  transIdToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.title = oldObj.PLANT_SK;
      newObj.name = oldObj.PLANT_NAME;
      newObjects.push(newObj);
    }
    return newObjects;
  }

  transSkToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.title = oldObj.MATERIAL_NUM_SK;
      newObj.name = oldObj.MATERIAL_NAME;
      newObj.id = oldObj.PLANT_SK;
      newObjects.push(newObj);
    }
    return newObjects;
  }

  transNameToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.title = oldObj.MATERIAL_NAME;
      newObj.name = oldObj.MATERIAL_NUM_SK;
      newObj.id = oldObj.PLANT_SK;
      newObjects.push(newObj);
    }
    return newObjects;
  }

  dateFormat = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    const yearmon = year.concat("", month);
    const newdate = yearmon.concat("", day);
    return newdate;
  }

  datetolabel = (date) => {
    const dt_year = date.slice(0, 4);
    const dt_month = date.slice(4, 6);
    const dt_day = date.slice(6, 8);
    const dt_yrmon = dt_year.concat("/", dt_month);
    const dt_ndate = dt_yrmon.concat("/", dt_day);
    return dt_ndate;
  }

  validDate = (num) => {

    var utc_days = Math.floor(num - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    let cal_date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());

    //let cal_date = (new Date((num - (25567 + 1)) * 86400 * 1000)).toString();
    const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
    const dt_month = cal_date.slice(4, 7);
    const dt_year = cal_date.slice(11, 15);
    const dt_day = "01";
    const month = months[dt_month];
    const dt_yearmon = dt_year.concat(month);
    const dt_ndate = dt_yearmon.concat(dt_day);
    return dt_ndate;
  }
  //-----------------------------Validate Numbers--------------------------
  validateNumber = (num) => {
    var isValidNum = false;
    if (/^\d+(\.\d{1,6})?$/.test(num)) { isValidNum = true; }
    return isValidNum;
  }
  //-----------------------------Component Mount---------------------------
  componentDidMount() {
    this.setState({ loading: true });
    fetch(`${config.host_url}/api/site`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          sites: this.transSitesToTitle(data),
          ids: this.transIdToTitle(data),
        });

        fetch(`${config.host_url}/api/pmtsite`)
          .then((res) => res.json())
          .then((data) => {
            this.setState({
              pmt_site: this.transPmtSite(data)
            });
            fetch(`${config.host_url}/api/reasonforchange`)
              .then((res) => res.json())
              .then((data) => {
                this.setState({
                  rfc_data: this.transRFC(data)
                });
                fetch(`${config.host_url}/api/itemcategory`)
                  .then((res) => res.json())
                  .then((data) => {
                    this.setState({
                      itemcategory_data: this.transItemCategory(data)
                    });


                    fetch(`${config.host_url}/api/currency`)
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          //loading: false,
                          currencyOptions: this.transCurrToValue(data)
                        });
                        //call user details
                        fetch(`${config.host_url}/user`, {
                          credentials: 'include' // fetch won't send cookies unless you set credentials
                        })
                          .then(response => response.json())
                          .then(response => {
                            this.setState(
                              {
                                UIDData: response.sub.toUpperCase(),
                                loading: false
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                            this.setState({
                              loading: false,
                            });
                            alert(`Connection Issue with User Data. Please try again`);
                            //code to redirect to landing page
                               window.location.href = `${config.host_url}/login`;
                          });


                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({
                          loading: false,
                        });
                        alert(`Connection Issue with Currency API. Please try again`);
                      });

                  })
                  .catch((err) => {
                    console.log(err);
                    this.setState({
                      loading: false,
                    });
                    alert(`Connection Issue with ItemCategory API. Please try again`);
                  });
              })
              .catch((err) => {
                console.log(err);
                this.setState({
                  loading: false,
                });
                alert(`Connection Issue with Reason For Change API. Please try again`);
              });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              loading: false,
            });
            alert(`Connection Issue with PMTSITE  API. Please try again`);
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        alert(`Connection Issue with Site ID API. Please try again`);
      });
  }

  handleSearchChangeSites = (searchText) => {
    this.setState({
      resultsSite: this.state.sites.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
      searchText
    });
  }

  handleSearchSiteSelect = (result) => {
    this.setState({
      searchSite: result.title,
      searchId: result.id,
      loading: true
    });

    fetch(`${config.host_url}/api/materials/${result.id}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          materialNum: this.transSkToTitle(data),
          materialName: this.transNameToTitle(data),
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        alert(`Connection Issue with Relevant Materials API. Please try again`);
      });
  }

  handleSearchChangeId = (searchText) => {
    this.setState({
      resultsId: this.state.ids.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
      searchText
    });
  }
  handleSearchIdSelect = (result) => {
    this.setState({
      searchId: result.title,
      searchSite: result.name,
      loading: true
    });

    fetch(`${config.host_url}/api/materials/${result.title}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          materialNum: this.transSkToTitle(data),
          materialName: this.transNameToTitle(data),
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        alert(`Connection Issue with revelant Materials API. Please try again`);
      });
  }

  handleSearchChangeMatId = (searchText) => {
    if (searchText.length >= 3) {
      this.setState({
        resultsMatId: this.state.materialNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
        searchText
      });
    }
    else {
      this.setState({ newVar: searchText });
    }
  }

  handleSearchChangeMatName = (searchText) => {
    if (searchText.length >= 3) {
      this.setState({
        resultsMatName: this.state.materialName.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
        searchText
      });
    }
    else {
      this.setState({ newVar: searchText });
    }
  }


  //  --------------material name and num-----------------

  validateVendor=(searchText)=>{
    if (searchText === '') {
        alert(`Text cannot be blank`);
    }
    else{
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/materials/base/${searchText}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    inputMaterialNum: data,
                
                });
                console.log(data);
                
                 console.log(this.state.inputMaterialNum,"inputMaterialNum");

                 if (this.state.inputMaterialNum.length > 0) {
                  this.setState({
                      // searchSupLocId: searchText,
                      searchMatId: this.state.inputMaterialNum[0].MATERIAL_NUM_SK,
                      searchMnumName: this.state.inputMaterialNum[0].MATERIAL_NAME,
                      loading: false
                  });
              }
              else {
                  alert(`Material  num is invalid `);
                  this.setState({ loading: false, disableSearch: true });

              }
               
               
            }  
            ) .catch((err) => {
                this.setState({ loading: false });
                alert(`Connection Issue. Cant get mat number. Please try again`);
            });
            // console.log(inputMaterialNum);
            
    }
    
}




  // handleSearchMatIdSelect = (result) => {
  //   this.setState({
  //     searchMatId: result.title,
  //     searchMatName: result.name,
  //   });
  // }

  // handleSearchMatNameSelect = (result) => {
  //   this.setState({
  //     searchMatName: result.title,
  //     searchMatId: result.name,
  //   });
  // }

  handleCheckBoxSelect = (eve) => {
    if (eve.length > 0) {
      this.setState({
        selectedItem: eve,
        updateDisabled: false,
        deleteDisabled: false,
        addDisabled: true,
        inputPrice: eve[0].BASELINE_PRICE_LCL,
        currencySelected: eve[0].LCL_CURRENCY,
        itemSelected: eve[0].ITEM_CATEGORY,
        changeSelected: eve[0].CHANGE_REASON,
        dateSelected: this.datetolabel(eve[0].EFFECTIVE_START_DATE.toString()),
        valcomments: eve[0].COMMENTS,
        buom: eve[0].BUOM
      });
      console.log(eve[0].ITEM_CATEGORY, "eve change");
      console.log(this.state.itemSelected, "change");
      //
    }
    else {
      this.setState({
        selectedItem: eve,
        updateDisabled: true,
        deleteDisabled: true,
        addDisabled: false,
        inputPrice: "",
        currencySelected: "",
        changeSelected: "",
        itemSelected: "",
        dateSelected: "",
        valcomments: "",
        buom:''
      });
    }
  }

  handleSearchSelect = () => {
    this.setState({
      err_siteid: "",
      err_matid: "",
      inputPrice: "",
      changeSelected: "",
      dateSelected: "",
      valcomments: "",
      itemSelected: "",
    });
    if (this.state.searchId === '' && this.state.searchMatId === '' && this.state.newVar === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_siteid: " ", err_matid: " " });
    } else if (this.state.searchId === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_siteid: " " });
    }
    else if (this.state.searchMatId === '' && this.state.newVar === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_matid: " " });
    }
    else {
      this.setState({
        updateDisabled: true,
        deleteDisabled: true,
        addDisabled: false,
        changeDisabled: false,
        itemDisabled: false,
        priceDisabled: false,
        currencyDisabled: false,
        dateDisabled: false,
        textDisabled: false,
        loading: true
      });
      //itemcategory show for pmt only 
      var pmtsearch_siteid = [];
      for (let i in this.state.pmt_site) {
        pmtsearch_siteid.push(this.state.pmt_site[i].text);
      }

      if (pmtsearch_siteid.includes(this.state.searchId)) {
        this.setState({ showItem: true })
      } else {
        this.setState({
          // itemSelected:"",
          showItem: false
        })
      }


      let matNum;
      if (this.state.searchMatId === '') {
        matNum = this.state.newVar.toString();
      }
      else {
        matNum = this.state.searchMatId.toString();
      }
      let search_res = matNum.replaceAll("/", "vxzqz");
      let search_url = search_res.replaceAll("+", "zqzxv");
      fetch(
        `${config.host_url}/api/materials/current/${this.state.searchId}/${search_url}`
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            currentData: data,
          });

          if (data.length === 0) {
            this.setState({
              updateDisabled: true,
              deleteDisabled: true,
              addDisabled: false
            })
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
          alert(`Could not perform a Search. Please try again`);
        });
    }
  }

  handleAddSelect = () => {
    this.setState({
      err_price: "",
      err_currency: "",
      err_res_for_chnage: "",
      err_date: "",
      flg_option: 'A'
    });
    if (this.state.inputPrice === '' && this.state.changeSelected === '' && this.state.dateSelected === '') {
      this.setState({
        err_price: " ",
        err_res_for_chnage: " ",
        err_date: " ",
      })
      alert("Please enter mandatory Fields");
    } else if (this.state.inputPrice === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_price: " " });
    } else if (this.state.changeSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_res_for_chnage: " " });
    } else if (this.state.dateSelected === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_date: " " });
    } else if (!this.validateNumber(this.state.inputPrice)) {
      alert("Please enter only digits for BaseLine Price");
      this.setState({ err_price: " " });
    }
    else {
      this.setState({
        loading: true
      });
      let matNum;
      if (this.state.searchMatId === '') {
        matNum = this.state.newVar.toString();
      }
      else {
        matNum = this.state.searchMatId.toString();
      }
      var additem_selected = "ALL";
      if (this.state.itemSelected === this.state.itemcategory_data[0].text) {
      additem_selected = '0';
      }
      if (this.state.itemSelected === this.state.itemcategory_data[1].text) {
        additem_selected = '1';
      }
      if (this.state.itemSelected === this.state.itemcategory_data[2].text) {
        additem_selected = '2';
      }if (this.state.itemSelected === this.state.itemcategory_data[3].text) {
        additem_selected = '3';
      }if (this.state.itemSelected === this.state.itemcategory_data[4].text) {
        additem_selected = '4';
      }if (this.state.itemSelected === this.state.itemcategory_data[5].text) {
        additem_selected = '5';
      }if (this.state.itemSelected === this.state.itemcategory_data[6].text) {
        additem_selected = '6';
      }if (this.state.itemSelected === this.state.itemcategory_data[7].text) {
        additem_selected = '7';
      }if (this.state.itemSelected === this.state.itemcategory_data[8].text) {
        additem_selected = '8';
      }
      if (this.state.itemSelected === this.state.itemcategory_data[9].text) {
        additem_selected = '9';
      }
      console.log(this.state.itemcategory_data,"itrhhh");

      if (this.state.currencySelected == '' || this.state.currencySelected == null) {
        alert("currency code can't be blank.");
        this.setState({
          loading: false
        });
      }
      else {
        const addvalue = {
          SOURCE_SYSTEM: 'OneSource',
          I_PLANT_SK: this.state.searchId,
          I_MATERIAL_NUM_SK: matNum,
          I_EFFECTIVE_START_DATE: this.dateFormat(this.state.dateSelected.toString()),
          I_BASELINE_PRICE_LCL: this.state.inputPrice,
          I_LCL_CURRENCY: this.state.currencySelected,
          I_CHANGE_REASON: this.state.changeSelected,
          I_ITEM_CATEGORY: additem_selected,
          I_COMMENTS: this.state.valcomments,
          I_MATL_BASE_UPDATED_BY: this.state.UIDData,
          I_FLAG: "I",
          I_MATL_BASE_UPDATE_TS: this.getTimeStamp().toString()
        };
        console.log("Add: ", addvalue);
        const new_var = JSON.stringify(addvalue);
        fetch(`${config.host_url}/api/materials/add`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: new_var,
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({
              loading: false,
              addDisabled: true,
              updateDisabled: false,
              deleteDisabled: false
            });
            this.handleSearchSelect();
            alert(`Data Added Successfully!`);
          })
          .catch((error) => {
            console.log("Error:", error);
            this.setState({
              loading: false
            });
            alert(`Could not insert data. Please try again`);
          });
      }
    }
  }

  handleUpdateSelect = () => {
    const selected = this.state.selectedItem[0];
    this.setState({
      err_price: ""
    });
    if (!this.validateNumber(this.state.inputPrice)) {
      alert("Please enter only digits for BaseLine Price");
      this.setState({ err_price: " " });
    }
    else {
      var updateitem_selected = "ALL";
      if (this.state.itemSelected === this.state.itemcategory_data[0].text) {
        updateitem_selected = '0';
        }
        if (this.state.itemSelected === this.state.itemcategory_data[1].text) {
          updateitem_selected = '1';
        }
        if (this.state.itemSelected === this.state.itemcategory_data[2].text) {
          updateitem_selected = '2';
        }if (this.state.itemSelected === this.state.itemcategory_data[3].text) {
          updateitem_selected = '3';
        }if (this.state.itemSelected === this.state.itemcategory_data[4].text) {
          updateitem_selected = '4';
        }if (this.state.itemSelected === this.state.itemcategory_data[5].text) {
          updateitem_selected = '5';
        }if (this.state.itemSelected === this.state.itemcategory_data[6].text) {
          updateitem_selected = '6';
        }if (this.state.itemSelected === this.state.itemcategory_data[7].text) {
          updateitem_selected = '7';
        }if (this.state.itemSelected === this.state.itemcategory_data[8].text) {
          updateitem_selected = '8';
        }
        if (this.state.itemSelected === this.state.itemcategory_data[9].text) {
          updateitem_selected = '9';
        }
        console.log(this.state.itemcategory_data,"itrhhh");
      if (this.state.currencySelected == '' || this.state.currencySelected == null) {
        alert("currency code can't be blank.")
      }
      else {
        const update = {
          SOURCE_SYSTEM: 'OneSource',
          PLANT_SK: selected.PLANT_SK,
          MATERIAL_NUM_SK: selected.MATERIAL_NUM_SK,
          U_EFFECTIVE_START_DATE: this.dateFormat(this.state.dateSelected.toString()),
          U_BASELINE_PRICE_LCL: this.state.inputPrice,
          U_LCL_CURRENCY: this.state.currencySelected,
          U_CHANGE_REASON: this.state.changeSelected,
          U_ITEM_CATEGORY: updateitem_selected,
          U_COMMENTS: this.state.valcomments,
          U_MATL_BASE_UPDATED_BY: this.state.UIDData,
          U_FLAG: "U",
          D_EFFECTIVE_START_DATE: selected.EFFECTIVE_START_DATE,
          D_BASELINE_PRICE_LCL: selected.BASELINE_PRICE_LCL,
          D_LCL_CURRENCY: selected.LCL_CURRENCY,
          D_CHANGE_REASON: selected.CHANGE_REASON,
          D_ITEM_CATEGORY: selected.ITEM_CATEGORY,
          D_COMMENTS: selected.COMMENTS,
          D_MATL_BASE_UPDATED_BY: selected.MATL_BASE_UPDATED_BY,
          D_FLAG: "D",
          MATL_BASE_UPDATE_TS: this.getTimeStamp().toString()
        };
        this.setState({
          loading: true,
          flg_option: 'U'
        });

        fetch(`${config.host_url}/api/materials/update`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(update),
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({
              loading: false
            });
            this.handleSearchSelect();
            alert(`Data Updated Successfully!`);
          })
          .catch((error) => {
            this.setState({
              loading: false,
              addDisabled: true
            });
            alert(`Could not update data. Please try again`);
            this.resetComponent();
          });
      }
    }
  }

  handleDeleteRecord = () => {
    const r = window.confirm(`Are you sure you want to DELETE this record? Click on OK to confirm or CANCEL to cancel delete request!`);
    if (r === true) {
      const selected = this.state.selectedItem[0];
      const DeleteRec = {
        SOURCE_SYSTEM: 'OneSource',
        D_PLANT_SK: selected.PLANT_SK,
        D_MATERIAL_NUM_SK: selected.MATERIAL_NUM_SK,
        D_EFFECTIVE_START_DATE: selected.EFFECTIVE_START_DATE,
        D_BASELINE_PRICE_LCL: selected.BASELINE_PRICE_LCL,
        D_LCL_CURRENCY: selected.LCL_CURRENCY,
        D_CHANGE_REASON: selected.CHANGE_REASON,
        D_COMMENTS: selected.COMMENTS,
        D_MATL_BASE_UPDATED_BY: selected.MATL_BASE_UPDATED_BY,
        D_FLAG: "D",
        D_MATL_BASE_UPDATE_TS: this.getTimeStamp().toString()
      };
      this.setState({
        loading: true,
        flg_option: 'D'
      });

      fetch(`${config.host_url}/api/materials/delete`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(DeleteRec),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false,
            addDisabled: true,
            inputPrice: "",
            currencySelected: "",
            changeSelected: "",
            dateSelected: "",
            valcomments: ""
          });
          this.handleSearchSelect();
          alert(`Data Deleted Successfully!`);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            addDisabled: true
          });
          console.error("Error:", error);
          alert(`Could not delete record. Please try again.`);
          this.resetComponent();
        });
    }
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    this.setState({ file }, () => {
      if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " " || this.state.file === undefined) {
        this.setState({ submitDisabled: true });
      }
      else {
        this.setState({ submitDisabled: false });
      }
    });
  }

  submitFile() {
    if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
      this.setState({ submitDisabled: true });
      alert(`Please select a file!`);
    }
    else {
      this.setState({
        loading: true
      });
      let f = this.state.file;
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
        /* Update state */
        let filteredData = data.filter(e => e.length);
        //remove header form base array

        filteredData.shift();
        for (let i = 0; i < filteredData.length; i++) {
          //remove Finance Reviewer
          filteredData[i].splice(7, 1);
          //Add timestamp        
          filteredData[i][9] = (this.getTimeStamp().toString());
          if (filteredData[i][0] !== undefined)
            filteredData[i][0] = filteredData[i][0].toString();
          if (filteredData[i][1] !== undefined)
            filteredData[i][1] = filteredData[i][1].toString();
          if (filteredData[i][2] !== undefined)
            filteredData[i][2] = this.ExcelDateToJSDate(filteredData[i][2]);
        }


        // Blank Value Validation 
        let flg_siteid = 0;
        let flg_partno = 0;
        let flg_EDate = 0;
        let flg_BaselinePrice = 0;
        let flg_curr = 0;
        let flg_rfc = 0;
        let flg_pmtitemsite = 0;
        let excel_err_msg = [];
        let flg_validation = 'F';

        let dup_arr = [];
        let arr_err_siteID = [];
        let arr_err_matID = [];
        let arr_siteID = [];
        let arr_Part = [];
        let arr_err_date = [];
        let arr_err_price = [];

        let result_siteid = [];
        for (var i in this.state.ids) {
          result_siteid.push(this.state.ids[i].title);
        }

        //put item category pmt site values
        var pmtitem_siteid = [];
        for (var k in this.state.pmt_site) {
          pmtitem_siteid.push(this.state.pmt_site[k].text);
        }


        //put Reason values in array
        let arr_err_rfc = [];
        let validate_rfc = [];
        for (i in this.state.rfc_data) {
          validate_rfc.push(this.state.rfc_data[i].text);
        }
        //put Currency values in array
        let arr_err_curr = [];
        let validate_curr = [];
        for (i in this.state.currencyOptions) {
          validate_curr.push(this.state.currencyOptions[i].text);
        }




        for (let i = 0; i < filteredData.length; i++) {

          let excel_siteid = filteredData[i][0];
          let excel_partno = filteredData[i][1];

          let excel_EDate = filteredData[i][2];
          let excel_BaselinePrice = filteredData[i][3];
          let excel_curr = filteredData[i][5];
          let excel_rfc = filteredData[i][4];
          let excel_itemcat = filteredData[i][8];

          // duplicate
          let arr_dup = [excel_siteid, excel_partno, excel_EDate];
          dup_arr.push(arr_dup);
          //Site ID validation
          if (excel_siteid === '' || excel_siteid === undefined) {
            flg_siteid = 1;
            flg_validation = 'T';
          }
          else {
            let val_plant = excel_siteid.toString();
            arr_siteID.push(val_plant);
            let cnt_site_match = 0;
            for (let j = 0; j < result_siteid.length; j++) {
              if (val_plant === result_siteid[j].toString()) {
                cnt_site_match = 1;
                break;
              }
            }
            if (cnt_site_match === 0) {
              arr_err_siteID.push(val_plant);
            }
          }

          if (arr_err_siteID.length > 0) {
            flg_siteid = 2;
            flg_validation = 'T';
          }

          //pmt item category validation
          if (excel_itemcat === '' || excel_itemcat === undefined) {
            flg_pmtitemsite = 1;
            flg_validation = 'T';
          }
          else {
            let val_itemcat = excel_itemcat.toString();


            let val_itemcatarr = [];
            let val_itemcatvalarr=[];
            let cnt_item_match = 0;
            // val_itemcatarr.push(this.state.itemcategory_data.text);

            for (var l in this.state.itemcategory_data) {
              val_itemcatarr.push(this.state.itemcategory_data[l].text);
            }
            for (var n in this.state.itemcategory_data) {
              val_itemcatvalarr.push(this.state.itemcategory_data[n].value1);
            }


            if (pmtitem_siteid.includes(excel_siteid)) {
              for(let p=0;p<10;p++){
                if (val_itemcat === val_itemcatarr[p].toString()) {
                  cnt_item_match = 1;
                  filteredData[i][8] = val_itemcatvalarr[p].toString() ;
              } 
                           
            }
            
            } else {
              filteredData[i][8] = "ALL";
            }

          }


          //Part Number Validation
          if (excel_partno === '' || excel_partno === undefined) {
            flg_partno = 1;
            flg_validation = 'T';
          }else{
            arr_Part.push(excel_partno.toString());
          }

          if (excel_EDate === '' || excel_EDate === undefined) {
            flg_EDate = 1;
            flg_validation = 'T';
          }
          else {

            let ne_date = this.ExcelDateToJSDate(excel_EDate);

            if (ne_date === 'NaNNaNNaN') {
              flg_EDate = 2;
              flg_validation = 'T';
              arr_err_date.push(i + 1);
            }
          }
          //Check numbers
          if (excel_BaselinePrice === '' || excel_BaselinePrice === undefined) {
            flg_BaselinePrice = 1;
            flg_validation = 'T';
          }
          if (typeof (excel_BaselinePrice) === 'string') {
            if (!(this.validateNumber(excel_BaselinePrice))) {
              flg_BaselinePrice = 2;
              flg_validation = 'T';
              arr_err_price.push(i + 1);
            }
          }
          //Reason Validation
          if (excel_rfc === '' || excel_rfc === undefined) {
            flg_rfc = 1;
            flg_validation = 'T';
          }
          else {
            let val_rfc = excel_rfc.toString();
            let cnt_reason_match = 0;
            for (let j = 0; j < validate_rfc.length; j++) {
              if (val_rfc === validate_rfc[j].toString()) {
                cnt_reason_match = 1;
                break;
              }
            }
            if (cnt_reason_match === 0) {
              arr_err_rfc.push(val_rfc);
            }
          }

          if (arr_err_rfc.length > 0) {
            flg_rfc = 2;
            flg_validation = 'T';
          }
          //Currency Validation
          if (excel_curr === '' || excel_curr === undefined) {
            //  filteredData[i][5] = "USD";

            flg_curr = 1;
            flg_validation = 'T';
          }
          else {
            let val_curr = excel_curr.toString();
            let cnt_curr_match = 0;
            for (let j = 0; j < validate_curr.length; j++) {
              if (val_curr === validate_curr[j].toString()) {
                cnt_curr_match = 1;
                break;
              }
            }
            if (cnt_curr_match === 0) {
              arr_err_curr.push(val_curr);
            }
          }

          if (arr_err_curr.length > 0) {
            flg_curr = 2;
            flg_validation = 'T';
          }
        }

        let flg_duplicate = 0;
        const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
        console.log(duplicate_count, "dupcount");
        if (duplicate_count > 0) {
          flg_duplicate = 1;
        }
        if (flg_duplicate === 1) {
          alert(`Duplicate Data found in template : 
            Could not Upload the data. Please try again.`);
          this.setState({ loading: false });
        }
        else {
          let arr_mat_data = [];
          arr_mat_data = (arr_Part);
          const js_mat_data = JSON.stringify(arr_mat_data);

          console.log(js_mat_data, "js");

          // Get Materials for selected Site ID's
          fetch(`${config.host_url}/api/allmaterials/base`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: js_mat_data,
          })
            .then((response) => response.json())
            .then((response) => {
              this.setState({
                all_mat_data: response
              });

              let result_matid = [];

              for (var i in this.state.all_mat_data) {
                result_matid.push(this.state.all_mat_data[i].MATERIAL_NUM_SK);
              }

              console.log(result_matid, "resuly");


              for (let i = 0; i < filteredData.length; i++) {
                let mat_val = filteredData[i][1].toString();
                let cnt_match = 0;

                console.log(mat_val, "matval");
                if (result_matid.includes(mat_val)) {
                  cnt_match = 1;

                }
                if (cnt_match === 0) {
                  arr_err_matID.push(mat_val);
                }
              }
              if (arr_err_matID.length > 0) {
                flg_validation = 'T';
                flg_partno = 2;
              }


              if (flg_validation === 'T') {
                if (flg_siteid === 1) {
                  excel_err_msg.push("Site ID can't be blank. \n");
                }
                if (flg_pmtitemsite === 1) {
                  excel_err_msg.push(" Item cat can't be blank. \n");
                }
                if (flg_siteid === 2) {
                  excel_err_msg.push("Site ID's not found in Base Table: " + arr_err_siteID);
                }
                if (flg_partno === 1) {
                  excel_err_msg.push("Part Number can't be blank. \n");
                }
                if (flg_partno === 2) {
                  excel_err_msg.push("Part Number/s not found in Base Table: " + arr_err_matID);
                }
                if (flg_rfc === 1) {
                  excel_err_msg.push("Reason for chnage cannot be blank. \n");
                }
                if (flg_rfc === 2) {
                  excel_err_msg.push("Reason for Change not found in table: " + arr_err_rfc);
                }
                if (flg_EDate === 1) {
                  excel_err_msg.push("Effective Date Can't be blank. \n");
                }
                if (flg_EDate === 2) {
                  excel_err_msg.push("Effective Date is invalid in row/s: " + arr_err_date);
                }
                if (flg_curr === 1) {
                  excel_err_msg.push("Currency Can't be blank. \n");
                }
                if (flg_curr === 2) {
                  excel_err_msg.push("Currency code not found: " + arr_err_curr);
                }
                if (flg_BaselinePrice === 1) {
                  excel_err_msg.push("Baseline Price Can't be blank. \n");
                }
                if (flg_BaselinePrice === 2) {
                  excel_err_msg.push("Enter BaseLine Price in format N.NN in row/s: ", arr_err_price);
                }
                alert("Validation Failed :\n " + excel_err_msg);
                this.setState({ loading: false });
              }
              else {


                const js_data = JSON.stringify(filteredData);
                console.log(js_data, "jss");
                fetch(`${config.host_url}/api/materials/massupload`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: js_data,
                })
                  .then((response) => response.json())
                  .then((response) => {
                    this.setState({
                      loading: false
                    });
                    let str_msg = JSON.stringify(response[0]);
                    alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));

                  })
                  .catch((error) => {

                    this.setState({ loading: false });
                    console.error("Mass Upload Error:", error);
                    alert(`Could not Upload the data. Please try again.`);
                  });

              }
            })

            .catch((error) => {
              this.setState({ loading: false });
              alert(`Material API data error`);
            });
        }
      };
      reader.readAsBinaryString(f);
    }

  }


  render() {
    return (
      <div>
        <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}>
        </Loader>
        <Grid className="main-body">
          <Grid.Row>
            <Grid.Column width={12} >
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <label style={{ fontSize: 24 }}>Baseline Prices (Part) Maintenance</label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <Search
                placeholder="Site ID"
                error={this.state.err_siteid}
                results={this.state.resultsId}
                value={this.state.searchId}
                key={this.state.ids}
                onResultSelect={(value) => this.handleSearchIdSelect(value)}
                onSearchChange={(value) => this.handleSearchChangeId(value)}
                style={{ "paddingLeft": 10 }}
              />
              <Search
                placeholder="Site"
                error={this.state.err_siteid}
                results={this.state.resultsSite}
                value={this.state.searchSite}
                onResultSelect={(value) => this.handleSearchSiteSelect(value)}
                onSearchChange={(value) => this.handleSearchChangeSites(value)
                }
                style={{ "paddingLeft": 10 }}
              />
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={12}>

              <Search
                error={this.state.err_matid}
                results={this.state.resultsMatId}
                key={this.state.searchMatId}
                value={this.state.searchMatId}
                minCharacters={3}
                placeholder="Material ID"
                onResultSelect={(value) =>
                  this.handleSearchMatIdSelect(value)
                }
                onSearchChange={(value) =>
                  this.handleSearchChangeMatId(value)
                }
                style={{ "paddingLeft": 10 }}
              />
              <Search
                error={this.state.err_matid}
                results={this.state.resultsMatName}
                value={this.state.searchMatName}
                minCharacters={3}
                placeholder="Material Name"
                onResultSelect={(value) => this.handleSearchMatNameSelect(value)}
                onSearchChange={(value) => this.handleSearchChangeMatName(value)}
                style={{ "paddingLeft": 10 }}
              />
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
                        <Grid.Column width={12}>
                                <div>
                                    <Input
                                        placeholder="Material number"
                                        value={this.state.searchMatId}
                                        onChange={(value) => this.setState({ searchMatId: value })}
                                        style={{  width: 225 }}
                                        // disabled={this.state.fieldDisabled}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button content="Validate" style={{ paddingTop: "10", paddingLeft: 10, }} 
                                    onClick={() => { this.validateVendor(this.state.searchMatId); }} />&nbsp;&nbsp;

                                    <Input
                                        placeholder="Material Name" 
                                        value={this.state.searchMnumName}
                                        style={{ paddingLeft: 10, width: 350 }}
                                        // disabled={this.state.fieldDisabled}
                                    />
                                </div>
                                </Grid.Column>
                    </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <div>
                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleSearchSelect} style={{ "float": "left" }} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} swidth={6}>
              <Input
                error={this.state.err_price}
                placeholder="Price"
                indicator="required"
                label=""
                value={this.state.inputPrice}
                onChange={(value) => this.setState({ inputPrice: value })}
                disabled={this.state.priceDisabled}
                style={{ "paddingLeft": 10 }}
              />
             
              <Select
                error={this.state.err_currency}
                placeholder="Select a currency"
                search={true}
                value={this.state.currencySelected}
                onChange={(value) =>
                  this.setState({ currencySelected: value })
                }
                options={this.state.currencyOptions}
                disabled={this.state.currencyDisabled}
                style={{ "paddingLeft": 10 }}
              />
               <Input
                error={this.state.err_price}
                placeholder="BUOM"
                
                
                value={this.state.buom}
                
                disabled= {true}
                style={{ "paddingLeft": 10 }}
              />
              <Grid.Column width={3}>
                <Select
                  fluid={true}
                  error={this.state.err_res_for_chnage}
                  placeholder="Item Category"
                  value={this.state.itemSelected}
                  onChange={(value) => this.setState({ itemSelected: value })}
                  options={this.state.itemcategory_data}
                  disabled={this.state.itemDisabled}
                  style={{ visibility: this.state.showItem ? 'visible' : 'hidden' }}
                />
              </Grid.Column>

            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Select
                fluid={true}
                error={this.state.err_res_for_chnage}
                placeholder="Reason for Change"
                value={this.state.changeSelected}
                onChange={(value) => this.setState({ changeSelected: value })}
                options={this.state.rfc_data}
                disabled={this.state.changeDisabled}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <DatePicker
                type="date"
                error={this.state.err_date}
                displayFormat="YYYY/MM/DD"
                value={this.state.dateSelected}
                onTextChange={(value) =>
                  this.setState({ dateSelected: value })
                }
                disabled={this.state.dateDisabled}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <TextArea
                error={this.state.err_comment}
                placeholder="Comments"
                onChange={(value) => {
                  this.setState({ valcomments: value });
                }}
                disabled={this.state.textDisabled}
                value={this.state.valcomments}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div>
                <Button content="Add"
                  onClick={this.handleAddSelect}
                  disabled={this.state.addDisabled}
                  style={{ "paddingLeft": 10 }}
                />
                <Button content="Clear" onClick={this.resetComponent} />
                <Button
                  content="Update"
                  onClick={this.handleUpdateSelect}
                  disabled={this.state.updateDisabled}
                />
                {/* <Button
                  content="Delete"
                  onClick={this.handleDeleteRecord}
                  disabled={this.state.deleteDisabled}
                /> */}

                <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
                <input
                  type="file"
                  id="file"
                  ref="fileUploader"
                  onChange={this.filePathset.bind(this)}
                />
                <button
                  onClick={() => {
                    this.submitFile();
                  }}
                  disabled={this.state.submitDisabled}>
                  Submit File
              </button>
                <a href={template} download="Baseline Price Template.xlsx" style={{ "paddingLeft": 5 }}>
                  <Button content="Download Template"></Button>
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div style={{ "paddingBottom": 5 }}>
                <DataTable
                  data={this.state.currentData}
                  selectionMode="single"
                  rows={10}
                  search={true}
                  selection={this.state.selectedItem}
                  onSelectionChange={
                    (eve) => {
                      this.handleCheckBoxSelect(eve)
                    }
                  }
                >

                  <DataTable.Column
                    field="EFFECTIVE_START_DATE"
                    header="Effective Date"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="PLANT_SK"
                    header="Plant ID"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="MATERIAL_NUM_SK"
                    header="Material ID"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="BASELINE_PRICE_LCL"
                    header="Baseline Price"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="LCL_CURRENCY"
                    header="Currency Code"
                    sortable={false}

                  />
                  <DataTable.Column
                    field="CHANGE_REASON"
                    header="Change Reason"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="COMMENTS"
                    header="Comments"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="MATL_BASE_UPDATED_BY"
                    header="Updated By"
                    sortable={false}
                  />
                  <DataTable.Column
                    field="ITEM_CATEGORY"
                    header="Item Category"
                    sortable={false}
                  />

                  <DataTable.Column
                    field="BUOM"
                    header="BUOM"
                    sortable={false}
                  />

                  <DataTable.Pagination />
                </DataTable>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}