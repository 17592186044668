import React from "react";
import { Grid, Checkbox, Button, Input, Loader } from "@scuf/common";
import { DataTable } from "@scuf/datatable";
const config = require('../config');
export default class UserData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userID: "",
            user_data: [],
            d_user: [],
            err_user: "",
            err_page: "",
            err_page_xref: "",
            err_page_paymentcal: "",
            err_page_supplierquality: "",
            err_page_vendor: "",
            err_page_nonreceipt: "",
            err_page_baseline: "",
            err_page_rejectdata: "",
            err_page_partpfc: "",
            err_page_usermaint: "",
            err_page_kpimaint: "",
            err_page_aot: "",
            err_page_siteidmaint: "",
            selectedItem: null,
            loading: false,
            isNonReciept: false,
            isBaseline: false,
            isVendorMaint: false,
            isPartPFC: false,
            isSupplierQuality: false,
            isPaymentCal: false,
            isRejectData: false,
            isXref: false,
            isUserMaint: false,
            isKpiMaint: false,
            isAOT: false,
            isSiteIdMaint: false,
            isAll: false,
            addDisabled: true,
            deleteDisabled: true
        };
    }

    resetComponent = () => {
        this.setState({
            userID: "",
            selectedItem: null,
            loading: false,
            isNonReciept: false,
            isBaseline: false,
            isVendorMaint: false,
            isPartPFC: false,
            isSupplierQuality: false,
            isPaymentCal: false,
            isRejectData: false,
            isXref: false,
            isUserMaint: false,
            isKpiMaint: false,
            isAOT:false,
            isSiteIdMaint:false,
            isAll: false,
            addDisabled: true,
            deleteDisabled: true
        });
    }

    loaduserData = () => {
        this.setState({ loading: true });

        fetch(`${config.host_url}/api/getuserroles`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    loading: false,
                    user_data: data
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
                alert(`Connection Issue with user Roles API. Please try again`);
            });

    }

    componentDidMount() {
        this.setState({ loading: true });
        //call user details
        fetch(`${config.host_url}/user`, {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(response => {
                this.setState(
                    {
                        UIDData: response.sub.toUpperCase(),
                        loading: false
                    });
                this.loaduserData();
            })
            .catch((err) => {
                console.log(err);
                alert(`Connection Issue with User Data. Please try again`);
                this.setState({ loading: false });
                //code to redirect to landing page
            });
    }

    onChangeNonReciept = () => {
        this.setState(initialState => ({
            isNonReciept: !initialState.isNonReciept,
        }));
    }
    onChangeBaseline = () => {
        this.setState(initialState => ({
            isBaseline: !initialState.isBaseline,
        }));
    }

    onChangeVendor = () => {
        this.setState(initialState => ({
            isVendorMaint: !initialState.isVendorMaint,
        }));
    }

    onChangeRejectData = () => {
        this.setState(initialState => ({
            isRejectData: !initialState.isRejectData,
        }));
    }
    onChangeXref = () => {
        this.setState(initialState => ({
            isXref: !initialState.isXref,
        }));
    }
    onChangePartPFC = () => {
        this.setState(initialState => ({
            isPartPFC: !initialState.isPartPFC,
        }));
    }
    onChangePaymentCal = () => {
        this.setState(initialState => ({
            isPaymentCal: !initialState.isPaymentCal,
        }));
    }
    onChangeSupplierQuality = () => {
        this.setState(initialState => ({
            isSupplierQuality: !initialState.isSupplierQuality,
        }));
    }
    onChangeUser = () => {
        this.setState(initialState => ({
            isUserMaint: !initialState.isUserMaint,
        }));
    }
    onChangeKpi = () => {
        this.setState(initialState => ({
            isKpiMaint: !initialState.isKpiMaint,
        }));
    }
    onChangeAot = () => {
        this.setState(initialState => ({
            isAOT: !initialState.isAOT,
        }));
    }
    onChangeSiteIdMaint = () => {
        this.setState(initialState => ({
            isSiteIdMaint: !initialState.isSiteIdMaint,
        }));
    }
    onChangeAll = () => {
        this.setState(initialState => ({
            isAll: !initialState.isAll,
            isUserMaint: !initialState.isUserMaint,
            isSupplierQuality: !initialState.isSupplierQuality,
            isPaymentCal: !initialState.isPaymentCal,
            isPartPFC: !initialState.isPartPFC,
            isXref: !initialState.isXref,
            isRejectData: !initialState.isRejectData,
            isVendorMaint: !initialState.isVendorMaint,
            isBaseline: !initialState.isBaseline,
            isNonReciept: !initialState.isNonReciept,
            isAOT: !initialState.isAOT,
            isSiteIdMaint: !initialState.isSiteIdMaint,
        }));
    }

    handleAddSelect = () => {
        this.setState({
            err_user: "",
            err_page: "",
            err_page_xref: "",
            err_page_paymentcal: "",
            err_page_supplierquality: "",
            err_page_vendor: "",
            err_page_nonreceipt: "",
            err_page_baseline: "",
            err_page_rejectdata: "",
            err_page_partpfc: "",
            err_page_usermaint: "",
            err_page_kpimaint: "",
            err_page_aot: "",
            err_page_siteidmaint: "",
                });
        if (this.state.userID === ''
            && this.state.isAll === false
            && this.state.isNonReciept === false
            && this.state.isBaseline === false
            && this.state.isVendorMaint === false
            && this.state.isPartPFC === false
            && this.state.isPaymentCal === false
            && this.state.isSupplierQuality === false
            && this.state.isXref === false
            && this.state.isUserMaint === false
            && this.state.isRejectData === false
            && this.state.isKpiMaint === false
            && this.state.isAOT === false
            && this.state.isSiteIdMaint === false) {
            this.setState({
                err_user: " ",
                err_page: " ",
                err_page_xref: " ",
                err_page_paymentcal: " ",
                err_page_supplierquality: " ",
                err_page_vendor: " ",
                err_page_nonreceipt: " ",
                err_page_baseline: " ",
                err_page_rejectdata: " ",
                err_page_partpfc: " ",
                err_page_usermaint: " ",
                err_page_kpimaint: " ",
                err_page_aot: " ",
                err_page_siteidmaint: " ",
            });
            alert("Please enter mandatory Fields");
        } else if (this.state.isAll === false
            && this.state.isNonReciept === false
            && this.state.isBaseline === false
            && this.state.isVendorMaint === false
            && this.state.isPartPFC === false
            && this.state.isPaymentCal === false
            && this.state.isSupplierQuality === false
            && this.state.isXref === false
            && this.state.isUserMaint === false
            && this.state.isRejectData === false
            && this.state.isKpiMaint === false
            && this.state.isAOT === false
            && this.state.isSiteIdMaint === false) {
            alert("Please select at least 1 checkbox");
            this.setState({
                loading: false,
                err_page: " ", err_page_baseline: " ", err_page_nonreceipt: " ",
                err_page_partpfc: " ", err_page_paymentcal: " ", err_page_rejectdata: " ", err_page_xref: " ",
                err_page_vendor: " ", err_page_supplierquality: " ", err_page_usermaint: " ", err_page_kpimaint: " ",
                err_page_aot: " ",err_page_siteidmaint: " ",
            });
        }
        else if (this.state.userID === '') {
            alert("Please enter UserID to add");
            this.setState({ err_user: " " });
        }

        else {
            let user_pages = [];
            //Add code for individual pages
            if (this.state.isAll === true) {
                user_pages.push([this.state.userID, 'Non Receipt']);
                user_pages.push([this.state.userID, 'Baseline']);
                user_pages.push([this.state.userID, 'vendorMaintenance']);
                user_pages.push([this.state.userID, 'usermaint']);
                user_pages.push([this.state.userID, 'supplierqulaity']);
                user_pages.push([this.state.userID, 'paymentcal']);
                user_pages.push([this.state.userID, 'rejectdata']);
                user_pages.push([this.state.userID, 'xRef']);
                user_pages.push([this.state.userID, 'KPI']);
                user_pages.push([this.state.userID, 'AOT']);
                user_pages.push([this.state.userID, 'SiteIdMaint']);
            }
            if (this.state.isNonReciept === true) {
                user_pages.push([this.state.userID, 'Non Receipt']);
            }
            if (this.state.isBaseline === true) {
                user_pages.push([this.state.userID, 'Baseline']);
            }
            if (this.state.isVendorMaint === true) {
                user_pages.push([this.state.userID, 'vendorMaintenance']);
            }
            if (this.state.isUserMaint === true) {
                user_pages.push([this.state.userID, 'usermaint']);
            }
            if (this.state.isSupplierQuality === true) {
                user_pages.push([this.state.userID, 'supplierqulaity']);
            }
            if (this.state.isPaymentCal === true) {
                user_pages.push([this.state.userID, 'paymentcal']);
            }
            if (this.state.isPartPFC === true) {
                user_pages.push([this.state.userID, 'partPFC']);
            }
            if (this.state.isRejectData === true) {
                user_pages.push([this.state.userID, 'rejectdata']);
            }
            if (this.state.isXref === true) {
                user_pages.push([this.state.userID, 'xRef']);
            }
            if (this.state.isKpiMaint === true) {
                user_pages.push([this.state.userID, 'KPI']);
            }
            if (this.state.isAOT === true) {
                user_pages.push([this.state.userID, 'AOT']);
            }
            if (this.state.isSiteIdMaint === true) {
                user_pages.push([this.state.userID, 'SiteIdMaint']);
            }
            this.setState({
                loading: true
            });

            fetch(`${config.host_url}/api/adduserroles`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user_pages),
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false,
                        addDisabled: false,
                        deleteDisabled: true
                    });
                    alert(`Data Added Successfully!`);
                    this.resetComponent();
                    this.loaduserData();
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        deleteDisabled: true
                    });
                    console.error("Error:", error);
                    alert(`Connection Issue. Please try again`);
                });
        }
    }

    handleDelete = () => {
        let d_user = [];
        if (this.state.selectedItem.length > 0) {
            for (let i = 0; i < this.state.selectedItem.length; i++) {
                d_user.push([this.state.selectedItem[i].USERID, this.state.selectedItem[i].PAGE]);
            }
            const js_del_data = JSON.stringify(d_user);
            fetch(`${config.host_url}/api/deleteuserroles`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: js_del_data,
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                    alert(`Data Deleted Successfully!`);
                    this.resetComponent();
                    this.loaduserData();
                    d_user = [];
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        //addDisabled: false
                    });
                    alert(`Connection Issue. Please try again`);
                    this.resetComponent();
                });
        }
        else {
            alert(`Please select a record.`);
        }
    }

    handleCheckBoxSelect = (eve) => {
        if (eve.length > 0) {
            this.setState({
                selectedItem: eve,
                addDisabled: true,
                deleteDisabled: false
            });
        }
        else {
            this.setState({
                selectedItem: eve,
                addDisabled: false,
                deleteDisabled: true
            });
        }
    }

    render() {
        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}></Loader>
                <Grid className="main-body">
                    <Grid.Row>
                        <Grid.Column width={12}>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <label style={{ fontSize: 18 }}>User Maintenance</label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Input
                                label="HID/EID"
                                error={this.state.err_user}
                                value={this.state.userID}
                                onChange={(value) => this.setState({ userID: value, addDisabled: false })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Checkbox
                                label="Select All"
                                error={this.state.err_page}
                                checked={this.state.isAll}
                                onChange={this.onChangeAll}
                            />

                            <Checkbox
                                label="Non receipt"
                                error={this.state.err_page_nonreceipt}
                                checked={this.state.isNonReciept}
                                onChange={this.onChangeNonReciept}
                            />

                            <Checkbox
                                label="Baseline"
                                error={this.state.err_page_baseline}
                                checked={this.state.isBaseline}
                                onChange={this.onChangeBaseline}
                            />

                            <Checkbox
                                label="Vendor"
                                error={this.state.err_page_vendor}
                                checked={this.state.isVendorMaint}
                                onChange={this.onChangeVendor}
                            />

                            <Checkbox
                                label="Part PFC"
                                error={this.state.err_page_partpfc}
                                checked={this.state.isPartPFC}
                                onChange={this.onChangePartPFC}
                            />

                            <Checkbox
                                label="Supplier Quality Profile"
                                error={this.state.err_page_supplierquality}
                                checked={this.state.isSupplierQuality}
                                onChange={this.onChangeSupplierQuality}
                            />

                            <Checkbox
                                label="Payment Calendar"
                                error={this.state.err_page_paymentcal}
                                checked={this.state.isPaymentCal}
                                onChange={this.onChangePaymentCal}
                            />
                            <Checkbox
                                label="Reject Data"
                                error={this.state.err_page_rejectdata}
                                checked={this.state.isRejectData}
                                onChange={this.onChangeRejectData}
                            />

                            <Checkbox
                                label="User Data"
                                error={this.state.err_page_usermaint}
                                checked={this.state.isUserMaint}
                                onChange={this.onChangeUser}
                            />

                            <Checkbox
                                label="KPI Data"
                                error={this.state.err_page_kpimaint}
                                checked={this.state.isKpiMaint}
                                onChange={this.onChangeKpi}
                            />

                            <Checkbox
                                label="AOT Data"
                                error={this.state.err_page_aot}
                                checked={this.state.isAOT}
                                onChange={this.onChangeAot}
                            />
                            <Checkbox
                                label="SITE Id Maint"
                                error={this.state.err_page_siteidmaint}
                                checked={this.state.isSiteIdMaint}
                                onChange={this.onChangeSiteIdMaint}
                            />

                            <Checkbox
                                label="X Ref Data"
                                error={this.state.err_page_xref}
                                checked={this.state.isXref}
                                onChange={this.onChangeXref}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button
                                content="Add"
                                onClick={this.handleAddSelect}
                                disabled={this.state.addDisabled}></Button>
                            <Button
                                content="Delete"
                                onClick={this.handleDelete}
                                disabled={this.state.deleteDisabled}></Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <div style={{ "paddingBottom": 30 }}>
                                <DataTable
                                    scrollable={true}
                                    reorderableColumns={true}
                                    resizableColumns={true}
                                    search={true}
                                    rows={50}
                                    data={this.state.user_data}
                                    selectionMode="single"
                                    selection={this.state.selectedItem}
                                    onSelectionChange={
                                        (eve) => {
                                            this.handleCheckBoxSelect(eve)
                                        }
                                    }
                                >
                                    <DataTable.Column
                                        field="USERID"
                                        header="HID/EID"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="PAGE"
                                        header="Access To"
                                        sortable={true}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Pagination />
                                </DataTable>
                            </div>
                        </Grid.Column>
                    </Grid.Row>


                </Grid>
            </div>
        );
    }


}