import React from "react";
import "@scuf/common/honeywell-compact/theme.css";
import NonReceipt from "./NonReceipt";
import { Route, Switch } from 'react-router-dom';
import BaselineDataMaint from "./BaselineDataMaint";
import xrefData from "./XRefDataMaint";
import vendorMaintenance from "./VendorMaintenance";
import paymentCalendar from "./PaymentCalendar"
import LandingPage from "./LandingPage";
import RejectData from "./RejectData";
import UserMaint from "./UserDataMaint";
import PFCMasterData from "./PFCMasterData";
import KPIMaint from "./KPIData";
import AlwaysOnTime from "./AlwaysOnTime";
import SiteIdMaintenance from "./SiteIdMaintenance";



export default class DataMain extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/" component={LandingPage} exact>
          </Route>
         
          <Route path="/nonreceipt" component={NonReceipt}>
          </Route>
          <Route path="/baseline" component={BaselineDataMaint}>
          </Route>
          <Route path="/xrefdatamaint" component={xrefData}>
          </Route>
          <Route path="/vendormaintenance" component={vendorMaintenance}>
          </Route>
          <Route path="/paymentcalendar" component={paymentCalendar}>
          </Route>
          <Route path="/rejectdata" component={RejectData}>
          </Route>
          <Route path="/usermaint" component={UserMaint}>
          </Route>
          <Route path="/partpfc" component={PFCMasterData}>
          </Route>
          <Route path="/supplierquality" component={UserMaint}>
          </Route>
          <Route path="/kpimaint" component={KPIMaint}>
          </Route>
          <Route path="/alwaysontime" component={AlwaysOnTime}>
          </Route>
          <Route path="/siteid" component={SiteIdMaintenance}>
          </Route>
          
        </Switch>
      </div>
    );
  }
}
