import React from "react";
import { Grid, Button, Input, Search, Loader, Select, Radio, TextArea } from "@scuf/common";
import { DataTable } from "@scuf/datatable";
import * as XLSX from "xlsx";
import {Redirect} from 'react-router-dom';
import template from '../resources/pfc_Template.xlsx';
const config = require('../config');
export default class PFCMasterData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tologin:false,
      loading: true,
      searchSiteId: "",
      searchSiteName: "",
      resultsSiteName: [],
      resultsSiteId: [],
      resultsId: [],
      selectedItem: [],
      sitesNames: [],
      siteIds: [],
      currentData: [],
      err_siteid: "",
      //Material Number
      resultsMatId: [],
      resultsMatName: [],
      materialNum: [],
      materialName: [],
      searchMatName: "",
      searchMatId: "",
      mat_newVar: "",
      err_matid: "",
      onCheckDisabled: false,
      enableOnSearch: true,
      updateDisabled: true,
      buyer: "",
      partFamilyCode: "",
      rdbvalueAutoPO: "",
      rdbvalueConsignment: "",
      rdbvaluePullCandidate: "",
      comments: "",
      pfcModified_TS: "",
      err_cid: "",
      err_pullCandidate: "",
      err_autoPO: "",
      err_consignment: "",
      err_partFC: "",
      cidLastName: "",
      partLoB: "",
      enterprise: "",
      siteAttribute1: "",
      siteAttribute2: "",
      siteAttribute3: "",
      siteAttribute4: "",
      exportReview: "",
      cidSelected: "",
      partCOE: "",
      strategySelected: "",
      replenishType: "",
      vmiFlag: "",
      vmiSignStatus: "",
      source: "",
      sourceControl: "",
      partSegmentationCode: "",
      unspsCode: "",
      cidOptions: [],
      erOptions: [],
      partOptions: [],
      strategyOptions: [],
      replenishOptions: [],
      sourceControlOptions: [],
      partSegmentOptions: [],
      vmiStatusOptions: [],
      vmiFlagOptions: [],
      sourceOptions: [],     
     multisourceOptions : [ { value: 'YES', text: 'YES' }, {value: 'NO', text:'NO' }],
      multisource: "",
    
      submitDisabled: true,
      selectedfile: "",
    }
    this.resetComponent = this.resetComponent.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    this.handleAddSelect = this.handleAddSelect.bind(this);
  }
  resetComponent() {
    this.setState({
      searchSiteId: "",
      searchSiteName: "",
      searchMatName: "",
      searchMatId: "",
      mat_newVar: "",
      err_siteid: "",
      err_matid: "",
      currentData: [],
      onCheckDisabled: false,
      enableOnSearch: true,
      updateDisabled: true,
      buyer: "",
      partFamilyCode: "",
      err_partFC: "",
      rdbvalueAutoPO: "",
      rdbvalueConsignment: "",
      rdbvaluePullCandidate: "",
      comments: "",
      pfcModified_TS: "",
      err_cid: "",
      err_pullCandidate: "",
      err_autoPO: "",
      err_consignment: "",
      cidLastName: "",
      partLoB: "",
      enterprise: "",
      siteAttribute1: "",
      siteAttribute2: "",
      siteAttribute3: "",
      siteAttribute4: "",
      exportReview: "",
      cidSelected: "",
      partCOE: "",
      strategySelected: "",
      replenishType: "",
      vmiFlag: "",
      vmiSignStatus: "",
      source: "",
      sourceControl: "",
      partSegmentationCode: "",
      unspsCode: "",
      selectedItem: [],
      submitDisabled: true,
      selectedfile: "",
      multisource: "",

    });
  }
  getTimeStamp = () => {
    return Math.floor((new Date()).getTime() / 1000);
  }
  //Date Format
  dateFormat = (date) => {
    const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
    const dt_month = date.slice(4, 7);
    const dt_year = date.slice(11, 15);
    const dt_day = date.slice(8, 10);
    const month = months[dt_month];
    const dt_yearmon = dt_year.concat(month);
    const dt_ndate = dt_yearmon.concat(dt_day);
    return dt_ndate;
  }

  //CID options
  transCID = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Export Review options
  transER = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Replenish Type options
  transReplenish = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Strategyoptions
  transStrategy = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //PartCOE options
  transPartCOE = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Source Control options
  transSourceControl = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Part Segment options
  transPartSegment = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Source options
  transSource = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE2;
      newObj.value = oldObj.VALUE2;
      newObjects.push(newObj);
    }
    return newObjects;
  }

 

  
   
  //VMI Status
  setVMIStatus = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE1;
      newObj.value = oldObj.VALUE1;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //VMI Flag
  setVMIFlag = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.text = oldObj.VALUE1;
      newObj.value = oldObj.VALUE1;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //site name
  siteIdToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.sitetitle = oldObj.PLANT_NAME;
      newObj.siteid = oldObj.PLANT_SK;
      newObj.title = oldObj.PLANT_SK;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //site ID
  siteNameToTitle = (objects) => {
    const newObjects = [];

    for (let oldObj of objects) {
      const newObj = {};
      newObj.siteid = oldObj.PLANT_SK;
      newObj.sitetitle = oldObj.PLANT_NAME;
      newObj.title = oldObj.PLANT_NAME;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Material Num
  transSkToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.title = oldObj.MATERIAL_NUM_SK;
      newObj.name = oldObj.MATERIAL_NAME;
      newObj.buyername = oldObj.BUYER;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  //Material Name
  transNameToTitle = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
      const newObj = {};
      newObj.title = oldObj.MATERIAL_NAME;
      newObj.name = oldObj.MATERIAL_NUM_SK;
      newObj.buyername = oldObj.BUYER;
      newObjects.push(newObj);
    }
    return newObjects;
  }
  componentDidMount() {
    fetch(`${config.host_url}/api/site`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          sitesNames: this.siteNameToTitle(data),
          siteIds: this.siteIdToTitle(data),
        });
        //CID
        fetch(`${config.host_url}/api/pfcm/cid`)
          .then((res) => res.json())
          .then((data) => {
            this.setState({
              cidOptions: this.transCID(data)
            });
            //Export Review
            fetch(`${config.host_url}/api/pfcm/er`)
              .then((res) => res.json())
              .then((data) => {
                this.setState({
                  erOptions: this.transER(data)
                });
                //Replenish Type
                fetch(`${config.host_url}/api/pfcm/replenish`)
                  .then((res) => res.json())
                  .then((data) => {
                    this.setState({
                      replenishOptions: this.transReplenish(data)
                    });
                    //Strategy
                    fetch(`${config.host_url}/api/pfcm/strategy`)
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          strategyOptions: this.transStrategy(data)
                        });
                        //Part COE
                        fetch(`${config.host_url}/api/pfcm/partcoe`)
                          .then((res) => res.json())
                          .then((data) => {
                            this.setState({
                              partOptions: this.transPartCOE(data)
                            });
                            //Source Control
                            fetch(`${config.host_url}/api/pfcm/sourcecontrol`)
                              .then((res) => res.json())
                              .then((data) => {
                                this.setState({
                                  sourceControlOptions: this.transSourceControl(data)
                                });
                                //PartSegment
                                fetch(`${config.host_url}/api/pfcm/partsegment`)
                                  .then((res) => res.json())
                                  .then((data) => {
                                    this.setState({
                                      partSegmentOptions: this.transPartSegment(data)
                                    });
                                    //Source Options
                                    fetch(`${config.host_url}/api/pfcm/sourceoptions`)
                                      .then((res) => res.json())
                                      .then((data) => {
                                        this.setState({
                                          sourceOptions: this.transSource(data)
                                        });
                                        //VMI Status
                                        fetch(`${config.host_url}/api/pfcm/vmistatus`)
                                          .then((res) => res.json())
                                          .then((data) => {
                                            this.setState({
                                              vmiStatusOptions: this.setVMIStatus(data)
                                            });
                                            //VMI Status
                                            fetch(`${config.host_url}/api/pfcm/vmiflag`)
                                              .then((res) => res.json())
                                              .then((data) => {
                                                this.setState({
                                                  vmiFlagOptions: this.setVMIFlag(data)
                                                });
                                                //call user details
                                                fetch(`${config.host_url}/user`, {
                                                  credentials: 'include' // fetch won't send cookies unless you set credentials
                                                })
                                                  .then(response => response.json())
                                                  .then(response => {
                                                    this.setState(
                                                      {
                                                        UIDData: response.sub.toUpperCase(),
                                                        loading: false
                                                      });
                                                  })
                                                  .catch((err) => {
                                                    alert(`Connection Issue with User Data. Please try again`);
                                                    this.setState({
                                                      loading: false,
                                                      // tologin:true,
                                                    });
                                                      window.location.href = `${config.host_url}/login`;
                                                  })
                                              })
                                              .catch((err) => {
                                                alert(`Connection Issue. Can't load VMI Flag options. Please try again`);
                                                this.setState({
                                                  loading: false,
                                                });
                                              });
                                          })
                                          .catch((err) => {
                                            alert(`Connection Issue. Can't load VMI Status options. Please try again`);
                                            this.setState({
                                              loading: false,
                                            });
                                          });
                                      })
                                      .catch((err) => {
                                        alert(`Connection Issue. Can't load Source options. Please try again`);
                                        this.setState({
                                          loading: false,
                                        });
                                      });
                                  })
                                  .catch((err) => {
                                    alert(`Connection Issue. Can't load Part Segment options. Please try again`);
                                    this.setState({
                                      loading: false,
                                    });
                                  });

                              })
                              .catch((err) => {
                                alert(`Connection Issue. Can't load Source Control options. Please try again`);
                                this.setState({
                                  loading: false,
                                });
                              });

                          })
                          .catch((err) => {
                            alert(`Connection Issue. Can't load Part COE options. Please try again`);
                            this.setState({
                              loading: false,
                            });
                          });

                      })
                      .catch((err) => {
                        alert(`Connection Issue. Can't load ARS Strategy options. Please try again`);
                        this.setState({
                          loading: false,
                        });
                      });

                  })
                  .catch((err) => {
                    alert(`Connection Issue. Can't load Replenish Type options. Please try again`);
                    this.setState({
                      loading: false,
                    });
                  });


              })
              .catch((err) => {
                alert(`Connection Issue. Can't load Export Review options. Please try again`);
                this.setState({
                  loading: false,
                });
              });

          })
          .catch((err) => {
            alert(`Connection Issue. Can't load CID options. Please try again`);
            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        alert(`Connection Issue with Site Data. Please try again`);
        this.setState({
          loading: false,
        });
      });
  }

  handleSearchChangeSites = (searchText) => {
    this.setState({
      resultsSiteName: this.state.sitesNames.filter(item =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
      )
    });
  }
  handleSearchChangeId = (searchText) => {
    this.setState({
      resultsSiteId: this.state.siteIds.filter(item =>
        item.title.toLowerCase().includes(searchText.toLowerCase())),
      searchText,
      resultsId: this.state.siteIds
    });
  }
  handleSearchSiteSelect = (result) => {
    this.setState({
      loading: true,
      searchSiteName: result.sitetitle,
      searchSiteId: result.siteid,
    });

    fetch(`${config.host_url}/api/pfcm/materials/${result.siteid}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          materialNum: this.transSkToTitle(data),
          materialName: this.transNameToTitle(data)
        });
      })
      .catch((err) => {
        //by p 
        console.log(err);
        this.setState({
          loading: false,
        });
        alert(`Connection Issue with Relevant Materials API. Please try again`);
      });

  }
  handleSearchIdSelect = (result) => {
    this.setState({
      searchSiteId: result.siteid,
      searchSiteName: result.sitetitle,
      loading: false
    });// by p 534 to 540
    fetch(`${config.host_url}/api/materials/${result.title}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          materialNum: this.transSkToTitle(data),
          materialName: this.transNameToTitle(data),
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        alert(`Connection Issue with revelant Materials API. Please try again`);
      });
  }
  handleSearchChangeMatId = (searchText) => {
    if (searchText.length >= 3) {
      this.setState({
        resultsMatId: this.state.materialNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
        searchText,
        mat_newVar: searchText,
        err_matid: ""
      });
    }
    else {
      this.setState({ mat_newVar: searchText });
    }
  }

  handleSearchChangeMatName = (searchText) => {
    if (searchText.length >= 3) {
      this.setState({
        resultsMatName: this.state.materialName.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
        searchText,
        err_matid: "",
        mat_newVar: searchText
      });
    }
    else {
      this.setState({ mat_newVar: searchText });
    }
  }
  handleSearchMatIdSelect = (result) => {
    this.setState({
      searchMatId: result.title,
      searchMatName: result.name,
      buyer: result.buyername
    });
  }
  handleSearchMatNameSelect = (result) => {
    this.setState({
      searchMatName: result.title,
      searchMatId: result.name,
      buyer: result.buyername
    });
  }

  handleSearchSelect = () => {
    this.setState({
      err_siteid: "",
      enableOnSearch: false,
      buyer: "",
      partFamilyCode: "",
      err_partFC: "",
      rdbvalueAutoPO: "",
      rdbvalueConsignment: "",
      rdbvaluePullCandidate: "",
      comments: "",
      pfcModified_TS: "",
      err_cid: "",
      err_pullCandidate: "",
      err_autoPO: "",
      err_consignment: "",
      cidLastName: "",
      partLoB: "",
      enterprise: "",
      siteAttribute1: "",
      siteAttribute2: "",
      siteAttribute3: "",
      siteAttribute4: "",
      exportReview: "",
      cidSelected: "",
      partCOE: "",
      strategySelected: "",
      replenishType: "",
      vmiFlag: "",
      vmiSignStatus: "",
      source: "",
      sourceControl: "",
      partSegmentationCode: "",
      unspsCode: "",
      selectedItem: [],
    });
    if (this.state.searchSiteId === '' && this.state.searchMatId === '' && this.state.mat_newVar === '' && this.state.searchMatName === '') {
      alert("Please enter Mandatory Fields");
      this.setState({ err_siteid: " ", err_matid: " ", loading: false });
    }
    else if (this.state.searchSiteId === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_siteid: " ", loading: false });
    }
    else if (this.state.searchMatId === '' && this.state.mat_newVar === '' && this.state.searchMatName === '') {
      alert("Please enter mandatory Material Fields");
      this.setState({ err_matid: " ", loading: false });
    }
    else if (this.state.searchMatId === '' && this.state.mat_newVar === '' && this.state.searchMatName !== '') {
      this.setState({ loading: true });
      fetch(
        `${config.host_url}/api/pfcm/search/name/${this.state.searchSiteId}/${this.state.searchMatName}`
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            currentData: data,
            enableOnSearch: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          alert(`Could not perform Search!`);
        });
    }
    else {
      let matNum;
      if (this.state.searchMatId !== '') {
        matNum = this.state.searchMatId.toString();
      }
      this.setState({ loading: true });
      fetch(
        `${config.host_url}/api/pfcm/search/${this.state.searchSiteId}/${matNum}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.setState({
            loading: false,
            currentData: data,
            enableOnSearch: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          alert(`could not perform Search!`);
        });
    }
  }

  handleSelectCheckBox = (e) => {
    if (e.length > 0) {
      this.setState({
        selectedItem: e,
        onCheckDisabled: true,
        updateDisabled: false,
        fieldDisabled: true,
        // searchSiteId: e[0].OS_SITE_ID,
        // searchMatId: e[0].MATERIAL_NUM_SK,
        buyer: e[0].BUYER,
        partFamilyCode: e[0].MATERIAL_GROUP,
        pfcModified_TS: e[0].PFC_MODIFIED_DATE,
        cidSelected: e[0].CID,
        cidLastName: e[0].CID_LAST_CODED_NAME,
        comments: e[0].COMPLIANCE_COMMENTS,
        exportReview: e[0].EXPORT_REVIEW,
        partLoB: e[0].PART_LOB,
        enterprise: e[0].ENTERPRISE,
        partCOE: e[0].ADD_UNSPSC_SEGMENT,
        unspsCode: e[0].UNSPSC_CODE,
        siteAttribute1: e[0].SITE_SOURCING_ATTRIUTE_1,
        siteAttribute2: e[0].SITE_SOURCING_ATTRIUTE_2,
        siteAttribute3: e[0].SITE_SOURCING_ATTRIUTE_3,
        siteAttribute4: e[0].SITE_SOURCING_ATTRIUTE_4,
        sourceControl: e[0].SOURCE_CONTROL,
        partSegmentationCode: e[0].PART_SEGMENT_CODE,
        rdbvaluePullCandidate: e[0].PULL_CANDIDATE,
        strategySelected: e[0].ARS_STRATEGY,
        rdbvalueAutoPO: e[0].AUTO_PO,
        rdbvalueConsignment: e[0].CONSIGNMENT,
        replenishType: e[0].REPLENISH_TYPE,
        vmiFlag: e[0].VMI,
        vmiSignStatus: e[0].VMI_SIGN_STATUS,
        source: e[0].SINGLE_SOLE_MULTI_SOURCE,
         multisource: e[0].MULTI_SOURCE_CAPABLE
      });
    }
    else {
      this.setState({
        selectedItem: e,
        updateDisabled: true,
        onCheckDisabled: false,
        // searchSiteId: "",
        // searchMatId: "",
        buyer: "",
        partFamilyCode: "",
        pfcModified_TS: "",
        cidSelected: "",
        cidLastName: "",
        comments: "",
        exportReview: "",
        partLoB: "",
        enterprise: "",
        partCOE: "",
        unspsCode: "",
        siteAttribute1: "",
        siteAttribute2: "",
        siteAttribute3: "",
        siteAttribute4: "",
        sourceControl: "",
        partSegmentationCode: "",
        rdbvaluePullCandidate: "",
        strategySelected: "",
        rdbvalueAutoPO: "",
        rdbvalueConsignment: "",
        replenishType: "",
        vmiFlag: "",
        vmiSignStatus: "",
        source: "",
        multisource:""
      });
    }
  }

  handleUpdateSelect = () => {
    const selected = this.state.selectedItem[0];
    this.setState({
      err_inputspendprice: "",
      err_inputprice: ""
    });
    if (this.state.partFamilyCode === '') {
      alert("Please enter PFC Code");
      this.setState({ err_partFC: " " });
    }
    else if (this.state.cidSelected === '' || this.state.cidSelected === null) {
      alert("Please select CID");
      this.setState({ err_cid: " " });
    }
    else if (this.state.rdbvalueAutoPO === '' || this.state.rdbvalueAutoPO === null) {
      alert(`Please select Auto PO`);
      this.setState({ err_autoPO: " " });
    }
    else if (this.state.rdbvalueConsignment === '' || this.state.rdbvalueConsignment === null) {
      alert(`Please select Consignment Value`);
      this.setState({ err_consignment: " " });
    }
    else if (this.state.rdbvaluePullCandidate === '' || this.state.rdbvaluePullCandidate === null) {
      alert(`Please select Pull Candidate`);
      this.setState({ err_pullCandidate: " " });
    }
    else if (this.state.pfcModified_TS === null) {
      this.setState({ pfcModified_TS: 0 });
    }
    else {
      console.log(this.state.multisource,"ss");
      const update = {
        U_OS_SITE_ID: this.state.searchSiteId,
        U_MATERIAL_NUM_SK: this.state.searchMatId,
        U_BUYER: this.state.buyer,
        U_MATERIAL_GROUP: this.state.partFamilyCode,
        U_PFC_MODIFIED_DATE: this.state.pfcModified_TS,
        U_CID: this.state.cidSelected,
        U_CID_LAST_CODED_NAME: this.state.cidLastName,
        U_COMPLIANCE_COMMENTS: this.state.comments,
        U_EXPORT_REVIEW: this.state.exportReview,
        U_PART_LOB: this.state.partLoB,
        U_ENTERPRISE: this.state.enterprise,
        U_ADD_UNSPSC_SEGMENT: this.state.partCOE,
        U_UNSPSC_CODE: this.state.unspsCode,
        U_SITE_SOURCING_ATTRIUTE_1: this.state.siteAttribute1,
        U_SITE_SOURCING_ATTRIUTE_2: this.state.siteAttribute2,
        U_SITE_SOURCING_ATTRIUTE_3: this.state.siteAttribute3,
        U_SITE_SOURCING_ATTRIUTE_4: this.state.siteAttribute4,
        U_SOURCE_CONTROL: this.state.sourceControl,
        U_PART_SEGMENT_CODE: this.state.partSegmentationCode,
        U_PULL_CANDIDATE: this.state.rdbvaluePullCandidate,
        U_ARS_STRATEGY: this.state.strategySelected,
        U_AUTO_PO: this.state.rdbvalueAutoPO,
        U_CONSIGNMENT: this.state.rdbvalueConsignment,
        U_REPLENISH_TYPE: this.state.replenishType,
        U_VMI: this.state.vmiFlag,
        U_VMI_SIGN_STATUS: this.state.vmiSignStatus,
        U_SINGLE_SOLE_MULTI_SOURCE: this.state.source,
        U_MULTI_SOURCE_CAPABLE: this.state.multisource,
        U_LAST_MODIFIED_DATE: this.getTimeStamp().toString(),
        U_LAST_MODIFIED_USER: this.state.UIDData,
        U_FLAG: 'U',
        D_OS_SITE_ID: selected.OS_SITE_ID,
        D_MATERIAL_NUM_SK: selected.MATERIAL_NUM_SK,
        D_FLAG: 'D'

      };


      this.setState({
        loading: true,
        flg_option: 'U'
      });
      const part_update = JSON.stringify(update);
      fetch(`${config.host_url}/api/pfcm/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: part_update,
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false
          });
          this.handleSearchSelect();
          alert(`Part Record Updated.`);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert(`Could not update record. Please try again`);
          this.resetComponent();
        });
    }
  }

  handleAddSelect = () => {
    this.setState({
      err_inputspendprice: "",
      err_inputprice: ""
    });
    if (this.state.partFamilyCode === '') {
      alert("Please enter PFC Code");
      this.setState({ err_partFC: " " });
    }
    else if (this.state.cidSelected === '' || this.state.cidSelected === null) {
      alert("Please select CID");
      this.setState({ err_cid: " " });
    }
    else if (this.state.rdbvalueAutoPO === '' || this.state.rdbvalueAutoPO === null) {
      alert(`Please select Auto PO`);
      this.setState({ err_autoPO: " " });
    }
    else if (this.state.rdbvalueConsignment === '' || this.state.rdbvalueConsignment === null) {
      alert(`Please select Consignment Value`);
      this.setState({ err_consignment: " " });
    }
    else if (this.state.rdbvaluePullCandidate === '' || this.state.rdbvaluePullCandidate === null) {
      alert(`Please select Pull Candidate`);
      this.setState({ err_pullCandidate: " " });
    }
    else if (this.state.pfcModified_TS === null) {
      this.setState({ pfcModified_TS: 0 });
    }
    else {
      const add = {
        I_OS_SITE_ID: this.state.searchSiteId,
        I_MATERIAL_NUM_SK: this.state.searchMatId,
        I_BUYER: this.state.buyer,
        I_MATERIAL_GROUP: this.state.partFamilyCode,
        I_PFC_MODIFIED_DATE: this.state.pfcModified_TS,
        I_CID: this.state.cidSelected,
        I_CID_LAST_CODED_NAME: this.state.cidLastName,
        I_COMPLIANCE_COMMENTS: this.state.comments,
        I_EXPORT_REVIEW: this.state.exportReview,
        I_PART_LOB: this.state.partLoB,
        I_ENTERPRISE: this.state.enterprise,
        I_ADD_UNSPSC_SEGMENT: this.state.partCOE,
        I_UNSPSC_CODE: this.state.unspsCode,
        I_SITE_SOURCING_ATTRIUTE_1: this.state.siteAttribute1,
        I_SITE_SOURCING_ATTRIUTE_2: this.state.siteAttribute2,
        I_SITE_SOURCING_ATTRIUTE_3: this.state.siteAttribute3,
        I_SITE_SOURCING_ATTRIUTE_4: this.state.siteAttribute4,
        I_SOURCE_CONTROL: this.state.sourceControl,
        I_PART_SEGMENT_CODE: this.state.partSegmentationCode,
        I_PULL_CANDIDATE: this.state.rdbvaluePullCandidate,
        I_ARS_STRATEGY: this.state.strategySelected,
        I_AUTO_PO: this.state.rdbvalueAutoPO,
        I_CONSIGNMENT: this.state.rdbvalueConsignment,
        I_REPLENISH_TYPE: this.state.replenishType,
        I_VMI: this.state.vmiFlag,
        I_VMI_SIGN_STATUS: this.state.vmiSignStatus,
        I_SINGLE_SOLE_MULTI_SOURCE: this.state.source,
        I_MULTI_SOURCE_CAPABLE: this.state.multisource,
        I_LAST_MODIFIED_DATE: this.getTimeStamp().toString(),
        I_LAST_MODIFIED_USER: this.state.UIDData,
        I_FLAG: 'I'
       
      }
      this.setState({
        loading: true,
        flg_option: 'I'
      });
      const part_insert = JSON.stringify(add);
      fetch(`${config.host_url}/api/pfcm/add`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: part_insert,
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false
          });
          this.handleSearchSelect();
          alert(`Part Record Inserted.`);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert(`Could not Insert record. Please try again`);
          this.resetComponent();
        });
    }
  }



  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var selectedfile = e.target.files[0];
    this.setState({ selectedfile }, () => {
      if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "" || this.state.selectedfile === undefined) {
        this.setState({ submitDisabled: true });
      }
      else {
        this.setState({ submitDisabled: false });
      }
    });
  }
  submitFile() {
    if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "") {
      this.setState({ submitDisabled: true });
      alert(`Please select a file!`);
    }
    else {
      this.setState({ loading: true });
      let f = this.state.selectedfile;
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
        /* Update state */
        let filteredData = data.filter(e => e.length);
        //remove header from base array
        filteredData.shift();
        for (let i = 0; i < filteredData.length; i++) {
          //remove unwanted rows
          filteredData[i].splice(0, 2);
          filteredData[i].splice(1, 1);
          filteredData[i].splice(2, 1);
          filteredData[i].splice(3, 1);
          filteredData[i].splice(20, 1);
          //Convert Site ID to string
          filteredData[i].splice(0, 1, '' + filteredData[i][0]);
        }
        // Blank Value Validation 
        let flg_eSiteid = 0;
        let flg_partno = 0;
        let flg_ePcf = 0;
        let flg_eBuyer = 0;
        let flg_eLastModified = 0;
        let excel_err_msg = [];
        let flg_validation = 'F';

        let dup_arr = [];

        // SiteID validation Variables
        let arr_err_siteID = [];
        let arr_err_matID = [];
        let arr_siteID = [];
        let result_siteid = [];
        for (var i in this.state.siteIds) {
          result_siteid.push(this.state.siteIds[i].title);
        }
        for (let i = 0; i < filteredData.length; i++) {
          let excel_siteid = filteredData[i][0];
          let excel_partnum = filteredData[i][1];
          let excel_pcf = filteredData[i][2];
          let excel_planner = filteredData[i][3];
          let excel_lastUser = filteredData[i][26];


          // duplicate
          let arr_dup = [excel_siteid, excel_partnum];
          dup_arr.push(arr_dup);



          //Site ID validation
          if (excel_siteid === '' || excel_siteid === undefined) {
            flg_eSiteid = 1;
            flg_validation = 'T';
          }
          else {
            let val_plant = excel_siteid.toString();
            let cnt_site_match = 0;
            if (result_siteid.includes(val_plant)) {
              arr_siteID.push(val_plant);
              cnt_site_match = 1;
            }
            if (cnt_site_match === 0) {
              arr_err_siteID.push(val_plant);
            }
          }
          if (arr_err_siteID.length > 0) {
            flg_eSiteid = 2;
            flg_validation = 'T';
          }
          if (excel_partnum === '' || excel_partnum === undefined) {
            flg_partno = 1;
            flg_validation = 'T';
          }
          if (excel_pcf === '' || excel_pcf === undefined) {
            flg_ePcf = 1;
            flg_validation = 'T';
          }
          if (excel_planner === '' || excel_planner === undefined) {
            flg_eBuyer = 1;
            flg_validation = 'T';
          }
          if (excel_lastUser === '' || excel_lastUser === undefined) {
            flg_eLastModified = 1;
            flg_validation = 'T';
          }
        }


        let flg_duplicate = 0;
        const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
        console.log(duplicate_count, "dupcount");
        if (duplicate_count > 0) {
          flg_duplicate = 1;
        }
        if (flg_duplicate === 1) {
          alert(`Duplicate Data found in template : 
            Could not Upload the data. Please try again.`);
          this.setState({ loading: false });
        }
        else {


        const js_mat_data = JSON.stringify(arr_siteID);
        // Get Materials for selected Site ID's
        
        fetch(`${config.host_url}/api/pfcm/allmaterials`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: js_mat_data,
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({
              all_mat_data: response
            });
            let result_matid = [];
            
            for (var i in this.state.all_mat_data) {
              result_matid.push((this.state.all_mat_data[i].MATERIAL_NUM_SK).toString());
    
            }
      
           
            for (let i = 0; i < filteredData.length; i++) {
              let val_material = filteredData[i][1].toString();
              console.log(filteredData[i][1].toString());
              let cnt_match = 0;
              if (result_matid.includes(val_material)) {
                cnt_match = 1;
              }
              if (cnt_match === 0) {
                arr_err_matID.push(val_material);
              }
            }
            if (arr_err_matID.length > 0) {
              flg_validation = 'T';
              flg_partno = 2;
            }
            if (flg_validation === 'T') {
              if (flg_eSiteid === 1) {
                excel_err_msg.push("Site ID can't be blank. \n");
              }
              if (flg_eSiteid === 2) {
                excel_err_msg.push("Site ID's not found in Base Table: " + arr_err_siteID);
              }
              if (flg_partno === 1) {
                excel_err_msg.push("Part Number can't be blank. \n");
              }
              if (flg_partno === 2) {
                excel_err_msg.push("Part Number/s not found in Base Table: " + arr_err_matID);
              }
              if (flg_eBuyer === 1) {
                excel_err_msg.push("Part Planner/Buyer can't be blank. \n");
              }
              if (flg_ePcf === 1) {
                excel_err_msg.push("Part Commodity Family(PFC) can't be blank. \n");
              }
              if (flg_eLastModified === 1) {
                excel_err_msg.push("Last Modified User can't be blank. \n");
              }
              alert("Validation Failed : \n" + excel_err_msg);
              this.setState({ loading: false });
            }
            else {
              for (let i = 0; i < filteredData.length; i++) {
                filteredData[i].push(this.getTimeStamp().toString());//Last modified date
                filteredData[i].push('I');
              }
              const js_data = JSON.stringify(filteredData);
              fetch(`${config.host_url}/api/pfcm/massupload`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: js_data,
              })
                .then((response) => response.json())
                .then((response) => {
                  this.setState({
                    loading: false
                  });
                  let str_msg = JSON.stringify(response[0]);
                  alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  alert(`Could not Upload the data. Please try again.`);
                });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            alert(`Material API data error`);
          });
        } 
      };
      reader.readAsBinaryString(f);
    }
  }

  render() {

    if (this.state.tologin) {
      return <Redirect to= "/LoginRed" />
    }
    return (
      <div>
        <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}>
        </Loader>
        <Grid className="main-body">
          <Grid.Row>
            <Grid.Column width={12}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <label style={{ fontSize: 18 }}>Part/PFC Data Maintenance</label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <Search
                placeholder="Site ID*"
                error={this.state.err_siteid}
                results={this.state.resultsSiteId}
                value={this.state.searchSiteId}
                key={this.state.siteIds}
                onResultSelect={(value) => this.handleSearchIdSelect(value)}
                onSearchChange={(value) => this.handleSearchChangeId(value)}
                style={{ "paddingLeft": 10 }}
                disabled={this.state.onCheckDisabled}
              />
              <Search
                placeholder="Site"
                error={this.state.err_siteid}
                results={this.state.resultsSiteName}
                value={this.state.searchSiteName}
                onResultSelect={(value) => this.handleSearchSiteSelect(value)}
                onSearchChange={(value) => this.handleSearchChangeSites(value)}
                style={{ "paddingLeft": 10 }}
                disabled={this.state.onCheckDisabled}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>

              {/* <Input
                label="Part Number*"
                style={{ "paddingLeft": 10 }}
                error={this.state.err_matid}
                value={this.state.searchMatId}
                onChange={(value) => this.setState({ searchMatId: value })
                }
                disabled={this.state.onCheckDisabled}
              /> */}
              <Search
                placeholder="PartNumber*"
                error={this.state.err_matid}
                results={this.state.resultsMatId}
                value={this.state.searchMatId}
                onResultSelect={(value) => this.handleSearchMatIdSelect(value)}
                onSearchChange={(value) => this.handleSearchChangeMatId(value)}
                style={{ "paddingLeft": 10 }}
                disabled={this.state.onCheckDisabled}
              />


              {/* <Input
                label="Part Description"
                style={{ "paddingLeft": 10 }}
                error={this.state.err_matid}
                value={this.state.searchMatName}
                onChange={(value) => this.setState({ searchMatName: value })
                }
                disabled={this.state.onCheckDisabled}
              /> */}
               <Search
                error={this.state.err_matid}
                results={this.state.resultsMatName}
                value={this.state.searchMatName}
                minCharacters={3}
                placeholder="Part Description"
                onResultSelect={(value) =>this.handleSearchMatNameSelect(value)}
                onSearchChange={(value) =>this.handleSearchChangeMatName(value)}
                style={{ "paddingLeft": 10 }}
                disabled={this.state.onCheckDisabled}
              />

            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <div>
                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleSearchSelect} style={{ "float": "left" }} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <Input
                label="Planner/Buyer*"
                value={this.state.buyer}
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="Part Family Code(PFC)*"
                value={this.state.partFamilyCode}
                error={this.state.err_partFC}
                onChange={(value) => this.setState({
                  partFamilyCode: value,
                  pfcModified_TS: this.dateFormat(new Date().toString()),
                })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="CID Last Coded Name"
                value={this.state.cidLastName}
                onChange={(value) => this.setState({ cidLastName: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="Part LOB"
                value={this.state.partLoB}
                onChange={(value) => this.setState({ partLoB: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <Select
                label="CID*"
                options={this.state.cidOptions}
                error={this.state.err_cid}
                value={this.state.cidSelected}
                onChange={(value) => this.setState({ cidSelected: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Export Review"
                options={this.state.erOptions}
                disabled={this.state.enableOnSearch}
                value={this.state.exportReview}
                onChange={(value) => this.setState({ exportReview: value })}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Part COE Code"
                options={this.state.partOptions}
                disabled={this.state.enableOnSearch}
                value={this.state.partCOE}
                onChange={(value) => this.setState({ partCOE: value })}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <Input
                label="Enterprise (HOS Gold)"
                value={this.state.enterprise}
                onChange={(value) => this.setState({ enterprise: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>

          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <Select
                label="ARS Strategy"
                options={this.state.strategyOptions}
                value={this.state.strategySelected}
                onChange={(value) => this.setState({ strategySelected: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Replenish Type"
                options={this.state.replenishOptions}
                value={this.state.replenishType}
                onChange={(value) => this.setState({ replenishType: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="VMI Flag"
                options={this.state.vmiFlagOptions}
                value={this.state.vmiFlag}
                onChange={(value) => this.setState({ vmiFlag: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="VMI Sign Status"
                options={this.state.vmiStatusOptions}
                disabled={this.state.enableOnSearch}
                value={this.state.vmiSignStatus}
                onChange={(value) => this.setState({ vmiSignStatus: value })}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Source Category"
                options={this.state.sourceOptions}
                disabled={this.state.enableOnSearch}
                value={this.state.source}
                onChange={(value) => this.setState({ source: value })}
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <Select
                label="Multi Source Capable"
                options={this.state.multisourceOptions}
                 disabled={this.state.enableOnSearch}
                value={this.state.multisource}
                onChange={(value) => this.setState({ multisource: value })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <Select
                label="Source Control"
                options={this.state.sourceControlOptions}
                disabled={this.state.enableOnSearch}
                value={this.state.sourceControl}
                onChange={(value) => this.setState({ sourceControl: value })}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Select
                label="Part Segmentation Code"
                options={this.state.partSegmentOptions}
                disabled={this.state.enableOnSearch}
                value={this.state.partSegmentationCode}
                onChange={(value) => this.setState({ partSegmentationCode: value })}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="UNSPSC (Part)"
                value={this.state.unspsCode}
                onChange={(value) => this.setState({ unspsCode: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column>
              <TextArea
                label="Compliance Comments"
                value={this.state.comments}
                onChange={(value) => this.setState({ comments: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>

          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={2}>
              <Input
                label="Site Sourcing Attribute 1"
                value={this.state.siteAttribute1}
                onChange={(value) => this.setState({ siteAttribute1: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="Site Sourcing Attribute 2"
                value={this.state.siteAttribute2}
                onChange={(value) => this.setState({ siteAttribute2: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="Site Sourcing Attribute 3"
                value={this.state.siteAttribute3}
                onChange={(value) => this.setState({ siteAttribute3: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                label="Site Sourcing Attribute 4"
                value={this.state.siteAttribute4}
                onChange={(value) => this.setState({ siteAttribute4: value })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>

          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <b style={{ fontSize: "12" }}>Auto PO*</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="Yes"
                name="RadioGroup"
                error={this.state.err_autoPO}
                checked={this.state.rdbvalueAutoPO === "1"}
                onChange={(value) => this.setState({ rdbvalueAutoPO: "1" })}
                disabled={this.state.enableOnSearch}
              />
              <Radio
                label="No"
                name="RadioGroup"
                error={this.state.err_autoPO}
                checked={this.state.rdbvalueAutoPO === "0"}
                onChange={(value) => this.setState({ rdbvalueAutoPO: "0" })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <b style={{ fontSize: "12" }}>Consignment*</b>&nbsp;&nbsp;
              <Radio
                label="Yes"
                name="RadioGroup"
                error={this.state.err_consignment}
                checked={this.state.rdbvalueConsignment === "1"}
                onChange={(value) => this.setState({ rdbvalueConsignment: "1" })}
                disabled={this.state.enableOnSearch}
              />
              <Radio
                label="No"
                name="RadioGroup"
                error={this.state.err_consignment}
                checked={this.state.rdbvalueConsignment === "0"}
                onChange={(value) => this.setState({ rdbvalueConsignment: "0" })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <b style={{ fontSize: "12" }}>Pull Candidate*</b>&nbsp;
              <Radio
                label="Yes"
                name="RadioGroup"
                error={this.state.err_pullCandidate}
                checked={this.state.rdbvaluePullCandidate === "1"}
                onChange={(value) => this.setState({ rdbvaluePullCandidate: "1" })}
                disabled={this.state.enableOnSearch}
              />
              <Radio
                label="No"
                name="RadioGroup"
                error={this.state.err_pullCandidate}
                checked={this.state.rdbvaluePullCandidate === "0"}
                onChange={(value) => this.setState({ rdbvaluePullCandidate: "0" })}
                disabled={this.state.enableOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>

              <div>
                <Button content="Add" onClick={this.handleAddSelect} disabled={this.state.onCheckDisabled} />
                <Button content="Clear" onClick={this.resetComponent} />
                <Button content="Update" onClick={this.handleUpdateSelect} disabled={this.state.updateDisabled} />
                <label style={{ "paddingLeft": 40 }}> Mass Upload : &nbsp;&nbsp;</label>
                <input type="file" id="file" ref="fileUploader" onChange={this.filePathset.bind(this)} />
                <button onClick={() => { this.submitFile(); }} disabled={this.state.submitDisabled}>Submit File</button>
                <a href={template} download="Part/PFC Template.xlsx" style={{ "paddingLeft": 40 }}>
                  <Button content="Download Template"></Button>
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <div style={{ "paddingBottom": 40 }}>
                <DataTable
                  scrollable={true}
                  scrollWidth='1300px'
                  reorderableColumns={true}
                  resizableColumns={true}
                  loading={false}
                  rows={10}
                  search={true}
                  data={this.state.currentData}
                  selection={this.state.selectedItem}
                  selectionMode="single"
                  onSelectionChange={(e) => { this.handleSelectCheckBox(e) }}
                >
                  <DataTable.Column
                    field="OS_SITE_ID"
                    header="Site ID"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="PLANT_NAME"
                    header="Site Name"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="MATERIAL_NUM_SK"
                    header="Part Number"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="MATERIAL_NAME"
                    header="Part Description"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="BUYER"
                    header="Planner/Buyer"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="MATERIAL_GROUP"
                    header="Part Family Code(PFC)"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="SBU_ABC_CODE"
                    header="SBU ABC Code"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="CID"
                    header="CID"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="CID_LAST_CODED_DATE"
                    header="CID Last Coded Date"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="CID_LAST_CODED_NAME"
                    header="CID Last Coded Name"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COMPLIANCE_COMMENTS"
                    header="Compliance Comments"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="EXPORT_REVIEW"
                    header="Export Review"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="ACT_AMT_USD_MA"
                    header="Part COE Code"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="UNSPSC_CODE"
                    header="UNSPSC (Part)"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_SAVINGS_VAR_USD_MA"
                    header="Invalid PFC"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="PART_FROM_TRANS"
                    header="Part From Transaction"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="SITE_SOURCING_ATTRIUTE_1"
                    header="Site Sourcing Attribute 1"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="SITE_SOURCING_ATTRIUTE_2"
                    header="Site Sourcing Attribute 2"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="SOURCE_CONTROL"
                    header="Source Control"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="MULTI_SOURCE_CAPABLE"
                    header="MULTI_SOURCE_CAPABLE"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="PART_SEGMENT_CODE"
                    header="Part Segmentation Code"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="PULL_CANDIDATE"
                    header="Pull Candidate"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="ARS_STRATEGY"
                    header="ARS Strategy"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Auto PO"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="CONSIGNMENT"
                    header="Consignment"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Replenish Type"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Last Modified Date"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Last Modified User Id"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Last Modified User Name"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="PFC_MODIFIED_DATE"
                    header="Last PFC Update Date"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Last PFC Update User Id"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COST_AVOID"
                    header="Last PFC Update User Name"
                    sortable={true}
                    initialWidth='250px'
                  />
                  <DataTable.Pagination />
                </DataTable>
              </div>
            </Grid.Column>
          </Grid.Row>





        </Grid>

      </div >
    );
  }

}