import React from "react";
//import LogInOut from "./LogInOut";
import { Card, Grid, Loader } from "@scuf/common";
import { NavLink } from "react-router-dom";
import LogInOutLanding from "./LogInOutLanding";
const config = require('../config');
//const host_url=process.env.host_url;
//const host_url = "http://localhost:3000";
export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: {},// this is the body from /user
            showBaseline: false,
            showNonReciept: false,
            showXRef: false,
            showVendor: false,
            showPaymentCal: false,
            showNoAccess: true,
            showReject: false,
            showUserMaint: false,
            showPartPFC: false,
            showSupplier: false,
            showKPI: false,
            showAOT: false,
            showSiteIdMaint: false,
            loading: false
        }
    }
    userPagesList = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.userid = oldObj.USERID;
            newObj.pages = oldObj.PAGE;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/user`, {
            credentials: 'include' // fetch won't send cookies unless you set credentials
        })
            .then(response => response.json())
            .then(response => {
                this.setState(
                    {
                        loading: false,
                        body: response,
                        userData: response
                    })
                if (this.state.userData.sub) {
                    let uiid = this.state.userData.sub.toUpperCase();
                    this.setState({ loading: true });
                    fetch(`${config.host_url}/api/getuser/${uiid}`)
                        .then((res) => res.json())
                        .then((data) => {
                            this.setState({
                                loading: false,
                                userPages: this.userPagesList(data),
                            });
                            console.log("Check:", this.state.userPages);
                            for (var i in this.state.userPages) {
                                if (this.state.userPages[i].pages === 'Non Receipt') {
                                    this.setState({ showNonReciept: true, showNoAccess: false });
                                }
                                else if (this.state.userPages[i].pages === 'Baseline') {
                                    this.setState({ showBaseline: true, showNoAccess: false });
                                }
                                else if (this.state.userPages[i].pages === 'xRef') {
                                    this.setState({ showXRef: true, showNoAccess: false });
                                }
                                else if (this.state.userPages[i].pages === 'vendorMaintenance') {
                                    this.setState({ showVendor: true, showNoAccess: false });
                                }
                                else if (this.state.userPages[i].pages === 'rejectdata') {
                                    this.setState({ showReject: true, showNoAccess: false });
                                }
                                else if (this.state.userPages[i].pages === 'paymentcal') {
                                    this.setState({ showPaymentCal: true, showNoAccess: false })
                                }
                                else if (this.state.userPages[i].pages === 'usermaint') {
                                    this.setState({ showUserMaint: true, showNoAccess: false })
                                }
                                else if (this.state.userPages[i].pages === 'partPFC') {
                                    this.setState({ showPartPFC: true, showNoAccess: false })
                                }
                                else if (this.state.userPages[i].pages === 'SupplierProfile') {
                                    this.setState({ showSupplier: true, showNoAccess: false })
                                }
                                else if (this.state.userPages[i].pages === 'KPI') {
                                    this.setState({ showKPI: true, showNoAccess: false })
                                }
                                else if (this.state.userPages[i].pages === 'AOT') {
                                    this.setState({ showAOT: true, showNoAccess: false })
                                }
                                else if (this.state.userPages[i].pages === 'SiteIdMaint') {
                                    this.setState({ showSiteIdMaint: true, showNoAccess: false })
                                }
                                else {
                                    this.setState({ showNoAccess: true });
                                }
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                loading: false
                            });
                            // console.log(error, "1");
                            alert(`Could not Fetch User ID in Landing1. Please try again`);
                        });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false
                });
                // console.log(error,"2");
                alert(`Could not Fetch User data in Landing. Please try again`);
            });
    }
    render() {
        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={1} style={{ position: "fixed", bgColor: "#303030" }}>
                </Loader>
                <Grid>
                    <Grid.Row>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                    {!this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={6} sWidth={3}>
                                <div style={{ paddingLeft: 40 }}>
                                    <p style={{ fontSize: 30 }}>Sign In</p>
                                    <LogInOutLanding body={this.state.body} uri={`${config.host_url}`} />
                                </div>
                            </Grid.Column>

                            <Grid.Column width={4} sWidth={3}>
                                <div>
                                    <Card style={{ backgroundColor: '#f7f7f7' }}>
                                        <Card.Content>
                                            <p style={{ fontSize: 20 }}><b>P2P Insights</b></p></Card.Content></Card>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    {this.state.showNonReciept && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/NonReceipt" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Non-Receipt Maintenance (Spend/Savings)</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    {this.state.showBaseline && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/BaseLine" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Baseline Prices(Part) Maintenance<br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }


                    {this.state.showXRef && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/xrefDataMaint" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>xRef Data Maintainance<br>
                                            </br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }


                    {this.state.showVendor && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/vendorMaintenance" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Vendor Data Maintainance<br>
                                            </br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    {this.state.showReject && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/rejectData" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Reject Data Maintainance<br>
                                            </br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    {this.state.showPaymentCal && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/paymentCalendar" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Payment Calendar Maintenance <br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }


                    {this.state.showKPI && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/kpimaint" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>KPI Data Maintenance <br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    {this.state.showAOT && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/alwaysontime" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Always On time (AOT) Data Maintenance<br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    {this.state.showSiteIdMaint && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/siteid" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>SITE ID  Maintenance<br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }


                    {this.state.showUserMaint && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/userMaint" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>User Data Maintenance <br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }


                    {this.state.showPartPFC && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/partPFC" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Part/PFC Data Maintenance <br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    {this.state.showSupplier && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <NavLink to="/supplierQuality" activeClassName="active">
                                    <Card>
                                        <Card.Content>
                                            <p style={{ textAlign: "center", fontSize: 16 }}>Supplier Quality Profile<br></br>&nbsp;</p>
                                        </Card.Content>
                                    </Card>
                                </NavLink>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    {this.state.showNoAccess && this.state.body.token &&
                        <Grid.Row>
                            <Grid.Column>
                                <Card>
                                    <Card.Content>
                                        <p>You do not have Access. <br></br>Please Contact the System Adminstrator to request Access.
                                    </p>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </div >
        )
    }
}