import React from "react";
import { NavLink } from "react-router-dom";
import {Redirect} from 'react-router-dom';
import * as XLSX from "xlsx";
import {
    Button, Card, 
    Loader,
} from "@scuf/common";

import AlwaysOnTime_template from '../resources/AlwaysOnTime.xlsx';
const config = require('../config');
export default class AlwaysOnTime extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            tologin:false,
            submitDisabled: true,
            file: "",
            siteIds: [],
            sitesNames: [],
            vendorSk: []
        }
    }




    transIdToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.PLANT_SK;
            newObj.name = oldObj.PLANT_NAME;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    transSitesToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.PLANT_NAME;
            newObj.id = oldObj.PLANT_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    vendorSkToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.VENDOR_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    getTimeStamp = () => {
        return Math.floor((new Date()).getTime() / 1000);
    }


    resetComponent = () => {
        this.setState({
            loading: false,
            submitDisabled: true,
            file: "",

        });
    }

    validateNumber = (num) => {
        var isValidNum = false;
        if (/^[0-9]*$/.test(num)) { isValidNum = true; }
        return isValidNum;
    }

    //********************************************************************************** */
   

    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/site`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    sitesNames: this.transIdToTitle(data),
                    siteIds: this.transIdToTitle(data),
                });
                fetch(`${config.host_url}/api/aot/vendor`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            vendorSk: this.vendorSkToTitle(data),
                        });
                        //Spend Saving Reason

                        //call user details
                        fetch(`${config.host_url}/user`, {
                            credentials: 'include' // fetch won't send cookies unless you set credentials
                        })
                            .then(response => response.json())
                            .then(response => {
                                this.setState(
                                    {
                                        //body: response,
                                        loading: false,
                                        UIDData: response.sub.toUpperCase()
                                    })
                            })
                            .catch((err) => {
                                alert(`Connection Issue with User Data. Please try again`);
                                this.setState({
                                    loading: false,
                                    // tologin:true,
                                });
                                window.location.href = `${config.host_url}/login`;
                            });
                    })
                    .catch((err) => {
                        alert(`Connection Issue. Can't load Sourcesystem. Please try again`);
                        this.setState({
                            loading: false,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                alert(`Connection Issue. Can't load Site data. Please try again`);
            });

    }
    /**************************************************************************************************** */

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        this.setState({ file }, () => {
            if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " " || this.state.file === undefined) {
                this.setState({ submitDisabled: true });
            }
            else {
                this.setState({ submitDisabled: false });
            }
        });
    }

    submitFile() {
        if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
            this.setState({ submitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({
                loading: true
            });
            let f = this.state.file;
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
                /* Update state */
                let aotFilteredData = data.filter(e => e.length);
                //remove header form base array
                console.log("hi");
                aotFilteredData.shift();
                console.log("hi");
                for (let i = 0; i < aotFilteredData.length; i++) {
                    aotFilteredData[i].splice(0, 1, '' + aotFilteredData[i][0]);//Convert Site to String
                    aotFilteredData[i].splice(1, 1, '' + aotFilteredData[i][1]);//Convert MATERIAL NO to string
                    aotFilteredData[i].splice(2, 1, '' + aotFilteredData[i][2]);//Convert Erp supplier no  to string                    
                    //aotFilteredData[i].splice(3,3);
                    aotFilteredData[i].splice(6, 1, '' + aotFilteredData[i][6]);//created by
                    //Add timestamp        
                    aotFilteredData[i][7] = (this.getTimeStamp().toString());
                    //Add timestamp        
                    aotFilteredData[i][9] = (this.getTimeStamp().toString());
                    aotFilteredData[i][10] = 'I';


                }
                console.log(aotFilteredData, "filter");
                console.log("hi");

                // Blank Value Validation 
                let flg_siteid = 0;
                let flg_materialno = 0;
                let flg_erpsupplierno = 0;
                let flg_early = 0;
                let flg_late = 0;

                let flg_aot = 0;
                let dup_arr = [];
                let excel_err_msg = [];
                let all_site_data = [];
                let all_materialno_data = [];
                let all_erpsupplierno_data = [];
                let flg_validation = 'F';
                for (let i = 0; i < aotFilteredData.length; i++) {
                    let excel_siteid = aotFilteredData[i][0];
                    let excel_materialno = aotFilteredData[i][1];
                    let excel_erpsupplierno = aotFilteredData[i][2];
                    let excel_earlydays = aotFilteredData[i][3];
                    let excel_latedays = aotFilteredData[i][4];
                    let excel_aot = aotFilteredData[i][5];
                    let excel_createdBy = aotFilteredData[i][6];

                    let arr_dup = [excel_siteid, excel_materialno, excel_erpsupplierno];
                    dup_arr.push(arr_dup);

                    if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
                        flg_siteid = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_site_data.push(excel_siteid); console.log(all_site_data, "all");
                    }
                    if (excel_materialno === '' || excel_materialno === undefined || excel_materialno === 'undefined') {
                        flg_materialno = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_materialno_data.push(excel_materialno); console.log(all_materialno_data, "matell");
                    }
                    if (excel_erpsupplierno === '' || excel_erpsupplierno === undefined || excel_erpsupplierno === 'undefined') {
                        flg_erpsupplierno = 1;
                        flg_validation = 'T'
                    }
                    else {
                        all_erpsupplierno_data.push(excel_erpsupplierno); console.log(all_erpsupplierno_data, "allerp sup");
                    }


                    if ((this.validateNumber(excel_earlydays)) && (this.validateNumber(excel_latedays))) {
                       if(excel_aot === '' || excel_aot === undefined || excel_aot === 'undefined' ){
                        aotFilteredData[i][5] = null;}
                        else{ flg_aot = 1;
                            flg_validation ='T';}
                        console.log("early", excel_earlydays);
                    }else{
                        if((excel_earlydays === '' || excel_earlydays === undefined || excel_earlydays === 'undefined') && (excel_latedays === '' || excel_latedays === undefined || excel_latedays === 'undefined') ){
                                aotFilteredData[i][3] = null; 
                                aotFilteredData[i][4] = null; }
                            else { 
                                if(!(this.validateNumber(excel_earlydays))){
                                    flg_early = 1;
                        flg_validation ='T';
                    }
                            if(!(this.validateNumber(excel_latedays))){
                                flg_late = 1;
                        flg_validation ='T';
                    }
                                     
                    }}
                
                    if (aotFilteredData[i][5] === 'NO' || aotFilteredData[i][5] === 'No' || aotFilteredData[i][5] === 'no') {
                        aotFilteredData[i][5] = 0;
                    }
                    else if (aotFilteredData[i][5] === 'YES' || aotFilteredData[i][5] === 'Yes' || aotFilteredData[i][5] === 'yes') {
                        aotFilteredData[i][5] = 1;
                    } else {
                        aotFilteredData[i][5] = null;
                    }

                    if (excel_createdBy === '' || excel_createdBy === undefined || excel_createdBy === 'undefined') {
                        aotFilteredData[i][6] = this.state.UIDData;
                    }
                    console.log(aotFilteredData[i][6], "ex", excel_createdBy);

                } let flg_duplicate = 0;
                const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
                console.log(duplicate_count, "dupcount");
                if (duplicate_count > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }
                else {

                    let arr_err_siteID = [];
                    let arr_excelSiteIds = [];
                    let arr_erpVendor = [];
                    let arr_err_erpVendor = [];
                    let arr_err_materialno = [];
                    let arr_excelmaterialno = [];
                    const js_mat_data = JSON.stringify(all_site_data);
                    fetch(`${config.host_url}/api/allmaterials`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: js_mat_data,
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            this.setState({
                                all_mat_data: response
                            });

                            let result_matid = [];

                            for (var i in this.state.all_mat_data) {
                                result_matid.push(this.state.all_mat_data[i].MATERIAL_NUM_SK);
                            }
                            console.log(result_matid, "result_matid");


                            let allSiteId = [];
                            for (var j in this.state.siteIds) {
                                allSiteId.push((this.state.siteIds[j].title).toString());
                            }
                            let all_vendorSk = [];
                            for (var k in this.state.vendorSk) {
                                all_vendorSk.push((this.state.vendorSk[k].title).toString());
                            }
                            for (let i = 0; i < aotFilteredData.length; i++) {
                                let val_site = aotFilteredData[i][0].toString();
                                let val_material = aotFilteredData[i][1].toString();
                                let val_vendor = aotFilteredData[i][2].toString();

                                let cnt_match_site = 0;
                                let cnt_match_vendor = 0;
                                let cnt_match_material = 0;

                                if (allSiteId.includes(val_site)) {
                                    cnt_match_site = 1;
                                    arr_excelSiteIds.push(val_site);
                                }
                                if (cnt_match_site === 0) {
                                    arr_err_siteID.push(val_site);
                                }
                                if (all_vendorSk.includes(val_vendor)) {
                                    cnt_match_vendor = 1;
                                    arr_erpVendor.push(val_vendor);
                                }
                                if (cnt_match_vendor === 0) {
                                    arr_err_erpVendor.push(val_vendor);
                                }
                                if (result_matid.includes(val_material)) {
                                    cnt_match_material = 1;
                                    arr_excelmaterialno.push(val_material);
                                }
                                if (cnt_match_material === 0) {
                                    arr_err_materialno.push(val_material);
                                }
                            }

                            if (arr_err_siteID.length > 0) {
                                if (arr_err_siteID.includes('undefined')) {
                                    flg_siteid = 1;
                                    flg_validation = 'T';
                                }
                                else {
                                    flg_validation = 'T';
                                    flg_siteid = 2;
                                }
                            }
                            if (arr_err_erpVendor.length > 0) {
                                if (arr_err_erpVendor.includes('undefined')) {
                                    flg_erpsupplierno = 1;
                                    flg_validation = 'T';
                                }
                                else {
                                    flg_validation = 'T';
                                    flg_erpsupplierno = 2;
                                }
                            }
                            if (arr_err_materialno.length > 0) {
                                if (arr_err_materialno.includes('undefined')) {
                                    flg_materialno = 1;
                                    flg_validation = 'T';
                                }
                                else {
                                    flg_validation = 'T';
                                    flg_materialno = 2;
                                }
                            }
                            if (flg_validation === 'T') {
                                if (flg_siteid === 1) {
                                    excel_err_msg.push("Site ID can't be blank. \n");
                                }
                                if (flg_early === 1) {
                                    excel_err_msg.push("Early data days is invalid \n");
                                }if (flg_late === 1) {
                                     excel_err_msg.push("late_Days data is invalid \n");
                                } if (flg_aot === 1) {
                                    excel_err_msg.push("Aot Flag is invalid \n");
                                }
                                if (flg_siteid === 2) {
                                    excel_err_msg.push("Site IDs not found in base table " + arr_err_siteID);
                                }

                                if (flg_erpsupplierno === 1) {
                                    excel_err_msg.push("Vendor local ID can't be blank. \n");
                                }
                                if (flg_erpsupplierno === 2) {
                                    excel_err_msg.push("Vendor local IDs not found in base table " + arr_err_erpVendor);
                                }

                                if (flg_materialno === 1) {
                                    excel_err_msg.push("Material No  can't be blank. \n");
                                }
                                if (flg_materialno === 2) {
                                    excel_err_msg.push("Material No  not found in base table " + arr_err_materialno);
                                }
                                alert("Validation Failed :\n " + excel_err_msg);
                                this.setState({ loading: false });
                            }
                            else {

                                const Aot_data = (JSON.stringify(aotFilteredData));
                                console.log(Aot_data, "erp ven");
                                fetch(`${config.host_url}/api/aot/massupload`, {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: Aot_data,
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        this.setState({
                                            loading: false
                                        });
                                        let str_msg = JSON.stringify(response[0]);
                                        alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                        this.resetComponent();
                                    })
                                    .catch((error) => {
                                        this.setState({ loading: false });
                                        alert(`Could not Upload the data. Please try again.`);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            alert(`Material API data error`);
                        });
                }

            }; reader.readAsBinaryString(f);

        }
    }


    render() {
        if (this.state.tologin) {
            return <Redirect to= "/LoginRed" />
          }
        return (
            <>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }} />

                <Card interactive={true} style={{ paddingBottom: 40 }}>
                    <Card.Content>
                        <div>
                            <label>Bulk Upload Always On Time</label>
                            <p style={{ fontSize: "14" }}><b></b></p>
                            <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
                            <input
                                type="file"
                                id="file"
                                ref="fileUploader"
                                onChange={this.filePathset.bind(this)}
                            />
                            <button onClick={() => { this.submitFile(); }}
                                disabled={this.state.submitDisabled}>Submit File</button>
                            <a href={AlwaysOnTime_template} download="AlwaysOnTime Template.xlsx" style={{ "paddingLeft": 5 }}>
                                <Button content="Download Template"></Button>
                            </a>
                             <NavLink to="/" activeClassName="active">
                            <Button
                                content="Back"

                            /></NavLink>
                        </div>
                    </Card.Content>
                </Card>
            </>
        )
    }
}