import React from "react";
import * as XLSX from "xlsx";
import { Grid, Button, Search, Loader, DatePicker, Input, Select, TextArea, Radio } from "@scuf/common";
import { DataTable } from "@scuf/datatable";
import {Redirect} from 'react-router-dom';
import template from '../resources/Reject_Template.xlsx';
const config = require('../config');
export default class RejectData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tologin:false,
            //Site Variables
            searchSiteId: "",
            searchSiteName: "",
            resultsSiteName: [],
            resultsSiteId: [],
            sitesNames: [],
            siteIds: [],
            //Material Number
            part_Num: [],
            vendorSk: [],
            resultsMatId: [],
            resultsMatName: [],
            materialNum: [],
            materialName: [],
            searchMatName: "",
            searchMatId: "",
            err_siteid: "",
            err_matid: "",
            comments: "",
            err_comment: "",
            purchaseOrder: "",
            mat_newVar: "",
            newVar: "",

            rdbvalue: "ACTIVE",
            rejectQty: "",
            originSelected: "",
            defectSelected: "",
            dispositionSelected: "",
            dateSelected: "",
            submittedQty: "",
            inspectedQty: "",
            rejectStatusSelected: "",


            err_supLocNum: "",
            resultsSupLocNum: [],
            searchSupLocNum: "",
            supLocNum: [],

            //rejectStatusOptions:[],
            rejectOptions: [],
            defectOptions: [],
            dispositionOptions: [],
            err_poNum: "",
            err_rejectQty: "",
            err_submittedQty: "",
            err_inspectedQty: "",
            err_rejectStatusSelected: "",
            err_defectSelected: "",
            err_dateSelected: "",
            err_originSelected: "",
            err_dispositionSelected: "",
            noticeNum: [],
            err_noticeNum: "",
            rejectNum: "",
            searchNoticeNum: "",
            resultsNoticeNum: [],
            callSearchOne: false,
            callSearchTwo: false,
            addDisabled: true,
            updateDisabled: true,
            enableOnSearch: true,
            onCheckDisabled: false,
            selectedfile: "",
            submitDisabled: true,
        };
        this.resetComponent = this.resetComponent.bind(this);
        this.handleSearchSelect = this.handleSearchSelect.bind(this);
        this.handleAddSelect = this.handleAddSelect.bind(this);
        this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    }

    resetComponent = () => {
        this.setState({
            submitDisabled: true,
            currentData: [],
            //selectedItem: [],
            //Site Variables
            searchSiteId: "",
            searchSiteName: "",
            //Material Number
            searchMatName: "",
            searchMatId: "",
            newVar: "",
            rejectNum: "",
            mat_newVar: "",
            err_siteid: "",
            err_matid: "",
            comments: "",
            err_comment: "",
            purchaseOrder: "",
            rejectQty: "",
            submittedQty: "",
            inspectedQty: "",
            onCheckDisabled: false,
            //rejectStatusSelected: "",
            err_supLocNum: "",
            searchSupLocNum: "",
            rdbvalue: "ACTIVE",
            err_poNum: "",
            err_rejectQty: "",
            err_submittedQty: "",
            err_inspectedQty: "",
            defectSelected: "",
            dateSelected: "",
            originSelected: "",
            dispositionSelected: "",

            err_noticeNum: "",
            searchNoticeNum: "",
            resultsNoticeNum: [],
            callSearchOne: false,
            callSearchTwo: false,
            addDisabled: true,
            updateDisabled: true,
            enableOnSearch: true,
            selectedfile: "",
        });
    }
    //-----------------------------Validate Numbers--------------------------
    validateNumber = (num) => {
        var isValidNum = false;
        // if (/^\d{1,8}$/.test(num)) { isValidNum = true; }
        if (/^(\d*\.)?\d+$/.test(num)) { isValidNum = true; }

        return isValidNum;
    }
    validatesNumber = (num) => {
        var isValidNum = false;
        console.log(num,"num");
        if (num === '' || num === null) {
            isValidNum = true;
        }
        else {

            // if (/^\d{1,8}$/.test(num)) { isValidNum = true; }
            if (/^(\d*\.)?\d+$/.test(num)) { isValidNum = true; }
        }

        return isValidNum;
    }
    dateFormat = (date) => {
        const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
        const dt_month = date.slice(4, 7);
        const dt_year = date.slice(11, 15);
        const dt_day = date.slice(8, 10);
        let month = months[dt_month];
        let month_new = '' + month;
        let day = '' + dt_day;
        let year = dt_year;
        return [year, month_new, day].join('-');
    }
    // ExcelDateToJSDate = (serial) => {
    //     //let cal_date = (new Date((num - (25567 + 1)) * 86400 * 1000)).toString();

    //     // var utc_days  = Math.floor(num - 25569);
    //     // var utc_value = utc_days * 86400;                                        
    //     // var date_info = new Date(utc_value * 1000);

    //     // let cal_date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());


    //     // const months = { 'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12' };
    //     // const dt_month = cal_date.slice(4, 7);
    //     // const dt_year = cal_date.slice(11, 15);
    //     // const dt_day = cal_date.slice(8, 10);
    //     // let month = months[dt_month];
    //     // let month_new = '' + month;
    //     // let day = '' + dt_day;
    //     // let year = dt_year;
    //     // return [year, month_new, day].join('-');
    //     var hours = Math.floor((serial % 1) * 24);
    //     var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    //     let d = new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    //     let month = '' + (d.getMonth() + 1);
    //     let day = '' + d.getDate();
    //     let year = d.getFullYear();
    //     if (month.length < 2)
    //       month = '0' + month;
    //     if (day.length < 2)
    //       day = '0' + day;
    //     //console.log([year, month, day]);
    //     return [year, month, day].join('');
    // }

    ExcelDateToJSDate(serial) {

        var hours = Math.floor((serial % 1) * 24);
        var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
        let d = new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        //console.log([year, month, day]);
        return [year, month, day].join('');

    }

    //---------------------------To Title Functions--------------------------

    //site name
    siteIdToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.sitetitle = oldObj.PLANT_NAME;
            newObj.siteid = oldObj.PLANT_SK;
            newObj.title = oldObj.PLANT_SK;
            newObjects.push(newObj);
        }
        //alert(newObjects);
        return newObjects;
    }
    //site ID
    siteNameToTitle = (objects) => {
        const newObjects = [];

        for (let oldObj of objects) {
            const newObj = {};
            newObj.siteid = oldObj.PLANT_SK;
            newObj.sitetitle = oldObj.PLANT_NAME;
            newObj.title = oldObj.PLANT_NAME;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Material Num
    transSkToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.MATERIAL_NUM_SK;
            newObj.name = oldObj.MATERIAL_NAME;
            newObj.id = oldObj.MATERIAL_NUM_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Material Name
    transNameToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.MATERIAL_NAME;
            newObj.name = oldObj.MATERIAL_NUM_SK;
            newObj.id = oldObj.MATERIAL_NUM_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Vendor SK
    transVendorSkToTitle = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.VENDOR_SK;
            newObj.id = oldObj.VENDOR_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transOrigin = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transDefect = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transDisposition = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.text = oldObj.VALUE1;
            newObj.value = oldObj.VALUE1;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    //Translate Notice Num
    transNoticeNum = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.REJECT_NOTICE_NUM;
            newObj.id = oldObj.REJECT_NOTICE_NUM;
            newObjects.push(newObj);
        }
        return newObjects;
    }
    transVendorSk = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.title = oldObj.VENDOR_SK;
            newObj.id = oldObj.VENDOR_SK;
            newObjects.push(newObj);
        }
        return newObjects;
    }
  
    getTimeStamp = () => {
        return Math.floor((new Date()).getTime() / 1000);
    }

    componentDidMount() {
        this.setState({ loading: true });
        fetch(`${config.host_url}/api/site`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    sitesNames: this.siteNameToTitle(data),
                    siteIds: this.siteIdToTitle(data),
                });
                // fetch(`${config.host_url}/api/reject/allvendors`)
                //     .then((res) => res.json())
                //     .then((data) => {
                //         this.setState({
                //             vendorSk: this.transVendorSk(data)
                //         });
                // fetch(`${config.host_url}/api/reject/partnum`)
                //     .then((res) => res.json())
                //     .then((data) => {
                //         this.setState({
                //             part_Num: this.transPartNum(data)
                //         });
                fetch(`${config.host_url}/api/reject/noticenum`)
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            noticeNum: this.transNoticeNum(data)
                        });
                        fetch(`${config.host_url}/api/reject/rejectorigin`)
                            .then((res) => res.json())
                            .then((data) => {
                                this.setState({
                                    rejectOptions: this.transOrigin(data)
                                });
                                fetch(`${config.host_url}/api/reject/primarydefect`)
                                    .then((res) => res.json())
                                    .then((data) => {
                                        this.setState({
                                            defectOptions: this.transDefect(data)
                                        });
                                        fetch(`${config.host_url}/api/reject/disposition`)
                                            .then((res) => res.json())
                                            .then((data) => {
                                                this.setState({
                                                    //loading: false,
                                                    dispositionOptions: this.transDisposition(data)
                                                });
                                                fetch(`${config.host_url}/user`, {
                                                    credentials: 'include' // fetch won't send cookies unless you set credentials
                                                })
                                                    .then(response => response.json())
                                                    .then(response => {
                                                        this.setState(
                                                            {
                                                                UIDData: response.sub.toUpperCase(),
                                                                loading: false
                                                            });
                                                    })
                                                    .catch((err) => {
                                                        alert(`Connection Issue with User Data. Please try again`);
                                                        this.setState({ loading: false,
                                                            // tologin:true,
                                                         });
                                                         window.location.href = `${config.host_url}/login`;
                                                    });
                                            })
                                            .catch((err) => {
                                                alert(`Connection Issue with Disposition Options. Please try again`);
                                                this.setState({ loading: false });
                                            });
                                    })
                                    .catch((err) => {
                                        alert(`Connection Issue with Defect Options. Please try again`);
                                        this.setState({ loading: false });
                                    });
                            })
                            .catch((err) => {
                                alert(`Connection Issue with Reject Origin Options. Please try again`);
                                this.setState({ loading: false });
                            });
                    })
                    .catch((err) => {
                        alert(`Connection Issue with Notice number Please try again`);
                        this.setState({ loading: false });
                    });
                // })
                // .catch((err) => {
                //     alert(`Connection Issue with PART number Please try again`);
                //     this.setState({ loading: false });
                // });
                // })
                // .catch((err) => {
                //     alert(`Connection Issue with local sup number Please try again`);


                //     this.setState({ loading: false });
                // });
            })
            .catch((err) => {
                alert(`Could not fetch Site data. Try again.`);
                this.setState({ loading: false });
            });
    }

    //Site Functions
    handleSearchChangeSites = (searchText) => {
        this.setState({
            resultsSiteName: this.state.sitesNames.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText,
            err_siteid: ""
        });
    }
    handleSearchSiteSelect = (result) => {
        this.setState({
            searchSiteName: result.sitetitle,
            searchSiteId: result.siteid,
            loading: true,
        });
        fetch(`${config.host_url}/api/reject/materials/${result.siteid}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    materialNum: this.transSkToTitle(data),
                    materialName: this.transNameToTitle(data),
                    loading: false
                });
            })
            .catch((err) => {
                alert(`Connection issue, could not fetch revelant Materials API. `);
                this.setState({ loading: false });
            });
    }
    handleSearchChangeId = (searchText) => {
        this.setState({
            resultsSiteId: this.state.siteIds.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText,
            resultsId: this.state.siteIds,
            err_siteid: ""
        });
    }
    handleSearchIdSelect = (result) => {
        this.setState({
            searchSiteId: result.siteid,
            searchSiteName: result.sitetitle,
            loading: true
        });
        fetch(`${config.host_url}/api/reject/materials/${result.siteid}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    materialNum: this.transSkToTitle(data),
                    materialName: this.transNameToTitle(data),
                    loading: false
                });
            })
            .catch((err) => {
                alert(`Connection issue, could not fetch revelant Materials API. `);
                this.setState({ loading: false });
            });
    }
    //Material Functions
    handleSearchChangeMatId = (searchText) => {

        if (searchText.length >= 3) {
            this.setState({
                resultsMatId: this.state.materialNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
                searchText,
                err_matid: "",
                //resultsMatId: _.filter(this.state.materialNum,(id)=>id.title.toLowerCase().includes(searchText.toLowerCase()))
            });
        }
        else {
            this.setState({ mat_newVar: searchText });
        }
    }

    handleSearchChangeMatName = (searchText) => {
        if (searchText.length >= 3) {
            this.setState({
                resultsMatName: this.state.materialName.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
                searchText,
                err_matid: "",
            });
        }
        else {
            this.setState({ mat_newVar: searchText });
        }
    }
    handleSearchMatIdSelect = (result) => {
        console.log(result.title,"iiii",result.name);
        this.setState({
            searchMatId: result.title,
            searchMatName: result.name,
            loading: true
        });
        console.log(this.state.searchMatName);
        fetch(`${config.host_url}/api/reject/vendor/${this.state.searchSiteId}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    supLocNum: this.transVendorSkToTitle(data),
                    loading: false
                });
            })
            .catch((err) => {
                alert(`Connection Issue with Vendor API. Please try again`);
                this.setState({ loading: false });
            });

    }
    handleSearchMatNameSelect = (result) => {
        console.log(result.title,"ii");
        this.setState({
            searchMatName: result.title,
            searchMatId: result.name,
            loading: true,
        });
        console.log(this.state.searchMatName);
        fetch(`${config.host_url}/api/reject/vendor/${this.state.searchSiteId}`)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    supLocNum: this.transVendorSkToTitle(data),
                    loading: false
                });
            })
            .catch((err) => {
                alert(`Connection Issue with Vendor API. Please try again`);
                this.setState({ loading: false });
            });
    }
    //Notice nUmber Functions
    handleSearchChangeNoticeNumId = (searchText) => {
        if (searchText.length >= 2) {
            this.setState({
                resultsNoticeNum: this.state.noticeNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
                searchText,
                newVar: searchText,
                //resultsNoticeNum: _.filter(this.state.noticeNum,(id)=>id.title.toLowerCase().includes(searchText.toLowerCase())
            });
        }
        else {
            this.setState({ newVar: searchText });
        }
    }
    handleSearchNoticeNumSelect = (result) => {
        this.setState({
            //loading: true,
            searchNoticeNum: result.title,
        });
    }
    // Local Supplier Number functions
    handleSearchSupLocSelect = (result) => {
        this.setState({
            searchSupLocNum: result.title
        });
    }
    handleSearchChangeSupLocNum = (searchText) => {
        this.setState({
            resultsSupLocNum: this.state.supLocNum.filter(item => item.title.toLowerCase().includes(searchText.toLowerCase())),
            searchText
        });
    }

    handleCheckBoxSelect = (e) => { //enable update button and disable add button
        if (e.length > 0) {
            this.setState({
                selectedItem: e,
                addDisabled: true,
                updateDisabled: false,
                onCheckDisabled: true,
                searchSiteId: e[0].OS_SITE_ID,
                searchMatId: e[0].MATERIAL_NUM_SK,
                searchNoticeNum: e[0].REJECT_NOTICE_NUM,
                searchSupLocNum: e[0].VENDOR_SK,
                purchaseOrder: e[0].PURCHASE_ORDER,
                rdbvalue: e[0].REJECT_STATUS,
                dateSelected: e[0].INSPECTION_DATE,
                rejectQty: e[0].REJECTED_QTY_BUOM,
                submittedQty: e[0].SUBMITTED_QTY_BUOM,
                inspectedQty: e[0].INSPECTED_QTY_BUOM,
                originSelected: e[0].REJECT_ORIGIN_CODE,
                defectSelected: e[0].DEFECT_CODE,
                dispositionSelected: e[0].DISPOSITION_CODE,
                comments: e[0].COMMENTS
            });
        }
        else {
            this.setState({
                selectedItem: e,
                addDisabled: false,
                updateDisabled: true,
                onCheckDisabled: false,
                searchNoticeNum: "",
                searchSupLocNum: "",
                rdbvalue: "ACTIVE",
                purchaseOrder: "",
                dateSelected: "",
                rejectQty: "",
                submittedQty: "",
                inspectedQty: "",
                originSelected: "",
                defectSelected: "",
                dispositionSelected: "",
                comments: ""
            });
        }
    }
    //search
    handleSearchSelect = () => {
        this.setState({
            updateDisabled: true,
            dateSelected: "",
            comments: "",
            inspectedQty: "",
            purchaseOrder: "",
            date: "",
            rejectQty: "",
            submittedQty: "",
            originSelected: "",
            defectSelected: "",
            dispositionSelected: "",
            err_siteid: "",
            err_matid: "",
            err_noticeNum: "",
            callSearchOne: false,
            callSearchTwo: false,
        });

        if (this.state.searchSiteId === '' && this.state.searchMatId === '' && this.state.mat_newVar === '' && this.state.searchNoticeNum === '') {
            alert("Please enter Mandatory Fields");
            this.setState({ err_siteid: " ", err_matid: " ", err_noticeNum: " ", loading: false });
        }
        else if (this.state.searchSiteId === '' && this.state.searchNoticeNum === '') {
            alert("Please enter Mandatory Site Fields");
            this.setState({ err_siteid: " ", loading: false });
        }
        else if (this.state.searchMatId === '' && this.state.mat_newVar === '' && this.state.searchNoticeNum === '') {
            alert("Please enter mandatory Material Fields");
            this.setState({ err_matid: " ", loading: false });
        }
        else if (this.state.searchSiteId !== '' && this.state.searchNoticeNum !== '' && this.state.searchMatId === '' && this.state.mat_newVar === '') {
            alert("Please enter mandatory Material Fields");
            this.setState({ err_matid: " ", loading: false });
        }
        else if (this.state.searchSiteId === '' && this.state.searchMatId === '' && this.state.mat_newVar === '' && this.state.searchNoticeNum !== '') {
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/reject/search_one/${this.state.searchNoticeNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        loading: false,
                        addDisabled: false,
                        currentData: data,
                        enableOnSearch: false
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    alert(`Could not perform Search. Please try again.`);
                });
        }
        else {
            let matNum;
            if (this.state.searchMatId === '') {
                matNum = this.state.mat_newVar.toString();
            }
            else {
                matNum = this.state.searchMatId.toString();
            }
            this.setState({ loading: true });
            fetch(`${config.host_url}/api/reject/search_two/${(this.state.searchSiteId).toString()}/${matNum}`)
                .then((res) => res.json())
                .then((data) => {
                    this.setState({
                        currentData: data,
                        addDisabled: false,
                        enableOnSearch: false,
                        loading: false
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    alert(`Could not perform Search. Please try again.`);
                });
        }
    }
    //Add record
    handleAddSelect = () => {
        this.setState({
            err_inspectedQty: "",
            err_noticeNum: "",
            err_poNum: "",
            err_purchaseOrder: "",
            err_date: "",
            err_comment: "",
            err_rejectQty: "",
            err_submittedQty: "",
            err_originSelected: "",
            err_defectSelected: "",
            err_dispositionSelected: "",
            flg_option: 'A',
        });
        if (this.state.newVar === '' &&
            this.state.dateSelected === '' &&
            this.state.searchSupLocNum === '' &&
            this.state.comments === '' &&
            this.state.rejectQty === '' &&
            // this.state.inspectedQty === '' &&
            // this.state.submittedQty === '' &&
            this.state.originSelected === '' &&
            this.state.defectSelected === '' &&
            this.state.dispositionSelected === '') {
            this.setState({
                err_noticeNum: " ",
                err_date: " ",
                err_supLocNum: " ",
                err_comment: " ",
                err_rejectQty: " ",
                // err_inspectedQty: " ",
                // err_submittedQty: " ",
                err_originSelected: " ",
                err_defectSelected: " ",
                err_dispositionSelected: " ",
            });
            alert("Please enter mandatory Fields");
        }
        else if (this.state.dateSelected === '' &&
            this.state.searchSupLocNum === '' &&
            this.state.comments === '' &&
            this.state.rejectQty === '' &&
            this.state.originSelected === '' &&
            this.state.defectSelected === '' &&
            this.state.dispositionSelected === ''
        ) {
            this.setState({
                err_date: " ",
                err_supLocNum: " ",
                err_comment: " ",
                err_rejectQty: " ",
                // err_inspectedQty: " ",
                // err_submittedQty: " ",
                err_originSelected: " ",
                err_defectSelected: " ",
                err_dispositionSelected: " ",
            });
            alert("Please enter mandatory Fields");
        }
        else if (this.state.newVar === '') {
            alert("Please enter Reject Notice Number");
            this.setState({ err_noticeNum: " " });
        }
        else if (this.state.dateSelected === '') {
            alert("Please enter Inspection date");
            this.setState({ err_date: " " });
        }
        else if (this.state.searchSupLocNum === '') {
            alert("Please enter Local Supplier Number");
            this.setState({ err_supLocNum: " " });
        }
        else if (this.state.comments === '') {
            alert("Please enter Comments");
            this.setState({ err_comment: " " });
        }
        else if (this.state.rejectQty === '') {
            alert("Please enter Reject Quantity");
            this.setState({ err_rejectQty: " " });
        }
        // else if (this.state.submittedQty === '') {
        //     alert("Please enter Submitted Quantity");
        //     this.setState({ err_submittedQty: " " });
        // }
        // else if (this.state.inspectedQty === '') {
        //     alert("Please enter Inspected Quantity");
        //     this.setState({ err_inspectedQty: " " });
        // }
        else if (this.state.originSelected === '') {
            alert("Please enter Reject origin");
            this.setState({ err_originSelected: " " });
        }
        else if (this.state.defectSelected === '') {
            alert("Please enter Primary Defect faced");
            this.setState({ err_defectSelected: " " });
        }
        else if (this.state.dispositionSelected === '') {
            alert("Please enter Disposition Number");
            this.setState({ err_dispositionSelected: " " });
        }
        else if (this.state.comments === '') {
            alert("Please enter Local Supplier Number");
            this.setState({ err_comment: " " });
        }
        else if (!this.validateNumber(this.state.rejectQty)) {
            alert("Please check digits/format for Reject Quantity");
            this.setState({ err_rejectQty: " " });
        }
        else if (!this.validatesNumber(this.state.submittedQty)) {
            alert("Please check digits/format for Submitted Quantity");
            this.setState({ err_submittedQty: " " });
        }
        else if (!this.validatesNumber(this.state.inspectedQty)) {
            alert("Please check digits/format for Inspected Quantity");
            this.setState({ err_inspectedQty: " " });
        }
        else {
            let rejectNum;
            if (this.state.searchNoticeNum === '') {
                rejectNum = this.state.newVar.toString();
            }
            else {
                rejectNum = this.state.searchNoticeNum.toString();
            }
            let allnoticeno = [];
            for (var j in this.state.noticeNum) {
                allnoticeno.push((this.state.noticeNum[j].title).toString());
            }
            if (allnoticeno.includes(rejectNum)) {

                alert(`Reject Notice Num Already exist
                       Please use fresh`);
                this.resetComponent();
            }
            else {

                let matNum;
                if (this.state.searchMatId === '') {
                    matNum = this.state.mat_newVar.toString();
                }
                else {
                    matNum = this.state.searchMatId.toString();
                }


                if (this.state.submittedQty === "") {
                    this.setState({
                        submittedQty: null,
                    })
                }
                if (this.state.inspectedQty === "") {
                    this.setState({
                        inspectedQty: null,
                    })
                }
                 if(matNum==='' || matNum===null){
                     alert("please enter  mandatory fields");
                 }
                 else{

                const addvalue = {
                    OS_SITE_ID: this.state.searchSiteId,
                    MATERIAL_NUM_SK: matNum,
                    REJECT_NOTICE_NUM: rejectNum,
                    VENDOR_SK: this.state.searchSupLocNum,
                    PURCHASE_ORDER: this.state.purchaseOrder,
                    REJECT_STATUS: this.state.rdbvalue,
                    INSPECTION_DATE: this.dateFormat(this.state.dateSelected.toString()),
                    REJECTED_QTY_BUOM: this.state.rejectQty,
                    SUBMITTED_QTY_BUOM: this.state.submittedQty,
                    INSPECTED_QTY_BUOM: this.state.inspectedQty,
                    REJECT_ORIGIN_CODE: this.state.originSelected,
                    RESPONSIBILITY_CODE: '01-SUPPLIER',
                    DEFECT_CODE: this.state.defectSelected,
                    DISPOSITION_CODE: this.state.dispositionSelected,
                    COMMENTS: this.state.comments,
                    LAST_MODIFIED_DATE: this.getTimeStamp().toString(),
                    LAST_MODIFIED_USER: this.state.UIDData,
                    CREATE_DATE: this.getTimeStamp().toString(),
                    CREATE_USER: this.state.UIDData,
                    FLAG: 'I',
                    REJECT_LOAD_TS: this.getTimeStamp().toString()
                };
                const check = JSON.stringify(addvalue);
                this.setState({
                    loading: true,
                    flg_option: 'A',
                });
                fetch(`${config.host_url}/api/reject/addrecord`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: check,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        this.setState({
                            loading: false,
                            addDisabled: true,
                            updateDisabled: false,
                        });
                        this.handleSearchSelect();
                        alert(`Reject Data Record Added.`);
                        this.resetComponent();
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false
                        });
                        alert(`Could not add data. Please try again`);
                    });
                }
            }
        }
    }
    //Update Record
    handleUpdateSelect = () => {
        const selected = this.state.selectedItem[0];
        this.setState({
            err_rejectQty: "",
            err_inspectedQty: "",
            err_submittedQty: "",
        });
        if (!this.validateNumber(this.state.rejectQty)) {
            alert("Please enter only digits for Rejct Quantity");
            this.setState({ err_rejectQty: " " });
        }
        else if ((!this.validatesNumber(this.state.submittedQty))) {
            alert("Please enter only digits for Submitted Quantity");
            this.setState({ err_submittedQty: " " });
        }

        else if ((!this.validatesNumber(this.state.inspectedQty))) {
            alert("Please enter only digits for Inspected Quantity");
            this.setState({ err_inspectedQty: " " });
        }
        else if (this.state.originSelected === '' || this.state.dispositionSelected=== null) {
            alert("Please enter Reject origin");
            this.setState({ err_originSelected: " " });
        }
        else if (this.state.defectSelected === '' || this.state.dispositionSelected=== null ) {
            alert("Please enter Primary Defect faced" );
            this.setState({ err_defectSelected: " " });
        }
        else if (this.state.dispositionSelected === '' || this.state.dispositionSelected=== null) {
            alert("Please enter Disposition CODE");
            this.setState({ err_dispositionSelected: " " });
        }

        else {
            let date_new = '';
            if (this.dateFormat(this.state.dateSelected.toString()).includes('-undefined-')) {
                date_new = this.state.dateSelected.toString();
            }
            else {
                date_new = this.dateFormat(this.state.dateSelected.toString());
            }
            if (this.state.submittedQty === "") {
                this.setState({
                    submittedQty: null,
                })
            }
            if (this.state.inspectedQty === "") {
                this.setState({
                    inspectedQty: null,
                })
            }
           
           


            const update = {
                U_OS_SITE_ID: this.state.searchSiteId,
                U_MATERIAL_NUM_SK: this.state.searchMatId,
                U_REJECT_NOTICE_NUM: this.state.searchNoticeNum,
                U_VENDOR_SK: this.state.searchSupLocNum,
                U_PURCHASE_ORDER: this.state.purchaseOrder,
                U_REJECT_STATUS: this.state.rdbvalue,
                U_INSPECTION_DATE: date_new,
                U_REJECTED_QTY_BUOM: this.state.rejectQty,
                U_SUBMITTED_QTY_BUOM: this.state.submittedQty,
                U_INSPECTED_QTY_BUOM: this.state.inspectedQty,
                U_REJECT_ORIGIN_CODE: this.state.originSelected,
                U_RESPONSIBILITY_CODE: '01-SUPPLIER',
                U_DEFECT_CODE: this.state.defectSelected,
                U_DISPOSITION_CODE: this.state.dispositionSelected,
                U_COMMENTS: this.state.comments,
                LAST_MODIFIED_DATE: this.getTimeStamp().toString(),
                LAST_MODIFIED_USER: this.state.UIDData,
                U_FLAG: 'U',
                U_REJECT_LOAD_TS: this.getTimeStamp().toString(),
                D_OS_SITE_ID: selected.OS_SITE_ID,
                D_REJECT_NOTICE_NUM: selected.REJECT_NOTICE_NUM,
                D_FLAG: 'D'
            };

            this.setState({
                loading: true,
                flg_option: 'U'
            });

            console.log(update,"update");

            const up= JSON.stringify(update)
            console.log(up,"2");

            fetch(`${config.host_url}/api/reject/updaterecord`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: up,
            })
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        loading: false
                    });
                    this.handleSearchSelect();
                    alert(`Reject Record Updated.`);
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        addDisabled: true
                    });
                    alert(`Could not update record. Please try again`);
                    this.resetComponent();
                });

        }
    }
    //-----------------------------Mass Upload---------------------------
    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        var selectedfile = e.target.files[0];
        this.setState({ selectedfile }, () => {
            if (this.state.selectedfile === "undefined" || this.state.selectedfile === " " || this.state.selectedfile === "" || this.state.selectedfile === undefined) {
                this.setState({ submitDisabled: true });
            }
            else {
                this.setState({ submitDisabled: false });
            }
        });
    }
    submitFile() {
        let f = this.state.selectedfile;
        if (f === "undefined" || f === " " || f === "") {
            this.setState({ submitDisabled: true });
            alert(`Please select a file!`);
        }
        else {
            this.setState({ loading: true });
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false, });
                let filteredData = data.filter(e => e.length);
                filteredData.shift();
                for (let i = 0; i < filteredData.length; i++) {
                    filteredData[i].splice(0, 1);//Removing Transaction Column from template
                    filteredData[i].splice(12, 1);
                    //OS Site ID Convert to string
                    filteredData[i].splice(0, 1, '' + filteredData[i][0]);
                    //reject notice # Convert to string
                    filteredData[i].splice(1, 1, '' + filteredData[i][1]);
                    //part Number
                    filteredData[i].splice(2, 1, '' + filteredData[i][2]);
                    //po Number
                    filteredData[i].splice(3, 1, '' + filteredData[i][3]);
                    // local supplier Convert to string
                    filteredData[i].splice(4, 1, '' + filteredData[i][4]);
                    if (filteredData[i][5] !== undefined)
                        filteredData[i][5] = this.ExcelDateToJSDate(filteredData[i][5]);
                }
                let flg_eSiteId = 0;
                let flg_eRejectNotice = 0;
                let flg_ePartNum = 0;
                let flg_eLocSupNum = 0;
                let flg_eInspectionDate = 0;
                let flg_eSubmittedQty = 0;
                let flg_eInspectedQty = 0;
                let flg_eRejectedQty = 0;
                let flg_eOriginSelected = 0;
                let flg_eRejectSelected = 0;
                let flg_eDispositionSelected = 0;

                let arr_siteID = [];
                let arr_err_siteID = [];
                let result_siteid = [];
                for (var i in this.state.siteIds) {
                    result_siteid.push(this.state.siteIds[i].title);
                }

                let arr_part = [];
                let arr_err_part = [];
                let validate_part = [];
                for (var a in this.state.part_Num) {
                    validate_part.push(this.state.part_Num[a].MATERIAL_NUM_SK);
                }
                //vendor
                let arr_vendor =[];
                let arr_err_vendor = [];
                let validate_vendor = [];
                for (var k in this.state.vendorSk) {
                    validate_vendor.push(this.state.vendorSk[k].title);
                }
                //
                let arr_err_origin = [];
                let validate_origin = [];
                for (var h in this.state.rejectOptions) {
                    validate_origin.push(this.state.rejectOptions[h].text);
                }

                let arr_err_selected = [];
                let validate_selected = [];
                for (a in this.state.defectOptions) {
                    validate_selected.push(this.state.defectOptions[a].text);
                }

                let arr_err_disposition = [];
                let validate_disposition = [];
                for (a in this.state.dispositionOptions) {
                    validate_disposition.push(this.state.dispositionOptions[a].text);
                }

                let flg_eComments = 0;
                let excel_err_msg = [];
                let flg_validation = 'F';

                let dup_arr = [];
                let arr_err_date = [];
                let arr_err_rejectedqty = [];
                let arr_err_submittedqty = [];
                let arr_err_inspectedqty = [];
                for (let i = 0; i < filteredData.length; i++) {
                    let excel_siteid = filteredData[i][0];
                    let excel_RejectNotice = filteredData[i][1];
                    let excel_PartNum = filteredData[i][2];
                    let excel_LocSupNum = filteredData[i][4];
                    let excel_InspectionDate = filteredData[i][5];
                    let excel_SubmittedQty = filteredData[i][6];
                    let excel_InspectedQty = filteredData[i][7];
                    let excel_RejectedQty = filteredData[i][8];
                    let excel_OriginSelected = filteredData[i][9];
                    let excel_DefectSelected = filteredData[i][10];
                    let excel_DispositionSelected = filteredData[i][11];
                    let excel_RejectComments = filteredData[i][12];

                    // duplicate
                    let arr_dup = [excel_siteid, excel_RejectNotice];
                    dup_arr.push(arr_dup);

                    //site id validation
                    if (excel_siteid === '' || excel_siteid === undefined || excel_siteid === 'undefined') {
                        flg_eSiteId = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_plant = excel_siteid.toString();
                        arr_siteID.push(val_plant);
                        let cnt_site_match = 0;
                        for (let j = 0; j < result_siteid.length; j++) {
                            if (val_plant === result_siteid[j].toString()) {
                                cnt_site_match = 1;
                                break;
                            }
                        }
                        if (cnt_site_match === 0) {
                            arr_err_siteID.push(val_plant);
                        }
                    }

                    if (arr_err_siteID.length > 0) {
                        flg_eSiteId = 2;
                        flg_validation = 'T';
                    }

                    if (excel_RejectNotice === '' || excel_RejectNotice === undefined || excel_RejectNotice === 'undefined') {
                        flg_eRejectNotice = 1;
                        flg_validation = 'T';
                    }


                    if (excel_PartNum === '' || excel_PartNum === undefined || excel_PartNum === 'undefined') {
                        flg_ePartNum = 1;
                        flg_validation = 'T';
                    }

                    if (excel_LocSupNum === '' || excel_LocSupNum === undefined || excel_LocSupNum === 'undefined') {
                        flg_eLocSupNum = 1;
                        flg_validation = 'T';
                    }
                    else{
                        let val_vendor = excel_LocSupNum.toString();
                        arr_vendor.push(val_vendor);
                        
                    }
                    // else {
                    //     let val_vendor = excel_LocSupNum.toString();
                    //     let cnt_reason_match = 0;
                    //     for (let j = 0; j < validate_vendor.length; j++) {
                    //         if (val_vendor === validate_vendor[j].toString()) {
                    //             cnt_reason_match = 1;
                    //             break;
                    //         }
                    //     }
                    //     if (cnt_reason_match === 0) {
                    //         arr_err_vendor.push(val_vendor);
                    //     }

                    // }
                    // if (arr_err_vendor.length > 0) {
                    //     flg_eLocSupNum = 2;
                    //     flg_validation = 'T';
                    // }

                    if (excel_OriginSelected === '' || excel_OriginSelected === undefined || excel_OriginSelected === 'undefined') {
                        flg_eOriginSelected = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_origin = excel_OriginSelected.toString();
                        let cnt_reason_match = 0;
                        for (let j = 0; j < validate_origin.length; j++) {
                            if (val_origin === validate_origin[j].toString()) {
                                cnt_reason_match = 1;
                                break;
                            }
                        }
                        if (cnt_reason_match === 0) {
                            arr_err_origin.push(val_origin);
                        }

                    }
                    if (arr_err_origin.length > 0) {
                        flg_eOriginSelected = 2;
                        flg_validation = 'T';
                    }
                    if (excel_DefectSelected === '' || excel_DefectSelected === undefined || excel_DefectSelected === 'undefined') {
                        flg_eRejectSelected = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_defect = excel_DefectSelected.toString();
                        let cnt_reason_match = 0;
                        for (let j = 0; j < validate_selected.length; j++) {
                            if (val_defect === validate_selected[j].toString()) {
                                cnt_reason_match = 1;
                                break;
                            }
                        }
                        if (cnt_reason_match === 0) {
                            arr_err_selected.push(val_defect);
                        }

                    }
                    if (arr_err_selected.length > 0) {
                        flg_eRejectSelected = 2;
                        flg_validation = 'T';
                    }

                    if (excel_DispositionSelected === '' || excel_DispositionSelected === undefined || excel_DispositionSelected === 'undefined') {
                        flg_eDispositionSelected = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let val_dis = excel_DispositionSelected.toString();
                        let cnt_reason_match = 0;
                        for (let j = 0; j < validate_disposition.length; j++) {
                            if (val_dis === validate_disposition[j].toString()) {
                                cnt_reason_match = 1;
                                break;
                            }
                        }
                        if (cnt_reason_match === 0) {
                            arr_err_disposition.push(val_dis);
                        }

                    }
                    if (arr_err_disposition.length > 0) {
                        flg_eDispositionSelected = 2;
                        flg_validation = 'T';
                    }

                    if (excel_RejectComments === '' || excel_RejectComments === undefined || excel_RejectComments === 'undefined') {
                        flg_eComments = 1;
                        flg_validation = 'T';
                    }
                    //Date Validation
                    if (excel_InspectionDate === '' || excel_InspectionDate === undefined || excel_InspectionDate === 'undefined') {
                        flg_eInspectionDate = 1;
                        flg_validation = 'T';
                    }
                    else {
                        let checkDate = this.ExcelDateToJSDate(excel_InspectionDate);

                        if (checkDate === 'NaNNaNNaN') {
                            flg_eInspectionDate = 2;

                            flg_validation = 'T';
                            arr_err_date.push(i + 1);
                        }

                    }
                    //Numeric Value check
                    if (excel_RejectedQty === '' || excel_RejectedQty === undefined || excel_RejectedQty === 'undefined') {
                        flg_eRejectedQty = 1;
                        flg_validation = 'T';
                    }
                    else if (typeof (excel_RejectedQty) != 'string') {
                        if (!(this.validateNumber(excel_RejectedQty))) {
                            flg_eRejectedQty = 2;
                            flg_validation = 'T';
                            arr_err_rejectedqty.push(i + 1);
                        }
                    }
                    // if (excel_SubmittedQty === '' || excel_SubmittedQty === undefined || excel_SubmittedQty === 'undefined') {
                    // flg_eSubmittedQty = 1;
                    // flg_validation = 'T';
                    // }
                    // else if (typeof (excel_SubmittedQty) != 'string') {
                    if (excel_SubmittedQty === '' || excel_SubmittedQty === undefined || excel_SubmittedQty === 'undefined') {
                        filteredData[i][6] = null;
                    }
                    else if (!(this.validateNumber(excel_SubmittedQty))) {
                        flg_eSubmittedQty = 2;
                        flg_validation = 'T';
                        arr_err_submittedqty.push(i + 1);
                    }
                    // }
                    // if (excel_InspectedQty === '' || excel_InspectedQty === undefined || excel_InspectedQty === 'undefined') {
                    //     flg_eInspectedQty = 1;
                    //     // flg_validation = 'T';
                    //     filteredData[i][6]=null;
                    // }
                    // else if (typeof (excel_InspectedQty) != 'string') {
                    if (excel_InspectedQty === '' || excel_InspectedQty === undefined || excel_InspectedQty === 'undefined') {
                        filteredData[i][7] = null;
                    }
                    else if (!(this.validateNumber(excel_InspectedQty))) {
                        flg_eInspectedQty = 2;
                        flg_validation = 'T';
                        arr_err_inspectedqty.push(i + 1);
                    }
                    // }
                }

                let flg_duplicate = 0;
                const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
                console.log(duplicate_count, "dupcount");
                if (duplicate_count > 0) {
                    flg_duplicate = 1;
                }
                if (flg_duplicate === 1) {
                    alert(`Duplicate Data found in template : 
                    Could not Upload the data. Please try again.`);
                    this.setState({ loading: false });
                }
                else {

                    let arr_mat_data = [];
                    arr_mat_data = (arr_siteID);
                    const js_mat_data = JSON.stringify(arr_mat_data);

                    console.log(js_mat_data, "js");

                    // Get Materials for selected Site ID's
                    fetch(`${config.host_url}/api/allmaterials`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: js_mat_data,
                    })
                        .then((response) => response.json())
                        .then((response) => {

                            this.setState({
                                all_mat_data: response
                            });

                            let result_matid = [];

                            for (var i in this.state.all_mat_data) {
                                result_matid.push(this.state.all_mat_data[i].MATERIAL_NUM_SK);
                            }

                            console.log(result_matid, "resuly");


                            for (let i = 0; i < filteredData.length; i++) {
                                let mat_val = filteredData[i][2].toString();
                                let cnt_match = 0;

                                console.log(mat_val, "matval");
                                if (result_matid.includes(mat_val)) {
                                    cnt_match = 1;

                                }
                                if (cnt_match === 0) {
                                    arr_err_part.push(mat_val);

                                } console.log("arr_err_part", arr_err_part);
                            }
                            if (arr_err_part.length > 0) {

                                flg_ePartNum = 2; flg_validation = 'T';
                                // flg_ePartNum = 2;
                            }

                            ///

                            let arr_ven_data = [];
                             arr_ven_data = (arr_vendor);

                              const js_ven_data = JSON.stringify(arr_ven_data);
                            fetch(`${config.host_url}/api/allvendors`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: js_ven_data,
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    this.setState({
                                        all_ven_data: response

                                    });
                                    //Upload Data in case Vendor Validation pass
                                    let result_venid = [];

                                    for (var i in this.state.all_ven_data) {
                                        result_venid.push(this.state.all_ven_data[i].VENDOR_SK);
                                    }


                                    for (let i = 0; i < filteredData.length; i++) {
                                        let val_vendor = filteredData[i][4].toString();
                                        let cnt_match = 0;

                                        for (let j = 0; j < result_venid.length; j++) {
                                            if (val_vendor === result_venid[j].toString()) {
                                                cnt_match = 1;
                                                break;
                                            }
                                        }
                                        if (cnt_match === 0) {
                                            arr_err_vendor.push(val_vendor);
                                        }
                                    }
                                    if (arr_err_vendor.length > 0) {
                                        flg_eLocSupNum = 2;
                                             flg_validation = 'T';
                                    }




                                    if (flg_validation === 'T') {
                                        if (flg_eSiteId === 1) {
                                            excel_err_msg.push("Site ID can't be blank.\n");
                                        }
                                        if (flg_eSiteId === 2) {
                                            excel_err_msg.push("Site ID not found in base table: ", arr_err_siteID);
                                        }
                                        if (flg_eRejectNotice === 1) {
                                            excel_err_msg.push("Reject Notice Number can't be blank.\n");
                                        }
                                        if (flg_ePartNum === 1) {
                                            excel_err_msg.push("Material Can't be blank.\n");
                                        }
                                        if (flg_ePartNum === 2) {
                                            excel_err_msg.push("Material not found in base table:\n", arr_err_part);
                                        }
                                        if (flg_eLocSupNum === 1) {
                                            excel_err_msg.push("Local Supplier Number Can't be blank.\n");
                                        }
                                        if (flg_eLocSupNum === 2) {
                                            excel_err_msg.push("Local Supplier Number not found in base table:\n", arr_err_vendor);
                                        }
                                        if (flg_eInspectionDate === 1) {
                                            excel_err_msg.push("Inspection Date Can't be blank.\n");
                                        }
                                        if (flg_eInspectionDate === 2) {
                                            excel_err_msg.push("\nDate Invalid in row: " + arr_err_date);
                                        }
                                        if (flg_eSubmittedQty === 1) {
                                            excel_err_msg.push("Submitted Qty Can't be blank.\n");
                                        }
                                        if (flg_eSubmittedQty === 2) {
                                            excel_err_msg.push("\nInvalid Submitted Qty in row: " + arr_err_submittedqty);
                                        }
                                        if (flg_eRejectedQty === 1) {
                                            excel_err_msg.push("Rejected Qty Can't be blank.\n");
                                        }
                                        if (flg_eRejectedQty === 2) {
                                            excel_err_msg.push("\nInvalid Rejected Qty in row: " + arr_err_rejectedqty);
                                        }
                                        if (flg_eInspectedQty === 1) {
                                            excel_err_msg.push("Inspected Qty Can't be blank.\n");
                                        }
                                        if (flg_eInspectedQty === 2) {
                                            excel_err_msg.push("\nInvalid Inspected Qty in row: " + arr_err_inspectedqty);
                                        }
                                        if (flg_eOriginSelected === 1) {
                                            excel_err_msg.push("Origin Code Can't be blank.\n");
                                        }
                                        if (flg_eRejectSelected === 1) {
                                            excel_err_msg.push("Defect Code Can't be blank.\n");
                                        }
                                        if (flg_eDispositionSelected === 1) {
                                            excel_err_msg.push("Disposition Code Can't be blank.\n");
                                        }
                                        if (flg_eOriginSelected === 2) {
                                            excel_err_msg.push("Invalid ORIGIN CODE :\n" + arr_err_origin);
                                        }
                                        if (flg_eRejectSelected === 2) {
                                            excel_err_msg.push("Defect Code is Invalid:\n" + arr_err_selected);
                                        }
                                        if (flg_eDispositionSelected === 2) {
                                            excel_err_msg.push("Disposition Code is Invalid:\n" + arr_err_disposition);
                                        }
                                        if (flg_eComments === 1) {
                                            excel_err_msg.push("Comments Can't be blank.\n");
                                        }
                                        alert("Validation Failed : \n" + excel_err_msg);
                                        this.setState({ loading: false });
                                    }
                                    else {
                                        for (let i = 0; i < filteredData.length; i++) {
                                            filteredData[i].push(this.getTimeStamp().toString());//Last modified timestamp
                                            filteredData[i].push(this.state.UIDData);//Last modified User
                                            filteredData[i].push('ACTIVE');//Reject Status
                                            filteredData[i].push('I');//FLag
                                            filteredData[i].push(this.getTimeStamp().toString());//FLag
                                            filteredData[i].push('01-SUPPLIER');//Responsibility Code
                                        }
                                        const js_data_n = JSON.stringify(filteredData);
                                        console.log(js_data_n, "mass");
                                        fetch(`${config.host_url}/api/reject/massupload`, {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: js_data_n,
                                        })
                                            .then((response) => response.json())
                                            .then((response) => {
                                                this.setState({ loading: false });
                                                let str_msg = JSON.stringify(response[0]);
                                                alert(`Bulk Upload Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                                            })
                                            .catch((error) => {
                                                this.setState({ loading: false });
                                                alert(`Could not perform Bulk Upload. Please try again.`);
                                                this.resetComponent();
                                            });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    alert(`Could not fetch Vendor data for validations. Please try again`);
                                });
                        })

                        .catch((error) => {
                            this.setState({ loading: false });
                            alert(`Material API data error`);
                        });
                }
            };
            reader.readAsBinaryString(f);
        }
    }


    resultRenderer(values) {
        return (
            <p style={{ paddingLeft: 10 }}>{values.title}</p>
        )
    }


    render() {
        if (this.state.tologin) {
            return <Redirect to= "/LoginRed" />
          }
        
        return (
            <div>
                <Loader loading={this.state.loading} overlayOpacity={0.2} style={{ position: "fixed", bgColor: "#303030" }}>
                </Loader>
                <Grid className="main-body">
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <label style={{ fontSize: 24 }}>Reject Data Maintenance</label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <Search
                                placeholder="Site ID"
                                error={this.state.err_siteid}
                                results={this.state.resultsSiteId}
                                value={this.state.searchSiteId}
                                key={this.state.siteIds}
                                onResultSelect={(value) => this.handleSearchIdSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeId(value)}
                                resultRenderer={(values) => this.resultRenderer(values)}
                                disabled={this.state.onCheckDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                            <Search
                                placeholder="Site"
                                error={this.state.err_siteid}
                                results={this.state.resultsSiteName}
                                value={this.state.searchSiteName}
                                onResultSelect={(value) => this.handleSearchSiteSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSites(value)}
                                resultRenderer={(values) => this.resultRenderer(values)}
                                disabled={this.state.onCheckDisabled}
                                style={{ "paddingLeft": 10 }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <Search
                                error={this.state.err_matid}
                                results={this.state.resultsMatId}
                                key={this.state.searchMatId}
                                value={this.state.searchMatId}
                                minCharacters={3}
                                placeholder="Material ID"
                                onResultSelect={(value) =>
                                    this.handleSearchMatIdSelect(value)
                                }
                                onSearchChange={(value) =>
                                    this.handleSearchChangeMatId(value)
                                }
                                resultRenderer={(values) => this.resultRenderer(values)}
                                style={{ "paddingLeft": 10 }}
                                disabled={this.state.onCheckDisabled}
                            />
                            <Search
                                error={this.state.err_matid}
                                results={this.state.resultsMatName}
                                value={this.state.searchMatName}
                                minCharacters={3}
                                placeholder="Material Name"
                                onResultSelect={(value) =>
                                    this.handleSearchMatNameSelect(value)
                                }
                                onSearchChange={(value) =>
                                    this.handleSearchChangeMatName(value)
                                }
                                resultRenderer={(values) => this.resultRenderer(values)}
                                style={{ "paddingLeft": 10 }}
                                disabled={this.state.onCheckDisabled}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <Search
                                error={this.state.err_noticeNum}
                                results={this.state.resultsNoticeNum}
                                value={this.state.searchNoticeNum}
                                maxLength={50}
                                placeholder="Reject Notice Number"
                                minCharacters={3}
                                onResultSelect={(value) =>
                                    this.handleSearchNoticeNumSelect(value)
                                }
                                onSearchChange={(value) =>
                                    this.handleSearchChangeNoticeNumId(value)
                                }
                                disabled={this.state.onCheckDisabled}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div>
                                <Button icon="search" content="Search" iconPosition="left"
                                    onClick={this.handleSearchSelect} style={{ "float": "left" }} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <Search
                                placeholder="Local Supplier Number"
                                error={this.state.err_supLocNum}
                                results={this.state.resultsSupLocNum}
                                value={this.state.searchSupLocNum}
                                key={this.state.supLocNum}
                                onResultSelect={(value) => this.handleSearchSupLocSelect(value)}
                                onSearchChange={(value) => this.handleSearchChangeSupLocNum(value)}
                                resultRenderer={(values) => this.resultRenderer(values)}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>

                        <Grid.Column width={12} sWidth={6}>
                            <Input
                                label="PO Number"
                                placeholder="PO Number"
                                maxLength={50}
                                value={this.state.purchaseOrder}
                                onChange={(value) => this.setState({ purchaseOrder: value })}
                                error={this.state.err_purchaseOrder}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <label style={{ "paddingLeft": 10 }}>Reject Status&nbsp;&nbsp;</label>
                            <Radio
                                label="ACTIVE"
                                name="RadioGroup"
                                checked={this.state.rdbvalue === "ACTIVE"}
                                onChange={(value) => this.setState({ rdbvalue: "ACTIVE" })}
                                disabled={this.state.enableOnSearch}
                            />
                            <Radio
                                label="VOID"
                                name="RadioGroup"
                                checked={this.state.rdbvalue === "VOID"}
                                onChange={(value) => this.setState({ rdbvalue: "VOID" })}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <DatePicker
                                label="Inspection Date:"
                                type="date"
                                error={this.state.err_date}
                                value={this.state.dateSelected}
                                placeholder="YYYY/MM/DD"
                                displayFormat="YYYY/MM/DD"
                                showYearSelector={true}
                                onChange={(value) => this.setState({ dateSelected: value })}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Input
                                label="Rejected Qty"
                                error={this.state.err_rejectQty}
                                value={this.state.rejectQty}
                                onChange={(value) => this.setState({ rejectQty: value })}
                                disabled={this.state.enableOnSearch} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Input
                                label="Submitted Qty"
                                error={this.state.err_submittedQty}
                                value={this.state.submittedQty}
                                onChange={(value) => this.setState({ submittedQty: value })}
                                disabled={this.state.enableOnSearch} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Input
                                label="Inspected Qty"
                                error={this.state.err_inspectedQty}
                                value={this.state.inspectedQty}
                                onChange={(value) => this.setState({ inspectedQty: value })}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Select
                                label="Reject Origin"
                                error={this.state.err_originSelected}
                                options={this.state.rejectOptions}
                                value={this.state.originSelected}
                                onChange={(value) => this.setState({ originSelected: value })}
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Select
                                label="Primary Defect:"
                                error={this.state.err_defectSelected}
                                options={this.state.defectOptions}
                                value={this.state.defectSelected}
                                onChange={(value) =>
                                    this.setState({ defectSelected: value })
                                }
                                disabled={this.state.enableOnSearch}
                            />
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Select
                                label="Disposition"
                                error={this.state.err_dispositionSelected}
                                options={this.state.dispositionOptions}
                                value={this.state.dispositionSelected}
                                disabled={this.state.enableOnSearch}
                                onChange={(value) =>
                                    this.setState({ dispositionSelected: value })
                                }
                            />
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Input
                                label="Responsibility:"
                                placeholder="01-SUPPLIER"
                                disabled={true}
                                value={"01-SUPPLIER"}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <TextArea
                                label="Comments"
                                maxLength={1000}
                                error={this.state.err_comment}
                                disabled={this.state.enableOnSearch}
                                placeholder="Comments"
                                value={this.state.comments}
                                onChange={(value) => this.setState({ comments: value })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} sWidth={6}>
                            <div>
                                <Button content="Add" onClick={this.handleAddSelect} disabled={this.state.addDisabled} />
                                <Button content="Clear" onClick={this.resetComponent} />
                                <Button content="Update" onClick={this.handleUpdateSelect} disabled={this.state.updateDisabled} />
                                <label style={{ "paddingLeft": 40 }}> Mass Upload : &nbsp;&nbsp;</label>
                                <input type="file" id="file" ref="fileUploader" onChange={this.filePathset.bind(this)} />
                                <button onClick={() => { this.submitFile(); }} disabled={this.state.submitDisabled}>Submit File</button>
                                <a href={template} download="Reject Data.xlsx" style={{ "paddingLeft": 40 }}>
                                    <Button content="Download Template"></Button>
                                </a>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <div style={{ "paddingBottom": 40 }}>
                                <DataTable
                                    scrollable={true}
                                    scrollWidth='1200px'
                                    reorderableColumns={true}
                                    resizableColumns={true}
                                    loading={false}
                                    data={this.state.currentData}
                                    selectionMode="single"
                                    selection={this.state.selectedItem}
                                    onSelectionChange={
                                        (eve) => {
                                            this.handleCheckBoxSelect(eve)
                                        }
                                    }
                                >
                                    <DataTable.Column
                                        field="OS_SITE_ID"
                                        header="Site"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="MATERIAL_NUM_SK"
                                        header="Material Number"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="REJECT_NOTICE_NUM"
                                        header="Reject Notice Number"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_SK"
                                        header="Local Supplier Number"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_DUNS_NUMBER"
                                        header="Supplier ID"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="VENDOR_FULL_NM"
                                        header="Supplier Name"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="PURCHASE_ORDER"
                                        header="Purchase Order"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="REJECT_STATUS"
                                        header="Reject Status"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="INSPECTION_DATE"
                                        header="Inspection Date"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="REJECTED_QTY_BUOM"
                                        header="Rejected Qty"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="SUBMITTED_QTY_BUOM"
                                        header="Submitted Qty"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="INSPECTED_QTY_BUOM"
                                        header="Inspected Qty"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="REJECT_ORIGIN_CODE"
                                        header="Reject Code"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="DEFECT_CODE"
                                        header="Defect Code"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="DISPOSITION_CODE"
                                        header="Disposition Code"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="CREATE_DATE"
                                        header="Created Date"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="CREATE_USER"
                                        header="Created by"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="LAST_MODIFIED_DATE"
                                        header="Last Modified Date"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="LAST_MODIFIED_USER"
                                        header="Last Modified User"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                    <DataTable.Column
                                        field="REJECT_LOAD_TS"
                                        header="Sync Up Date"
                                        sortable={false}
                                        initialWidth='250px'
                                    />
                                </DataTable>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}