import React from "react";
import { SidebarLayout, Header } from '@scuf/common';
import { NavLink } from "react-router-dom";
import LogInOut from './LogInOut';
const config = require('../config');
//const host_url=process.env.host_url;
//const host_url = "http://localhost:3000";

export default class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            activeName: 'P2P Insights',
            body: {},// this is the body from /user
            showBaseline: false,
            showNonReciept: false,
            showXRef: false,
            showVendor: false,
            showPaymentCal: false,
            showRejectData: false,
            showLanding: true,
            showUserMaint: false,
            showSignOut: true,
            showPartPFC: false,
            showSupplier: false,
            showKPI: false,
            showAOT: false,
            showSiteIdMaint: false,
            loading: false
        };
        this.handleCollapse = this.handleCollapse.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleOnHeaderTransition = this.handleOnHeaderTransition.bind(this);
    }

    userPagesList = (objects) => {
        const newObjects = [];
        for (let oldObj of objects) {
            const newObj = {};
            newObj.userid = oldObj.USERID;
            newObj.pages = oldObj.PAGE;
            newObjects.push(newObj);
        }
        return newObjects;
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        fetch(`${config.host_url}/user`, {
            credentials: 'include' // won't send cookies unless you set credentials
        })
            .then(response => response.json())
            .then(response => {
                this.setState(
                    {
                        body: response,
                        userData: response,
                        loading: false
                    });
                if (this.state.userData.sub) {
                    let uiid = this.state.userData.sub.toUpperCase();
                    fetch(`${config.host_url}/api/getuser/${uiid}`)
                        .then((res) => res.json())
                        .then((data) => {
                            this.setState({
                                userPages: this.userPagesList(data),
                            });
                            for (var i in this.state.userPages) {
                                if (this.state.userPages[i].pages === 'Non Receipt') {
                                    this.setState({ showNonReciept: true });
                                }
                                else if (this.state.userPages[i].pages === 'Baseline') {
                                    this.setState({ showBaseline: true });
                                }
                                else if (this.state.userPages[i].pages === 'xRef') {
                                    this.setState({ showXRef: true });
                                }
                                else if (this.state.userPages[i].pages === 'vendorMaintenance') {
                                    this.setState({ showVendor: true });
                                }
                                else if (this.state.userPages[i].pages === 'rejectdata') {
                                    this.setState({ showRejectData: true });
                                }
                                else if (this.state.userPages[i].pages === 'paymentcal') {
                                    this.setState({ showPaymentCal: true });
                                }
                                else if (this.state.userPages[i].pages === 'usermaint') {
                                    this.setState({ showUserMaint: true });
                                }
                                else if (this.state.userPages[i].pages === 'partPFC') {
                                    this.setState({ showPartPFC: true});
                                }
                                else if (this.state.userPages[i].pages === 'SupplierProfile') {
                                    this.setState({ showSupplier: true });
                                }
                                else if (this.state.userPages[i].pages === 'KPI') {
                                    this.setState({ showKPI: true });
                                }
                                else if (this.state.userPages[i].pages === 'AOT') {
                                    this.setState({ showAOT: true });
                                }
                                else if (this.state.userPages[i].pages === 'SiteIdMaint') {
                                    this.setState({ showSiteIdMaint: true });
                                }
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                loading: false
                            });
                            alert(`Could not Fetch User ID side bar. Please try again`);
                        });
                }
                else{
                    for (var i in this.state.userPages) {
                        if (this.state.userPages[i].pages === 'Non Receipt') {
                            this.setState({ showNonReciept: false });
                        }
                        else if (this.state.userPages[i].pages === 'Baseline') {
                            this.setState({ showBaseline: false });
                        }
                        else if (this.state.userPages[i].pages === 'xRef') {
                            this.setState({ showXRef: false });
                        }
                        else if (this.state.userPages[i].pages === 'vendorMaintenance') {
                            this.setState({ showVendor: false });
                        }
                        else if (this.state.userPages[i].pages === 'rejectdata') {
                            this.setState({ showRejectData: false });
                        }
                        else if (this.state.userPages[i].pages === 'paymentcal') {
                            this.setState({ showPaymentCal: false })
                        }
                        else if (this.state.userPages[i].pages === 'usermaint') {
                            this.setState({ showUserMaint: false });
                        }
                        else if (this.state.userPages[i].pages === 'partPFC') {
                            this.setState({ showPartPFC: false });
                        }
                        else if (this.state.userPages[i].pages === 'SupplierProfile') {
                            this.setState({ showSupplier: false });
                        }
                        else if (this.state.userPages[i].pages === 'KPI') {
                            this.setState({ showKPI: false });
                        }
                        else if (this.state.userPages[i].pages === 'AOT') {
                            this.setState({ showAOT: false });
                        }
                        else if (this.state.userPages[i].pages === 'SiteIdMaint') {
                            this.setState({ showSiteIdMaint: true });
                        }
                    }

                }
            }
            )
            .catch((error) => {
                this.setState({
                    loading: false
                });
                alert(`Could not Fetch User data in side bar. Please try again`);
            });
    }
    handleCollapse() {
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    }

    handleItemClick(event, itemProps) {
        const { name } = itemProps;
        this.setState({ activeName: name, collapsed: true });
    }

    handleOnHeaderTransition(data) {
        if (data.collapsed || data.compressed) {
            this.setState({ collapsed: true });
        }
    }

    render() {
        const { collapsed, activeName } = this.state;
        return (
            <div>
                <Header title="P2P Insights" onMenuToggle={this.handleCollapse}>
                    {this.state.showSignOut &&
                        <Header.Item>
                            <Header.UserProfile firstName={this.state.body.given_name} lastName={this.state.body.family_name}>
                                <Header.UserProfile.Item><LogInOut body={this.state.body} uri={`${config.host_url}`} /></Header.UserProfile.Item>
                            </Header.UserProfile>
                        </Header.Item>}
                </Header>
                <SidebarLayout collapsed={collapsed} noIcons={true}>
                    <SidebarLayout.Sidebar>
                        {this.state.showLanding && this.state.body.token &&
                            <NavLink to="/" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Home"
                                    activeName={activeName}
                                    name="Home"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }
                        {this.state.showNonReciept && this.state.body.token &&
                            <NavLink to="/nonreceipt" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Non-Receipt Maintenance (Spend/Savings)"
                                    activeName={activeName}
                                    name="Non-Receipt Maintenance (Spend/Savings)"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }
                        {this.state.showBaseline && this.state.body.token &&
                            <NavLink to="/baseline" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Baseline Prices (Part) Maintenance"
                                    activeName={activeName}
                                    name="Baseline Prices (Part) Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showXRef && this.state.body.token &&
                            <NavLink to="/xrefdatamaint" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="xref Data Maintenance"
                                    activeName={activeName}
                                    name="xref Data Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showVendor && this.state.body.token &&
                            <NavLink to="/vendormaintenance" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Vendor Maintenance Component"
                                    activeName={activeName}
                                    name="Vendor Maintenance Component"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showRejectData && this.state.body.token &&
                            <NavLink to="/rejectdata" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Reject Data Maintenance"
                                    activeName={activeName}
                                    name="Reject Data Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showPartPFC && this.state.body.token &&
                            <NavLink to="/partpfc" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Part/PFC Data Maintenance"
                                    activeName={activeName}
                                    name="Part/PFC Data Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showPaymentCal && this.state.body.token &&
                            <NavLink to="/paymentcalendar" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Payment Calendar"
                                    activeName={activeName}
                                    name="Payment Calendar"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }
                        
                        {this.state.showKPI && this.state.body.token &&
                            <NavLink to="/kpimaint" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="KPI Maintenance"
                                    activeName={activeName}
                                    name="KPI Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showAOT && this.state.body.token &&
                            <NavLink to="/alwaysontime" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="AOT Maintenance"
                                    activeName={activeName}
                                    name="AOT Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }
                         {this.state.showSiteIdMaint && this.state.body.token &&
                            <NavLink to="/siteid" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="Site Id Maintenance"
                                    activeName={activeName}
                                    name="Site Id Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }

                        {this.state.showUserMaint && this.state.body.token &&
                            <NavLink to="/usermaint" activeClassName="active">
                                <SidebarLayout.Sidebar.Item
                                    content="User Maintenance"
                                    activeName={activeName}
                                    name="User Maintenance"
                                    onClick={this.handleItemClick} />
                            </NavLink>
                        }


                    </SidebarLayout.Sidebar>
                    <SidebarLayout.Content>{this.props.children}</SidebarLayout.Content>
                </SidebarLayout>

            </div>
        );
    }
}

