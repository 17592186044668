import React from "react";
import * as XLSX from "xlsx";
import { Grid, Button, Input, Loader, InputLabel,Select } from "@scuf/common";


import XREF_TRANS_TEMPLATE from '../resources/xref_datamaint_Template.xlsx';
import { DataTable } from "@scuf/datatable";
const config = require('../config');
//const host_url=process.env.host_url;
//const host_url = "http://localhost:3000";

export default class SearchComp extends React.Component {
  constructor(props) {

    super(props);

    this.state = {

      xref_data: [],
      currentdata: [],
      sourceSystem: [],
      sourceSystemselected: "",


      err_srcSystem: "",
      srcSystem: "OneSource",
      srcSystemDisabled: true,

      variable: "",
      err_variable: false,
      variableDisabled: false,

      err_transobj: false,
      transobj: "",
      transobjDisabled: false,

      err_value1: false,
      value1: "",
      value1Disabled: false,

      err_value2: false,
      value2: "",
      value2Disabled: false,

      err_value3: false,
      value3: "",
      value3Disabled: false,

      err_value4: false,
      value4: "",
      value4Disabled: false,

      err_value5: false,
      value5: "",
      value5Disabled: false,

      err_combinationset: false,
      combinationset: "",
      combinationsetDisabled: false,

      err_spendTtransform: false,
      spendTtransform: [''],
      spendTtransformDisabled: false,

      err_updatets: false,
      updatets: "",
      updatetsDisabled: false,

      loading: false,

      selectedItem: [],
      updateDisabled: true,
      deleteDisabled: true,
      addDisabled: false,

      SOURCE_SYSTEM: "",
      VARIABLE: "",
      TRANSFORMATION_OBJECT: "",
      VALUE1: "",
      VALUE2: "",
      VALUE3: "",
      VALUE4: "",
      VALUE5: "",
      COMBINATION_SET: "",
      SPENDT_TRANSFORM: "",
      UPDATE_TS: "",
      FLAG: "",
      UIDData: ""

    };

    this.handleUpdateSelect = this.handleUpdateSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.resetComponent = this.resetComponent.bind(this);
    this.handleAddSelect = this.handleAddSelect.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);

  }

  resetComponent = () => {
    this.setState({

      loading: false,
      err_srcSystem: "",
      srcSystem: "OneSource",
      srcSystemDisabled: true,

      variable: "",
      err_variable: false,
      variableDisabled: false,

      err_transobj: false,
      transobj: "",
      transobjDisabled: false,

      err_value1: false,
      value1: "",
      value1Disabled: false,

      err_value2: false,
      value2: "",
      value2Disabled: false,

      err_value3: false,
      value3: "",
      value3Disabled: false,

      err_value4: false,
      value4: "",
      value4Disabled: false,

      err_value5: false,
      value5: "",
      value5Disabled: false,

      err_combinationset: false,
      combinationset: "",
      combinationsetDisabled: false,

      err_spendTtransform: false,
      spendTtransform: [],
      spendTtransformDisabled: false,

      err_updatets: false,
      updatets: "",
      updatetsDisabled: false,

      selectedItem: [],
      updateDisabled: true,
      deleteDisabled: true,
      addDisabled: false,

      currentdata: [],

    });
  }

  getTimeStamp = () => {
    return Math.floor((new Date()).getTime() / 1000);
  }

  // loadxrefData = () => {
  //   this.setState({ loading: true });

  //   fetch(`${config.host_url}/api/getxrefdata`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       this.setState({
  //         loading: false,
  //         xref_data: data
  //       });
  //       // console.log(this.state.xref_data,"xref1");

  //     })
  //     .catch((err) => {
  //       this.setState({
  //         loading: false
  //       });
  //       console.log(err);
  //       alert(`Connection Issue with xref API. Please try again`);
  //     });

  // }

  transSourceSystem = (objects) => {
    const newObjects = [];
    for (let oldObj of objects) {
        const newObj = {};
        newObj.text = oldObj.VALUE1;
        newObj.value = oldObj.VALUE1;
        newObjects.push(newObj);
    }
    return newObjects;
}
  componentDidMount() {


    fetch(`${config.host_url}/api/xref/sourcesystem`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({

          sourceSystem: this.transSourceSystem(data),
        }); 

         fetch(`${config.host_url}/user`, {
          credentials: 'include' // fetch won't send cookies unless you set credentials
         })
          .then(response => response.json())
          .then(response => {
            this.setState(
              {
                //body: response,
                loading: false,
                UIDData: response.sub.toUpperCase()
              })
          })
          .catch((err) => {
            alert(`Connection Issue with User Data. Please try again`);
            this.setState({
              loading: false,
              // tologin:true,
            });
             window.location.href = `${config.host_url}/login`;
          });


      })
      .catch((err) => {
        alert(`Connection Issue with User Data. Please try again`);
        this.setState({
          loading: false,
          // tologin:true,
        });
        // window.location.href = `${config.host_url}/login`;
      });
  }


  handleCheckBoxSelect = (eve) => {
    if (eve.length > 0) {
      this.setState({
        selectedItem: eve,
        updateDisabled: false,
        deleteDisabled: false,
        addDisabled: true,
        sourceSystemselected: eve[0].SOURCE_SYSTEM,
        variable: eve[0].VARIABLE,
        transobj: eve[0].TRANSFORMATION_OBJECT,
        value1: eve[0].VALUE1,
        value2: eve[0].VALUE2,
        value3: eve[0].VALUE3,
        value4: eve[0].VALUE4,
        value5: eve[0].VALUE5,
        combinationset: eve[0].COMBINATION_SET,
        spendTtransform: eve[0].SPENDT_TRANSFORM
      });
    } else {
      this.setState({
        selectedItem: eve,
        updateDisabled: true,
        deleteDisabled: true,
        addDisabled: false,
        sourceSystemselected: "",
        variable: "",
        transobj: "",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        combinationset: "",
        spendTtransform: ""
      });
    }
  }

  handleAddSelect = () => {
    this.setState({
      err_variable: "",
      err_transobj: "",
      err_value1: ""
    });

    if (this.state.variable === '' && this.state.transobj === '' && this.state.value1 === '') {
      this.setState({
        err_variable: " ",
        err_transobj: " ",
        err_value1: " "
      })
      alert("Please enter mandatory Fields");
    } else if (this.state.variable === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_variable: " " });
    } else if (this.state.transobj === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_transobj: " " });
    } else if (this.state.value1 === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_value1: " " });
    }
    else {
      // this.state.spendTtransform=  null;
      // this.state.value2=null;
      // this.state.value3=null;
      // this.state.value4=null;
      // this.state.value5=null;

      this.setState.spendTtransform = [""];


      const addvalue = {
        SOURCE_SYSTEM: this.state.sourceSystemselected,
        VARIABLE: this.state.variable,
        TRANSFORMATION_OBJECT: this.state.transobj,
        VALUE1: this.state.value1,
        VALUE2: this.state.value2,
        VALUE3: this.state.value3,
        VALUE4: this.state.value4,
        VALUE5: this.state.value5,
        COMBINATION_SET: this.state.combinationset,
        SPENDT_TRANSFORM: this.state.spendTtransform,
        FLAG: "I",
        UPDATE_TS: this.getTimeStamp().toString(),
        UPDATED_BY: this.state.UIDData
      };

      this.setState({
        loading: true
      });

      console.log(addvalue, "add");
      fetch(`${config.host_url}/api/addxref`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addvalue),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false,
            addDisabled: false,

          });
          alert(`Data Added Successfully!`);
          this.resetComponent();

        })
        .catch((error) => {
          this.setState({
            loading: false,
            addDisabled: false,

          });
          console.error("Error:", error);
          alert(`Connection Issue. Please try again`);
        });
    }
  }

  handleUpdateSelect = () => {
    const selected = this.state.selectedItem[0];
    const update = {
      SOURCE_SYSTEM: this.state.sourceSystemselected,
      U_VARIABLE: this.state.variable,
      U_TRANSFORMATION_OBJECT: this.state.transobj,
      U_VALUE1: this.state.value1,
      U_VALUE2: this.state.value2,
      U_VALUE3: this.state.value3,
      U_VALUE4: this.state.value4,
      U_VALUE5: this.state.value5,
      U_COMBINATION_SET: this.state.combinationset,
      U_SPENDT_TRANSFORM: this.state.spendTtransform,
      U_UPDATED_BY:  this.state.UIDData,
      U_FLAG: "U",
      D_VARIABLE: selected.VARIABLE,
      D_TRANSFORMATION_OBJECT: selected.TRANSFORMATION_OBJECT,
      D_VALUE1: selected.VALUE1,
      D_VALUE2: selected.VALUE2,
      D_VALUE3: selected.VALUE3,
      D_VALUE4: selected.VALUE4,
      D_VALUE5: selected.VALUE5,
      D_COMBINATION_SET: selected.COMBINATION_SET,
      D_SPENDT_TRANSFORM: selected.SPENDT_TRANSFORM,
      D_UPDATED_BY: this.state.UIDData,
      D_FLAG: "D",
      UPDATE_TS: this.getTimeStamp().toString()
    };
    this.setState({
      loading: true
    });

    console.log(update, "update");
    fetch(`${config.host_url}/api/updatexref`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          loading: false,

        });
        alert(`Data Updated Successfully!`);
        this.resetComponent();

      })
      .catch((error) => {
        this.setState({
          loading: false,
          addDisabled: false
        });
        console.error("Error:", error);
        alert(`Connection Issue. Please try again`);
        this.resetComponent();
      });
  }

  handleDelete = () => {
    const selected = this.state.selectedItem[0];
    const del = {
      SOURCE_SYSTEM: this.state.sourceSystemselected,
      D_VARIABLE: selected.VARIABLE,
      D_TRANSFORMATION_OBJECT: selected.TRANSFORMATION_OBJECT,
      D_VALUE1: selected.VALUE1,
      D_VALUE2: selected.VALUE2,
      D_VALUE3: selected.VALUE3,
      D_VALUE4: selected.VALUE4,
      D_VALUE5: selected.VALUE5,
      D_COMBINATION_SET: selected.COMBINATION_SET,
      D_SPENDT_TRANSFORM: selected.SPENDT_TRANSFORM,
      D_UPDATED_BY: this.state.UIDData,
      D_FLAG: "D",
      D_UPDATE_TS: this.getTimeStamp().toString()
    };
    this.setState({
      loading: true
    });

    fetch(`${config.host_url}/api/deletexref`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(del),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          loading: false
        });
        alert(`Data Deleted Successfully!`);
        this.resetComponent();

      })
      .catch((error) => {
        this.setState({
          loading: false,
          addDisabled: false
        });
        console.error("Error:", error);
        alert(`Connection Issue. Please try again`);
        this.resetComponent();
      });
  }

  handleSearchSelect = () => {
    this.setState({
      err_variable: "",
      err_transobj: "",
      err_value1: ""
    });

    if (this.state.variable === '' && this.state.transobj === '' && this.state.value1 === '') {
      this.setState({
        err_variable: " ",
        err_transobj: " ",
        err_value1: " "
      })
      alert("Please enter mandatory Fields");
    } else if (this.state.variable === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_variable: " " });
    } else if (this.state.transobj === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_transobj: " " });
    } else if (this.state.value1 === '') {
      alert("Please enter mandatory Fields");
      this.setState({ err_value1: " " });
    }
    else {



      const searchvalue = {
        SOURCE_SYSTEM: this.state.sourceSystemselected,
        VARIABLE: this.state.variable,
        TRANSFORMATION_OBJECT: this.state.transobj,
        VALUE1: this.state.value1,
        VALUE2: this.state.value2,
        VALUE3: this.state.value3,
        VALUE4: this.state.value4,
        VALUE5: this.state.value5,
        COMBINATION_SET: this.state.combinationset,
        SPENDT_TRANSFORM: this.state.spendTtransform,
        FLAG: "I",
        UPDATE_TS: this.getTimeStamp().toString(),
        UPDATED_BY: this.state.UIDData
      };

      this.setState({
        loading: true
      });

      console.log(searchvalue, "add");
      fetch(`${config.host_url}/api/searchxref`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(searchvalue),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            loading: false,
            currentdata: response
          });
          // alert(`Data Added Successfully!`);
          // this.resetComponent();
          // this.loadxrefData();
        })
        .catch((error) => {
          this.setState({
            loading: false,
            addDisabled: false,
            updateDisabled: false,
            deleteDisabled: false
          });
          console.error("Error:", error);
          alert(`Connection Issue with xref  Please try again`);
        });
    }

  }


  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    this.setState({ file }, () => {
      if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " " || this.state.file === undefined) {
        this.setState({ submitDisabled: true });
      }
      else {
        this.setState({ submitDisabled: false });
      }
    });
  }

  submitFile() {
    if (this.state.file === "undefined" || this.state.file === "" || this.state.file === " ") {
      this.setState({ submitDisabled: true });
      alert(`Please select a file!`);
    }
    else {
      this.setState({
        loading: true
      });
      let f = this.state.file;
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
        /* Update state */
        let xrefFilteredData = data.filter(e => e.length);
        //remove header form base array
        console.log("hi");
        xrefFilteredData.shift();
        console.log("hi");
        for (let i = 0; i < xrefFilteredData.length; i++) {
          xrefFilteredData[i].splice(0, 1, '' + xrefFilteredData[i][0]);//Convert ONE SOURCE	to String
          xrefFilteredData[i].splice(1, 1, '' + xrefFilteredData[i][1]);//Convert VARIABLE	to String
          xrefFilteredData[i].splice(2, 1, '' + xrefFilteredData[i][2]);//Convert TRANSFORMATION_OBJECT	 to string
          xrefFilteredData[i].splice(3, 1, '' + xrefFilteredData[i][3]);//Convert VALUE1	to string                    
          xrefFilteredData[i].splice(4, 1, '' + xrefFilteredData[i][4]);//Convert VALUE2	  to string                    
          xrefFilteredData[i].splice(5, 1, '' + xrefFilteredData[i][5]);//Convert VALUE3	  to string                    
          xrefFilteredData[i].splice(6, 1, '' + xrefFilteredData[i][6]);//Convert VALUE4	to string                    
          xrefFilteredData[i].splice(7, 1, '' + xrefFilteredData[i][7]);//Convert VALUE5  to string                    
          xrefFilteredData[i].splice(8, 1, '' + xrefFilteredData[i][8]);//Convert COMBINATION_SET   to string                    

          //Add timestamp        
          xrefFilteredData[i][9] = (this.getTimeStamp().toString());
          xrefFilteredData[i][10] = "I";
          xrefFilteredData[i][11] = this.state.UIDData;

        }
        console.log(xrefFilteredData, "filter");

        console.log("hi");

        // Blank Value Validation 
        let flg_variable = 0;
        let flg_transobj = 0;
        let flg_value1 = 0;
       let  flg_sourcesystem= 0;
        // let flg_early = 0;
        // let flg_late = 0;

        // let flg_aot = 0;
        let dup_arr = [];
        let excel_err_msg = [];
        let all_sourcesystem_data = [];
        let all_variable_data = [];
        let all_transobj_data = [];
        let all_value1_data = [];
        let flg_validation = 'F';
         var cnt_match_sourcesystem =0;
         var arr_err_sourcesystem=[];

        var all_sourcesystem = [];
          for (var m in this.state.sourceSystem) {
                all_sourcesystem.push((this.state.sourceSystem[m].text));
}
        for (let i = 0; i < xrefFilteredData.length; i++) {
          let excel_sourcesystem = xrefFilteredData[i][0];
          let excel_variable = xrefFilteredData[i][1];
          let excel_transobj = xrefFilteredData[i][2];
          let excel_value1 = xrefFilteredData[i][3];
          let excel_value2 = xrefFilteredData[i][4];
          let excel_value3 = xrefFilteredData[i][5];
          let excel_value4 = xrefFilteredData[i][6];
          let excel_value5 = xrefFilteredData[i][7];
          let excel_combset = xrefFilteredData[i][8];


           let arr_dup = [excel_sourcesystem, excel_variable, excel_transobj,excel_value1,excel_value2,excel_value3,excel_value4,excel_value5,excel_combset];
           dup_arr.push(arr_dup);

          if (excel_sourcesystem === '' || excel_sourcesystem === undefined || excel_sourcesystem === 'undefined') {
            flg_sourcesystem = 1;
            flg_validation = 'T'
          }
          else {
            all_sourcesystem_data.push(excel_sourcesystem); console.log(all_sourcesystem_data, "all");
            if (all_sourcesystem.includes(excel_sourcesystem)) {
              cnt_match_sourcesystem = 1;
              // arr_sourcesystem.push(excel_sourcesystem);
            }
            if (cnt_match_sourcesystem === 0) {
              flg_sourcesystem=2;
              arr_err_sourcesystem.push(excel_sourcesystem);
            }
          }

          if (excel_variable === '' || excel_variable === undefined || excel_variable === 'undefined') {
            flg_variable = 1;
            flg_validation = 'T'
          }
          else {
            all_variable_data.push(excel_variable); console.log(all_variable_data, "all");
          }
          if (excel_transobj === '' || excel_transobj === undefined || excel_transobj === 'undefined') {
            flg_transobj = 1;
            flg_validation = 'T'
          }
          else {
            all_transobj_data.push(excel_transobj); console.log(all_transobj_data, "matell");
          }
          if (excel_value1 === '' || excel_value1 === undefined || excel_value1 === 'undefined') {
            flg_value1 = 1;
            flg_validation = 'T'
          }
          else {
            all_value1_data.push(excel_value1); console.log(all_value1_data, "allerp sup");
          }

          if (excel_value2 === '' || excel_value2 === undefined || excel_value2 === 'undefined') {
            xrefFilteredData[i][4] = "";
          }

          if (excel_value3 === '' || excel_value3 === undefined || excel_value3 === 'undefined') {
            xrefFilteredData[i][5] = "";
          }

          if (excel_value4 === '' || excel_value4 === undefined || excel_value4 === 'undefined') {
            xrefFilteredData[i][6] = "";
          }

          if (excel_value5 === '' || excel_value5 === undefined || excel_value5 === 'undefined') {
            xrefFilteredData[i][7] = "";
          }


          if (excel_combset === '' || excel_combset === undefined || excel_combset === 'undefined') {
            xrefFilteredData[i][8] = "";
          }


          // if (excel_createdBy === '' || excel_createdBy === undefined || excel_createdBy === 'undefined') {
          //     xrefFilteredData[i][6] = this.state.UIDData;
          // }
          // console.log(xrefFilteredData[i][6], "ex", excel_createdBy);

        }
        let flg_duplicate = 0;
        const duplicate_count = dup_arr.length - new Set(dup_arr.map(JSON.stringify)).size;
        console.log(duplicate_count, "dupcount");
        if (duplicate_count > 0) {
          flg_duplicate = 1;
        }
        if (flg_duplicate === 1) {
          alert(`Duplicate Data found in template : 
                Could not Upload the data. Please try again.`);
          this.setState({ loading: false });
        }
        else {

          // let arr_err_siteID = [];
          // let arr_excelSiteIds = [];
          // let arr_erpVendor = [];
          // let arr_err_erpVendor = [];
          // let arr_err_materialno = [];
          // let arr_excelmaterialno = [];

          if (flg_validation === 'T') {
            if (flg_variable === 1) {
              if (flg_sourcesystem === 1) {
                excel_err_msg.push("SourceSystem can't be blank. \n");
            }

            if (flg_sourcesystem === 2) {
                excel_err_msg.push("Sourcesystem not found in base table " + arr_err_sourcesystem);
            }
              excel_err_msg.push("VARIABLE  can't be blank. \n");
            }
            if (flg_transobj === 1) {
              excel_err_msg.push("TRANSOBJ  can't be blank.  \n");
            } if (flg_value1 === 1) {
              excel_err_msg.push("VALUE1  can't be blank.  \n");
            }

            alert("Validation Failed :\n " + excel_err_msg);
            this.setState({ loading: false });
          }
          else {

            const xref_data = (JSON.stringify(xrefFilteredData));
            console.log(xref_data, "erp ven");
            fetch(`${config.host_url}/api/xref/massupload`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: xref_data,
            })
              .then((response) => response.json())
              .then((response) => {
                this.setState({
                  loading: false
                });
                let str_msg = JSON.stringify(response[0]);
                alert(`Data Uploaded Successfully!\n` + str_msg.substring(1, str_msg.length - 1));
                this.resetComponent();
              })
              .catch((error) => {
                this.setState({ loading: false });
                alert(`Could not Upload the data. Please try again.`);
              });
          }

        }

      }; reader.readAsBinaryString(f);

    }
  }


  render() {
    return (
      <div>
        <Loader loading={this.state.loading} overlayOpacity={0.2}>
        </Loader>
        <Grid className="main-body">
          <Grid.Row>
            <Grid.Column width={12} >
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12} sWidth={6}>
              <label style={{ fontSize: 18 }}>XRef Data Maintenance</label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <InputLabel label="Source System:" />
            </Grid.Column>
            <Grid.Column width={2}>
              {/* <Input
                error={this.state.err_srcSystem}
                indicator="required"
                label=""
                value={this.state.srcSystem}
                onChange={(value) => this.setState({ srcSystem: value })}
                // disabled={this.state.srcSystemDisabled}
              /> */}
               <Select
                                        options={this.state.sourceSystem}
                                        placeholder="Source system"
                                        value={this.state.sourceSystemselected}
                                        onChange={(value) =>
                                            this.setState({ sourceSystemselected: value })
                                        }
                                    />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2} >
              <InputLabel label="Varibale:" />
            </Grid.Column>
            <Grid.Column width={2} >
              <Input
                error={this.state.err_variable}
                indicator="required"
                label=""
                value={this.state.variable}
                onChange={(value) => this.setState({ variable: value })}
                disabled={this.state.variableDisabled}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <InputLabel label="Transformation Object:" />

            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_transobj}
                indicator="required"
                label=""
                value={this.state.transobj}
                onChange={(value) => this.setState({ transobj: value })}
                disabled={this.state.transobjDisabled}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <InputLabel label="Value 1:" />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_value1}
                indicator="required"
                label=""
                value={this.state.value1}
                onChange={(value) => this.setState({ value1: value })}
                disabled={this.state.value1Disabled}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <InputLabel label="Value 2:" />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_value2}
                indicator="required"
                label=""
                value={this.state.value2}
                onChange={(value) => this.setState({ value2: value })}
                disabled={this.state.value2Disabled}
              />

            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={2}>
              <InputLabel label="Value 3:" />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_value3}
                indicator="required"
                label=""
                value={this.state.value3}
                onChange={(value) => this.setState({ value3: value })}
                disabled={this.state.value3Disabled}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <InputLabel label="Value 4:" />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_value4}
                indicator="required"
                label=""
                value={this.state.value4}
                onChange={(value) => this.setState({ value4: value })}
                disabled={this.state.value4Disabled}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <InputLabel label="Value 5:" />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_value5}
                indicator="required"
                label=""
                value={this.state.value5}
                onChange={(value) => this.setState({ value5: value })}
                disabled={this.state.value5Disabled}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2}>
              <InputLabel label="Combination Set:" />
            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_combinationset}
                indicator="required"
                label=""
                value={this.state.combinationset}
                onChange={(value) => this.setState({ combinationset: value })}
                disabled={this.state.combinationsetDisabled}
              />
            </Grid.Column>
            {/* <Grid.Column width={2}>
              <InputLabel label="SpendT Transformation:" />

            </Grid.Column>
            <Grid.Column width={2}>
              <Input
                error={this.state.err_spendTtransform}
                indicator="required"
                label=""
                value={this.state.spendTtransform}
                onChange={(value) => this.setState({ spendTtransform: value })}
                disabled={this.state.spendTtransformDisabled}
              />
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div>
                <Button icon="search" content="Search" iconPosition="left" onClick={this.handleSearchSelect} style={{ "float": "left" }} />
              </div>
            </Grid.Column>


            <Grid.Column width={12}>
              <Button content="Add"
                onClick={this.handleAddSelect}
                disabled={this.state.addDisabled}
              ></Button>
              <Button
                content="Update"
                onClick={this.handleUpdateSelect}
                disabled={this.state.updateDisabled}
              />
              <Button
                content="Delete"
                onClick={this.handleDelete}
                disabled={this.state.deleteDisabled}
              />
              <Button content="Clear" onClick={this.resetComponent} />
            </Grid.Column>
          </Grid.Row>
          <div>
            <br />
            <label style={{ "paddingLeft": 1 }}>Mass Upload : &nbsp;&nbsp;</label>
            <input
              type="file"
              id="file"
              ref="fileUploader"
              onChange={this.filePathset.bind(this)}
            />
            <button onClick={() => { this.submitFile(); }}
              disabled={this.state.submitDisabled}>Submit File</button>
            <a href={XREF_TRANS_TEMPLATE} download="XREF_DATAMAINT_TEMPLATE.xlsx" style={{ "paddingLeft": 5 }}>
              <Button content="Download Template"  ></Button>
            </a>
          </div>
          <Grid.Row>
            <Grid.Column width={12}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <div style={{ "paddingBottom": 5 }}>
                <DataTable
                  scrollable={true}
                  scrollWidth='1450px'
                  reorderableColumns={true}
                  resizableColumns={true}
                  search={true}
                  rows={10}

                  data={this.state.currentdata}
                  selectionMode="single"
                  selection={this.state.selectedItem}
                  onSelectionChange={
                    (eve) => {
                      this.handleCheckBoxSelect(eve)
                    }
                //  onSelectAll={(e) => this.setState({selectedAll: e})}

                //  selectionMode="multiple"
                //     onSelectAll={(e) => this.setState({selectedAll: e})}
                //     onSelectionChange={(e) => this.setState({selectedRow: e})}

                  }
                >
                  <DataTable.Column
                    field="SOURCE_SYSTEM"
                    header="Source System"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="VARIABLE"
                    header="Variable"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="TRANSFORMATION_OBJECT"
                    header="Transformation Object"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="VALUE1"
                    header="Value1"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="VALUE2"
                    header="Value2"
                    sortable={false}
                    initialWidth='250px'

                  />
                  <DataTable.Column
                    field="VALUE3"
                    header="Value3"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="VALUE4"
                    header="Value4"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="VALUE5"
                    header="Value5"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="COMBINATION_SET"
                    header="Combination Set"
                    sortable={false}
                    initialWidth='250px'
                  />
                  {/* <DataTable.Column
                    field="SPENDT_TRANSFORM"
                    header="Spend Transformation"
                    sortable={false}
                    initialWidth='250px'
                  />
                  <DataTable.Column
                    field="UPDATE_TS"
                    header="Update Timestamp"
                    sortable={false}
                    initialWidth='250px'
                  /> */}
                  <DataTable.Pagination />
                </DataTable>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

